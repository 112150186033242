import React from 'react';
import PropTypes from 'prop-types';
import ContainerPaper from 'components/ContainerPaper';
import moment from 'moment';
import { isEmpty, difference } from 'lodash';
import { Helmet } from 'react-helmet';
import Money from 'components/Money';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from '@material-ui/core/Checkbox';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import SortableCells from 'components/SortableCells';
import TableToolbar from 'components/TableToolbar';
import TableFooter from 'components/TableFooter';
import { makeSelectDelegations } from 'containers/App/selectors';

import {
  TableContainerOpenedStyles,
  TableContainerStyles,
} from '../../mui-theme';
import { makeSelectSidebarIsOpen } from '../Layout/selectors';
import { DATE_FORMAT } from '../../utils/constants';
import InvoicesFilter from './InvoicesFilter';
import { ROWS_PER_PAGE_OPTIONS } from './constants';

const styles = () => ({
  rootClosed: TableContainerStyles,
  rootOpened: TableContainerOpenedStyles,
});

class InvoicesTable extends React.Component {
  constructor() {
    super();

    this.state = {
      filterOpen: false,
    };
    this.createSortHandler = this.createSortHandler.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
  }

  toggleFilter() {
    if (this.state.filterOpen) {
      this.props.resetFilter();
    }
    this.setState(state => ({ filterOpen: !state.filterOpen }));
    this.props.setFilter({
      target: {
        name: 'has_no_payments',
        value: 1,
      },
    });
    this.props.setFilter({
      target: {
        name: 'status',
        value: 'ACTIVE',
      },
    });
  }

  createSortHandler(property) {
    return event => {
      const { sortBy, sortDirection } = this.props;
      let direction = 'asc';
      if (property === sortBy) {
        direction = sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.props.setSorting(event, property, direction);
    };
  }

  render() {
    const {
      models,
      classes,
      sortBy,
      sortDirection,
      setPageNumber,
      setRowsPerPage,
      setFilter,
      params,
      sidebarIsOpen,
      selectedInvoices,
      toggleSelection,
      delegations,
    } = this.props;
    const { data, meta, included } = models;

    if (isEmpty(meta)) {
      return null;
    }

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;
    const allChecked =
      difference(data.map(row => row.id), selectedInvoices).length === 0;
    return (
      <ContainerPaper className={rootClass}>
        <Helmet>
          <title>View and Download Invoices</title>
        </Helmet>
        <TableToolbar title="Selected Invoices" onClick={this.toggleFilter} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                padding="checkbox"
                onClick={() =>
                  toggleSelection(data.map(row => row.id), allChecked)
                }
              >
                <Checkbox checked={allChecked} />
              </TableCell>
              <SortableCells
                sortDirection={sortDirection}
                sortBy={sortBy}
                onClick={this.createSortHandler}
                cells={{
                  'First Name': 'first_name',
                  'Last Name': 'last_name',
                  'Invoice Number': 'number',
                  'Invoice Date': 'invoice_date',
                  'Due Date': 'due_date',
                  Delegation: 'delegation',
                  'Year of Fee': 'year',
                  Amount: 'amount',
                }}
              />
            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.filterOpen && (
              <InvoicesFilter params={params} setFilter={setFilter} />
            )}
            {data.map(row => (
              <TableRow
                key={row.id}
                selected={selectedInvoices.includes(row.id)}
              >
                <TableCell
                  padding="checkbox"
                  onClick={() => toggleSelection([row.id])}
                >
                  <Checkbox checked={selectedInvoices.includes(row.id)} />
                </TableCell>
                {included.filter(include => include.type === 'members').map(
                  include =>
                    include.id === String(row.relationships.member.data.id) && (
                      <React.Fragment key={include.id}>
                        <TableCell>{include.attributes.first_name}</TableCell>
                        <TableCell>{include.attributes.last_name}</TableCell>
                      </React.Fragment>
                    ),
                )}
                <TableCell>{row.attributes.number}</TableCell>
                <TableCell>
                  {moment(row.attributes.invoice_date.date).format(DATE_FORMAT)}
                </TableCell>
                <TableCell>
                  {moment(row.attributes.due_date.date).format(DATE_FORMAT)}
                </TableCell>
                <TableCell>
                  {
                    delegations.attributes.delegation.find(
                      delegation =>
                        delegation.column_id ===
                        parseInt(row.relationships.delegation.data.id, 10),
                    ).name
                  }
                </TableCell>
                <TableCell>{row.attributes.year}</TableCell>
                <TableCell>
                  <Money amount={row.attributes.amount} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {data.length ? (
            <TableFooter
              colSpan={12}
              meta={meta}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setRowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            />
          ) : null}
        </Table>
      </ContainerPaper>
    );
  }
}

InvoicesTable.propTypes = {
  delegations: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  resetFilter: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  selectedInvoices: PropTypes.array.isRequired,
  toggleSelection: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  sidebarIsOpen: makeSelectSidebarIsOpen(),
  delegations: makeSelectDelegations(),
});

export default connect(mapStateToProps)(withStyles(styles)(InvoicesTable));
