/*
 *
 * InvoiceGeneratePage actions
 *
 */

import {
  GET_INVOICED_COUNT,
  GET_INVOICED_COUNT_SUCCESS,
  SUBMIT_FORM,
} from './constants';

export function submitForm(payload) {
  return {
    type: SUBMIT_FORM,
    payload,
  };
}

export function getInvoicedCount(year) {
  return {
    type: GET_INVOICED_COUNT,
    year,
  };
}

export function getInvoicedCountSuccess(payload) {
  return {
    type: GET_INVOICED_COUNT_SUCCESS,
    payload,
  };
}
