import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';

import {
  SET_INVOICE_ERROR,
  invoiceErrors,
  SET_PAYMENT_ERROR,
  paymentErrors,
  SET_TRANSACTION_ERROR,
  transactionErrors,
  SET_NOTIFICATION_ERROR,
  notificationErrors,
  SET_UPDATEFEE_ERROR,
  updateFeeErrors,
  SET_UPDATEDUEDATES_ERROR,
  updateDueDatesErrors,
} from './constants';

export const initialState = fromJS({
  invoiceErrors: cloneDeep(invoiceErrors),
  paymentErrors: cloneDeep(paymentErrors),
  transactionErrors: cloneDeep(transactionErrors),
  notificationErrors: cloneDeep(notificationErrors),
  updateFeeErrors: cloneDeep(updateFeeErrors),
  updateDueDatesErrors: cloneDeep(updateDueDatesErrors),
});

const invoicePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_ERROR:
      return state.set('invoiceErrors', action.errors);
    case SET_PAYMENT_ERROR:
      return state.set('paymentErrors', action.errors);
    case SET_TRANSACTION_ERROR:
      return state.set('transactionErrors', action.errors);
    case SET_NOTIFICATION_ERROR:
      return state.set('notificationErrors', action.errors);
    case SET_UPDATEFEE_ERROR:
      return state.set('updateFeeErrors', action.errors);
    case SET_UPDATEDUEDATES_ERROR:
      return state.set('updateDueDatesErrors', action.errors);
    default:
      return state;
  }
};

export default invoicePageReducer;
