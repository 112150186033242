import {
  LOAD_MODELS,
  LOAD_MODELS_SUCCESS,
  SET_MODEL_TYPE,
  SET_PAGE_NUMBER,
  SET_ROWS_PER_PAGE,
  SET_MODEL,
  SET_MODEL_INCLUDES,
  SUBMIT_MODEL,
  SUBMIT_MODEL_SUCCESS,
  SUBMIT_MODEL_ERROR,
  SET_FILTER,
  RESET_FILTER,
  SET_SORTING,
  RESET_REDUCER,
  DELETE_MODEL,
  DELETE_MODEL_SUCCESS,
  SHOW_MODEL,
  SHOW_MODEL_SUCCESS,
  SET_INCLUDES,
} from './constants';

export const resetReducer = () => ({
  type: RESET_REDUCER,
});

export const setModelType = modelType => ({
  type: SET_MODEL_TYPE,
  modelType,
});

export const loadModels = () => ({
  type: LOAD_MODELS,
});

export const loadModelsSuccess = response => ({
  type: LOAD_MODELS_SUCCESS,
  response,
});

export const setPageNumber = (event, page) => ({
  type: SET_PAGE_NUMBER,
  event,
  page,
});

export const setRowsPerPage = event => ({
  type: SET_ROWS_PER_PAGE,
  event,
});

export const setFilter = event => ({
  type: SET_FILTER,
  event,
});

export const resetFilter = filters => ({
  type: RESET_FILTER,
  payload: filters,
});

export const setSorting = (event, property, direction) => ({
  type: SET_SORTING,
  event,
  property,
  direction,
});

export const setModel = model => ({
  type: SET_MODEL,
  model,
});

export const submitModel = () => ({
  type: SUBMIT_MODEL,
});

export const submitModelSuccess = response => ({
  type: SUBMIT_MODEL_SUCCESS,
  response,
});

export const submitModelError = () => ({
  type: SUBMIT_MODEL_ERROR,
});

export const deleteModel = model => ({
  type: DELETE_MODEL,
  model,
});

export const deleteModelSuccess = () => ({
  type: DELETE_MODEL_SUCCESS,
});

export const showModel = modelId => ({
  type: SHOW_MODEL,
  modelId,
});

export const showModelSuccess = model => ({
  type: SHOW_MODEL_SUCCESS,
  model,
});

export const setIncludes = includes => ({
  type: SET_INCLUDES,
  includes,
});

export const setModelIncludes = included => ({
  type: SET_MODEL_INCLUDES,
  included,
});

export const actionPreProcessor = (action, reducerKey) =>
  Object.assign({}, action, { type: `${action.type}/${reducerKey}` });
