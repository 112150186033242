import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { isMobile } from 'react-device-detect';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';

import injectReducer from 'utils/injectReducer';
import {
  makeRolesSelector,
  makeUserSelector,
} from 'containers/AuthButton/selectors';
import reducer from './reducer';
import { setSidebarOpen } from './actions';
import { makeSelectSidebarIsOpen } from './selectors';

export class Layout extends React.Component {
  componentWillMount() {
    this.props.setSidebarOpen(this.props.sidebarIsOpen);
  }

  toggleSidebar = () => {
    this.props.setSidebarOpen(!this.props.sidebarIsOpen);
  };

  render() {
    return (
      <React.Fragment>
        <Header
          toggleSidebar={this.toggleSidebar}
          sidebarIsOpen={this.props.sidebarIsOpen}
          roles={this.props.roles}
          user={this.props.user}
        />
        <Sidebar
          toggleSidebar={this.toggleSidebar}
          sidebarIsOpen={this.props.sidebarIsOpen}
          user={this.props.user}
        />
        <Footer sidebarIsOpen={this.props.sidebarIsOpen} />
      </React.Fragment>
    );
  }
}

Layout.defaultProps = {
  sidebarIsOpen: !isMobile,
};

Layout.propTypes = {
  roles: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  sidebarIsOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  roles: makeRolesSelector(),
  user: makeUserSelector(),
  sidebarIsOpen: makeSelectSidebarIsOpen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    setSidebarOpen: sidebarIsOpen => dispatch(setSidebarOpen(sidebarIsOpen)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'layout', reducer });

export default compose(
  withReducer,
  withConnect,
)(Layout);
