import { cloneDeep } from 'lodash';
import { fromJS } from 'immutable';
import { DEFAULT_ACTION, SET_EVENT_ERROR, eventErrors } from './constants';

export const initialState = fromJS({
  eventErrors: cloneDeep(eventErrors),
});

export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EVENT_ERROR:
      return state.set('eventErrors', action.errors);
    case DEFAULT_ACTION: // Fall-throught
    default:
      return state;
  }
}
