import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import EnumTable from './EnumTable';
import EnumForm from './EnumForm';

const styles = () => ({
  flex: {
    display: 'flex',
  },
});

const EnumPage = ({ classes }) => (
  <div>
    <Helmet>
      <title>EnumPage</title>
      <meta name="description" content="Description of EnumPage" />
    </Helmet>
    <div className={classes.flex}>
      <EnumTable />
      <EnumForm />
    </div>
  </div>
);

EnumPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const withReducer = injectReducer({ key: 'enumPage', reducer });
const withSaga = injectSaga({ key: 'enumPage', saga });

export default compose(
  withReducer,
  withSaga,
)(withStyles(styles)(EnumPage));
