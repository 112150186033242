import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { throttle } from 'lodash';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import {
  makeSelectUserRoles,
  makeSelectDelegations,
} from 'containers/App/selectors';
import ActiveFilter from 'components/ActiveFilter';

const UserFilter = props => {
  const { userRoles, params } = props;

  const options = Object.keys(userRoles.attributes)
    .filter(role => role !== 'AUTHENTICATED_USER')
    .map(role => ({
      value: role,
      label: userRoles.attributes[role],
    }));

  const delegations = props.delegations.attributes.delegation.map(item => ({
    value: item.column_id,
    label: item.name,
  }));

  return (
    <TableRow>
      <TableCell>
        <FormControl>
          <InputLabel htmlFor="search-for-first-name">
            Search for first name
          </InputLabel>
          <Input
            id="search-for-first-name"
            name="first_name"
            onChange={throttle(event => {
              event.persist();
              props.setFilter(event);
            }, 200)}
            startAdornment={
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            }
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl>
          <InputLabel htmlFor="search-for-last-name">
            Search for last name
          </InputLabel>
          <Input
            id="search-for-last-name"
            name="last_name"
            onChange={throttle(event => {
              event.persist();
              props.setFilter(event);
            }, 200)}
            startAdornment={
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            }
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl>
          <InputLabel htmlFor="search-for-username">
            Search for username
          </InputLabel>
          <Input
            id="search-for-username"
            name="name"
            onChange={throttle(event => {
              event.persist();
              props.setFilter(event);
            }, 200)}
            startAdornment={
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            }
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <Select
          isClearable
          defaultValue={params.filter.role ? params.filter.role : null}
          options={options}
          onChange={value => {
            const event = {
              target: {
                name: 'role',
                value: value ? value.value : null,
              },
            };
            props.setFilter(event);
          }}
        />
      </TableCell>
      <TableCell>
        <Select
          isClearable
          isSearchable
          options={delegations}
          onChange={value => {
            const event = {
              target: {
                name: 'delegation_id',
                value: value ? value.value : null,
              },
            };
            props.setFilter(event);
          }}
        />
      </TableCell>
      <TableCell>
        <ActiveFilter setFilter={props.setFilter} isClearable />
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

UserFilter.defaultProps = {
  userRoles: {
    attributes: [],
  },
  delegations: {
    attributes: {
      delegation: [],
    },
  },
};

UserFilter.propTypes = {
  params: PropTypes.object.isRequired,
  userRoles: PropTypes.object,
  delegations: PropTypes.object,
  setFilter: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  userRoles: makeSelectUserRoles(),
  delegations: makeSelectDelegations(),
});

export default connect(mapStateToProps)(UserFilter);
