import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import SelectInput from 'components/SelectInput';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';

import { cloneDeep, isEmpty, isEqual, throttle } from 'lodash';
import { BACKEND_DATE } from 'utils/constants';
import download from 'utils/download';

import withCrud from 'containers/ModelWrapper';
import TextInput from 'components/TextInput';
import DateSelector from 'components/DatePicker';

import {
  makeSelectCountries,
  makeSelectDelegations,
  makeSelectEnumTypes,
  makeSelectEnumValues,
  makeSelectGenders,
  makeSelectErrorObject,
} from 'containers/App/selectors';
import { setErrorObject } from 'containers/App/actions';
import {
  registrationRequestErrors as resetErrors,
  registrationRequestSkeleton,
} from './constants';
import ContainerPaper from '../../components/ContainerPaper';
import { PageRootContainerStyles } from '../../mui-theme';

const styles = theme => ({
  root: PageRootContainerStyles,
  button: {
    margin: theme.spacing.unit,
  },
  dateSelector: {
    marginBottom: '20px',
  },
});

class RegistrationRequestForm extends React.Component {
  state = {
    birthDate: null,
    model: null,
    registrationRequestErrors: cloneDeep(resetErrors),
    isSuccessful: false,
  };

  constructor(props) {
    super(props);
    this.setBirthDate = this.setBirthDate.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
  }

  componentWillMount() {
    this.props.setModel(cloneDeep(registrationRequestSkeleton));
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.errorObject &&
      nextProps.errorObject &&
      nextProps.errorObject.response
    ) {
      this.displayBackendErrors(nextProps);
      return;
    }
    if (!isEmpty(this.props.model) && isEmpty(nextProps.model)) {
      this.setState({ isSuccessful: true });
    }
  }

  displayBackendErrors(props) {
    const { errorObject, showErrorToast } = props;
    const registrationRequestErrors = cloneDeep(resetErrors);
    const privateEmailError = errorObject.response.data.errors.filter(
      error => error.source.pointer === 'data.attributes.private_email',
    );

    if (privateEmailError.length) {
      registrationRequestErrors.private_email =
        'This email address has already been taken.';
      showErrorToast('Private email address has already been taken');
    }
    this.setState({ registrationRequestErrors });
  }

  inputChanged(event) {
    const selectedRegistrationRequest = cloneDeep(this.props.model);
    selectedRegistrationRequest.attributes[event.target.name] =
      event.target.value;
    this.props.setModel(selectedRegistrationRequest);
  }

  setBirthDate(event) {
    const { state } = this;
    if (!event.target.value) {
      this.setState({
        birthDate: null,
      });
      this.inputChanged({
        target: {
          name: 'date_of_birth',
          value: '',
        },
      });
      return;
    }
    if (!state[event.target.name]) {
      state[event.target.name] = moment();
    }
    state[event.target.name].year(event.target.value.year());
    state[event.target.name].month(event.target.value.month());
    state[event.target.name].dayOfYear(event.target.value.dayOfYear());
    this.setState(state);
  }

  downloadPDF() {
    axios({
      url: 'export/member/pdf',
      method: 'POST',
      responseType: 'blob',
      data: { data: this.state.model },
    })
      .then(response => {
        download(response, response.headers['x-file-name'] || 'member.pdf');
      })
      .catch(error => {
        this.props.showErrorToast(error.message);
      });
  }

  submit() {
    this.props.setErrorObject(null);
    const registrationRequestErrors = cloneDeep(resetErrors);
    this.setState({ registrationRequestErrors });
    const registrationRequest = this.props.model;
    const { birthDate } = this.state;
    const requiredFields = {
      preferred_language: 'Preferred language',
      last_name: 'Last name',
      delegation: 'Delegation',
      member_type: 'Member type',
      private_email: 'Private email',
    };

    const emailFields = {
      private_email: 'Private email',
      office_email: 'Office email',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!String(registrationRequest.attributes[field]).length) {
        registrationRequestErrors[field] = `${
          requiredFields[field]
        } can not be empty`;
      }
    });

    const validEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    Object.keys(emailFields).forEach(field => {
      if (
        registrationRequest.attributes[field] &&
        !validEmailFormat.test(registrationRequest.attributes[field])
      ) {
        registrationRequestErrors[field] = `${
          emailFields[field]
        } must have a valid email format`;
      }
    });

    if (!isEqual(registrationRequestErrors, resetErrors)) {
      this.setState({ registrationRequestErrors });
      this.props.showErrorToast('Form data invalid');
      return;
    }

    if (birthDate) {
      this.inputChanged({
        target: {
          name: 'date_of_birth',
          value: birthDate.format(BACKEND_DATE),
        },
      });
      registrationRequest.attributes.date_of_birth = birthDate.format(
        BACKEND_DATE,
      );
    }
    this.setState({ model: registrationRequest });
    this.props.submitModel();
  }

  render() {
    const {
      classes,
      countries,
      delegations,
      enumTypes,
      enumValues,
      genders,
    } = this.props;
    const { registrationRequestErrors, isSuccessful } = this.state;
    const registrationRequest = this.props.model;

    const { birthDate } = this.state;

    if (isSuccessful) {
      return (
        <ContainerPaper className={classes.paper}>
          <Typography variant="h5" gutterBottom>
            Thank you! Vielen Dank! Merci beaucoup! Спасибо!
          </Typography>
          <Typography variant="body1" paragraph>
            Please download the completed form below to print it and send us
            back the signed version(
            <a href="mailto:membership@cic-wildlife.org">
              membership@cic-wildlife.org
            </a>).
          </Typography>
          <Typography variant="body1" paragraph>
            Bitte laden Sie das ausgefüllte Formular herunter, drucken Sie es
            aus, und senden es uns bitte unterzeichnet zurück (
            <a href="mailto:membership@cic-wildlife.org">
              membership@cic-wildlife.org
            </a>).
          </Typography>
          <Typography variant="body1" paragraph>
            S’il vous plaît, veuillez télécharger et imprimer le formulaire
            d’adhésion ci-dessous et nous le renvoyer signé (
            <a href="mailto:membership@cic-wildlife.org">
              membership@cic-wildlife.org
            </a>).
          </Typography>
          <Typography variant="body1" paragraph>
            Пожалуйста, скачайте файл заполненного заявления по нижеприведенной
            ссылке, подпишите, и отправьте на электронный адрес CIC (
            <a href="mailto:membership@cic-wildlife.org">
              membership@cic-wildlife.org
            </a>) отсканированную версию.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={this.downloadPDF}
          >
            Download PDF
          </Button>
        </ContainerPaper>
      );
    }

    if (
      isEmpty(registrationRequest) ||
      isEmpty(countries) ||
      isEmpty(delegations) ||
      isEmpty(enumValues) ||
      isEmpty(enumTypes) ||
      isEmpty(genders)
    ) {
      return null;
    }

    const getEnumTypeIndex = machineName =>
      enumTypes.attributes.enumtype[
        enumTypes.attributes.enumtype
          .map(e => e.machine_name)
          .indexOf(machineName)
      ].column_id;

    return (
      <ContainerPaper className={classes.paper}>
        <Typography variant="h5" component="h2" gutterBottom>
          Registration request
        </Typography>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <SelectInput
            nullable
            attribute="first_title"
            model={registrationRequest}
            modelErrors={registrationRequestErrors}
            onChange={event => {
              this.inputChanged(event);
            }}
            label="First Title / Premier Titre / Erster Titel / Первое звание"
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('member_title') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <SelectInput
            nullable
            attribute="second_title"
            model={registrationRequest}
            modelErrors={registrationRequestErrors}
            onChange={event => {
              this.inputChanged(event);
            }}
            label="Second Title / Deuxième Titre / Zweiter Titel / Второе звание"
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('member_title') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <TextInput
            required
            model={registrationRequest}
            modelErrors={registrationRequestErrors}
            attribute="last_name"
            label="Name / Nom / Nachname / Фамилия"
            onChange={throttle(event => this.inputChanged(event), 200)}
          />
          <TextInput
            model={registrationRequest}
            modelErrors={registrationRequestErrors}
            attribute="first_name"
            label="First name / Prénom / Vorname / Имя"
            onChange={throttle(event => this.inputChanged(event), 200)}
          />
          <SelectInput
            nullable
            attribute="gender"
            model={registrationRequest}
            modelErrors={registrationRequestErrors}
            onChange={event => {
              this.inputChanged(event);
            }}
            label="Gender / Sexe / Geschlecht / Пол"
          >
            {Object.keys(genders.attributes).map(key => (
              <MenuItem value={key} key={key}>
                {genders.attributes[key]}
              </MenuItem>
            ))}
          </SelectInput>
          <Grid container justify="space-between" spacing={40}>
            <Grid item xs={12} sm={6}>
              <SelectInput
                nullable
                attribute="delegation"
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                onChange={event => {
                  this.inputChanged(event);
                }}
                label="Delegation / Délégation / Delegation / Делегация"
              >
                {delegations.attributes.delegation.map(
                  item =>
                    item.name.indexOf('<') === -1 && (
                      <MenuItem value={item.column_id} key={item.column_id}>
                        {item.name}
                      </MenuItem>
                    ),
                )}
              </SelectInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateSelector
                className={classes.dateSelector}
                label="Date of birth / Date de naissance / Geburtsdatum / Дата рождения"
                value={birthDate}
                onChange={date => {
                  const event = {
                    target: {
                      name: 'birthDate',
                      value: date || '',
                    },
                  };
                  this.setBirthDate(event);
                }}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between" spacing={40}>
            <Grid item xs={12} sm={6}>
              <SelectInput
                nullable
                attribute="postal_country"
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                onChange={event => {
                  this.inputChanged(event);
                }}
                label="Postal country / Pays postal / Land / Страна"
              >
                {countries.attributes.country.map(item => (
                  <MenuItem value={item.column_id} key={item.column_id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectInput>
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="postal_city"
                label="Postal city / Ville postal / Stadt / Город"
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="postal_zip"
                label="Postal zip / Zip Postal / Postleitzahl / Почтовый индекс"
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="postal_address"
                label="Postal Address / Addresse Postale / Anschrift / Улица и т.д."
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="private_tel"
                label="Private / Privé / Privat / частный Tel."
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="private_fax"
                label="Private / Privé / Privat / частный Fax."
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <TextInput
                required
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="private_email"
                label="Private / Privé / Privat / частный Email"
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="private_mobile"
                label="Private / Privé / Privat / частный Mobile"
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="private_skype"
                label="Private / Privé / Privat / частный Skype"
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between" spacing={40}>
            <Grid item xs={12} sm={6}>
              <SelectInput
                nullable
                attribute="secondary_country"
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                onChange={event => {
                  this.inputChanged(event);
                }}
                label="Secondary country / Pays / Sekundäres Land / Страна (доп.
                  адрес)"
              >
                {countries.attributes.country.map(item => (
                  <MenuItem value={item.column_id} key={item.column_id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectInput>
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="secondary_city"
                label="Secondary city / Ville secondaire / Sekundäre Stadt / Город (доп. адрес)"
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="secondary_zip"
                label="Secondary Zip / Zip / Sekundäre Zip / Почтовый индекс (доп. адрес)"
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="secondary_address"
                label="Secondary Address / Addresse / Sekundäre Adresse / Улица и т.д. (доп. адрес)"
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="office_tel"
                label="Office / Bureau / Büro / офис Tel."
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="office_fax"
                label="Office / Bureau / Büro / офис Fax"
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <TextInput
                model={registrationRequest}
                modelErrors={registrationRequestErrors}
                attribute="office_email"
                label="Office / Bureau / Büro / офис Email"
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
            </Grid>
          </Grid>

          <SelectInput
            nullable
            attribute="member_type"
            model={registrationRequest}
            modelErrors={registrationRequestErrors}
            onChange={event => {
              this.inputChanged(event);
            }}
            label="Member Type / Catégorie d&apos;adhésion / Mitgliedschaftskategorie
                / Тип членства"
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('member_type') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <SelectInput
            nullable
            attribute="expertise"
            model={registrationRequest}
            modelErrors={registrationRequestErrors}
            onChange={event => {
              this.inputChanged(event);
            }}
            label="Expertise / Expertise / Ekspertise / Квалификация"
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('expertise') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <SelectInput
            nullable
            attribute="preferred_language"
            model={registrationRequest}
            modelErrors={registrationRequestErrors}
            onChange={event => {
              this.inputChanged(event);
            }}
            label="Preferred language / Votre langue préférée / Bevorzugte Sprache /
            Предпочитаемый язык"
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('preferred_language') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <TextInput
            rows={4}
            model={registrationRequest}
            modelErrors={registrationRequestErrors}
            attribute="comments"
            label="Comments / Les commentaires / Bemerkungen / Комментарии"
            onChange={throttle(event => this.inputChanged(event), 200)}
          />
          <FormGroup row>
            <Button
              color="secondary"
              className={classes.button}
              onClick={this.props.onCancel}
              href="/"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
            >
              Submit
            </Button>
          </FormGroup>
        </form>
      </ContainerPaper>
    );
  }
}

RegistrationRequestForm.defaultProps = {};

RegistrationRequestForm.propTypes = {
  classes: PropTypes.object.isRequired,
  model: PropTypes.object,
  countries: PropTypes.object,
  delegations: PropTypes.object,
  enumTypes: PropTypes.object,
  enumValues: PropTypes.object,
  setModel: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  showErrorToast: PropTypes.func.isRequired,
  setErrorObject: PropTypes.func.isRequired,
  genders: PropTypes.object,
  errorObject: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  countries: makeSelectCountries(),
  delegations: makeSelectDelegations(),
  enumTypes: makeSelectEnumTypes(),
  enumValues: makeSelectEnumValues(),
  genders: makeSelectGenders(),
  errorObject: makeSelectErrorObject(),
});

const mapDispatchToProps = dispatch => ({
  setErrorObject: error => dispatch(setErrorObject(error)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles)(
    withCrud('registrationForm', 'members', RegistrationRequestForm, false),
  ),
);
