import { fromJS } from 'immutable';
import { cloneDeep, assign } from 'lodash';

import { TABLE_DEFAULT_PAGE_SIZE } from 'utils/constants';
import {
  LOAD_MODELS,
  LOAD_MODELS_SUCCESS,
  SET_MODEL_TYPE,
  SET_PAGE_NUMBER,
  SET_ROWS_PER_PAGE,
  SET_MODEL,
  SET_MODEL_INCLUDES,
  SUBMIT_MODEL,
  SUBMIT_MODEL_SUCCESS,
  SUBMIT_MODEL_ERROR,
  SET_FILTER,
  RESET_FILTER,
  SET_SORTING,
  RESET_REDUCER,
  DELETE_MODEL,
  DELETE_MODEL_SUCCESS,
  SHOW_MODEL,
  SHOW_MODEL_SUCCESS,
  SET_INCLUDES,
} from './constants';

const defaultParams = {
  filter: {},
  page: {
    size: TABLE_DEFAULT_PAGE_SIZE,
    number: 1,
  },
  sort: {},
  includes: '',
};

const defaultState = {
  models: {
    data: [],
    meta: {},
  },
  model: {},
  loading: false,
  params: defaultParams,
  modelId: 0,
  modelType: '',
  submitResponse: {},
};

export const initialState = fromJS(cloneDeep(defaultState));

const enumPageReducer = reducerKey => (state = initialState, action) => {
  switch (action.type) {
    case `${LOAD_MODELS}/${reducerKey}`:
      return state.set('loading', true);
    case `${LOAD_MODELS_SUCCESS}/${reducerKey}`:
      return state.set('loading', false).set('models', action.response);
    case `${SET_PAGE_NUMBER}/${reducerKey}`:
      return state.setIn(['params', 'page', 'number'], action.page + 1);
    case `${SET_ROWS_PER_PAGE}/${reducerKey}`:
      return state
        .setIn(['params', 'page', 'size'], action.event.target.value)
        .setIn(['params', 'page', 'number'], 1);
    case `${SET_MODEL_TYPE}/${reducerKey}`:
      return state
        .set('modelType', action.modelType)
        .set(action.modelType, fromJS(cloneDeep(defaultState)));
    case `${SET_MODEL}/${reducerKey}`:
      return state.set(
        'model',
        assign({}, state.toJS().model, { data: action.model }),
      );
    case `${SET_MODEL_INCLUDES}/${reducerKey}`:
      return state.set(
        'model',
        assign({}, state.toJS().model, { included: action.included }),
      );
    case `${SUBMIT_MODEL}/${reducerKey}`:
      return state.set('loading', true).set('submitResponse', {});
    case `${SET_SORTING}/${reducerKey}`:
      return state
        .removeIn(['params', 'sort'])
        .setIn(['params', 'sort', action.property], action.direction);
    case `${SET_FILTER}/${reducerKey}`:
      if (
        action.event.target.value === null ||
        !action.event.target.value.toString().length
      ) {
        return state.removeIn(['params', 'filter', action.event.target.name]);
      }
      return state.setIn(
        ['params', 'filter', action.event.target.name],
        action.event.target.value,
      );
    case `${SET_INCLUDES}/${reducerKey}`:
      return state.setIn(['params', 'include'], action.includes);
    case `${RESET_FILTER}/${reducerKey}`:
      return state.setIn(
        ['params', 'filter'],
        fromJS({
          ...action.payload,
        }),
      );
    case `${SUBMIT_MODEL_SUCCESS}/${reducerKey}`:
      return state
        .set('model', fromJS({}))
        .set('loading', false)
        .set('submitResponse', action.response);
    case `${SUBMIT_MODEL_ERROR}/${reducerKey}`:
      return state.set('loading', false);
    case `${RESET_REDUCER}/${reducerKey}`:
      return fromJS(cloneDeep(defaultState));
    case `${DELETE_MODEL}/${reducerKey}`:
      return state.set('modelId', action.model.id);
    case `${DELETE_MODEL_SUCCESS}/${reducerKey}`:
      return state.set('modelId', 0);
    case `${SHOW_MODEL}/${reducerKey}`:
      return state.set('loading', true).set('modelId', action.modelId);
    case `${SHOW_MODEL_SUCCESS}/${reducerKey}`:
      return state.set('loading', false).set('model', action.model);
    default:
      return state;
  }
};

export default enumPageReducer;
