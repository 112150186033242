import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { isValidUrl } from '../../utils/isValidUrl';

const styles = theme => ({
  typography: {
    fontWeight: 500,
  },
  row: {
    borderBottom: `1px solid lightgray`,
    marginTop: theme.spacing.unit,
  },
});

const GridRow = ({ data, row, classes }) => {
  const getValue = () => {
    switch (row.type) {
      case 'boolean':
        return data.attributes[row.attribute] ? 'Yes' : 'No';
      case 'url':
        return isValidUrl(data.attributes[row.attribute]) ? (
          <a href={data.attributes[row.attribute]}>
            {data.attributes[row.attribute]}
          </a>
        ) : (
          data.attributes[row.attribute]
        );
      case 'value':
        return row.value;
      default:
        return data.attributes[row.attribute];
    }
  };

  return (
    <div>
      <Grid container spacing={24} className={classes.row}>
        <Grid item xs={6}>
          <Typography variant="body1" className={classes.typography}>
            {row.label}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">{getValue()}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

GridRow.propTypes = {
  data: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridRow);
