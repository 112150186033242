import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty, find, isEqual } from 'lodash';
import withCrud from 'containers/ModelWrapper';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableToolbar from 'components/TableToolbar';

import TableFooter from 'components/TableFooter';
import {
  makeSelectCountries,
  makeSelectDelegations,
} from 'containers/App/selectors';
import PhoneBookFilter from './PhoneBookFilter';
import {
  TableContainerOpenedStyles,
  TableContainerStyles,
} from '../../mui-theme';
import { makeSelectSidebarIsOpen } from '../Layout/selectors';

const styles = theme => ({
  rootClosed: {
    ...TableContainerStyles,
    marginBottom: theme.spacing.unit * 2,
  },
  rootOpened: {
    ...TableContainerOpenedStyles,
    marginBottom: theme.spacing.unit * 2,
  },
  table: {
    width: '100%',
  },
  cell: {
    fontSize: '16px',
  },
  text: {
    lineHeight: '18px',
    margin: 0,
  },
  flex: {
    display: 'flex',
  },
  profileImage: {
    maxWidth: '420px',
    padding: '10px',
    objectFit: 'contain',
  },
});

const OtherContact = props => {
  const { field, classes, data } = props;
  if (isEmpty(data[field[0]])) {
    return null;
  }

  return (
    <p className={classes.text}>
      <b>{field[1]}</b>: {data[field[0]]}
    </p>
  );
};
OtherContact.propTypes = {
  field: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const ExpandMoreButton = ({ onClick }) => (
  <IconButton aria-label="Expand more" onClick={onClick}>
    <Icon fontSize="small">expand_more_icon</Icon>
  </IconButton>
);

ExpandMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const ExpandLessButton = ({ onClick }) => (
  <IconButton aria-label="Expand less" onClick={onClick}>
    <Icon fontSize="small">expand_less_icon</Icon>
  </IconButton>
);

ExpandLessButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class PhoneBookTable extends React.Component {
  PARTNER_DELEGATION_NAME = '<Partner>';

  constructor(props) {
    super(props);
    this.createSortHandler = this.createSortHandler.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.state = {
      filterOpen: false,
      expandedRows: [],
    };
  }

  componentWillMount() {
    this.loadMembers(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.loadMembers(nextProps);
      return;
    }
    if (!isEqual(this.props.params, nextProps.params)) {
      this.props.loadModels();
    }
  }

  loadMembers(props) {
    const { setFilter } = props;

    setFilter({
      target: {
        name: 'status',
        value: 'ACTIVE',
      },
    });
    setFilter({
      target: {
        name: 'active',
        value: 1,
      },
    });
  }

  toggleFilter() {
    if (this.state.filterOpen) {
      this.props.resetFilter();
    }
    this.setState(state => ({ filterOpen: !state.filterOpen }));
  }

  createSortHandler(property) {
    return event => {
      const { sortBy, sortDirection } = this.props;
      let direction = 'asc';
      if (property === sortBy) {
        direction = sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.props.setSorting(event, property, direction);
    };
  }

  handleRowClick(rowId) {
    const { expandedRows } = this.state;
    const isRowExpanded = expandedRows.includes(rowId);
    const newExpandedRows = isRowExpanded
      ? expandedRows.filter(id => id !== rowId)
      : expandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  render() {
    const {
      models,
      classes,
      setPageNumber,
      setRowsPerPage,
      setFilter,
      params,
      sidebarIsOpen,
    } = this.props;
    const { expandedRows } = this.state;

    const { data, meta } = models;

    if (isEmpty(meta) || isEmpty(this.props.delegations)) {
      return null;
    }
    const otherContacts = {
      private_tel: 'Phone - Home 1',
      private_tel2: 'Phone - Home 2',
      private_mobile: 'Phone - Mobile',
      office_tel: 'Phone - Office 1',
      office_tel2: 'Phone - Office 2',
      other_tel: 'Phone - Other',
      private_fax: 'Fax - Home',
      office_fax: 'Fax - Office',
      other_fax: 'Fax - Other',
      private_email: 'Email 1',
      office_email: 'Email 2',
      other_email: 'Email 3',
      private_skype: 'Skype',
      website: 'Website',
    };

    const { delegation } = this.props.delegations.attributes;
    const { country } = this.props.countries.attributes;

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;
    const isRowExpanded = rowId => expandedRows.includes(rowId);

    const dataWithoutPartner = data.filter(
      row =>
        !row.attributes.delegation ||
        find(delegation, {
          column_id: row.attributes.delegation,
        }).name !== this.PARTNER_DELEGATION_NAME,
    );

    return (
      <Paper className={rootClass}>
        <TableToolbar title="Phonebook" onClick={this.toggleFilter} />
        <PhoneBookFilter params={params} setFilter={setFilter} />

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width="30%">Name, Mobile Phone, Email</TableCell>
              <TableCell width="30%">Delegation, Other Func, Address</TableCell>
              <TableCell width="30%">Other contacts</TableCell>
              <TableCell width="30%" />
            </TableRow>
          </TableHead>

          <TableBody>
            {dataWithoutPartner.map(row => (
              <React.Fragment>
                <TableRow key={row.id}>
                  <TableCell>
                    <p className={classes.text}>
                      <b>{`${row.attributes.first_name} ${
                        row.attributes.particle
                      } ${row.attributes.last_name}`}</b>
                      <br />
                      {row.attributes.private_mobile} <br />
                      {row.attributes.private_email}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p className={classes.text}>
                      {row.attributes.delegation && (
                        <span>
                          <b>
                            {
                              find(delegation, {
                                column_id: row.attributes.delegation,
                              }).name
                            }
                          </b>
                          <br />
                        </span>
                      )}
                      {row.attributes.postal_country && (
                        <span>
                          {
                            find(country, {
                              column_id: row.attributes.postal_country,
                            }).name
                          }
                          {', '}
                        </span>
                      )}
                      {` ${row.attributes.postal_zip}
                      ${row.attributes.postal_city}
                      ${row.attributes.postal_address}`}
                    </p>
                  </TableCell>
                  <TableCell>
                    {Object.entries(otherContacts).map(contact => (
                      <OtherContact
                        key={contact[0]}
                        field={contact}
                        data={row.attributes}
                        classes={classes}
                      />
                    ))}
                  </TableCell>
                  <TableCell align="right">
                    {isRowExpanded(row.id) ? (
                      <ExpandLessButton
                        onClick={() => this.handleRowClick(row.id)}
                      />
                    ) : (
                      <ExpandMoreButton
                        onClick={() => this.handleRowClick(row.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
                {isRowExpanded(row.id) && (
                  <TableRow key={`expanded-${row.id}`}>
                    <TableCell colSpan="2">
                      {row.attributes.short_bio ? (
                        <Typography
                          className={classes.text}
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: row.attributes.short_bio,
                          }}
                        />
                      ) : (
                        <p className={classes.text}>No bio</p>
                      )}
                    </TableCell>
                    <TableCell colSpan="2">
                      {row.attributes.profile_image ? (
                        <img
                          src={row.attributes.profile_image.dimensions.original}
                          alt="Profile"
                          className={classes.profileImage}
                        />
                      ) : (
                        <p className={classes.text}>No profile picture</p>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
          {data.length ? (
            <TableFooter
              colSpan={3}
              meta={meta}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setRowsPerPage}
            />
          ) : null}
        </Table>
      </Paper>
    );
  }
}

PhoneBookTable.defaultProps = {
  delegations: {
    attributes: {
      delegation: [],
    },
  },
  countries: {
    attributes: {
      country: [],
    },
  },
};

PhoneBookTable.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  resetFilter: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  countries: PropTypes.object,
  delegations: PropTypes.object,
  setFilter: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  loadModels: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  countries: makeSelectCountries(),
  delegations: makeSelectDelegations(),
  sidebarIsOpen: makeSelectSidebarIsOpen(),
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(
    withCrud('phoneBookTable', 'members', PhoneBookTable, true),
  ),
);
