import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  DELETE_FILE,
  LOAD_IMAGE,
  RESET_STORE,
  SET_VISIBILITY,
} from './constants';

export const fileUpload = file => ({
  type: UPLOAD_FILE,
  file,
});

export const fileUploadSuccess = file => ({
  type: UPLOAD_FILE_SUCCESS,
  file,
});

export const fileUploadError = () => ({
  type: UPLOAD_FILE_ERROR,
});

export const deleteFile = () => ({
  type: DELETE_FILE,
});

export const resetStore = () => ({
  type: RESET_STORE,
});

export const loadImage = id => ({
  type: LOAD_IMAGE,
  id,
});

export const setVisibility = visibility => ({
  type: SET_VISIBILITY,
  visibility,
});
