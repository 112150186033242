import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the news state domain
 */

const selectNewsFormDomain = state => state.get('newsForm', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by News
 */

const makeSelectNews = () =>
  createSelector(selectNewsFormDomain, substate => substate.toJS());

const makeSelectNewsErrors = () =>
  createSelector(selectNewsFormDomain, substate => substate.toJS().newsErrors);

export default makeSelectNews;
export { selectNewsFormDomain, makeSelectNewsErrors };
