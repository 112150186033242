import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import messages from './messages';
import ContainerPaper from '../../components/ContainerPaper';

const styles = () => ({});

const DelegationPage = ({ classes }) => (
  <div className={classes.root}>
    <Helmet>
      <title>Home | CIC</title>
    </Helmet>
    <div>
      <ContainerPaper className={classes.paper}>
        <Typography variant="h4" gutterBottom>
          <FormattedMessage {...messages.pageTitle} />
        </Typography>
        <Typography variant="body2" gutterBottom>
          <FormattedMessage {...messages.welcomeText} />
        </Typography>
      </ContainerPaper>
    </div>
  </div>
);

DelegationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default compose(withConnect)(withStyles(styles)(DelegationPage));
