import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { cloneDeep, isEmpty, remove } from 'lodash';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import ConfirmDialog from 'components/ConfirmDialog';
import withCrud from 'containers/ModelWrapper';
import FileUpload from 'containers/FileUpload';
import download from 'utils/download';

const styles = () => ({
  panel: {
    display: 'block',
  },
  table: {
    width: '100%',
  },
});

const DeleteDialog = props => (
  <ConfirmDialog
    open
    onClose={props.close}
    cancelAction={props.close}
    confirmAction={() => {
      props.close();
      props.removeFromList(props.file);
    }}
    title={`Are you sure you want to delete ${props.file.attributes.name}?`}
    description="This action can not be undone."
  />
);

DeleteDialog.propTypes = {
  file: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired,
};

const FileDeleteDialog = withCrud(
  'deleteMemberFile',
  'files',
  DeleteDialog,
  false,
);

class MemberAttachments extends React.Component {
  state = {
    deleteFile: null,
  };

  attachFile(file) {
    if (isEmpty(file)) {
      return;
    }
    const member = cloneDeep(this.props.model);
    const attachments = member.relationships.files.data;
    attachments.push({
      id: file.id,
      type: 'files',
    });
    member.relationships = Object.assign({}, member.relationships, {
      files: { data: attachments },
    });
    const included = cloneDeep(this.props.modelIncludes);
    included.unshift(file);
    this.props.setModel(member);
    this.props.setModelIncludes(included);
    setTimeout(() => {
      this.props.showInfoToast(
        'You have to click on save button to finialize file upload!',
      );
    }, 1500);
  }

  downloadFile(file) {
    axios({
      url: file.attributes.dimensions.original,
      method: 'GET',
      responseType: 'blob',
    })
      .then(response => {
        download(response, file.attributes.name);
      })
      .catch(error => {
        this.props.showErrorToast(error.message);
      });
  }

  detachFile(file) {
    if (isEmpty(file)) {
      return;
    }
    const member = cloneDeep(this.props.model);
    remove(member.relationships.files.data, item => item.id === file.id);

    const included = cloneDeep(this.props.modelIncludes);
    remove(included, item => item.id === file.id);

    this.props.setModel(member);
    this.props.setModelIncludes(included);

    this.props.showInfoToast(
      'You have to click on save button to finialize file changes!',
    );
  }

  openDialog(file) {
    this.setState({ deleteFile: file });
  }

  render() {
    const { classes, modelIncludes } = this.props;
    const files = modelIncludes.filter(item => item.type === 'files');
    return (
      <ExpansionPanel>
        {this.state.deleteFile && (
          <FileDeleteDialog
            file={this.state.deleteFile}
            close={() => {
              this.setState(() => ({ deleteFile: null }));
            }}
            removeFromList={file => {
              this.detachFile(file);
            }}
          />
        )}
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            Member Attachments
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panel}>
          <FileUpload
            value={null}
            visibility="private"
            attachFile={file => this.attachFile(file)}
            accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.ppt,.pptx,.txt"
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>File name</TableCell>
                <TableCell>Size (Bytes)</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.attributes.name}</TableCell>
                  <TableCell>{row.attributes.rawSize}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Download"
                      onClick={() => this.downloadFile(row)}
                    >
                      <Icon fontSize="small">cloud_download</Icon>
                    </IconButton>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => this.openDialog(row)}
                    >
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

MemberAttachments.defaultProps = {
  modelIncludes: [],
};

MemberAttachments.propTypes = {
  classes: PropTypes.object.isRequired,
  setModel: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  modelIncludes: PropTypes.array,
  showErrorToast: PropTypes.func.isRequired,
  showInfoToast: PropTypes.func.isRequired,
  setModelIncludes: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withCrud('memberFormPage', 'members', MemberAttachments, false),
);
