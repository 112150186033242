import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRoute = state => state.get('route');

const selectAppDomain = state => state.get('App', initialState);

const makeSelectLocation = () =>
  createSelector(selectRoute, routeState => routeState.get('location').toJS());

const makeSelectConfig = () =>
  createSelector(selectAppDomain, substate => substate.toJS().config);

const makeSelectAreaType = () =>
  createSelector(
    selectAppDomain,
    substate =>
      substate
        .toJS()
        .config.filter(item => item.id === 'functionalareatype')[0],
  );

const makeSelectUserRoles = () =>
  createSelector(
    selectAppDomain,
    substate =>
      substate.toJS().config.filter(item => item.id === 'userroles')[0],
  );

const makeSelectCountries = () =>
  createSelector(
    selectAppDomain,
    substate => substate.toJS().config.filter(item => item.id === 'country')[0],
  );

const makeSelectInvoiceYears = () =>
  createSelector(
    selectAppDomain,
    substate => substate.toJS().config.filter(item => item.id === 'invoice')[0],
  );

const makeSelectDelegations = () =>
  createSelector(
    selectAppDomain,
    substate =>
      substate.toJS().config.filter(item => item.id === 'delegation')[0],
  );

const makeSelectEnumTypes = () =>
  createSelector(
    selectAppDomain,
    substate =>
      substate.toJS().config.filter(item => item.id === 'enumtype')[0],
  );

const makeSelectEnumValues = () =>
  createSelector(
    selectAppDomain,
    substate =>
      substate.toJS().config.filter(item => item.id === 'enumvalue')[0],
  );

const makeSelectAllEnumValues = () =>
  createSelector(
    selectAppDomain,
    substate =>
      substate.toJS().config.filter(item => item.id === 'allenumvalues')[0],
  );

const makeSelectGenders = () =>
  createSelector(
    selectAppDomain,
    substate => substate.toJS().config.filter(item => item.id === 'genders')[0],
  );

const makeSelectNewsCategories = () =>
  createSelector(
    selectAppDomain,
    substate =>
      substate.toJS().config.filter(item => item.id === 'newscategories')[0],
  );

const makeSelectErrorObject = () =>
  createSelector(selectAppDomain, substate => substate.toJS().error);

const makeSelectEnumValuesOf = enumType =>
  createSelector(selectAppDomain, substate => {
    const types = substate.toJS().config.filter(item => item.id === 'enumtype');
    if (!types.length) {
      return [];
    }
    const type = types[0].attributes.enumtype.filter(
      item => item.machine_name === enumType,
    )[0];
    return substate
      .toJS()
      .config.filter(item => item.id === 'enumvalue')[0]
      .attributes.enumvalue.filter(
        item => item.enum_type_id === type.column_id,
      );
  });

const makeSelectLoading = () =>
  createSelector(selectAppDomain, substate => substate.toJS().loading);

export {
  makeSelectLocation,
  selectAppDomain,
  makeSelectConfig,
  makeSelectAreaType,
  makeSelectUserRoles,
  makeSelectCountries,
  makeSelectDelegations,
  makeSelectEnumTypes,
  makeSelectEnumValues,
  makeSelectAllEnumValues,
  makeSelectGenders,
  makeSelectNewsCategories,
  makeSelectErrorObject,
  makeSelectEnumValuesOf,
  makeSelectLoading,
  makeSelectInvoiceYears,
};
