import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Checkbox from '@material-ui/core/Checkbox';
import { union, remove } from 'lodash';

import withCrud from 'containers/ModelWrapper';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'redux';
import { getImageUrl } from './actions';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    margin: '30px 0',
  },
  gridList: {
    maxWidth: 1200,
    maxHeight: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  image: {
    objectFit: 'contain',
  },
});

class ItemGridList extends React.Component {
  constructor(props) {
    super(props);
    this.itemSelect = this.itemSelect.bind(this);
  }

  state = {
    selectedItems: [],
  };

  componentWillMount() {
    this.props.setRowsPerPage({
      target: { value: 100 },
    });
    this.props.setSorting({}, 'created_at', 'desc');

    if (this.props.value.length) {
      this.setState({ selectedItems: this.props.value });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value.length && !this.state.selectedItems.length) {
      this.setState({ selectedItems: nextProps.value });
    }

    if (!nextProps.value.length) {
      this.setState({
        selectedItems: [],
      });
    }
  }

  itemSelect(event) {
    let { selectedItems } = this.state;
    remove(
      selectedItems,
      item => parseInt(item, 10) === parseInt(event.target.value, 10),
    );
    if (event.target.checked) {
      selectedItems = union(selectedItems, [event.target.value]);
    }
    this.setState({ selectedItems });
    this.props.onChange(selectedItems);
  }

  getGridListCols = () => {
    const { width } = this.props;

    if (isWidthUp('md', width)) {
      return 4;
    }

    if (isWidthUp('sm', width)) {
      return 2;
    }

    return 1;
  };

  render() {
    const { classes, models } = this.props;
    if (!models.data) {
      return null;
    }

    return (
      <div className={classes.root}>
        <GridList
          cellHeight={180}
          className={classes.gridList}
          cols={this.getGridListCols()}
        >
          {models.data.map(item => (
            <GridListTile key={item.id}>
              <img
                src={getImageUrl(item, models.included)}
                alt={item.attributes.name}
                className={classes.image}
              />
              <GridListTileBar
                title={item.attributes.name}
                actionIcon={
                  <Checkbox
                    className={classes.icon}
                    checked={this.state.selectedItems.indexOf(item.id) > -1}
                    onChange={this.itemSelect}
                    value={item.id}
                    name={item.id}
                  />
                }
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    );
  }
}

ItemGridList.propTypes = {
  classes: PropTypes.object.isRequired,
  models: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  width: PropTypes.string,
  setSorting: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withWidth(),
)(withCrud('itemGridList', 'items', ItemGridList));
