import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEqual } from 'lodash';
import moment from 'moment';
import { Link as LinkTo } from 'react-router-dom';

import Typography from '@material-ui/core/Typography/Typography';
import Icon from '@material-ui/core/Icon/Icon';
import Button from '@material-ui/core/Button/Button';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import Table from '@material-ui/core/Table/Table';
import IconButton from '@material-ui/core/IconButton/IconButton';

import {
  makeSelectAreaType,
  makeSelectEnumValuesOf,
} from 'containers/App/selectors';
import { getIncludedModel } from 'utils/fractal';
import withCrud from '../ModelWrapper';
import { DATE_FORMAT } from '../../utils/constants';
import Authorization from '../../utils/authorization';
import ContainerPaper from '../../components/ContainerPaper';
import {
  PageRootContainerOpenedStyles,
  PageRootContainerStyles,
} from '../../mui-theme';
import { makeSelectSidebarIsOpen } from '../Layout/selectors';
import ConfirmDialog from '../../components/ConfirmDialog';

const styles = () => ({
  rootClosed: {
    ...PageRootContainerStyles,
    marginLeft: '0px !important',
  },
  rootOpened: {
    ...PageRootContainerOpenedStyles,
    marginLeft: '0px !important',
  },
});

const AdminAction = Authorization([
  'SYSTEM_ADMINISTRATOR',
  'MEMBERSHIP_ADMINISTRATOR',
  'YOUNG_OPINION',
]);

const CreateButton = AdminAction(({ member }) => (
  <LinkTo to={`/admin/members/edit/${member.id}/member-function`}>
    <Button color="primary">
      <Icon>add</Icon>Add new
    </Button>
  </LinkTo>
));

CreateButton.propTypes = {
  member: PropTypes.object.isRequired,
};

const EditButton = AdminAction(({ member, memberFunction }) => (
  <LinkTo
    to={`/admin/members/edit/${member.id}/member-function/${memberFunction.id}`}
  >
    <IconButton aria-label="Edit">
      <Icon fontSize="small">edit_icon</Icon>
    </IconButton>
  </LinkTo>
));

EditButton.propTypes = {
  open: PropTypes.func.isRequired,
};

const DeleteButton = AdminAction(({ open }) => (
  <IconButton aria-label="Edit" onClick={open}>
    <Icon fontSize="small">delete</Icon>
  </IconButton>
));

EditButton.propTypes = {
  member: PropTypes.object.isRequired,
  memberFunction: PropTypes.object.isRequired,
};

const CreateAction = AdminAction(CreateButton);
const EditAction = AdminAction(EditButton);
const DeleteAction = AdminAction(DeleteButton);

class MemberFunctionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteDialogOpen: false,
    };
  }

  componentWillMount() {
    this.loadFunctions(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.member, this.props.member)) {
      this.loadFunctions(nextProps);
    }
  }

  loadFunctions(props) {
    const { member } = props;
    if (!member || !member.id) {
      return;
    }
    this.props.setFilter({
      target: {
        name: 'member_id',
        value: this.props.member.id,
      },
    });
    this.props.setRowsPerPage({
      target: { value: 100 },
    });
    this.props.setSorting({}, 'start_date', 'desc');
    this.props.loadModels();
  }

  openDialog(memberFunction) {
    this.setState(() => ({
      deleteDialogOpen: true,
      destroyMemberFunction: memberFunction,
    }));
  }

  render() {
    const {
      classes,
      member,
      teamTypes,
      enumValues,
      sidebarIsOpen,
      models: { data, included },
    } = this.props;

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;

    return (
      <ContainerPaper className={rootClass}>
        <ConfirmDialog
          open={this.state.deleteDialogOpen}
          onClose={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          cancelAction={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          confirmAction={() => {
            this.props.deleteModel(this.state.destroyMemberFunction);
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          title="Are you sure you want to remove member function?"
          description="This action can not be undone."
        />

        <Typography variant="h5" gutterBottom>
          Member Functions
        </Typography>

        <CreateAction member={member} />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Team Name</TableCell>
              <TableCell>Team Type Name</TableCell>
              <TableCell>Function Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  {
                    getIncludedModel(
                      row.attributes,
                      'team',
                      'functional-areas',
                      included,
                    ).name
                  }
                </TableCell>
                <TableCell>
                  {
                    teamTypes.attributes[
                      getIncludedModel(
                        row.attributes,
                        'team',
                        'functional-areas',
                        included,
                      ).area_type
                    ]
                  }
                </TableCell>
                <TableCell>
                  {
                    enumValues.find(
                      item =>
                        item.column_id === row.attributes.function_enum_id,
                    ).value
                  }
                </TableCell>
                <TableCell>
                  {row.attributes.start_date &&
                    moment(row.attributes.start_date).format(DATE_FORMAT)}
                </TableCell>
                <TableCell>
                  {row.attributes.end_date &&
                    moment(row.attributes.end_date).format(DATE_FORMAT)}
                </TableCell>
                <TableCell>
                  <EditAction memberFunction={row} member={member} />
                  <DeleteAction
                    open={() => {
                      this.openDialog(row);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ContainerPaper>
    );
  }
}

MemberFunctionList.propTypes = {
  loadModels: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  teamTypes: PropTypes.object.isRequired,
  enumValues: PropTypes.array.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  setSorting: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  deleteModel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  teamTypes: makeSelectAreaType(),
  enumValues: makeSelectEnumValuesOf('member_functions'),
  sidebarIsOpen: makeSelectSidebarIsOpen(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(
  withStyles(styles)(
    withCrud(
      'memberFunctionList',
      'member-functions',
      MemberFunctionList,
      false,
    ),
  ),
);
