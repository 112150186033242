import React from 'react';
import axios from 'axios';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { cloneDeep, get } from 'lodash';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Card from '@material-ui/core/Card/Card';
import CardActionArea from '@material-ui/core/CardActionArea/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia/CardMedia';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Typography from '@material-ui/core/Typography/Typography';
import CardActions from '@material-ui/core/CardActions/CardActions';
import { Link as LinkTo } from 'react-router-dom';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import { userHasRole } from 'utils/userHasRole';
import {
  makeRolesSelector,
  makeUserSelector,
} from 'containers/AuthButton/selectors';
import { setMessage } from 'containers/Snackbar/actions';
import { MESSAGE_TYPES } from 'containers/Snackbar/constants';
import ConfirmDialog from 'components/ConfirmDialog';
import Money from 'components/Money';
import {
  getCurrentBid,
  getCurrentBidAmount,
  getCurrentBidder,
  getImageUrl,
  getNextBidAmount,
  isAuctionActive,
  roundToDecimals,
} from './actions';
import { bidSkeleton } from './constants';
import {
  deInitWebsocketConnection,
  getEchoClient,
  initWebsocketConnection,
} from '../../utils/axios';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginLeft: '30px',
    'min-height': '500px',
  },
  paper: {
    width: '100%',
    margin: `${theme.spacing.unit}px 0`,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 200,
    objectFit: 'contain',
  },
  filters: {
    display: 'flex',
    flexDirection: 'columns',
  },
  select: {
    margin: '15px 30px 15px 0px',
    minWidth: 150,
  },
  bidder: {
    marginRight: '5px',
  },
});

const DeleteDialog = props => (
  <ConfirmDialog
    open
    onClose={props.close}
    cancelAction={props.close}
    confirmAction={() => {
      props.deleteModel(props.item);
      props.close();
    }}
    title={`Are you sure you want to delete "${props.item.attributes.name}"?`}
    description="This action can not be undone."
  />
);

DeleteDialog.propTypes = {
  item: PropTypes.object.isRequired,
  deleteModel: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deleteItem: null };
    this.setBid = this.setBid.bind(this);
    this.bidUpdatedCallback = this.bidUpdatedCallback.bind(this);
    this.payItem = this.payItem.bind(this);
  }

  isUserAdmin = userHasRole(this.props.roles, [
    'SYSTEM_ADMINISTRATOR',
    'MEMBERSHIP_ADMINISTRATOR',
  ]);

  channelType = this.isUserAdmin ? 'auction-bids-admin' : 'auction-bids';

  componentWillMount() {
    this.updateBids(this.props);
    initWebsocketConnection(
      this.channelType,
      '.BidUpdated',
      this.bidUpdatedCallback,
    );
  }

  componentWillUnmount() {
    deInitWebsocketConnection(this.channelType);
  }

  componentDidUpdate(prevProps) {
    if (this.props.auction.id !== prevProps.auction.id) {
      this.updateBids(this.props);
    }
  }

  bidUpdatedCallback = data => {
    const itemId = parseInt(this.props.model.id, 10);
    if (!itemId || itemId !== data.auction_item_id) {
      return;
    }
    const bidUser =
      data.bid_socket === getEchoClient().socketId() ? this.props.user : null;
    this.setCurrentBid(data, data.user ? data.user : bidUser);
  };

  updateBids(props) {
    const { auction, model, included } = props;
    const currentBid = getCurrentBid(auction, model, included);
    const currentBidAttributes = currentBid ? currentBid.attributes : undefined;

    this.setState({
      nextBidAmount: getNextBidAmount(currentBidAttributes, model),
      currentBid: currentBidAttributes,
      currentBidAmount: getCurrentBidAmount(currentBidAttributes, model),
      currentBidder: getCurrentBidder(currentBidAttributes, included),
    });
  }

  setBid() {
    const { auction, model } = this.props;
    const bidModel = cloneDeep(bidSkeleton);

    bidModel.attributes.auction_id = auction.id;
    bidModel.attributes.auction_item_id = model.id;
    bidModel.attributes.amount = roundToDecimals(this.state.nextBidAmount, 2);

    this.props.setBidModel(bidModel);
  }

  setCurrentBid(data, user) {
    const {
      /* eslint-disable camelcase */
      auction_id,
      auction_item_id,
      author_user_id,
      is_current,
      amount,
      created_at,
    } = data;
    const { model } = this.props;
    const currentBidSkeleton = {
      auction_id,
      auction_item_id,
      author_user_id,
      is_current,
      amount,
      created_at,
    };
    this.setState({
      currentBidAmount: getCurrentBidAmount(currentBidSkeleton, model),
      nextBidAmount: getNextBidAmount(currentBidSkeleton, model),
      currentBidder: user,
      currentBid: currentBidSkeleton,
    });
  }

  handleCardActionClick = () => {
    const { model, auction, history } = this.props;
    history.push(
      this.isUserAdmin
        ? `/admin/items/edit/${model.id}/${auction.id}`
        : `/items/${model.id}/${auction.id}`,
      { auction: this.props.auction },
    );
  };

  payItem = () => {
    const {
      model: { id: itemId },
      auction: { id: auctionId },
    } = this.props;

    axios
      .post('item-pay', {
        data: {
          attributes: {
            auction_id: auctionId,
            auction_item_id: itemId,
          },
        },
      })
      .then(response => {
        window.location.href = response.data.data.attributes.redirect_url;
      })
      .catch(error => this.props.showErrorToast(error.message));
  };

  render() {
    const {
      classes,
      model,
      included,
      auction,
      data,
      user: { first_name, particle, last_name },
    } = this.props;

    const userName = [first_name, particle, last_name].join(' ');

    if (!auction) {
      return null;
    }

    const getCurrentBidData = () =>
      `${this.state.currentBid ? 'Current bid: ' : 'Starting bid: '}`;

    const getBidderName = () => {
      if (this.state.currentBid) {
        if (this.state.currentBidder) {
          return [
            this.state.currentBidder.first_name,
            this.state.currentBidder.particle,
            this.state.currentBidder.last_name,
          ].join(' ');
        }

        if (this.state.currentBid.custom_name && this.isUserAdmin) {
          return this.state.currentBid.custom_name;
        }

        return 'Other member';
      }

      return '-';
    };

    const isPayable = !get(this.state.currentBid, 'is_paid', false);

    const isMyBid = getBidderName() === userName;

    const PlaceBidButton = () =>
      isAuctionActive(auction.attributes) && (
        <Button size="small" color="primary" onClick={this.setBid}>
          Place a Bid
        </Button>
      );

    const EditButton = () =>
      this.isUserAdmin && (
        <LinkTo to={`/admin/items/edit/${model.id}/${auction.id}`}>
          <Button size="small" color="primary">
            Edit
          </Button>
        </LinkTo>
      );

    const ViewButton = () => (
      <LinkTo
        to={{
          pathname: `/items/${model.id}/${auction.id}`,
          state: {
            siblingItems: data.map(item => item.id),
          },
        }}
      >
        <Button size="small" color="primary">
          View
        </Button>
      </LinkTo>
    );

    const PayButton = () =>
      !model.attributes.hide_pay &&
      isPayable &&
      isMyBid &&
      !isAuctionActive(auction.attributes) && (
        <Button size="small" color="primary" onClick={this.payItem}>
          Pay
        </Button>
      );

    const PaidLabel = () =>
      !isPayable &&
      (this.isUserAdmin || isMyBid) && <Typography>Paid</Typography>;

    const hasRelationships =
      model.relationships && model.relationships.files.data.length;

    return (
      <Card className={classes.card}>
        {this.state.deleteItem && (
          <DeleteDialog
            item={this.state.deleteItem}
            deleteModel={this.props.deleteModel}
            close={() => {
              this.setState(() => ({ deleteItem: null }));
            }}
          />
        )}
        <CardActionArea>
          <LinkTo to={`/items/${model.id}/${auction.id}`}>
            {hasRelationships && (
              <CardMedia
                component="img"
                className={classes.media}
                image={getImageUrl(model, included)}
                title="Item image"
              />
            )}
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {model.attributes.name}
              </Typography>
              <Typography component="p">
                {getCurrentBidData()}
                <Money amount={parseFloat(this.state.currentBidAmount)} />
              </Typography>
              <Typography component="p">Bidder: {getBidderName()}</Typography>
            </CardContent>
          </LinkTo>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          {model.attributes.bidding_enable && <PlaceBidButton />}
          <EditButton />
          <ViewButton />
          <PayButton />
          <PaidLabel />
          {this.isUserAdmin && (
            <IconButton
              onClick={() => {
                this.setState({ deleteItem: model });
              }}
            >
              <Icon fontSize="small" color="primary">
                delete
              </Icon>
            </IconButton>
          )}
        </CardActions>
      </Card>
    );
  }
}

Item.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  included: PropTypes.array.isRequired,
  auction: PropTypes.object,
  setBidModel: PropTypes.func.isRequired,
  deleteModel: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  showErrorToast: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  roles: makeRolesSelector(),
  user: makeUserSelector(),
});

const mapDispatchToProps = dispatch => ({
  showErrorToast: message =>
    dispatch(
      setMessage({
        type: MESSAGE_TYPES.ERROR,
        value: message,
      }),
    ),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(withStyles(styles)(Item));
