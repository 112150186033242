import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { isEmpty, isEqual } from 'lodash';
import { Link as LinkTo } from 'react-router-dom';
import withCrud from 'containers/ModelWrapper';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import ConfirmDialog from 'components/ConfirmDialog';
import SortableCells from 'components/SortableCells';
import TableToolbar from 'components/TableToolbar';
import TableFooter from 'components/TableFooter';
import { DATE_TIME_FORMAT } from 'utils/constants';
import AuctionsFilter from './AuctionsFilter';
import {
  PageRootContainerOpenedStyles,
  PageRootContainerStyles,
} from '../../mui-theme';
import { makeSelectSidebarIsOpen } from '../Layout/selectors';

const styles = () => ({
  rootClosed: PageRootContainerStyles,
  rootOpened: PageRootContainerOpenedStyles,
  table: {
    width: '100%',
  },
});

const DeleteDialog = props => (
  <ConfirmDialog
    open
    onClose={props.close}
    cancelAction={props.close}
    confirmAction={() => {
      props.deleteModel(props.auction);
      props.close();
    }}
    title={`Are you sure you want to delete "${
      props.auction.attributes.name
    }"?`}
    description="This action can not be undone."
  />
);

DeleteDialog.propTypes = {
  auction: PropTypes.object.isRequired,
  deleteModel: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

class AuctionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.createSortHandler = this.createSortHandler.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.state = {
      filterOpen: false,
      deleteAuction: null,
    };
  }

  componentWillMount() {
    this.props.loadModels();
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.params, this.props.params)) {
      this.props.loadModels();
    }
  }

  toggleFilter() {
    if (this.state.filterOpen) {
      this.props.resetFilter();
    }
    this.setState(state => ({ filterOpen: !state.filterOpen }));
  }

  createSortHandler(property) {
    return event => {
      const { sortBy, sortDirection } = this.props;
      let direction = 'asc';
      if (property === sortBy) {
        direction = sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.props.setSorting(event, property, direction);
    };
  }

  render() {
    const {
      models,
      classes,
      setPageNumber,
      sortBy,
      sortDirection,
      setRowsPerPage,
      setFilter,
      params,
      sidebarIsOpen,
    } = this.props;

    const { data, meta } = models;

    if (isEmpty(meta)) {
      return null;
    }

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;
    return (
      <Paper className={rootClass}>
        {this.state.deleteAuction && (
          <DeleteDialog
            auction={this.state.deleteAuction}
            deleteModel={this.props.deleteModel}
            close={() => {
              this.setState(() => ({ deleteAuction: null }));
            }}
          />
        )}
        <TableToolbar title="Auctions" onClick={this.toggleFilter} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <SortableCells
                sortDirection={sortDirection}
                sortBy={sortBy}
                onClick={this.createSortHandler}
                cells={{
                  Name: 'name',
                  'Start date': 'start_time',
                  'End date': 'end_time',
                  Live: 'live',
                }}
              />
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.filterOpen && (
              <AuctionsFilter params={params} setFilter={setFilter} />
            )}
            {data.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.attributes.name}</TableCell>
                <TableCell>
                  {moment(row.attributes.start_time).format(DATE_TIME_FORMAT)}
                </TableCell>
                <TableCell>
                  {moment(row.attributes.end_time).format(DATE_TIME_FORMAT)}
                </TableCell>
                <TableCell>
                  <Checkbox checked={!!row.attributes.live} disableRipple />
                </TableCell>
                <TableCell>
                  <LinkTo to={`/admin/auctions/edit/${row.id}`}>
                    <IconButton aria-label="Edit">
                      <Icon fontSize="small">edit_icon</Icon>
                    </IconButton>
                  </LinkTo>
                  {!row.attributes.is_active && (
                    <IconButton
                      onClick={() => {
                        this.setState({ deleteAuction: row });
                      }}
                    >
                      <Icon fontSize="small" color="primary">
                        delete
                      </Icon>
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {data.length ? (
            <TableFooter
              colSpan={5}
              meta={meta}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setRowsPerPage}
            />
          ) : null}
        </Table>
      </Paper>
    );
  }
}

AuctionsTable.propTypes = {
  loadModels: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  resetFilter: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  deleteModel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  sidebarIsOpen: makeSelectSidebarIsOpen(),
});

export default connect(mapStateToProps)(
  withStyles(styles)(
    withCrud('auctionsTable', 'auctions', AuctionsTable, false),
  ),
);
