import React from 'react';
import PropTypes from 'prop-types';

import SelectInput from 'components/SelectInput';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextInput from 'components/TextInput';
import MoneyInput from '../../components/MoneyInput';

const MemberFormForNewMember = ({
  model,
  memberErrors,
  classes,
  delegations,
  enumValues,
  getEnumTypeIndex,
  onSubmit,
  onChange,
  cancel,
  isUserAdmin,
  isUserYO,
  isUserArtemis,
  isUserDelegationHead,
}) => (
  <form onSubmit={onSubmit}>
    <Grid container style={{ marginBottom: '16px' }}>
      <Typography variant="h5" gutterBottom>
        Create Member
      </Typography>
    </Grid>
    <SelectInput
      attribute="first_title"
      model={model}
      modelErrors={memberErrors}
      onChange={onChange}
      label="First title"
      disabled={isUserArtemis}
      nullable
    >
      {Object.keys(enumValues.attributes.enumvalue).map(
        key =>
          enumValues.attributes.enumvalue[key].enum_type_id ===
            getEnumTypeIndex('member_title') && (
            <MenuItem
              value={enumValues.attributes.enumvalue[key].column_id}
              key={key}
            >
              {enumValues.attributes.enumvalue[key].value}
            </MenuItem>
          ),
      )}
    </SelectInput>
    <SelectInput
      attribute="second_title"
      model={model}
      modelErrors={memberErrors}
      onChange={onChange}
      label="Second title"
      disabled={isUserArtemis}
      nullable
    >
      {Object.keys(enumValues.attributes.enumvalue).map(
        key =>
          enumValues.attributes.enumvalue[key].enum_type_id ===
            getEnumTypeIndex('member_title') && (
            <MenuItem
              value={enumValues.attributes.enumvalue[key].column_id}
              key={key}
            >
              {enumValues.attributes.enumvalue[key].value}
            </MenuItem>
          ),
      )}
    </SelectInput>
    <TextInput
      model={model}
      modelErrors={memberErrors}
      attribute="first_name"
      label="First name"
      onChange={onChange}
    />
    <SelectInput
      attribute="particle"
      model={model}
      modelErrors={memberErrors}
      onChange={onChange}
      label="Particle"
      disabled={!isUserAdmin && !isUserDelegationHead && !isUserYO}
      nullable
    >
      {Object.keys(enumValues.attributes.enumvalue).map(
        key =>
          enumValues.attributes.enumvalue[key].enum_type_id ===
            getEnumTypeIndex('member_particle') && (
            <MenuItem
              value={enumValues.attributes.enumvalue[key].column_id}
              key={key}
            >
              {enumValues.attributes.enumvalue[key].value}
            </MenuItem>
          ),
      )}
    </SelectInput>
    <TextInput
      model={model}
      modelErrors={memberErrors}
      attribute="last_name"
      label="Last name"
      onChange={onChange}
    />
    <SelectInput
      attribute="delegation"
      model={model}
      modelErrors={memberErrors}
      onChange={onChange}
      label="Delegation"
    >
      {delegations.attributes.delegation.map(item => (
        <MenuItem value={item.column_id} key={item.column_id}>
          {item.name}
        </MenuItem>
      ))}
    </SelectInput>
    <MoneyInput
      model={model}
      modelErrors={memberErrors}
      attribute="fee"
      label="Fee"
      onChange={isUserAdmin ? onChange : null}
      disabled={!isUserAdmin}
    />
    <SelectInput
      attribute="member_type"
      model={model}
      modelErrors={memberErrors}
      onChange={onChange}
      label="Member type"
    >
      {Object.keys(enumValues.attributes.enumvalue).map(
        key =>
          enumValues.attributes.enumvalue[key].enum_type_id ===
            getEnumTypeIndex('member_type') && (
            <MenuItem
              value={enumValues.attributes.enumvalue[key].column_id}
              key={key}
            >
              {enumValues.attributes.enumvalue[key].value}
            </MenuItem>
          ),
      )}
    </SelectInput>
    <SelectInput
      attribute="preferred_language"
      model={model}
      modelErrors={memberErrors}
      onChange={onChange}
      label="Preferred language"
    >
      {Object.keys(enumValues.attributes.enumvalue).map(
        key =>
          enumValues.attributes.enumvalue[key].enum_type_id ===
            getEnumTypeIndex('preferred_language') && (
            <MenuItem
              value={enumValues.attributes.enumvalue[key].column_id}
              key={key}
            >
              {enumValues.attributes.enumvalue[key].value}
            </MenuItem>
          ),
      )}
    </SelectInput>
    <TextInput
      required
      model={model}
      modelErrors={memberErrors}
      attribute="private_email"
      label="Email 1"
      onChange={onChange}
    />

    <FormGroup row>
      <Button color="secondary" className={classes.button} onClick={cancel}>
        Cancel
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        className={classes.button}
      >
        Save
      </Button>
    </FormGroup>
  </form>
);

MemberFormForNewMember.propTypes = {
  model: PropTypes.object.isRequired,
  memberErrors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  delegations: PropTypes.object.isRequired,
  enumValues: PropTypes.object.isRequired,
  getEnumTypeIndex: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  isUserAdmin: PropTypes.bool.isRequired,
  isUserYO: PropTypes.bool.isRequired,
  isUserArtemis: PropTypes.bool.isRequired,
  isUserDelegationHead: PropTypes.bool.isRequired,
};

export default MemberFormForNewMember;
