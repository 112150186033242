import React from 'react';
import PropTypes from 'prop-types';
import ContainerPaper from 'components/ContainerPaper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { DATE_FORMAT, BACKEND_DATE, DATE_FORMAT_MASK } from 'utils/constants';
import { MESSAGE_TYPES } from 'containers/Snackbar/constants';
import moment from 'moment';

const styles = theme => ({
  button: {
    margin: `${2 * theme.spacing.unit}px 0`,
  },
});

class GeneratePaymentForm extends React.Component {
  state = {
    payment_method:
      this.props.paymentTypes.length && this.props.paymentTypes[0].value,
    payment_date: null,
  };

  handleValidation = () => {
    if (!this.state.payment_method || !this.state.payment_date) {
      return this.showErrorMessage('Both fields are required!');
    }
    if (this.props.selectedInvoices.length === 0) {
      return this.showErrorMessage('At least one item must be selected.');
    }

    return false;
  };

  showErrorMessage = message =>
    this.props.setMessage({
      type: MESSAGE_TYPES.ERROR,
      value: message,
    });

  handleSubmit = e => {
    e.preventDefault();

    if (this.handleValidation()) {
      return;
    }

    const data = {
      invoices: this.props.selectedInvoices,
      ...this.state,
    };
    this.props.submitForm(data);
  };
  handleChange = e =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  render() {
    const { classes } = this.props;

    return (
      <ContainerPaper>
        <Typography variant="h5" gutterBottom>
          Generate Payment
        </Typography>
        <form onSubmit={this.handleSubmit}>
          <Grid container direction="column" alignItems="flex-start">
            <Select
              id="payment_method"
              name="payment_method"
              value={this.state.payment_method}
              onChange={this.handleChange}
            >
              {this.props.paymentTypes.map(item => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                keyboard
                label="Payment Date"
                format={DATE_FORMAT}
                clearable
                adornmentPosition="end"
                value={this.state.payment_date}
                mask={value => (value ? DATE_FORMAT_MASK : [])}
                disableOpenOnEnter
                onChange={date => {
                  const event = {
                    target: {
                      name: 'payment_date',
                      value: date ? moment(date).format(BACKEND_DATE) : '',
                    },
                  };
                  this.handleChange(event);
                }}
              />
            </MuiPickersUtilsProvider>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </form>
      </ContainerPaper>
    );
  }
}

GeneratePaymentForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  selectedInvoices: PropTypes.array.isRequired,
};

export default withStyles(styles)(GeneratePaymentForm);
