import React from 'react';
import PropTypes from 'prop-types';
import _, { cloneDeep, isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import Recaptcha from 'react-recaptcha';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  Button,
  FormGroup,
  Typography,
  Grid,
} from '@material-ui/core';
import withCrud from 'containers/ModelWrapper';
import TextInput from 'components/TextInput';
import Money from 'components/Money';
import {
  makeSelectCountries,
  makeSelectEnumTypes,
  makeSelectEnumValues,
  makeSelectErrorObject,
  makeSelectAllEnumValues,
} from 'containers/App/selectors';
import { setErrorObject } from 'containers/App/actions';
import ContainerPaper from 'components/ContainerPaper';
import {
  eventRegistrationErrors as resetErrors,
  eventRegistrationParticipant,
  eventRegistrationParticipantSkeleton,
  eventRegistrationSkeleton,
  validEmailFormat,
} from './constants';
import { PageRootContainerStyles } from '../../mui-theme';
import ParticipantItem from './ParticipantItem';
import { DATE_FORMAT, RECAPTCHA_SITEKEY } from '../../utils/constants';

import { makeSelectSidebarIsOpen } from '../Layout/selectors';

const styles = theme => ({
  root: PageRootContainerStyles,
  button: {
    margin: theme.spacing.unit,
  },
  price: {
    textAlign: 'Right',
    fontSize: '20px',
  },
  GridMargin: {
    padding: '15px 15px',
  },
  additional: {
    paddingLeft: '20px',
  },
  participant: {
    ...theme.paper,
    marginTop: '15px',
    marginBottom: '15px',
    cursor: 'pointer',
  },
  mTopBottom: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  description: {
    '& img': {
      height: 'auto',
    },
  },
  robotoFont: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

class EventRegistrationForm extends React.Component {
  state = {
    eventRegistrationErrors: cloneDeep(resetErrors),
    participants: [0],
    data: null,
    eventAccesses: [],
    programs: {
      free: {},
      paid: {},
    },
    programCount: 0,
    freeProgramCount: 0,
    paidProgramCount: 0,
    recaptcha: false,
    totals: [0],
    accepted: false,
    isReviewScreen: false,
    resetErrors: cloneDeep(resetErrors),
    eventRegistrationParticipantSkeleton: cloneDeep(
      eventRegistrationParticipantSkeleton,
    ),
  };

  constructor(props) {
    super(props);
    this.inputParticipantChanged = this.inputParticipantChanged.bind(this);
    this.addParticipanTotal = this.addParticipanTotal.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onBackEdit = this.onBackEdit.bind(this);
    this.inputParticipantProgramChanged = this.inputParticipantProgramChanged.bind(
      this,
    );
    this.deleteParticipant = this.deleteParticipant.bind(this);
  }

  preLoadModel(props) {
    const { match } = props;
    this.props.showModel(match.params.slug);
  }

  componentWillMount() {
    this.preLoadModel(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const redirectUrl = _.get(
      nextProps,
      'submitResponse.attributes.redirect_url',
    );
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
    if (!this.state.data && nextProps.model.id) {
      const dayNumber =
        moment(nextProps.model.attributes.end_date).diff(
          moment(nextProps.model.attributes.start_date),
          'days',
        ) + 1;
      let programCount = 0;
      let freeProgramCount = 0;
      let paidProgramCount = 0;
      const programs = {
        free: {},
        paid: {},
      };

      for (let i = 0; i < dayNumber; i += 1) {
        const date = moment(nextProps.model.attributes.start_date)
          .add(i, 'day')
          .format('YYYY-MM-DD');
        programs.free[date] = [];
        programs.paid[date] = [];
      }

      const eventAccesses = [];
      if (nextProps.modelIncludes && nextProps.modelIncludes.length !== 0) {
        nextProps.modelIncludes.forEach(includedModel => {
          if (includedModel.type === 'programs') {
            const p = {
              id: includedModel.id,
              title: includedModel.attributes.title,
              date: includedModel.attributes.date,
              fee: includedModel.attributes.fee,
            };
            if (p.fee === 0 && programs.free[p.date]) {
              programs.free[p.date].push(p);
              freeProgramCount += 1;
              programCount += 1;
            } else if (programs.paid[p.date]) {
              programs.paid[p.date].push(p);
              paidProgramCount += 1;
              programCount += 1;
            }
          } else if (includedModel.type === 'eventAccesses') {
            eventAccesses.push(includedModel);
          }
        });
      }
      this.setState({
        data: nextProps.model,
        eventAccesses,
        programs,
        programCount,
        freeProgramCount,
        paidProgramCount,
      });
    }
  }

  addParticipanTotal = (total, key) => {
    const totals = cloneDeep(this.state.totals);
    totals[key] = total;
    this.setState({
      totals,
    });
  };

  componentWillUpdate(nextProps, nextState) {
    if (
      _.get(nextState, 'data.id') &&
      !isEqual(nextState.data, this.state.data)
    ) {
      this.props.setModel(cloneDeep(eventRegistrationSkeleton));
    }
  }

  inputChanged(event) {
    const selectedEventRegistration = cloneDeep(this.props.model);
    selectedEventRegistration.attributes[event.target.name] =
      event.target.value;
    this.props.setModel(selectedEventRegistration);
  }

  inputParticipantProgramChanged(event, key) {
    const selectedEventRegistration = cloneDeep(this.props.model);
    if (event.target.value) {
      selectedEventRegistration.attributes.participants[
        key
      ].attributes.program_ids.push(event.target.name);
    } else {
      const index = selectedEventRegistration.attributes.participants[
        key
      ].attributes.program_ids.indexOf(event.target.name);
      selectedEventRegistration.attributes.participants[
        key
      ].attributes.program_ids.splice(index, 1);
    }
    this.props.setModel(selectedEventRegistration);
  }

  inputParticipantChanged(event, key) {
    const selectedEventRegistration = cloneDeep(this.props.model);
    selectedEventRegistration.attributes.participants[key].attributes[
      event.target.name
    ] =
      event.target.value;
    this.props.setModel(selectedEventRegistration);
  }

  onBackEdit = () => {
    this.setState({
      isReviewScreen: false,
      recaptcha: false,
    });
  };

  onSubmit = () => {
    this.props.submitModel();
  };

  onValidate() {
    this.props.setErrorObject(null);
    const eventRegistrationErrors = cloneDeep(this.state.resetErrors);
    this.state.participants.filter(v => v !== 0).forEach(() => {
      eventRegistrationErrors.participants.push(
        _.cloneDeep(eventRegistrationParticipant),
      );
    });
    const defaultError = cloneDeep(eventRegistrationErrors);
    this.setState({ eventRegistrationErrors });

    const eventRegistration = this.props.model;

    const requiredParticipantsFields = {
      name: 'Name',
      email: 'Email',
      event_access_id: 'Access',
      phone: 'Phone',
      country: 'Delegation',
    };

    const requiredFields = {
      accept: 'Terms and conditions',
    };

    const emailFields = {
      email: 'Email',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!String(eventRegistration.attributes[field]).length) {
        eventRegistrationErrors[field] = `${
          requiredFields[field]
        } can not be empty`;
      }
    });

    Object.keys(requiredParticipantsFields).forEach(field => {
      eventRegistration.attributes.participants.forEach((item, index) => {
        if (!String(item.attributes[field]).length) {
          eventRegistrationErrors.participants[index][field] = `${
            requiredParticipantsFields[field]
          } can not be empty`;
        }
      });
    });

    Object.keys(emailFields).forEach(field => {
      eventRegistration.attributes.participants.forEach((item, index) => {
        if (
          item.attributes[field] &&
          !validEmailFormat.test(item.attributes[field])
        ) {
          eventRegistrationErrors.participants[index][field] = `${
            emailFields[field]
          } must have a valid email format`;
        }
      });
    });

    if (!isEqual(eventRegistrationErrors, defaultError)) {
      this.setState({ eventRegistrationErrors });
      this.props.showErrorToast('Form data invalid');
      return;
    }

    this.setState({ eventRegistrationErrors });

    eventRegistration.attributes.event_id = this.state.data.id;
    this.props.setModel(eventRegistration);
    this.setState({
      isReviewScreen: true,
    });
  }

  addParticipants = () => {
    const newKey =
      this.state.participants[this.state.participants.length - 1] + 1;
    const participants = cloneDeep(this.state.participants);
    participants.push(newKey);
    const model = cloneDeep(this.props.model);
    model.attributes.participants.push(
      cloneDeep(this.state.eventRegistrationParticipantSkeleton),
    );
    this.props.setModel(model);
    const eventRegistrationErrors = cloneDeep(
      this.state.eventRegistrationErrors,
    );
    eventRegistrationErrors.participants.push(eventRegistrationParticipant);
    this.setState({
      participants,
      eventRegistrationErrors,
    });
  };

  deleteParticipant = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.participants.length === 1) {
      return;
    }

    const model = _.cloneDeep(this.props.model);
    const eventRegistrationErrors = cloneDeep(
      this.state.eventRegistrationErrors,
    );

    eventRegistrationErrors.participants.splice(index, 1);

    model.attributes.participants = model.attributes.participants.filter(
      participant => participant !== model.attributes.participants[index],
    );

    this.setState(
      {
        participants: [...model.attributes.participants.keys()],
        eventRegistrationErrors,
      },
      () => {
        this.props.setModel(model);
      },
    );
  };

  render() {
    const {
      classes,
      enumValues,
      enumTypes,
      allEnumValues,
      countries,
      modelIncludes,
      sidebarIsOpen,
    } = this.props;

    const {
      eventRegistrationErrors,
      participants,
      data,
      programs,
      programCount,
      freeProgramCount,
      paidProgramCount,
      isReviewScreen,
      recaptcha,
      accepted,
      eventAccesses,
    } = this.state;

    const eventRegistration = this.props.model;

    if (
      isEmpty(eventRegistration) ||
      isEmpty(enumValues) ||
      isEmpty(enumTypes) ||
      isEmpty(countries) ||
      _.get(eventRegistration, 'id') ||
      !data
    ) {
      return null;
    }

    return (
      <ContainerPaper className={classes.paper}>
        <Typography variant="h3" component="h2">
          {data.attributes.title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary" gutterBottom>
          {moment(data.attributes.start_date).format(DATE_FORMAT)}-{moment(
            data.attributes.end_date,
          ).format(DATE_FORMAT)}
          <br />
          {data.attributes.location}
        </Typography>
        {!isReviewScreen && (
          <Typography
            component="div"
            gutterBottom
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: data.attributes.description }}
          />
        )}
        <Typography variant="h4" component="h3" gutterBottom>
          {!isReviewScreen ? 'Registration' : 'Review and Checkout'}
        </Typography>
        <Typography variant="h5" component="h4" gutterBottom>
          Participants
        </Typography>
        {!isReviewScreen && (
          <Typography
            className={classes.pos}
            color="textSecondary"
            gutterBottom
          >
            Please add all participants you would like to register on the event
          </Typography>
        )}
        <form
          onSubmit={e => {
            e.preventDefault();
            this.onSubmit();
          }}
        >
          {participants.map(key => (
            <ParticipantItem
              classes={classes}
              enumValues={enumValues}
              enumTypes={enumTypes}
              allEnumValues={allEnumValues}
              countries={countries}
              eventRegistration={eventRegistration}
              eventRegistrationErrors={eventRegistrationErrors}
              inputParticipantChanged={this.inputParticipantChanged}
              inputParticipantProgramChanged={
                this.inputParticipantProgramChanged
              }
              value={key}
              key={key}
              data={data}
              programs={programs}
              programCount={programCount}
              freeProgramCount={freeProgramCount}
              paidProgramCount={paidProgramCount}
              eventAccesses={eventAccesses}
              modelIncludes={modelIncludes}
              addParticipanTotal={this.addParticipanTotal}
              deleteParticipant={this.deleteParticipant}
              isReviewScreen={isReviewScreen}
            />
          ))}

          {!isReviewScreen && (
            <FormGroup row>
              <Button
                type="button"
                color="secondary"
                onClick={this.addParticipants}
              >
                Add more participant
              </Button>
            </FormGroup>
          )}
          <div className={classes.mTopBottom}>
            <TextInput
              model={eventRegistration}
              modelErrors={eventRegistrationErrors}
              attribute="dietary"
              label="Special dietary requirements, please, let us know if any"
              fullWidth
              onChange={event => {
                this.inputChanged(event);
              }}
              disabled={isReviewScreen}
            />
            <TextInput
              model={eventRegistration}
              modelErrors={eventRegistrationErrors}
              attribute="comment"
              label="Comments"
              fullWidth
              rows={4}
              onChange={event => {
                this.inputChanged(event);
              }}
              disabled={isReviewScreen}
            />
          </div>
          {!isReviewScreen && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(accepted)}
                  onChange={(event, checked) =>
                    this.setState({
                      accepted: checked,
                    })
                  }
                  name="accept"
                  color="primary"
                />
              }
              label={
                <a href="/terms-and-conditions" target="_blank">
                  I accept the Terms and Conditions
                </a>
              }
            />
          )}
          {!isReviewScreen && (
            <div className={classes.mTopBottom}>
              <Recaptcha
                sitekey={process.env.RECAPTCHA_SITEKEY || RECAPTCHA_SITEKEY}
                render="explicit"
                size={sidebarIsOpen ? 'normal' : 'compact'}
                verifyCallback={r => {
                  this.setState({ recaptcha: r });
                }}
              />
            </div>
          )}

          <ContainerPaper className={classes.paper}>
            <Grid container className={classes.GridMargin}>
              <Grid item xs={12}>
                <Typography component="div" align="right">
                  Total registration fee
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="div" className={classes.price}>
                  <Money amount={this.state.totals.reduce((a, b) => a + b)} />
                </Typography>
              </Grid>
            </Grid>
          </ContainerPaper>

          {data.attributes.useful_information && (
            <ContainerPaper className={classes.paper}>
              <Grid container className={classes.GridMargin}>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: data.attributes.useful_information,
                    }}
                  />
                </Grid>
              </Grid>
            </ContainerPaper>
          )}

          <FormGroup row>
            {!isReviewScreen && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                className={classes.button}
                disabled={!recaptcha || !accepted}
                onClick={this.onValidate}
              >
                Checkout
              </Button>
            )}
            {isReviewScreen && (
              <Button
                type="button"
                color="secondary"
                variant="contained"
                className={classes.button}
                onClick={this.onBackEdit}
              >
                Back to edit
              </Button>
            )}
            {isReviewScreen && (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
              >
                Pay & Register
              </Button>
            )}
          </FormGroup>
        </form>
      </ContainerPaper>
    );
  }
}

EventRegistrationForm.defaultProps = {};

EventRegistrationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  model: PropTypes.object,
  modelIncludes: PropTypes.array,
  countries: PropTypes.object,
  setModel: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  showModel: PropTypes.func.isRequired,
  showErrorToast: PropTypes.func.isRequired,
  setErrorObject: PropTypes.func.isRequired,
  enumValues: PropTypes.object,
  enumTypes: PropTypes.object,
  allEnumValues: PropTypes.object,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  countries: makeSelectCountries(),
  errorObject: makeSelectErrorObject(),
  enumValues: makeSelectEnumValues(),
  enumTypes: makeSelectEnumTypes(),
  allEnumValues: makeSelectAllEnumValues(),
  sidebarIsOpen: makeSelectSidebarIsOpen(),
});

const mapDispatchToProps = dispatch => ({
  setErrorObject: error => dispatch(setErrorObject(error)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(
  withStyles(styles)(
    withCrud(
      'eventRegistrationForm',
      'event-registration',
      EventRegistrationForm,
      false,
    ),
  ),
);
