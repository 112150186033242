import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep, isEqual } from 'lodash';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import injectReducer from 'utils/injectReducer';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import withCrud from 'containers/ModelWrapper';
import TextInput from 'components/TextInput';
import { makeSelectErrorObject } from 'containers/App/selectors';
import { setErrorObject } from 'containers/App/actions';

import reducer from './reducer';
import {
  passwordResetSkeleton,
  passwordResetErrors as resetErrors,
} from './constants';
import { setPasswordResetErrors } from './actions';
import { makeSelectPasswordResetErrors } from './selectors';
import ContainerPaper from '../../components/ContainerPaper';
import { PageRootContainerStyles } from '../../mui-theme';

const styles = theme => ({
  root: PageRootContainerStyles,
  button: {
    margin: theme.spacing.unit,
  },
});

class PasswordResetRequest extends React.Component {
  constructor(props) {
    super(props);
    this.inputChanged = this.inputChanged.bind(this);
    this.state = {
      requestSent: false,
    };
  }

  componentWillMount() {
    const { match } = this.props;
    const modelWithToken = cloneDeep(passwordResetSkeleton);
    modelWithToken.attributes.token = match.params.token;
    this.props.setModel(modelWithToken);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(this.props.model) && isEmpty(nextProps.model)) {
      this.setState({ requestSent: true });
    }
    if (
      !this.props.errorObject &&
      nextProps.errorObject &&
      nextProps.errorObject.response
    ) {
      this.displayBackendErrors(nextProps);
    }
  }

  displayBackendErrors(props) {
    const { errorObject, showErrorToast } = props;
    if (errorObject.response.data === 'token expired') {
      showErrorToast('Token expired.');
    }
  }

  inputChanged(event) {
    const selectedPasswordReset = cloneDeep(this.props.model);
    selectedPasswordReset.attributes[event.target.name] = event.target.value;
    this.props.setModel(selectedPasswordReset);
  }

  submit() {
    this.props.setErrorObject(null);
    const { model } = this.props;
    const passwordResetErrors = cloneDeep(resetErrors);
    const requiredFields = {
      new_password: 'New password',
      password_confirmation: 'Password confirmation',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!String(model.attributes[field]).length) {
        passwordResetErrors[field] = `${
          requiredFields[field]
        } can not be empty`;
      }
    });

    const isPasswordFormatValid = /^.*(?=.{3,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(
      model.attributes.password,
    );
    const isPasswordLengthValid =
      !isEmpty(model.attributes.password) &&
      model.attributes.password.length >= 6;
    const isPasswordEmpty =
      isEmpty(model.attributes.password) ||
      model.attributes.password.length === 0;

    if (!isPasswordEmpty && !isPasswordFormatValid) {
      passwordResetErrors.password =
        'Password should contain contain upper and lowercase letters and numbers';
    }
    if (!isPasswordEmpty && !isPasswordLengthValid) {
      passwordResetErrors.password =
        'Password should be at least 6 characters long.';
    }

    if (model.attributes.password !== model.attributes.password_confirmation) {
      passwordResetErrors.password_confirmation =
        'Retype password and password does not match.';
    }

    this.props.setErrors(passwordResetErrors);
    if (!isEqual(passwordResetErrors, resetErrors)) {
      this.props.showErrorToast('Form data invalid');
      return;
    }

    this.props.submitModel();
  }

  render() {
    const { classes, model, passwordResetErrors, errorObject } = this.props;

    if (errorObject && errorObject.response.data === 'token expired') {
      return (
        <ContainerPaper className={classes.paper}>
          <Typography variant="body2">
            Token expired, please send another password reset request.
          </Typography>
        </ContainerPaper>
      );
    }

    if (this.state.requestSent) {
      return (
        <ContainerPaper className={classes.paper}>
          <Typography variant="body2">
            Your password has been updated.
          </Typography>
        </ContainerPaper>
      );
    }

    if (isEmpty(model)) {
      return null;
    }

    return (
      <React.Fragment>
        {' '}
        <Helmet>
          <title>Password Reset Page | CIC</title>
        </Helmet>
        <ContainerPaper className={classes.paper}>
          <Typography variant="h5">Password reset form</Typography>

          <form
            onSubmit={e => {
              e.preventDefault();
              this.submit();
            }}
          >
            <TextInput
              model={model}
              modelErrors={passwordResetErrors}
              attribute="password"
              label="New password"
              onChange={this.inputChanged}
              type="password"
            />
            <TextInput
              model={model}
              modelErrors={passwordResetErrors}
              attribute="password_confirmation"
              label="Password confirmation"
              onChange={this.inputChanged}
              type="password"
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
            >
              Submit
            </Button>
          </form>
        </ContainerPaper>
      </React.Fragment>
    );
  }
}

PasswordResetRequest.propTypes = {
  match: PropTypes.object,
  model: PropTypes.object.isRequired,
  passwordResetErrors: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setModel: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  showErrorToast: PropTypes.func.isRequired,
  setErrorObject: PropTypes.func.isRequired,
  errorObject: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  passwordResetErrors: makeSelectPasswordResetErrors(),
  errorObject: makeSelectErrorObject(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setPasswordResetErrors(errors)),
  setErrorObject: error => dispatch(setErrorObject(error)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'passwordReset', reducer });

export default compose(
  withReducer,
  withConnect,
)(
  withStyles(styles)(
    withCrud(
      'passwordResetRequest',
      'passwordReset',
      PasswordResetRequest,
      false,
    ),
  ),
);
