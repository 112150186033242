import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import DatePicker from 'components/DatePicker';
import injectReducer from '../../utils/injectReducer';
import withCrud from '../ModelWrapper';
import reducer from './reducer';
import { updateDueDatesErrors as resetErrors } from './constants';
import { setUpdateDueDatesErrors } from './actions';
import { makeSelectUpdateDueDatesFormErrors } from './selectors';
import TextInput from '../../components/TextInput';
import ContainerPaper from '../../components/ContainerPaper';
import { BACKEND_DATE_TIME_FORMAT } from '../../utils/constants';

class UpdateDueDatesForm extends React.Component {
  constructor(props) {
    super(props);
    this.inputChanged = this.inputChanged.bind(this);
  }

  componentWillMount() {
    this.setDefaultModel();
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.setDefaultModel();
    }
  }

  setDefaultModel() {
    this.props.setErrors(cloneDeep(resetErrors));
    this.props.showModel(0);
  }

  inputChanged(event) {
    const selectedUpdateDueDates = cloneDeep(this.props.model);
    selectedUpdateDueDates.attributes[event.target.name] = event.target.value;
    this.props.setModel(selectedUpdateDueDates);
  }

  submit() {
    const { setErrors, model } = this.props;

    setErrors(cloneDeep(resetErrors));
    const updateDueDatesErrors = cloneDeep(resetErrors);

    const requiredFields = {
      due_date: 'Due Date',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!model.attributes[field].toString().length) {
        updateDueDatesErrors[field] = `${
          requiredFields[field]
        } can not be empty`;
      }
    });

    if (!isEqual(updateDueDatesErrors, resetErrors)) {
      this.props.setErrors(updateDueDatesErrors);
      return this.forceUpdate();
    }

    return this.props.submitModel();
  }

  formatDate = date => moment(date).format(BACKEND_DATE_TIME_FORMAT);

  render() {
    const { model, updateDueDatesErrors } = this.props;
    if (isEmpty(model) || isEmpty(updateDueDatesErrors)) {
      return null;
    }
    return (
      <ContainerPaper>
        <Helmet>
          <title>Update Due Dates</title>
        </Helmet>
        <Grid container style={{ marginBottom: '16px' }}>
          <Typography variant="h5" gutterBottom>
            Update Due Dates
          </Typography>
        </Grid>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <TextInput
            model={model}
            modelErrors={updateDueDatesErrors}
            attribute="number"
            label="Number of Invoices without Payment"
            disabled
          />
          <DatePicker
            required
            label="Due Date"
            value={model.attributes.due_date || {}}
            onChange={date => {
              const event = {
                target: {
                  name: 'due_date',
                  value: this.formatDate(date) || '',
                },
              };
              this.inputChanged(event);
            }}
          />
          <FormGroup row>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </FormGroup>
        </form>
      </ContainerPaper>
    );
  }
}

UpdateDueDatesForm.propTypes = {
  model: PropTypes.object,
  setModel: PropTypes.func.isRequired,
  updateDueDatesErrors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  showModel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  updateDueDatesErrors: makeSelectUpdateDueDatesFormErrors(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setUpdateDueDatesErrors(errors)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'updateDueDatesForm', reducer });

export default compose(
  withReducer,
  withConnect,
)(
  withCrud('updateDueDatesPage', 'update-due-dates', UpdateDueDatesForm, false),
);
