import { call, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';

import { setMessage } from 'containers/Snackbar/actions';
import { MESSAGE_TYPES } from 'containers/Snackbar/constants';
import { LOAD_ENUM_TYPES, LOAD_ENUM_VALUES, SUBMIT_ENUM } from './constants';
import {
  loadEnumTypesSuccessed,
  loadEnumTypesError,
  loadEnumValuesSuccessed,
  loadEnumValuesError,
  submitEnumSuccess,
  loadEnumValues,
} from './actions';
import { makeSelectParams, makeSelectEnumValue } from './selectors';

export function* loadEnumTypes() {
  try {
    const response = yield call(() => axios.get(`/enums`).then(res => res));
    yield put(loadEnumTypesSuccessed(response.data.data));
  } catch (err) {
    yield put(loadEnumTypesError(err));
  }
}

export function* getEnumValues() {
  const params = yield select(makeSelectParams());
  try {
    const response = yield call(() =>
      axios
        .get(`/enum-values`, {
          params,
          paramsSerializer: param => qs.stringify(param, { encode: false }),
        })
        .then(res => res),
    );
    yield put(loadEnumValuesSuccessed(response.data));
  } catch (err) {
    yield put(loadEnumValuesError(err));
  }
}

export function* submitEnum() {
  const data = yield select(makeSelectEnumValue());
  const params = yield select(makeSelectParams());
  data.attributes.enum_type_id = params.filter.enum_type_id;
  try {
    let url = '/enum-values';
    let method = 'post';
    if (data.id) {
      method = 'put';
      url += `/${data.id}`;
    }
    yield call(() =>
      axios({
        method,
        url,
        data: { data },
      }).then(res => res),
    );
    yield put(submitEnumSuccess());
    yield put(
      setMessage({
        type: MESSAGE_TYPES.SUCCESS,
        value: 'Enum has been saved',
      }),
    );
    yield put(loadEnumValues());
  } catch (error) {
    yield put(
      setMessage({
        type: MESSAGE_TYPES.ERROR,
        value: error.message,
      }),
    );
  }
}

// Individual exports for testing
export default function* enums() {
  yield takeLatest(LOAD_ENUM_TYPES, loadEnumTypes);
  yield takeLatest(LOAD_ENUM_VALUES, getEnumValues);
  yield takeLatest(SUBMIT_ENUM, submitEnum);
}
