/*
 *
 * Media constants
 *
 */

export const DEFAULT_ACTION = 'app/Media/DEFAULT_ACTION';
export const SET_MEDIA_ERROR = 'app/Media/SET_MEDIA_ERROR';

export const mediaErrors = {
  updated_at: '',
};
