/*
 * DelegationPage Messages
 *
 * This contains all the text for the DelegationPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.DelegationPage.header',
    defaultMessage: 'This is DelegationPage container !',
  },
  metaTitle: {
    id: 'app.containers.DelegationPage.metaTitle',
    defaultMessage: 'Delegation Welcome Page.',
  },
  pageTitle: {
    id: 'app.containers.DelegationPage.pageTitle',
    defaultMessage: 'Welcome to the CIC Membership Management System!',
  },
  welcomeText: {
    id: 'app.containers.DelegationPage.welcomeText',
    defaultMessage: 'You may handle personal data, or look for other Members.',
  },
});
