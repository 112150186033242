import React from 'react';
import PropTypes from 'prop-types';
import withCrud from 'containers/ModelWrapper';
import { isEmpty, isEqual } from 'lodash';

import TextInput from 'components/TextInput';

class DisplayMemberName extends React.Component {
  componentWillMount() {
    this.props.showModel(this.props.memberId);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.memberId, this.props.memberId)) {
      this.props.showModel(this.props.memberId);
    }
  }

  render() {
    const { model } = this.props;

    if (isEmpty(model)) {
      return null;
    }

    return (
      <TextInput
        model={model}
        label="Member Name"
        attribute="member_number"
        modelErrors={{ member_number: '' }}
        disabled
      />
    );
  }
}

DisplayMemberName.propTypes = {
  model: PropTypes.object.isRequired,
  memberId: PropTypes.string.isRequired,
  showModel: PropTypes.func.isRequired,
};

export default withCrud(
  'memberDisplayName',
  'members',
  DisplayMemberName,
  false,
);
