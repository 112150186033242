import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Menu from '@material-ui/core/Menu';

import AuthButton from 'containers/AuthButton';
import logo from 'images/logo-white-sm.svg';
import logoSmall from 'images/logo-small.svg';
import { Link as LinkTo, withRouter } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import { isEmpty } from 'lodash';
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../utils/constants';
import {
  deInitWebsocketConnection,
  initWebsocketConnection,
} from '../../utils/axios';
import NotificationListItem from './NotificationListItem';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: '0 24px',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    margin: '0',
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    padding: '0',
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  role: {
    color: '#FFF',
    padding: '5px 15px',
  },
  menuButton: {
    marginLeft: 0,
    [theme.breakpoints.down('lg')]: {
      marginLeft: `-${theme.spacing.unit}px`,
    },
    paddingLeft: 0,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  logo: {
    padding: '5px 0 0',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoSmall: {
    padding: '5px 0 0',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  badge: {
    margin: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 3,
  },
  welcomeText: {
    padding: '0 15px',
  },
});

const getUserName = user => {
  if (isEmpty(user) || !user.id) {
    return '';
  }
  const userName = [user.first_name, user.particle, user.last_name].join(' ');
  if (userName.length > 120 && isTablet) {
    return `${userName.substring(0, 120 - 3)}...`;
  }
  if (userName.length > 50 && isMobile) {
    return `${userName.substring(0, 50 - 3)}...`;
  }
  return userName;
};

class Header extends Component {
  channelType = 'member-notifications';

  state = {
    anchorEl: null,
    notifications: [],
  };

  constructor(props) {
    super(props);
    this.setNotificationList = this.setNotificationList.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
    this.notificationRead = this.notificationRead.bind(this);
    this.notificationArrivedCallback = this.notificationArrivedCallback.bind(
      this,
    );
  }

  componentWillMount() {
    initWebsocketConnection(
      this.channelType,
      '.NotificationArrived',
      this.notificationArrivedCallback,
    );
  }

  componentDidMount() {
    this.setNotificationList();
  }

  componentWillUnmount() {
    deInitWebsocketConnection(this.channelType);
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.setNotificationList();
    }
  }

  notificationArrivedCallback = async data => {
    const userId = parseInt(this.props.user.id, 10);
    if (userId !== data.user_id) {
      return;
    }
    this.setNotificationList();
  };

  handleNotificationsMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  setNotificationList = async () => {
    const response = await this.getList();
    this.setState({ notifications: response.data.data });
  };

  async getList() {
    return axios.get(`/notifications`).then(res => res);
  }

  notificationRead(notification) {
    return axios
      .put(`/notifications/${notification.id}`, { data: notification })
      .then(res => {
        this.setNotificationList();
        return res;
      });
  }
  deleteNotification(notification) {
    return axios.delete(`/notifications/${notification.id}`).then(res => {
      this.setNotificationList();
      return res;
    });
  }

  render() {
    const { classes, sidebarIsOpen, toggleSidebar, user, history } = this.props;
    const userExists = user && user.id;

    const welcomeText = userExists && `Welcome, ${getUserName(user)}`;
    const { anchorEl, notifications } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const hasNotification = notifications.length > 0;
    const newNotifications = notifications.filter(
      element => !element.attributes.read_at,
    );
    const hasNew = newNotifications.length > 0;

    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
          className={classNames(
            classes.appBar,
            sidebarIsOpen && classes.appBarShift,
          )}
        >
          <Toolbar disableGutters={!sidebarIsOpen}>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={toggleSidebar}
              className={classNames(
                classes.menuButton,
                sidebarIsOpen && classes.hide,
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.grow}
              style={{ flex: 1 }}
            >
              <LinkTo to="/">
                <img
                  src={logo}
                  alt="CIC"
                  height={HEADER_HEIGHT - 10}
                  className={classes.logo}
                />
                <img
                  src={logoSmall}
                  alt="CIC"
                  height={HEADER_HEIGHT - 10}
                  className={classes.logoSmall}
                />
              </LinkTo>
            </Typography>
            <Typography
              variant="subtitle2"
              color="inherit"
              className={classes.welcomeText}
            >
              {welcomeText}
            </Typography>
            {userExists && (
              <IconButton
                color="inherit"
                aria-haspopup="true"
                disabled={notifications.length === 0}
                onClick={this.handleNotificationsMenuOpen}
              >
                <Badge
                  className={classes.badge}
                  badgeContent={newNotifications.length}
                  max={9}
                  color="secondary"
                >
                  {!hasNotification && <NotificationsNoneIcon />}
                  {hasNotification && !hasNew && <NotificationsIcon />}
                  {hasNotification && hasNew && <NotificationsActiveIcon />}
                </Badge>
              </IconButton>
            )}
            <AuthButton />
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={this.handleMenuClose}
        >
          {notifications.map(notification => (
            <NotificationListItem
              key={notification.id}
              notification={notification}
              deleteNotification={this.deleteNotification}
              handleMenuClose={this.handleMenuClose}
              notificationRead={this.notificationRead}
              history={history}
            />
          ))}
        </Menu>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(withRouter)(
  withStyles(styles, { withTheme: true })(Header),
);
