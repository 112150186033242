import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { LOAD_AUCTIONS } from './constants';
import { loadAuctionsSuccess, loadAuctionsError } from './actions';

export function* loadAuctions() {
  try {
    const response = yield call(() => axios.get(`/auctions`).then(res => res));
    yield put(loadAuctionsSuccess(response.data));
  } catch (error) {
    yield put(loadAuctionsError(error));
  }
}

// Individual exports for testing
export default function* items() {
  yield takeLatest(LOAD_AUCTIONS, loadAuctions);
}
