import { cloneDeep } from 'lodash';
import { fromJS } from 'immutable';
import { SET_NEWS_ERROR, newsErrors } from './constants';

export const initialState = fromJS({
  newsErrors: cloneDeep(newsErrors),
});

function newsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NEWS_ERROR:
      return state.set('newsErrors', action.errors);
    default:
      return state;
  }
}

export default newsReducer;
