import {
  SET_INVOICE_ERROR,
  SET_PAYMENT_ERROR,
  SET_TRANSACTION_ERROR,
  SET_UPDATEDUEDATES_ERROR,
  SET_UPDATEFEE_ERROR,
  SET_NOTIFICATION_ERROR,
} from './constants';

export const setInvoiceErrors = errors => ({
  type: SET_INVOICE_ERROR,
  errors,
});

export const setPaymentErrors = errors => ({
  type: SET_PAYMENT_ERROR,
  errors,
});
export const setTransactionErrors = errors => ({
  type: SET_TRANSACTION_ERROR,
  errors,
});

export const setNotificationErrors = errors => ({
  type: SET_NOTIFICATION_ERROR,
  errors,
});

export const setUpdateFeeErrors = errors => ({
  type: SET_UPDATEFEE_ERROR,
  errors,
});

export const setUpdateDueDatesErrors = errors => ({
  type: SET_UPDATEDUEDATES_ERROR,
  errors,
});
