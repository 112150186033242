/*
 *
 * News actions
 *
 */

import { DEFAULT_ACTION, SET_NEWS_ERROR } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export const setNewsErrors = errors => ({
  type: SET_NEWS_ERROR,
  errors,
});
