export const SET_ITEM_ERROR = 'app/ItemPage/SET_ITEM_ERROR';
export const LOAD_AUCTIONS = 'app/ItemPage/LOAD_AUCTIONS';
export const LOAD_AUCTIONS_SUCCESS = 'app/ItemPage/LOAD_AUCTIONS_SUCCESS';
export const LOAD_AUCTIONS_ERROR = 'app/ItemPage/LOAD_AUCTIONS_ERROR';

export const itemSkeleton = Object.freeze({
  id: null,
  type: 'items',
  attributes: {
    item_type: 'OBJECT',
    name: '',
    starting_bid: '',
    donor: '',
    place: '',
    accomodation_included: '',
    website: '',
    participants: '',
    uniqueness: '',
    game_offered: '',
    suggested_time_frame: '',
    delegation: '',
    assigned_auctions: '',
    bidding_enable: true,
    notify_bid: false,
    hide_pay: false,
  },
  relationships: {
    files: {
      data: [],
    },
  },
});

export const bidderType = {
  OTHER_MEMBER: 'Other member',
  NONE: '-',
};

export const auctionSkeleton = Object.freeze({
  id: null,
  type: 'auctions',
  attributes: {
    name: '',
    start_time: '',
    end_time: '',
    live: true,
    enable_notification: false,
    notify_winner: true,
  },
});

export const auctionErrors = Object.freeze({
  name: '',
  start_time: '',
  end_time: '',
  live: true,
  enable_notification: false,
});

export const itemErrors = {
  item_type: '',
  name: '',
  starting_bid: '',
  donor: '',
  place: '',
  accomodation_included: '',
  website: '',
  participants: '',
  uniqueness: '',
  game_offered: '',
  suggested_time_frame: '',
  delegation: '',
  files: '',
  file_id: '',
  assigned_auctions: '',
};

export const bidSkeleton = Object.freeze({
  id: null,
  type: 'bids',
  attributes: {
    user_id: '',
    auction_id: '',
    auction_item_id: '',
    custom_name: '',
    amount: 0,
    comment: '',
  },
});

export const bidErrors = {
  user_id: '',
  auction_id: '',
  auction_item_id: '',
  custom_name: '',
  amount: '',
  comment: '',
};

export const sortByOptions = [
  {
    key: 'starting_bid',
    value: 'starting_bid_asc',
    label: 'Bid ascending',
    dir: 'asc',
  },
  {
    key: 'starting_bid',
    value: 'starting_bid_desc',
    label: 'Bid descending',
    dir: 'desc',
  },
  {
    key: 'name',
    value: 'name_asc',
    label: 'Name ascending',
    dir: 'asc',
  },
  {
    key: 'name',
    value: 'name_desc',
    label: 'Name descending',
    dir: 'desc',
  },
];
