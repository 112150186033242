import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Select from 'react-select';
import { TableCell, TableRow, withStyles } from '@material-ui/core';

import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import TextFilter from 'components/TextFilter';
import {
  DATE_FORMAT,
  DATE_FORMAT_MASK,
  BACKEND_DATE,
} from '../../../utils/constants';

const styles = () => ({
  datePicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  datePickerWrapper: {
    width: '145px',
  },
  tr: {
    maxWidth: '250px',
  },
});

const RegistrantsFilter = props => {
  const { params, delegations, programs } = props;
  const delegationOptions = delegations.attributes.delegation.map(item => ({
    value: item.column_id,
    label: item.column_id === 1 ? '<Country>' : item.name,
  }));
  const programsOptions = programs.map(program => ({
    value: program.id,
    label: program.attributes.title,
  }));
  return (
    <TableRow>
      <TableCell className={props.classes.tr}>
        <TextFilter
          Id="search-for-name"
          label="Search for Name"
          name="name"
          setFilter={props.setFilter}
        />
      </TableCell>
      <TableCell colSpan="2">
        <TextFilter
          Id="search-for-email"
          label="Search for email"
          name="email"
          setFilter={props.setFilter}
        />
      </TableCell>
      <TableCell className={props.classes.tr}>
        <Select
          isClearable
          options={delegationOptions}
          onChange={value => {
            const registrant = {
              target: {
                name: 'country',
                value: value ? value.value : null,
              },
            };
            props.setFilter(registrant);
          }}
        />
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={props.classes.datePicker}>
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="From"
              format={DATE_FORMAT}
              clearable
              adornmentPosition="end"
              value={
                (params.filter.registration_date_from &&
                  moment(params.filter.registration_date_from)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const registrant = {
                  target: {
                    name: 'registration_date_from',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(registrant);
              }}
            />
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="To"
              format={DATE_FORMAT}
              adornmentPosition="end"
              clearable
              value={
                (params.filter.registration_date_to &&
                  moment(params.filter.registration_date_to)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const registrant = {
                  target: {
                    name: 'registration_date_to',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(registrant);
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </TableCell>
      <TableCell colSpan="2" />

      <TableCell className={props.classes.tr}>
        <Select
          isClearable
          isMulti
          options={programsOptions}
          onChange={value => {
            const program = {
              target: {
                name: 'program',
                value: value ? value.map(v => v.value) : null,
              },
            };
            props.setFilter(program);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

RegistrantsFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  delegations: PropTypes.object,
  programs: PropTypes.array,
};

export default connect()(withStyles(styles)(RegistrantsFilter));
