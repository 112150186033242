import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ContainerPaper from 'components/ContainerPaper';

const styles = () => ({});

const AccessDeniedPage = props => {
  const { classes } = props;
  return (
    <ContainerPaper className={classes.paper}>
      <Typography variant="h4" gutterBottom>
        Access Denied
      </Typography>
    </ContainerPaper>
  );
};

AccessDeniedPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccessDeniedPage);
