import { createSelector } from 'reselect';
import { initialState } from './reducer';
/**
 * Direct selector to the authButton state domain
 */

const selectAuthButtonDomain = state => state.get('authButton', initialState);

/**
 * Other specific selectors
 */
export const makeUserSelector = () =>
  createSelector(selectAuthButtonDomain, substate => substate.toJS().user);

export const makeRolesSelector = () =>
  createSelector(selectAuthButtonDomain, substate => substate.toJS().roles);

/**
 * Default selector used by AuthButton
 */

const makeSelectAuthButton = () =>
  createSelector(selectAuthButtonDomain, substate => substate.toJS());

export default makeSelectAuthButton;
