import React from 'react';
import Typography from '@material-ui/core/Typography';

const PhoneBookDisclaimer = () => (
  <React.Fragment>
    <Typography variant="h6" gutterBottom>
      Rights / Data privacy protection
    </Typography>
    <Typography variant="body2" gutterBottom>
      All rights of the materials displayed on the website are reserved,
      especially the right to multiplication and distribution. The online
      Membership Directory or parts of it shall not, in any form, be copied,
      distributed or used for business or promotion purposes without previous
      written consent of the CIC Executive Committee. Access to the online
      Membership Directory is granted solely to the CIC members. By the
      introduction of the online Membership Directory, this source qualifies as
      the only official and up-to-date database to membership information.
      Previous printed versions of the Directory are no longer valid anymore.
      Note that the personal information listed in the database does not grant
      any rights to unauthorized official contact with CIC members. Unauthorized
      contact should include, but is not limited to, advertisements, promotions
      and bulk e-mailing. In official matters, CIC members shall be addressed
      solely through the Heads of Delegations or/and the CIC Administrative
      Office, depending on the nature of the respective matter. Any other form
      of communication and membership administration qualifies as unofficial and
      does not have a binding nature.
    </Typography>
    <Typography variant="h6" gutterBottom>
      Disclaimer
    </Typography>
    <Typography variant="body2">
      The membership directory was complied and is updated by the CIC
      Headquarters and the CIC members on a regular basis. Note that the
      information published within the database was provided by the CIC members
      on voluntary basis and CIC does not have the means to verify their
      correctness.
    </Typography>
  </React.Fragment>
);

export default PhoneBookDisclaimer;
