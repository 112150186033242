import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { userHasRole } from 'utils/userHasRole';
import { makeRolesSelector } from 'containers/AuthButton/selectors';

import {
  makeSelectDelegations,
  makeSelectEnumValues,
  makeSelectEnumTypes,
} from 'containers/App/selectors';

const styles = theme => ({
  root: {
    margin: `0 ${theme.spacing.unit * 2}px`,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 150,
  },
});

const PhoneBookFilter = props => {
  const PARTNER_DELEGATION_NAME = '<Partner>';
  const nameChars = 'abcdefghijklmnopqrstuvwxyz'.split('').map(char => ({
    value: char,
    label: char.toUpperCase(),
  }));
  nameChars.unshift({
    value: null,
    label: 'All',
  });

  const delegations = props.delegations.attributes.delegation
    .map(item => ({
      value: item.column_id,
      label: item.name,
    }))
    .filter(delegation => delegation.label !== PARTNER_DELEGATION_NAME);

  const { enumTypes, enumValues, classes } = props;

  const getEnumTypeIndex = machineName =>
    enumTypes.attributes.enumtype[
      enumTypes.attributes.enumtype
        .map(e => e.machine_name)
        .indexOf(machineName)
    ].column_id;
  const getEnumTypeArray = machineName =>
    Object.keys(enumValues.attributes.enumvalue)
      .filter(
        key =>
          enumValues.attributes.enumvalue[key].enum_type_id ===
          getEnumTypeIndex(machineName),
      )
      .map(key => ({
        label: enumValues.attributes.enumvalue[key].value,
        value: enumValues.attributes.enumvalue[key].column_id,
      }));

  const memberTypes = getEnumTypeArray('member_type');
  const expertiseTypes = getEnumTypeArray('expertise');
  const roleTypes = [
    { label: 'Council', value: 'COUNCIL' },
    { label: 'Executive Committee', value: 'EXECUTIVE_COMMITTEE' },
    { label: 'Head of Delegation', value: 'HEAD_OF_DELEGATION' },
  ];
  const memberFunctionTypes = getEnumTypeArray('member_functions');
  const isUserYO = userHasRole(props.roles, ['YOUNG_OPINION']);

  return (
    <FormGroup row className={classes.root}>
      <div className={classes.formControl}>
        <Typography variant="subtitle2">Filter last name letter</Typography>
        <Select
          isClearable
          placeholder="All"
          options={nameChars}
          onChange={value => {
            const event = {
              target: {
                name: 'last_name_letter',
                value: value ? value.value : null,
              },
            };
            props.setFilter(event);
          }}
        />
      </div>
      {!isUserYO && (
        <div className={classes.formControl}>
          <Typography variant="subtitle2">Filter membership type</Typography>
          <Select
            isClearable
            placeholder="All"
            options={memberTypes}
            onChange={value => {
              const event = {
                target: {
                  name: 'member_type',
                  value: value ? value.value : null,
                },
              };
              props.setFilter(event);
            }}
          />
        </div>
      )}
      <div className={classes.formControl}>
        <Typography variant="subtitle2">Filter delegation</Typography>
        <Select
          isClearable
          placeholder="All"
          options={delegations}
          onChange={value => {
            const event = {
              target: {
                name: 'delegation',
                value: value ? value.value : null,
              },
            };
            props.setFilter(event);
          }}
        />
      </div>
      <div className={classes.formControl}>
        <Typography variant="subtitle2">Filter expertise</Typography>
        <Select
          isClearable
          placeholder="All"
          options={expertiseTypes}
          onChange={value => {
            const event = {
              target: {
                name: 'expertise',
                value: value ? value.value : null,
              },
            };
            props.setFilter(event);
          }}
        />
      </div>
      <div className={classes.formControl}>
        <Typography variant="subtitle2">
          Head of delegation and Executive Committee
        </Typography>
        <Select
          isClearable
          placeholder="All"
          options={roleTypes}
          onChange={value => {
            const event = {
              target: {
                name: 'role',
                value: value ? value.value : null,
              },
            };
            props.setFilter(event);
          }}
        />
      </div>
      <div className={classes.formControl}>
        <Typography variant="subtitle2">Filter member functions</Typography>
        <Select
          isClearable
          placeholder="All"
          options={memberFunctionTypes}
          onChange={value => {
            const event = {
              target: {
                name: 'member_functions',
                value: value ? value.value : null,
              },
            };
            props.setFilter(event);
          }}
        />
      </div>
    </FormGroup>
  );
};

PhoneBookFilter.defaultProps = {
  delegations: {
    attributes: {
      delegation: [],
    },
  },
};

PhoneBookFilter.propTypes = {
  roles: PropTypes.array.isRequired,
  delegations: PropTypes.object,
  setFilter: PropTypes.func.isRequired,
  enumTypes: PropTypes.object.isRequired,
  enumValues: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  delegations: makeSelectDelegations(),
  enumValues: makeSelectEnumValues(),
  enumTypes: makeSelectEnumTypes(),
  roles: makeRolesSelector(),
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(PhoneBookFilter),
);
