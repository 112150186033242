/*
 *
 * Members actions
 *
 */

import { DEFAULT_ACTION, SET_MEDIA_ERROR } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export const setMediaErrors = errors => ({
  type: SET_MEDIA_ERROR,
  errors,
});
