import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import withCrud from 'containers/ModelWrapper';
import { isEmpty, clone, cloneDeep, isEqual } from 'lodash';
import moment from 'moment';

import { BACKEND_DATE } from 'utils/constants';
import { MESSAGE_TYPES } from 'containers/Snackbar/constants';
import ConfirmDialog from 'components/ConfirmDialog';
import {
  makeSelectCountries,
  makeSelectDelegations,
  makeSelectEnumTypes,
  makeSelectEnumValues,
  makeSelectGenders,
  makeSelectErrorObject,
} from 'containers/App/selectors';
import { setErrorObject } from 'containers/App/actions';
import { makeUserSelector } from 'containers/AuthButton/selectors';
import { setMessage } from 'containers/Snackbar/actions';
import injectReducer from 'utils/injectReducer';
import { userHasRole } from 'utils/userHasRole';
import reducer from './reducer';
import { memberSkeleton, memberErrors as resetErrors } from './constants';
import { makeSelectMemberErrors } from './selectors';
import { setMemberErrors } from './actions';
import MemberFormForExistingMember from './MemberFormForExistingMember';
import MemberFormForNewMember from './MemberFormForNewMember';
import MemberInvoiceTable from '../Invoice/InvoicesTable';
import Authorization from '../../utils/authorization';
import MemberFunctionList from './MemberFunctionList';
import {
  PageRootContainerOpenedStyles,
  PageRootContainerStyles,
} from '../../mui-theme';
import ContainerPaper from '../../components/ContainerPaper';
import { makeSelectSidebarIsOpen } from '../Layout/selectors';

const styles = theme => ({
  rootClosed: {
    ...PageRootContainerStyles,
    marginLeft: '0px !important',
  },
  rootOpened: {
    ...PageRootContainerOpenedStyles,
    marginLeft: '0px !important',
  },
  button: {
    margin: theme.spacing.unit,
  },
  dateSelector: {
    marginBottom: '20px',
  },
});

const AdminComponent = Authorization([
  'SYSTEM_ADMINISTRATOR',
  'MEMBERSHIP_ADMINISTRATOR',
  'HEAD_OF_DELEGATION',
  'YOUNG_OPINION',
  'AUTHENTICATED_USER',
]);

const Invoices = ({ member }) => <MemberInvoiceTable member={member} />;
Invoices.propTypes = {
  member: PropTypes.object.isRequired,
};

const InvoiceTable = AdminComponent(Invoices);

class MemberForm extends React.Component {
  constructor(props) {
    super(props);
    this.setSubscription = this.setSubscription.bind(this);
    this.loadSubscriptions = this.loadSubscriptions.bind(this);
    this.state = {
      activateDialogOpen: false,
      dialogTitle: '',
      birthDate: null,
      leftCIC: null,
      memberSince: null,
      profileImage: null,
      isUserAdmin: userHasRole(props.roles, [
        'SYSTEM_ADMINISTRATOR',
        'MEMBERSHIP_ADMINISTRATOR',
      ]),
      isUserYO: userHasRole(props.roles, ['YOUNG_OPINION']),
      isUserArtemis: userHasRole(props.roles, ['ARTEMIS']),
      isUserDelegationHead: userHasRole(props.roles, ['HEAD_OF_DELEGATION']),
      subscriptions: cloneDeep(memberSkeleton.attributes.subscriptions),
    };
  }

  componentWillMount() {
    if (
      this.state.isUserAdmin ||
      this.state.isUserDelegationHead ||
      this.state.isUserYO ||
      this.state.isUserArtemis
    ) {
      this.preLoadModel(this.props);
    } else {
      this.props.showModel(this.props.user.member_id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.errorObject &&
      nextProps.errorObject &&
      nextProps.errorObject.response
    ) {
      this.displayBackendErrors(nextProps);
      return;
    }

    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      if (isEmpty(this.props.model.attributes.status)) {
        this.preLoadModel(this.props);
        return;
      }
      const memberTypes = {
        NEW: 'waiting-for-approval',
        ACTIVE: 'approved',
      };
      const backToPage =
        this.state.isUserAdmin ||
        this.state.isUserDelegationHead ||
        this.state.isUserYO
          ? `/admin/members/${memberTypes[this.props.model.attributes.status]}`
          : `/`;
      this.props.history.push(backToPage);
      return;
    }

    if (isEmpty(nextProps.model)) {
      return;
    }

    if (
      !isEmpty(nextProps.modelIncludes) &&
      !isEqual(this.props.modelIncludes, nextProps.modelIncludes)
    ) {
      this.loadSubscriptions(nextProps);
    }

    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.preLoadModel(nextProps);
    }

    const birthDate = moment(nextProps.model.attributes.date_of_birth);
    const memberSince = moment(nextProps.model.attributes.member_since);
    const leftCIC = moment(nextProps.model.attributes.left_cic);

    this.setState({
      birthDate: birthDate.isValid() ? birthDate : null,
      memberSince: memberSince.isValid() ? memberSince : null,
      leftCIC: leftCIC.isValid() ? leftCIC : null,
      profileImage: this.state.profileImage
        ? this.state.profileImage
        : nextProps.model.attributes.profile_image,
    });
  }

  displayBackendErrors(props) {
    const { errorObject, showErrorToast } = props;
    const memberErrors = cloneDeep(resetErrors);
    const privateEmailError = errorObject.response.data.errors.filter(
      error => error.source.pointer === 'data.attributes.private_email',
    );

    if (privateEmailError.length) {
      memberErrors.private_email = 'This email address has already been taken.';
      showErrorToast('Private email address has already been taken');
    }

    this.props.setErrors(memberErrors);
  }

  preLoadModel(props) {
    const { match } = props;
    this.props.setModel(cloneDeep(memberSkeleton));
    this.props.setErrors(cloneDeep(resetErrors));
    if (parseInt(match.params.memberId, 10)) {
      this.props.showModel(match.params.memberId);
    }
  }

  loadSubscriptions({ modelIncludes }) {
    const subscriptions = modelIncludes.filter(
      model => model.type === 'subscriptions',
    );

    const newSubscriptionsState = cloneDeep(
      memberSkeleton.attributes.subscriptions,
    );

    subscriptions.forEach(({ attributes: { category } }) => {
      newSubscriptionsState[category.toLowerCase()] = 1;
    });

    this.setState({ subscriptions: clone(newSubscriptionsState) });
  }

  inputChanged(event) {
    const selectedMember = cloneDeep(this.props.model);
    selectedMember.attributes[event.target.name] = event.target.value;
    this.props.setModel(selectedMember);
  }

  setSubscription(event) {
    const { state } = this;
    const nestedAttributes = event.target.name.split('.');

    state[nestedAttributes[0]][nestedAttributes[1]] = event.target.value;
    this.setState(state);
  }

  submit(activate = false) {
    this.props.setErrorObject(null);
    this.props.setErrors(null);
    const { model } = this.props;
    const {
      birthDate,
      leftCIC,
      memberSince,
      subscriptions,
      profileImage,
    } = this.state;
    const memberErrors = cloneDeep(resetErrors);
    const requiredFields = {
      preferred_language: 'Preferred language',
      last_name: 'Last name',
      delegation: 'Delegation',
      member_type: 'Member type',
      private_email: 'Email 1',
    };

    const emailFields = {
      private_email: 'Email 1',
      office_email: 'Email 2',
      other_email: 'Email 3',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!String(model.attributes[field]).length) {
        memberErrors[field] = `${requiredFields[field]} can not be empty`;
      }
    });

    const validFloatFormat = /^\d*(\.\d{2})?$/;

    if (!validFloatFormat.test(model.attributes.fee)) {
      memberErrors.fee =
        'Fee should contain numbers only and maximum 2 digits in the fractional part';
    }

    if (model.attributes.fee < 0 || model.attributes.fee > 99999999.99) {
      memberErrors.fee = 'Fee should have valid value';
    }

    const validEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    Object.keys(emailFields).forEach(field => {
      if (
        model.attributes[field] &&
        !validEmailFormat.test(model.attributes[field])
      ) {
        memberErrors[field] = `${
          emailFields[field]
        } must have a valid email format`;
      }
    });

    const isPasswordFormatValid = /^.*(?=.{3,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(
      model.attributes.password,
    );
    const isPasswordLengthValid =
      !isEmpty(model.attributes.password) &&
      model.attributes.password.length >= 6;
    const isPasswordEmpty =
      isEmpty(model.attributes.password) ||
      model.attributes.password.length === 0;
    const isCurrentPasswordEmpty =
      isEmpty(model.attributes.current_password) ||
      model.attributes.current_password.length === 0;
    if (!isPasswordEmpty && !isPasswordFormatValid) {
      memberErrors.password =
        'Password should contain contain upper and lowercase letters and numbers';
    }
    if (!isPasswordEmpty && !isPasswordLengthValid) {
      memberErrors.password = 'Password should be at least 6 characters long.';
    }

    if (model.attributes.password !== model.attributes.password_confirmation) {
      memberErrors.password_confirmation =
        'Retype password and password does not match.';
    }

    if (
      !this.state.isUserAdmin &&
      !this.state.isUserYO &&
      !isPasswordEmpty &&
      isCurrentPasswordEmpty
    ) {
      memberErrors.current_password =
        'Provide current password for password change';
    }

    if (
      model.id !== null &&
      model.attributes.status === 'ACTIVE' &&
      model.attributes.member_since === '' &&
      !memberSince
    ) {
      memberErrors.member_since =
        'Member since cannot be empty for active members.';
    }

    this.props.setErrors(memberErrors);
    if (!isEqual(memberErrors, resetErrors)) {
      this.props.showErrorToast('Form data invalid');
      return;
    }

    const selectedMember = cloneDeep(this.props.model);
    if (activate) {
      selectedMember.attributes.status = 'ACTIVE';
    }

    if (birthDate) {
      selectedMember.attributes.date_of_birth = birthDate.format(BACKEND_DATE);
    }

    if (memberSince) {
      selectedMember.attributes.member_since = memberSince.format(BACKEND_DATE);
    }

    if (leftCIC) {
      selectedMember.attributes.left_cic = leftCIC.format(BACKEND_DATE);
    }

    if (subscriptions) {
      selectedMember.attributes.subscriptions = subscriptions;
    }

    if (profileImage) {
      selectedMember.attributes.profile_image = profileImage.id;
    }

    this.props.setModel(selectedMember);

    this.props.submitModel();
  }

  openDialog(model) {
    this.setState(() => ({
      activateDialogOpen: true,
      dialogTitle: `Are you sure you want to activate "${
        model.attributes.first_name
      } ${model.attributes.last_name}" member?`,
    }));
  }

  onCancel() {
    this.props.setErrors(resetErrors);
    this.props.setModel({});
  }
  copyName = () => {
    const model = this.props.model.attributes;

    let name = 'Dear';
    const firstTitle = this.getEnumTypeArray('member_title').find(
      title => title.value === model.first_title,
    );
    if (firstTitle) {
      name += ` ${firstTitle.label}`;
    }

    const secondTitle = this.getEnumTypeArray('member_title').find(
      title => title.value === model.second_title,
    );
    if (secondTitle) {
      name += ` ${secondTitle.label}`;
    }

    name += ` ${model.last_name},`;

    navigator.clipboard.writeText(name);
    this.props.setMessage({
      type: MESSAGE_TYPES.SUCCESS,
      value: "'Copy name' action was successful!",
    });
  };

  copyNameAndAddress = () => {
    const model = this.props.model.attributes;

    let name = '';
    const firstTitle = this.getEnumTypeArray('member_title').find(
      title => title.value === model.first_title,
    );
    if (firstTitle) {
      name += `${firstTitle.label}`;
    }

    const secondTitle = this.getEnumTypeArray('member_title').find(
      title => title.value === model.second_title,
    );
    if (secondTitle) {
      name += ` ${secondTitle.label}`;
    }

    name += ` ${model.first_name}`;

    const particle = this.getEnumTypeArray('member_particle').find(
      item => item.value === model.particle,
    );
    if (particle) {
      name += ` ${particle.label}`;
    }

    name += ` ${model.last_name}`;

    const country = this.props.countries.attributes.country.find(
      countryObj => countryObj.column_id === model.postal_country,
    );

    const text = [
      name,
      model.postal_address,
      `${model.postal_zip} ${model.postal_city}`,
      country ? country.name : null,
    ].join('\n');

    navigator.clipboard.writeText(text);
    this.props.setMessage({
      type: MESSAGE_TYPES.SUCCESS,
      value: "'Copy name and address' action was successful!",
    });
  };

  getEnumTypeIndex = machineName =>
    this.props.enumTypes.attributes.enumtype[
      this.props.enumTypes.attributes.enumtype
        .map(e => e.machine_name)
        .indexOf(machineName)
    ].column_id;

  getEnumTypeArray = machineName =>
    Object.keys(this.props.enumValues.attributes.enumvalue)
      .filter(
        key =>
          this.props.enumValues.attributes.enumvalue[key].enum_type_id ===
          this.getEnumTypeIndex(machineName),
      )
      .map(key => ({
        label: this.props.enumValues.attributes.enumvalue[key].value,
        value: this.props.enumValues.attributes.enumvalue[key].column_id,
      }));

  uploadProfileImage = profileImage => {
    this.setState({ profileImage });
  };

  handleEditorChange = event => {
    this.inputChanged({
      target: {
        name: event.target.id,
        value: event.target.getContent(),
      },
    });
  };

  render() {
    const {
      model,
      classes,
      memberErrors,
      countries,
      delegations,
      enumTypes,
      enumValues,
      genders,
      sidebarIsOpen,
    } = this.props;

    if (
      isEmpty(model) ||
      isEmpty(countries) ||
      isEmpty(delegations) ||
      isEmpty(enumValues) ||
      isEmpty(enumTypes) ||
      isEmpty(genders)
    ) {
      return null;
    }

    if (
      (this.state.isUserAdmin || this.state.isUserDelegationHead) &&
      isEmpty(this.props.match.params.memberId)
    ) {
      return null;
    }

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;

    return (
      <React.Fragment>
        {model.id && (
          <React.Fragment>
            <ContainerPaper className={rootClass}>
              <ConfirmDialog
                open={this.state.activateDialogOpen}
                onClose={() => {
                  this.setState(() => ({ activateDialogOpen: false }));
                }}
                cancelAction={() => {
                  this.setState(() => ({ activateDialogOpen: false }));
                }}
                confirmAction={() => {
                  this.submit(true);
                  this.setState(() => ({ activateDialogOpen: false }));
                }}
                title={this.state.dialogTitle}
                description="This action can not be undone."
              />
              <MemberFormForExistingMember
                model={model}
                memberErrors={memberErrors}
                classes={classes}
                countries={countries}
                delegations={delegations}
                enumTypes={enumTypes}
                enumValues={enumValues}
                genders={genders}
                getEnumTypeIndex={this.getEnumTypeIndex}
                onSubmit={(event, activate = false) => {
                  event.preventDefault();
                  this.submit(activate);
                }}
                onChange={event => {
                  this.inputChanged(event);
                }}
                uploadProfileImage={this.uploadProfileImage}
                handleEditorChange={this.handleEditorChange}
                openDialog={() => this.openDialog(model)}
                cancel={() => this.props.setModel({})}
                birthDate={this.state.birthDate}
                leftCIC={this.state.leftCIC}
                memberSince={this.state.memberSince}
                profileImage={this.state.profileImage}
                subscriptions={this.state.subscriptions}
                setSubscription={this.setSubscription}
                isUserAdmin={this.state.isUserAdmin}
                isUserDelegationHead={this.state.isUserDelegationHead}
                isUserYO={this.state.isUserYO}
                isUserArtemis={this.state.isUserArtemis}
                copyName={this.copyName}
                copyNameAndAddress={this.copyNameAndAddress}
              />
            </ContainerPaper>
            <InvoiceTable member={model} />
            <MemberFunctionList member={model} />
          </React.Fragment>
        )}
        {!model.id && (
          <ContainerPaper className={classes.paper}>
            <MemberFormForNewMember
              model={model}
              memberErrors={memberErrors}
              classes={classes}
              delegations={delegations}
              enumTypes={enumTypes}
              enumValues={enumValues}
              genders={genders}
              getEnumTypeIndex={this.getEnumTypeIndex}
              onSubmit={(event, activate = true) => {
                event.preventDefault();
                this.submit(activate);
              }}
              onChange={event => {
                this.inputChanged(event);
              }}
              openDialog={() => this.openDialog(model)}
              cancel={() => this.props.setModel({})}
              isUserAdmin={this.state.isUserAdmin}
              isUserYO={this.state.isUserYO}
              isUserArtemis={this.state.isUserArtemis}
              isUserDelegationHead={this.state.isUserDelegationHead}
            />
          </ContainerPaper>
        )}
      </React.Fragment>
    );
  }
}

MemberForm.defaultProps = {};

MemberForm.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  modelIncludes: PropTypes.array,
  memberErrors: PropTypes.object,
  errorObject: PropTypes.object,
  countries: PropTypes.object,
  delegations: PropTypes.object,
  enumTypes: PropTypes.object,
  enumValues: PropTypes.object,
  setModel: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setErrorObject: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  showErrorToast: PropTypes.func.isRequired,
  genders: PropTypes.object,
  history: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  memberErrors: makeSelectMemberErrors(),
  errorObject: makeSelectErrorObject(),
  countries: makeSelectCountries(),
  delegations: makeSelectDelegations(),
  enumTypes: makeSelectEnumTypes(),
  enumValues: makeSelectEnumValues(),
  genders: makeSelectGenders(),
  user: makeUserSelector(),
  sidebarIsOpen: makeSelectSidebarIsOpen(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setMemberErrors(errors)),
  setErrorObject: error => dispatch(setErrorObject(error)),
  setMessage: message => dispatch(setMessage(message)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'memberForm', reducer });

export default compose(
  withReducer,
  withConnect,
)(withStyles(styles)(withCrud('memberFormPage', 'members', MemberForm, false)));
