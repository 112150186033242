import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import Button from '@material-ui/core/Button';
import MoneyInput from 'components/MoneyInput';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';

import injectReducer from '../../utils/injectReducer';
import withCrud from '../ModelWrapper';
import reducer from './reducer';
import { updateFeeErrors as resetErrors } from './constants';
import { setUpdateFeeErrors } from './actions';
import { makeSelectUpdateFeesFormErrors } from './selectors';
import TextInput from '../../components/TextInput';
import ContainerPaper from '../../components/ContainerPaper';

class UpdateFeesForm extends React.Component {
  constructor(props) {
    super(props);
    this.inputChanged = this.inputChanged.bind(this);
  }

  componentWillMount() {
    this.setDefaultModel();
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.setDefaultModel();
    }
  }

  setDefaultModel() {
    this.props.setErrors(cloneDeep(resetErrors));
    this.props.showModel(0);
  }

  inputChanged(event) {
    const selectedUpdateFee = cloneDeep(this.props.model);
    selectedUpdateFee.attributes[event.target.name] = event.target.value;
    this.props.setModel(selectedUpdateFee);
  }

  submit() {
    const { setErrors, model } = this.props;

    setErrors(cloneDeep(resetErrors));
    const updateFeeErrors = cloneDeep(resetErrors);

    const requiredFields = {
      amount: 'Fee for Individual Members',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!model.attributes[field].toString().length) {
        updateFeeErrors[field] = `${requiredFields[field]} can not be empty`;
      }
    });

    if (!isEqual(updateFeeErrors, resetErrors)) {
      this.props.setErrors(updateFeeErrors);
      return this.forceUpdate();
    }

    return this.props.submitModel();
  }

  render() {
    const { model, updateFeeErrors } = this.props;

    if (isEmpty(model) || isEmpty(updateFeeErrors)) {
      return null;
    }

    return (
      <ContainerPaper>
        <Helmet>
          <title>Update Fees</title>
        </Helmet>
        <Grid container style={{ marginBottom: '16px' }}>
          <Typography variant="h5" gutterBottom>
            Update Fees
          </Typography>
        </Grid>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <TextInput
            model={model}
            modelErrors={updateFeeErrors}
            attribute="number"
            label="Number of Active Individual Member"
            disabled
          />
          <MoneyInput
            model={model}
            modelErrors={updateFeeErrors}
            onChange={this.inputChanged}
            attribute="amount"
            label="Fee for Individual Members"
          />
          <FormGroup row>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </FormGroup>
        </form>
      </ContainerPaper>
    );
  }
}

UpdateFeesForm.propTypes = {
  model: PropTypes.object,
  setModel: PropTypes.func.isRequired,
  updateFeeErrors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  showModel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  updateFeeErrors: makeSelectUpdateFeesFormErrors(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setUpdateFeeErrors(errors)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'updateFeesForm', reducer });

export default compose(
  withReducer,
  withConnect,
)(withCrud('updateFeesPage', 'update-fees', UpdateFeesForm, false));
