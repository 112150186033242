import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';

import { HEADER_HEIGHT } from 'utils/constants';
import {
  SIDEBAR_OPENED_WIDTH,
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_XS,
} from '../../mui-theme';
import AnonymMenu from './AnonymMenu';
import UserMenu from './UserMenu';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: SIDEBAR_OPENED_WIDTH,
    margin: '0',
    padding: '0',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: SIDEBAR_WIDTH_XS,
    [theme.breakpoints.up('sm')]: {
      width: SIDEBAR_WIDTH,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: HEADER_HEIGHT,
  },
});

function Sidebar(props) {
  const { classes, theme, sidebarIsOpen, toggleSidebar, user } = props;
  const Menu = isEmpty(user) ? AnonymMenu : UserMenu;

  return (
    <Drawer
      variant="permanent"
      onClick={() => {
        if (!sidebarIsOpen) {
          if (!isEmpty(user) || (isEmpty(user) && !isMobile)) toggleSidebar();
        }
      }}
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !sidebarIsOpen && classes.drawerPaperClose,
        ),
      }}
      open={sidebarIsOpen}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={toggleSidebar}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <div className={classes.root}>
        <List component="nav">
          <Menu toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
        </List>
      </div>
    </Drawer>
  );
}

Sidebar.propTypes = {
  sidebarIsOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Sidebar);
