import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Paper, Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';

import TextInput from 'components/TextInput';

import DateSelector from '../../../components/DatePicker';

const styles = theme => ({
  papper: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: '100%',
  },
  grid: {
    flexGrow: 1,
  },
  datePickerGrid: {
    flexGrow: 1,
    alignSelf: 'baseline',
  },
});

const ProgramRow = ({ data, classes, onChange, onDelete, errors, index }) => (
  <Paper key={index} className={classes.papper}>
    <Grid container alignItems="flex-end" spacing={8}>
      <Grid item md className={classes.datePickerGrid}>
        <DateSelector
          required
          label="Day"
          value={moment(data.attributes.date)}
          onChange={date => {
            const event = {
              target: {
                name: 'date',
                value: date || '',
              },
            };
            onChange(event, index, 'date');
          }}
          error={Boolean(errors && errors.date)}
          helperText={(errors && errors.date) || ''}
        />
      </Grid>
      <Grid item md={6} className={classes.grid}>
        <TextInput
          required
          model={data}
          attribute="title"
          label="Title"
          modelErrors={errors || { title: '' }}
          onChange={event => onChange(event, index, 'input')}
        />
      </Grid>
      <Grid item md className={classes.grid}>
        <TextInput
          required
          type="number"
          model={data}
          attribute="fee"
          label="Fee"
          modelErrors={errors || { fee: '' }}
          onChange={event => onChange(event, index, 'input')}
        />
      </Grid>
      <Grid>
        <IconButton
          aria-label="Delete"
          onClick={event => onDelete(event, index)}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  </Paper>
);

ProgramRow.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.object,
  index: PropTypes.number.isRequired,
};

export default withStyles(styles)(ProgramRow);
