import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';

import RegistrationRequestForm from './RegistrationRequestForm';

const styles = () => ({
  flex: {
    display: 'flex',
  },
});

const RegistrationRequestPage = ({ classes }) => (
  <div>
    <Helmet>
      <title>Registration Request</title>
      <meta
        name="description"
        content="Description of RegistrationRequestPage"
      />
    </Helmet>
    <div className={classes.flex}>
      <RegistrationRequestForm />
    </div>
  </div>
);

RegistrationRequestPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegistrationRequestPage);
