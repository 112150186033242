import { find } from 'lodash';

import {
  getIncludedModel,
  getIncludedModelById,
  getIncludedModelsOfType,
} from '../../utils/fractal';
import {
  LOAD_AUCTIONS,
  LOAD_AUCTIONS_ERROR,
  LOAD_AUCTIONS_SUCCESS,
  SET_ITEM_ERROR,
} from './constants';

const moment = require('moment-timezone');

export const setItemErrors = errors => ({
  type: SET_ITEM_ERROR,
  errors,
});

export const loadAuctions = () => ({
  type: LOAD_AUCTIONS,
});

export const loadAuctionsSuccess = response => ({
  type: LOAD_AUCTIONS_SUCCESS,
  response,
});

export const loadAuctionsError = error => ({
  type: LOAD_AUCTIONS_ERROR,
  error,
});

const deepGet = (path, obj) =>
  path.split('.').reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj);

export const getImageUrl = (model, included) => {
  const files = deepGet(`relationships.files.data`, model);
  let file = null;
  files.forEach(item => {
    const includeModel = getIncludedModelById(item.id, 'files', included);
    if (includeModel.fileType === 'image') {
      file = includeModel;
    }
  });
  return file ? file.dimensions.original : null;
};

export const getCurrentBid = (auction, item, included) => {
  const bids = getIncludedModelsOfType('bids', included);
  return find(
    bids,
    o =>
      o.attributes.auction_id === parseInt(auction.id, 10) &&
      o.attributes.auction_item_id === parseInt(item.id, 10) &&
      o.attributes.is_current,
  );
};

export const getCurrentBidAmount = (currentBid, auctionItem) =>
  currentBid ? currentBid.amount : auctionItem.attributes.starting_bid;

export const getNextBidAmount = (currentBid, auctionItem) =>
  currentBid ? currentBid.amount * 1.1 : auctionItem.attributes.starting_bid;

export const getCurrentBidder = (currentBid, included) => {
  const user = currentBid
    ? getIncludedModel(currentBid, 'user_id', 'users', included)
    : null;

  return user || null;
};

export const isAuctionActive = auction => {
  const timeZone = 'Europe/Budapest';
  const auctionStartInUtc = moment.tz(auction.start_time, timeZone);
  const auctionEndInUtc = moment.tz(auction.end_time, timeZone);
  const currentTimeInUtc = moment().tz(timeZone);
  return currentTimeInUtc.isBetween(auctionStartInUtc, auctionEndInUtc);
};

export const roundToDecimals = (value, decimals) =>
  Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`).toFixed(
    decimals,
  );
