import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty, cloneDeep, isEqual } from 'lodash';
import withCrud from 'containers/ModelWrapper';

import {
  makeSelectEnumTypes,
  makeSelectEnumValues,
  makeSelectErrorObject,
  makeSelectAllEnumValues,
} from 'containers/App/selectors';
import { setErrorObject } from 'containers/App/actions';
import { setMessage } from 'containers/Snackbar/actions';
import injectReducer from 'utils/injectReducer';
import { userHasRole } from 'utils/userHasRole';
import { BACKEND_DATE } from 'utils/constants';
import { makeSelectEventErrors } from '../selectors';
import reducer from '../reducer';
import {
  eventErrors as resetErrors,
  eventSkeleton,
  eventAccessSkeleton,
  eventAccessErrors,
  programSkeleton,
  programErrors,
} from '../constants';
import { setEventErrors } from '../actions';
import EventEditorForm from './EventEditorForm';
import {
  TableContainerOpenedStyles,
  TableContainerStyles,
} from '../../../mui-theme';
import { makeSelectSidebarIsOpen } from '../../Layout/selectors';

const styles = theme => ({
  rootClosed: {
    ...TableContainerStyles,
    marginTop: '15px',
  },
  rootOpened: {
    ...TableContainerOpenedStyles,
    marginTop: '15px',
  },
  button: {
    margin: theme.spacing.unit,
  },
  dateSelector: {
    marginBottom: '20px',
  },
});

class EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.setDate = this.setDate.bind(this);
    this.state = {
      activateDialogOpen: false,
      dialogTitle: '',
      isUserAdmin: userHasRole(props.roles, [
        'SYSTEM_ADMINISTRATOR',
        'MEMBERSHIP_ADMINISTRATOR',
      ]),
      start_date: moment(),
      end_date: moment(),
      eventAccesses: [],
      programs: [],
      eventRequestErrors: cloneDeep(resetErrors),
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.setDate = this.setDate.bind(this);
    this.onAddEventAccess = this.onAddEventAccess.bind(this);
    this.onDeleteEventAccess = this.onDeleteEventAccess.bind(this);
    this.eventAccessFieldChange = this.eventAccessFieldChange.bind(this);
    this.onAddProgram = this.onAddProgram.bind(this);
    this.onDeleteProgram = this.onDeleteProgram.bind(this);
    this.programFieldChange = this.programFieldChange.bind(this);
    this.displayBackendErrors = this.displayBackendErrors.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    this.preLoadModel(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const resetErrorsWithIncludes = cloneDeep(resetErrors);
    this.state.programs.forEach(() => {
      resetErrorsWithIncludes.programs.push(programSkeleton);
    });

    this.state.eventAccesses.forEach(() => {
      resetErrorsWithIncludes.eventAccesses.push(eventAccessSkeleton);
    });

    this.setState({ eventRequestErrors: resetErrors });

    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.props.history.push(`/admin/events`);
      return;
    }

    if (isEmpty(nextProps.model)) {
      return;
    }

    if (
      !this.props.errorObject &&
      nextProps.errorObject &&
      nextProps.errorObject.response
    ) {
      this.displayBackendErrors(nextProps);
      return;
    }

    if (!isEqual(nextProps.eventErrors, resetErrorsWithIncludes)) {
      return;
    }

    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.preLoadModel(nextProps);
    }
    if (nextProps.model.id && nextProps.modelIncludes) {
      const programs = nextProps.modelIncludes
        .filter(model => model.type === 'programs')
        .map(program => {
          const newProgram = cloneDeep(program);
          newProgram.attributes.date = moment(program.attributes.date);
          return newProgram;
        });

      const eventAccesses = nextProps.modelIncludes
        .filter(model => model.type === 'eventAccesses')
        .map(eventAccess => {
          const newEventAccess = cloneDeep(eventAccess);
          return newEventAccess;
        });
      this.setState({ eventAccesses, programs });
    }
    if (nextProps.model) {
      // eslint-disable-next-line camelcase
      const { start_date, end_date } = nextProps.model.attributes;
      this.setState({
        start_date: moment(start_date),
        end_date: moment(end_date),
      });
    }
  }

  preLoadModel(props) {
    const { match } = props;
    this.props.setModel(cloneDeep(eventSkeleton));
    this.props.setErrors(cloneDeep(resetErrors));
    if (parseInt(match.params.eventId, 10)) {
      this.props.showModel(match.params.eventId);
    }
  }

  displayBackendErrors({ errorObject, showErrorToast }) {
    const eventRequestErrors = cloneDeep(this.state.eventRequestErrors);
    const titleSlugError = errorObject.response.data.errors.filter(
      error => error.source.pointer === 'data.attributes.title_slug',
    );

    if (titleSlugError.length) {
      eventRequestErrors.title_slug = 'Title slug must be unique.';
      showErrorToast(eventRequestErrors.title_slug);
    }
    this.setState({ eventRequestErrors });
  }

  handleEditorChange(event) {
    this.inputChanged({
      target: {
        name: event.target.id,
        value: event.target.getContent(),
      },
    });
  }

  inputChanged(event) {
    const selectedEvent = cloneDeep(this.props.model);
    selectedEvent.attributes[event.target.name] = event.target.value;
    this.props.setModel(selectedEvent);
  }

  eventAccessFieldChange(event, id) {
    const { eventAccesses } = this.state;
    const selectedEventAccess = eventAccesses[id];

    selectedEventAccess.attributes[event.target.name] = event.target.value;

    this.setState({ eventAccesses });
  }

  programFieldChange(event, id, fieldType) {
    const { programs } = this.state;
    const selectedProgram = programs[id];

    switch (fieldType) {
      case 'date':
        if (!event.target.value) {
          selectedProgram.attributes[event.target.name] = null;
          break;
        }
        if (!selectedProgram.attributes[event.target.name]) {
          selectedProgram.attributes[event.target.name] = moment();
        }
        selectedProgram.attributes[event.target.name].year(
          event.target.value.year(),
        );
        selectedProgram.attributes[event.target.name].month(
          event.target.value.month(),
        );
        selectedProgram.attributes[event.target.name].dayOfYear(
          event.target.value.dayOfYear(),
        );
        break;
      case 'input': // Fall-trough
      default:
        selectedProgram.attributes[event.target.name] = event.target.value;
    }

    this.setState({ programs });
  }

  setDate(event) {
    const { state } = this;
    if (!event.target.value) {
      state[event.target.name] = null;
      this.setState(state);
      this.inputChanged({
        target: {
          name: event.target.field,
          value: '',
        },
      });
      return;
    }
    if (!state[event.target.name]) {
      state[event.target.name] = moment();
    }
    state[event.target.name].year(event.target.value.year());
    state[event.target.name].month(event.target.value.month());
    state[event.target.name].dayOfYear(event.target.value.dayOfYear());
    this.setState(state);
  }

  submit() {
    this.props.setErrorObject(null);
    this.setState({ eventRequestErrors: cloneDeep(resetErrors) });
    const { model } = this.props;
    const {
      start_date: startDate,
      end_date: endDate,
      programs,
      eventAccesses,
    } = this.state;
    const eventErrors = cloneDeep(resetErrors);
    const resetErrorsWithIncludes = cloneDeep(resetErrors);

    const requiredFields = {
      title: 'Title',
      title_slug: 'Slug',
      description: 'Description',
      location: 'Location',
      start_date: 'Start date',
      end_date: 'End Date',
    };

    const requiredEventAccessFields = {
      name: 'Name',
      fee: 'Fee',
    };

    const validFloatFormat = /^\d*(\.\d{2})?$/;

    // Event accesses validation
    eventAccesses.forEach((eventAccess, index) => {
      const { attributes } = eventAccess;
      eventErrors.eventAccesses.push(cloneDeep(eventAccessErrors));
      resetErrorsWithIncludes.eventAccesses.push(cloneDeep(eventAccessErrors));

      Object.keys(attributes).forEach(key => {
        if (key === 'fee') {
          const feeField = attributes[key];

          if (!validFloatFormat.test(feeField)) {
            eventErrors.eventAccesses[index][key] =
              'Fee should contain numbers only and maximum 2 digits in the fractional part';
          }

          if (feeField < 0 || feeField > 99999999.99) {
            eventErrors.eventAccesses[index][key] =
              'Fee should have valid value';
          }
        } else if (!attributes[key] || !String(attributes[key]).length) {
          eventErrors.eventAccesses[index][key] = `${
            requiredEventAccessFields[key]
          } can not be empty`;
        }
      });
    });

    const requiredProgramFields = {
      date: 'Day',
      title: 'Title',
      fee: 'Fee',
    };

    // Programs validation
    programs.forEach((program, index) => {
      const { attributes } = program;
      eventErrors.programs.push(cloneDeep(programErrors));
      resetErrorsWithIncludes.programs.push(cloneDeep(programErrors));

      Object.keys(attributes).forEach(key => {
        if (key === 'fee') {
          if (parseFloat(attributes[key]) < 0) {
            eventErrors.programs[index][key] = `${
              requiredProgramFields[key]
            } can not be negative`;
          }
        } else if (!attributes[key] || !String(attributes[key]).length) {
          eventErrors.programs[index][key] = `${
            requiredProgramFields[key]
          } can not be empty`;
        }
      });
    });

    if (startDate && endDate) {
      model.attributes.start_date = startDate.format(BACKEND_DATE);
      model.attributes.end_date = endDate.format(BACKEND_DATE);
    }

    // Event validation
    Object.keys(requiredFields).forEach(field => {
      if (!String(model.attributes[field]).length) {
        eventErrors[field] = `${requiredFields[field]} can not be empty`;
      }
    });

    this.props.setErrors(eventErrors);

    if (
      !isEqual(eventErrors, resetErrorsWithIncludes) ||
      eventAccesses.length < 1
    ) {
      this.props.showErrorToast('Form data invalid');
      return;
    }

    const selectedEvent = cloneDeep(model);

    selectedEvent.attributes.eventAccesses = eventAccesses.map(eventAccess =>
      cloneDeep(eventAccess),
    );

    selectedEvent.attributes.programs = programs.map(program => {
      const newProgram = cloneDeep(program);
      newProgram.attributes.date = moment(program.attributes.date).format(
        BACKEND_DATE,
      );
      return newProgram;
    });

    this.props.setModel(selectedEvent);

    this.props.submitModel();
  }

  onAddEventAccess() {
    const { eventAccesses } = this.state;
    eventAccesses.push(cloneDeep(eventAccessSkeleton));
    this.setState({ eventAccesses });
  }

  onDeleteEventAccess(event, index) {
    let { eventAccesses } = this.state;

    eventAccesses = eventAccesses.filter(
      eventAccess => eventAccess !== eventAccesses[index],
    );

    this.setState({ eventAccesses });
  }

  onAddProgram() {
    const { programs } = this.state;
    programs.push(cloneDeep(programSkeleton));
    this.setState({ programs });
  }

  onDeleteProgram(event, index) {
    let { programs } = this.state;

    programs = programs.filter(program => program !== programs[index]);

    this.setState({ programs });
  }

  onCancel() {
    this.props.setErrors(resetErrors);
    this.props.setModel({});
  }

  render() {
    const {
      model,
      classes,
      eventErrors,
      sidebarIsOpen,
      enumTypes,
      enumValues,
      allEnumValues,
    } = this.props;

    if (isEmpty(model) || isEmpty(enumValues) || isEmpty(enumTypes))
      return null;

    model.attributes.start_date = this.state.start_date.format(BACKEND_DATE);
    model.attributes.end_date = this.state.end_date.format(BACKEND_DATE);

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;

    return (
      <React.Fragment>
        <EventEditorForm
          model={model}
          eventErrors={eventErrors}
          enumValues={enumValues}
          enumTypes={enumTypes}
          allEnumValues={allEnumValues}
          classes={classes}
          onSubmit={(event, activate = true) => {
            event.preventDefault();
            this.submit(activate);
          }}
          onChange={this.inputChanged}
          cancel={() => this.props.setModel({})}
          isUserAdmin={this.state.isUserAdmin}
          handleEditorChange={this.handleEditorChange}
          setDate={this.setDate}
          eventAccesses={this.state.eventAccesses}
          onAddEventAccess={this.onAddEventAccess}
          onDeleteEventAccess={this.onDeleteEventAccess}
          eventAccessFieldChange={this.eventAccessFieldChange}
          programs={this.state.programs}
          onAddProgram={this.onAddProgram}
          onDeleteProgram={this.onDeleteProgram}
          programFieldChange={this.programFieldChange}
          rootClass={rootClass}
        />
      </React.Fragment>
    );
  }
}

EventForm.defaultProps = {};

EventForm.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  modelIncludes: PropTypes.array,
  eventErrors: PropTypes.object,
  setModel: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  showErrorToast: PropTypes.func.isRequired,
  showModel: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  errorObject: PropTypes.object,
  setErrorObject: PropTypes.func.isRequired,
  enumValues: PropTypes.object,
  enumTypes: PropTypes.object,
  allEnumValues: PropTypes.object,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  eventErrors: makeSelectEventErrors(),
  enumTypes: makeSelectEnumTypes(),
  enumValues: makeSelectEnumValues(),
  allEnumValues: makeSelectAllEnumValues(),
  sidebarIsOpen: makeSelectSidebarIsOpen(),
  errorObject: makeSelectErrorObject(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setEventErrors(errors)),
  setMessage: message => dispatch(setMessage(message)),
  setErrorObject: error => dispatch(setErrorObject(error)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'eventForm', reducer });

export default compose(
  withReducer,
  withConnect,
)(withStyles(styles)(withCrud('eventFormPage', 'events', EventForm, true)));
