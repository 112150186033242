import React from 'react';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const ContainerPaper = withStyles(theme => ({
  root: {
    width: '100%',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    [theme.breakpoints.only('xs')]: {
      margin: 0,
      padding: `${theme.spacing.unit}px`,
    },
    '& + &': {
      marginTop: theme.spacing.unit,
    },
  },
}))(props => <Paper {...props} />);

export default ContainerPaper;
