import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { TableRow, TableCell, Chip } from '@material-ui/core';
import { DATE_FORMAT } from 'utils/constants';

const RegistrantRow = ({
  row,
  accessValues,
  countries,
  programs,
  eventRegistration,
}) => (
  <TableRow>
    <TableCell>{row.attributes.name}</TableCell>
    <TableCell>{row.attributes.email}</TableCell>
    <TableCell>{row.attributes.phone}</TableCell>
    <TableCell>
      {_.get(
        countries.find(country => country.column_id === row.attributes.country),
        'name',
      )}
    </TableCell>
    <TableCell>
      {moment(row.attributes.registration_date).format(DATE_FORMAT)}
    </TableCell>
    <TableCell>
      {_.get(
        accessValues.find(access => access.value === row.attributes.access),
        'label',
      )}
    </TableCell>
    <TableCell>{row.attributes.price} EUR</TableCell>
    <TableCell>
      {row.attributes.programs.map(program => {
        const programItem = _.get(
          programs.find(p => parseInt(p.id, 10) === parseInt(program.id, 10)),
          'attributes.title',
        );
        if (programItem) {
          return <Chip label={programItem} variant="outlined" />;
        }
        return null;
      })}
    </TableCell>
    <TableCell>{row.attributes.isMember || 'False'}</TableCell>
    <TableCell>{eventRegistration.attributes.status || '-'}</TableCell>
  </TableRow>
);

RegistrantRow.propTypes = {
  row: PropTypes.object.isRequired,
  accessValues: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  programs: PropTypes.array.isRequired,
  eventRegistration: PropTypes.object.isRequired,
};

export default RegistrantRow;
