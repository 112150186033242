import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

const SortableCells = ({ cells, sortDirection, sortBy, onClick }) => (
  <Fragment>
    {Object.keys(cells).map(field => (
      <TableCell key={field}>
        {cells[field] ? (
          <TableSortLabel
            active={sortBy === cells[field]}
            direction={sortDirection}
            onClick={onClick(cells[field])}
          >
            <Typography variant="subtitle2">{field}</Typography>
          </TableSortLabel>
        ) : (
          <Typography variant="subtitle2">{field}</Typography>
        )}
      </TableCell>
    ))}
  </Fragment>
);

SortableCells.propTypes = {
  cells: PropTypes.object.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SortableCells;
