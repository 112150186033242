import React from 'react';
import PropTypes from 'prop-types';

import { formatAmount } from 'utils/constants';

const Money = props => (
  <span className={props.className} style={props.style}>
    {formatAmount(props.amount)}
  </span>
);

Money.propTypes = {
  className: PropTypes.string,
  amount: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default Money;
