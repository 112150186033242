import moment from 'moment';

/*
 *
 * Event constants
 *
 */

export const DEFAULT_ACTION = 'app/Event/DEFAULT_ACTION';
export const SET_EVENT_ERROR = 'app/Event/SET_EVENT_ERROR';

export const eventSkeleton = Object.freeze({
  id: null,
  type: 'events',
  attributes: {
    title: '',
    title_slug: '',
    description: '',
    location: '',
    useful_information: '',
    start_date: moment(),
    end_date: moment(),
    active: true,
    eventAccesses: [],
    programs: [],
    updated_at: '',
  },
});

export const eventAccessSkeleton = Object.freeze({
  id: null,
  type: 'eventAccesses',
  attributes: {
    name: '',
    fee: 0,
  },
});

export const programSkeleton = Object.freeze({
  id: null,
  type: 'programs',
  attributes: {
    date: moment(),
    title: '',
    fee: 0,
  },
});

export const eventErrors = {
  title: '',
  title_slug: '',
  description: '',
  location: '',
  useful_information: '',
  start_date: '',
  end_date: '',
  active: '',
  eventAccesses: [],
  programs: [],
  updated_at: '',
};

export const eventAccessErrors = {
  name: '',
  fee: '',
};

export const programErrors = {
  date: '',
  title: '',
  fee: '',
};
