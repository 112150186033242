export const LOAD_MODELS = 'app/ModelWrapper/LOAD_MODELS';
export const LOAD_MODELS_SUCCESS = 'app/ModelWrapper/LOAD_MODELS_SUCCESS';

export const SET_PAGE_NUMBER = 'app/ModelWrapper/SET_PAGE_NUMBER';
export const SET_ROWS_PER_PAGE = 'app/ModelWrapper/SET_ROWS_PER_PAGE';
export const SET_FILTER = 'app/ModelWrapper/SET_FILTER';
export const RESET_FILTER = 'app/ModelWrapper/RESET_FILTER';
export const SET_SORTING = 'app/ModelWrapper/SET_SORTING';
export const SET_INCLUDES = 'app/ModelWrapper/SET_INCLUDES';

export const SET_MODEL_TYPE = 'app/ModelWrapper/SET_MODEL_TYPE';

export const RESET_REDUCER = 'app/ModelWrapper/RESET_REDUCER';

export const SET_MODEL = 'app/ModelWrapper/SET_MODEL';
export const SET_MODEL_INCLUDES = 'app/ModelWrapper/SET_MODEL_INCLUDES';
export const SUBMIT_MODEL = 'app/ModelWrapper/SUBMIT_MODEL';
export const SUBMIT_MODEL_SUCCESS = 'app/ModelWrapper/SUBMIT_MODEL_SUCCESS';
export const SUBMIT_MODEL_ERROR = 'app/ModelWrapper/SUBMIT_MODEL_ERROR';

export const SHOW_MODEL = 'app/ModelWrapper/SHOW_MODEL';
export const SHOW_MODEL_SUCCESS = 'app/ModelWrapper/SHOW_MODEL_SUCCESS';

export const DELETE_MODEL = 'app/ModelWrapper/DELETE_MODEL';
export const DELETE_MODEL_SUCCESS = 'app/ModelWrapper/DELETE_MODEL_SUCCESS';
