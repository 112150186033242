/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  footer: {
    id: 'app.components.Footer.header',
    defaultMessage:
      'International Council for Game and Wildlife Conservation - CIC Administrative Office - H-2092 Budakeszi, ' +
      'P.O. Box 82, Hungary - Telephone: +36 23 453 830 - Fax: +36 23 453 832 - membership@cic-wildlife.org',
  },
});
