import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TextFilter from 'components/TextFilter';
import {
  BACKEND_DATE,
  DATE_FORMAT,
  DATE_FORMAT_MASK,
} from '../../utils/constants';

const styles = () => ({
  datePicker: {
    display: 'block',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  datePickerWrapper: {
    width: '100%',
    display: 'block',
  },
  input: {
    fontsize: '12px',
  },
  tr: {
    maxWidth: '250px',
  },
});

const MediaFilter = props => {
  const { params } = props;
  return (
    <TableRow>
      <TableCell className={props.classes.tr} />
      <TableCell className={props.classes.tr}>
        <TextFilter
          Id="search-for-name"
          label="Search for name"
          name="name"
          setFilter={props.setFilter}
        />
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={props.classes.datePicker}>
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="From"
              format={DATE_FORMAT}
              clearable
              adornmentPosition="end"
              value={
                (params.filter.created_at_from &&
                  moment(params.filter.created_at_from)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'created_at_from',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="To"
              format={DATE_FORMAT}
              adornmentPosition="end"
              clearable
              value={
                (params.filter.created_at_to &&
                  moment(params.filter.created_at_to)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'created_at_to',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

MediaFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaFilter);
