import {
  LOAD_FUNCTIONAL_AREAS,
  LOAD_FUNCTIONAL_AREAS_ERROR,
  LOAD_FUNCTIONAL_AREAS_SUCCESS,
  SET_FUNCTIONAL_AREA,
  SET_FUNCTIONAL_AREA_ERROR,
  SET_PAGE_NUMBER,
  SET_ROWS_PER_PAGE,
  SUBMIT_FUNCTIONAL_AREA,
  SUBMIT_FUNCTIONAL_AREA_SUCCESS,
  SUBMIT_FUNCTIONAL_AREA_ERROR,
} from './constants';

export const loadFunctionalAreas = () => ({
  type: LOAD_FUNCTIONAL_AREAS,
});

export const loadFunctionalAreasSuccessed = response => ({
  type: LOAD_FUNCTIONAL_AREAS_SUCCESS,
  response,
});

export const loadFunctionalAreasError = error => ({
  type: LOAD_FUNCTIONAL_AREAS_ERROR,
  error,
});

export const setFunctionalArea = selectedFunctionalArea => ({
  type: SET_FUNCTIONAL_AREA,
  selectedFunctionalArea,
});

export const setFunctionalAreaErrors = errors => ({
  type: SET_FUNCTIONAL_AREA_ERROR,
  errors,
});

export const setPageNumber = page => ({
  type: SET_PAGE_NUMBER,
  page,
});

export const setRowsPerPage = event => ({
  type: SET_ROWS_PER_PAGE,
  event,
});

export const submitFunctionalArea = () => ({
  type: SUBMIT_FUNCTIONAL_AREA,
});

export const submitFunctionalAreaSuccess = functionalArea => ({
  type: SUBMIT_FUNCTIONAL_AREA_SUCCESS,
  functionalArea,
});

export const submitFunctionalAreaError = error => ({
  type: SUBMIT_FUNCTIONAL_AREA_ERROR,
  error,
});
