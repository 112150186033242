import React from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { createStructuredSelector } from 'reselect/lib/index';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import MenuItem from '../MenuItem';
import SubmenuCollapse from '../SubmenuCollapse';
import {
  makeSelectLocation,
  makeSelectNewsCategories,
} from '../../containers/App/selectors';
import { makeRolesSelector } from '../../containers/AuthButton/selectors';
import { userHasRole } from '../../utils/userHasRole';
import { createMenuStructure, ROUTE_MAP } from './constants';
import { ROLES_MEMBER_ONLY } from '../../utils/constants';

export class UserMenu extends React.Component {
  initialState = {
    members: false,
    invoice: false,
    administration: false,
    auction: false,
    auctionSub: false,
    auctionNewsSub: false,
    news: false,
    previousPage: null,
  };

  state = this.initialState;

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.menuMapper = this.menuMapper.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.menuStructure = createMenuStructure(
      this.props.roles,
      this.props.newsCategories,
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.location.pathname, this.props.location.pathname) &&
      nextProps.sidebarIsOpen
    ) {
      const { previousPage, ...initialState } = this.initialState;
      const state = {
        previousPage: this.props.location.pathname,
      };

      if (isMobile) {
        this.props.toggleSidebar();
        Object.assign(state, initialState);
      }

      this.setState(state);
    }
  }

  handleClick(type) {
    this.setState(state => {
      const newState = cloneDeep(state);
      newState[type] = !state[type];
      return newState;
    });
  }

  isSelected(menuItemUrl) {
    const url = this.props.location.pathname;
    if (menuItemUrl === url) return true;

    const matchedRoutes = Object.entries(ROUTE_MAP).filter(route =>
      url.match(`^${route[0]}`),
    );
    if (!matchedRoutes.length) return false;
    const replacementRoute =
      matchedRoutes[0][1] === '::history::'
        ? this.state.previousPage
        : matchedRoutes[0][1];
    return menuItemUrl === replacementRoute;
  }

  menuMapper(menuItem, level = 0) {
    const isSelected = this.isSelected(menuItem.url);
    const isAllowed = userHasRole(this.props.roles, menuItem.roles);
    if (!isAllowed) return null;
    if (
      isEqual(menuItem.roles, ROLES_MEMBER_ONLY) &&
      !isEqual(this.props.roles, ROLES_MEMBER_ONLY)
    )
      return null;
    if (menuItem.type === 'item') {
      return (
        <MenuItem
          key={menuItem.url + menuItem.title}
          url={menuItem.url}
          title={menuItem.title}
          icon={menuItem.icon}
          external={menuItem.external}
          selected={isSelected}
        />
      );
    }
    if (menuItem.type === 'submenu') {
      return (
        <SubmenuCollapse
          key={menuItem.stateKey}
          open={this.state[menuItem.stateKey]}
          toggle={() => this.handleClick(menuItem.stateKey)}
          icon={menuItem.icon}
          label={menuItem.title}
          level={level}
        >
          {menuItem.items.map(itm => this.menuMapper(itm, level + 2))}
        </SubmenuCollapse>
      );
    }
    return null;
  }

  render() {
    return (
      <React.Fragment>
        {this.menuStructure.map(i => this.menuMapper(i))}
      </React.Fragment>
    );
  }
}

UserMenu.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  newsCategories: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  newsCategories: makeSelectNewsCategories(),
  roles: makeRolesSelector(),
  location: makeSelectLocation(),
});

export default connect(mapStateToProps)(UserMenu);
