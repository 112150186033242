/**
 *
 * InvoiceGeneratePage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import withCrud from 'containers/ModelWrapper';
import { setMessage as setMessageAction } from 'containers/Snackbar/actions';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import {
  makeSelectEnumTypes,
  makeSelectEnumValues,
} from 'containers/App/selectors';
import { getEnumTypeArray } from 'utils/fractal';
import { makeSelectSelectedInvoices } from './selectors';
import reducer from './reducer';
import saga from './saga';
import { submitForm, addSelection, removeSelection } from './actions';
import InvoicesTable from './InvoicesTable';
import GeneratePaymentForm from './GeneratePaymentForm';

export class GenerateInvoicePage extends React.Component {
  toggleSelection = (idArray, toggleState = null) =>
    toggleState !== null
      ? this.handleMassSelection(idArray, toggleState)
      : this.handleSelection(idArray);

  handleMassSelection = (idArray, toggleState) =>
    idArray.map(id => {
      if (toggleState) {
        return (
          this.props.selectedInvoices.includes(id) &&
          this.props.removeSelection(id)
        );
      }

      return (
        !this.props.selectedInvoices.includes(id) && this.props.addSelection(id)
      );
    });

  handleSelection = idArray =>
    idArray.map(
      id =>
        this.props.selectedInvoices.includes(id)
          ? this.props.removeSelection(id)
          : this.props.addSelection(id),
    );
  paymentTypes = getEnumTypeArray(
    this.props.enumTypes,
    this.props.enumValues,
    'payment_method',
  );

  resetModel = () => {
    this.props.setFilter({
      target: {
        name: 'has_no_payments',
        value: 1,
      },
    });
    this.props.setFilter({
      target: {
        name: 'status',
        value: 'ACTIVE',
      },
    });
    this.props.setRowsPerPage({
      target: {
        value: 200,
      },
    });
    this.props.loadModels();
  };

  componentDidMount() {
    this.resetModel();
  }

  render() {
    const {
      models,
      loadModels,
      resetFilter,
      setFilter,
      selectedInvoices,
      setRowsPerPage,
      setPageNumber,
      setMessage,
      params,
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>Summary of Invoices</title>
        </Helmet>
        <GeneratePaymentForm
          selectedInvoices={selectedInvoices}
          submitForm={this.props.submitForm}
          paymentTypes={this.paymentTypes}
          setMessage={setMessage}
        />
        <InvoicesTable
          selectedInvoices={selectedInvoices}
          toggleSelection={this.toggleSelection}
          params={params}
          models={models}
          loadModels={loadModels}
          setFilter={setFilter}
          resetFilter={resetFilter}
          setRowsPerPage={setRowsPerPage}
          setPageNumber={setPageNumber}
          sortDirection="asc"
          setSorting={() => {}}
        />
      </div>
    );
  }
}

GenerateInvoicePage.propTypes = {
  models: PropTypes.object.isRequired,
  loadModels: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.array.isRequired,
  addSelection: PropTypes.func.isRequired,
  removeSelection: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  enumValues: PropTypes.object.isRequired,
  enumTypes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  selectedInvoices: makeSelectSelectedInvoices(),
  enumTypes: makeSelectEnumTypes(),
  enumValues: makeSelectEnumValues(),
});

function mapDispatchToProps(dispatch) {
  return {
    submitForm: data => dispatch(submitForm(data)),
    addSelection: item => dispatch(addSelection(item)),
    removeSelection: item => dispatch(removeSelection(item)),
    setMessage: message => dispatch(setMessageAction(message)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'invoiceSummaryPage', reducer });
const withSaga = injectSaga({ key: 'invoiceSummaryPage', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(withCrud('invoiceSummaryPageCrud', 'invoices', GenerateInvoicePage, true));
