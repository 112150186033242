import React from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select/index';
import MenuItem from '@material-ui/core/MenuItem/index';
import TextField from '@material-ui/core/TextField/index';
import FormControl from '@material-ui/core/FormControl/index';
import InputLabel from '@material-ui/core/InputLabel/index';
import Button from '@material-ui/core/Button';

import DatePicker from 'components/DatePicker';
import { BACKEND_DATE_TIME_FORMAT } from 'utils/constants';

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit * 3,
  },
});

const getYears = (startYear, stopYear) => {
  const dateArray = [];
  let currentDate = stopYear;
  while (currentDate >= startYear) {
    dateArray.push(currentDate);
    currentDate -= 1;
  }
  return dateArray;
};

class GenerateInvoiceForm extends React.Component {
  initialState = {
    year: moment().year(),
    invoice_date: null,
    due_date: null,
  };

  state = this.initialState;

  componentDidMount() {
    this.props.getInvoicedCount(this.state.year);
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.year !== nextState.year) {
      this.props.getInvoicedCount(nextState.year);
    }
  }

  handleChange = e =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  handleSubmit = e => {
    e.preventDefault();

    this.props.onSubmit(this.state);
    this.setState(this.initialState);
  };

  formatDate = date => moment(date).format(BACKEND_DATE_TIME_FORMAT);

  render() {
    const { memberCounts, classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <FormControl aria-describedby="value-error-text" fullWidth>
          <InputLabel htmlFor="year">Year of Invoice</InputLabel>
          <Select
            required
            id="year"
            name="year"
            value={this.state.year}
            onChange={this.handleChange}
          >
            {getYears(
              1999,
              moment()
                .add(5, 'years')
                .year(),
            ).map(date => (
              <MenuItem value={date} key={date}>
                {date}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          fullWidth
          disabled
          label="Number of generated invoices for the given year"
          value={memberCounts.invoiced_members}
        />
        <TextField
          margin="normal"
          fullWidth
          disabled
          label="Number of members whom have not been invoiced"
          value={memberCounts.non_invoiced_members}
        />

        <DatePicker
          required
          label="Invoice Date"
          value={this.state.invoice_date}
          onChange={date => {
            const event = {
              target: {
                name: 'invoice_date',
                value: this.formatDate(date) || '',
              },
            };
            this.handleChange(event);
          }}
        />
        <DatePicker
          required
          label="Due Date"
          value={this.state.due_date}
          onChange={date => {
            const event = {
              target: {
                name: 'due_date',
                value: this.formatDate(date) || '',
              },
            };
            this.handleChange(event);
          }}
        />
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className={classes.button}
        >
          Generate Invoices
        </Button>
      </form>
    );
  }
}

GenerateInvoiceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  memberCounts: PropTypes.object.isRequired,
  getInvoicedCount: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GenerateInvoiceForm);
