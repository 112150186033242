import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';

import { TABLE_DEFAULT_PAGE_SIZE } from 'utils/constants';
import {
  LOAD_ENUM_TYPES,
  LOAD_ENUM_VALUES,
  LOAD_ENUM_TYPES_SUCCESS,
  LOAD_ENUM_TYPES_ERROR,
  SET_ENUM_TYPE,
  LOAD_ENUM_VALUES_SUCCESS,
  LOAD_ENUM_VALUES_ERROR,
  SET_PAGE_NUMBER,
  SET_ROWS_PER_PAGE,
  SET_ENUM_VALUE,
  enumErrors,
  SET_ENUM_ERROR,
  SUBMIT_ENUM,
  SUBMIT_ENUM_SUCCESS,
  SUBMIT_ENUM_ERROR,
} from './constants';

const defaultParams = fromJS({
  filter: {},
  page: {
    size: TABLE_DEFAULT_PAGE_SIZE,
    number: 1,
  },
});

export const initialState = fromJS({
  enumTypes: [],
  enumValues: {
    data: [],
    meta: {},
  },
  loading: false,
  errorMessage: '',
  successMessage: '',
  params: defaultParams,
  selectedEnum: {},
  enumErrors: cloneDeep(enumErrors),
});

const enumPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ENUM_TYPES:
      return state.set('loading', true);
    case LOAD_ENUM_TYPES_SUCCESS:
      return state.set('loading', false).set('enumTypes', action.response);
    case LOAD_ENUM_TYPES_ERROR:
      return state
        .set('loading', false)
        .set('errorMessage', action.error)
        .set('enumTypes', []);
    case SET_ENUM_TYPE:
      return state
        .set('params', defaultParams)
        .setIn(['params', 'filter', 'enum_type_id'], action.id);
    case LOAD_ENUM_VALUES:
      return state.set('loading', true);
    case LOAD_ENUM_VALUES_SUCCESS:
      return state.set('loading', false).set('enumValues', action.response);
    case LOAD_ENUM_VALUES_ERROR:
      return state
        .set('loading', false)
        .set('errorMessage', action.error)
        .set('enumValues', []);
    case SET_PAGE_NUMBER:
      return state.setIn(['params', 'page', 'number'], action.page + 1);
    case SET_ROWS_PER_PAGE:
      return state
        .setIn(['params', 'page', 'size'], action.event.target.value)
        .setIn(['params', 'page', 'number'], 1);
    case SET_ENUM_VALUE:
      return state
        .set('selectedEnum', action.selectedEnum)
        .set('enumErrors', cloneDeep(enumErrors));
    case SET_ENUM_ERROR:
      return state.set('enumErrors', action.errors);
    case SUBMIT_ENUM:
      return state.set('enumErrors', cloneDeep(enumErrors));
    case SUBMIT_ENUM_SUCCESS:
      return state
        .set('selectedEnum', {})
        .set('successMessage', 'Enum has been saved.');
    case SUBMIT_ENUM_ERROR:
      return state.set('errorMessage', action.error);
    default:
      return state;
  }
};

export default enumPageReducer;
