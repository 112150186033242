import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect/lib/index';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { isMobile } from 'react-device-detect';

import withCrud from 'containers/ModelWrapper';
import MenuItem from '../MenuItem';
import { slugify } from '../../utils/constants';
import SubmenuCollapse from '../SubmenuCollapse';
import {
  makeSelectLocation,
  makeSelectNewsCategories,
} from '../../containers/App/selectors';

class AnonymMenu extends Component {
  initialState = {
    news: false,
    events: false,
  };

  state = this.initialState;

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    const { loadModels } = this.props;
    loadModels(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.location.pathname, this.props.location.pathname) &&
      nextProps.sidebarIsOpen
    ) {
      if (isMobile) {
        this.props.toggleSidebar();
        this.setState(this.initialState);
      }
    }
  }

  handleClick(type) {
    this.setState(state => {
      const newState = cloneDeep(state);
      newState[type] = !state[type];
      return newState;
    });
  }

  render() {
    const { models, newsCategories } = this.props;

    const { data, meta } = models;

    if (isEmpty(meta)) {
      return null;
    }

    const urlLead = '/auction-news/';
    const eventUrlLead = '/events/';
    return (
      <React.Fragment>
        <MenuItem
          url="/"
          icon="home"
          title="Home"
          selected={this.props.location.pathname === '/'}
        />
        <SubmenuCollapse
          open={this.state.news}
          toggle={() => this.handleClick('news')}
          label="Auction News"
          icon="rss_feed"
        >
          {Object.entries(newsCategories.attributes)
            .filter(item => item[0].match(/^AUCTION_SUB_/))
            .map(item => {
              const url = urlLead + slugify(item[0]);
              return (
                <MenuItem
                  url={url}
                  title={item[1]}
                  key={url}
                  selected={this.props.location.pathname === url}
                />
              );
            })}
        </SubmenuCollapse>
        {data.length ? (
          <SubmenuCollapse
            open={this.state.events}
            toggle={() => this.handleClick('events')}
            label="Events"
            icon="rss_feed"
          >
            {data.map(row => {
              const url = eventUrlLead + row.attributes.title_slug;
              return (
                <MenuItem
                  url={url}
                  title={row.attributes.title}
                  key={url}
                  selected={this.props.location.pathname === url}
                />
              );
            })}
          </SubmenuCollapse>
        ) : null}
        <MenuItem
          url="/registration"
          icon="how_to_reg"
          title="E-application"
          selected={this.props.location.pathname === '/registration'}
        />
      </React.Fragment>
    );
  }
}

AnonymMenu.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  newsCategories: PropTypes.object,
  location: PropTypes.object.isRequired,
  models: PropTypes.object.isRequired,
  loadModels: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  newsCategories: makeSelectNewsCategories(),
  location: makeSelectLocation(),
});

export default connect(mapStateToProps)(
  withCrud('anonymMenu', 'public-events', AnonymMenu, false),
);
