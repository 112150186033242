import { createMuiTheme } from '@material-ui/core/styles';
import { DRAWER_WIDTH } from './utils/constants';

const defaultTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

const spacing = defaultTheme.spacing.unit;

export const SIDEBAR_OPENED_WIDTH = DRAWER_WIDTH;
export const SIDEBAR_WIDTH = spacing * 9;
export const SIDEBAR_WIDTH_XS = spacing * 7;

export const TableContainerStyles = {
  // TODO: should merge with PageRootContainerStyles
  width: '100%',
  overflowX: 'auto',
  maxWidth: `calc(100vw - ${SIDEBAR_WIDTH_XS}px - ${spacing * 1.5}px)`,
  [defaultTheme.breakpoints.up('sm')]: {
    maxWidth: `calc(100vw - ${SIDEBAR_WIDTH}px - ${spacing * 6}px)`,
  },
};

export const TableContainerOpenedStyles = {
  ...TableContainerStyles,
  [defaultTheme.breakpoints.up('sm')]: {
    maxWidth: `calc(100vw - ${SIDEBAR_OPENED_WIDTH}px - ${spacing * 6}px)`,
  },
};

export const PageRootContainerStyles = {
  ...TableContainerStyles,
  marginLeft: `${spacing}px`,
  marginRight: `${spacing / 2}px`,
  marginBottom: `${spacing}px`,
  maxWidth: `calc(100vw - ${SIDEBAR_WIDTH_XS}px - ${spacing}px)`,
  [defaultTheme.breakpoints.up('sm')]: {
    // marginLeft: `${spacing * 4}px`,
    // marginRight: `${spacing * 2}px`,
    maxWidth: `calc(100vw - ${SIDEBAR_WIDTH}px - ${spacing}px)`,
  },
  minHeight: '500px',
};

export const PageRootContainerOpenedStyles = {
  ...PageRootContainerStyles,
  [defaultTheme.breakpoints.up('sm')]: {
    maxWidth: `calc(100vw - ${SIDEBAR_OPENED_WIDTH}px - ${spacing * 6}px)`,
  },
};

export const paperWrapperStyles = {
  marginTop: 40,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export default createMuiTheme({
  palette: {
    primary: {
      main: '#00682d',
    },
    secondary: {
      main: '#388e3c',
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTableCell: {
      root: {
        [defaultTheme.breakpoints.only('xs')]: {
          padding: `${spacing / 2}px ${spacing * 2}px ${spacing / spacing}px`,
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        [defaultTheme.breakpoints.only('xs')]: {
          whiteSpace: 'normal',
        },
      },
    },
    MuiMenuItem: {
      root: {
        [defaultTheme.breakpoints.only('xs')]: {
          whiteSpace: 'normal',
          fontSize: '0.875rem',
        },
      },
    },
    MuiListItemText: {
      root: {
        paddingLeft: '0',
        paddingRight: '26px',
      },
      primary: {
        [defaultTheme.breakpoints.only('xs')]: {
          fontSize: '0.875em',
        },
      },
      secondary: {
        [defaultTheme.breakpoints.only('xs')]: {
          fontSize: '0.875em',
        },
      },
    },
  },
});
