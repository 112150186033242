import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _, { cloneDeep, isEmpty, isEqual } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import injectReducer from '../../utils/injectReducer';
import withCrud from '../ModelWrapper';
import reducer from './reducer';
import { notificationErrors as resetErrors } from './constants';
import { setNotificationErrors } from './actions';
import { makeSelectNotificationFormErrors } from './selectors';
import TextInput from '../../components/TextInput';

class NotificationForm extends React.Component {
  constructor(props) {
    super(props);
    this.closeForm = this.closeForm.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
  }

  componentWillMount() {
    this.setDefaultModel(this.props.notification);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(
        _.get(nextProps, 'submitResponse'),
        _.get(this.props, 'submitResponse'),
      )
    ) {
      this.props.setSendSuccessState(
        _.get(nextProps, 'submitResponse.attributes.sent_invoices'),
      );
    }
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.props.refreshList();
      this.props.closeForm();
      return;
    }

    if (!isEmpty(nextProps.notification) && isEmpty(nextProps.model)) {
      this.setDefaultModel(nextProps.notification);
    }

    if (!isEqual(this.props.models, nextProps.models)) {
      if (nextProps.models.meta) {
        const { model } = nextProps;
        this.props.setModel(model);
      }
    }
  }

  setDefaultModel(defaultModel) {
    const model = cloneDeep(defaultModel);
    this.props.setModel(model);
  }

  inputChanged(event) {
    const selectedNotification = cloneDeep(this.props.model);
    selectedNotification.attributes[event.target.name] = event.target.value;

    this.props.setModel(selectedNotification);
  }

  closeForm() {
    this.props.setModel({});
    this.props.setErrors(cloneDeep(resetErrors));
    this.props.closeForm();
  }

  submit() {
    const { setErrors, model } = this.props;

    setErrors(cloneDeep(resetErrors));
    const NotificationErrors = cloneDeep(resetErrors);

    const requiredFields = {
      message: 'Email text',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!model.attributes[field].toString().length) {
        NotificationErrors[field] = `${requiredFields[field]} can not be empty`;
      }
    });

    if (!isEqual(NotificationErrors, resetErrors)) {
      this.props.setErrors(NotificationErrors);
      return this.forceUpdate();
    }

    return this.props.submitModel();
  }

  render() {
    const { model, notificationErrors } = this.props;

    if (isEmpty(model)) {
      return null;
    }

    return (
      <Dialog
        maxWidth="md"
        fullWidth
        open
        onClose={this.closeForm}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Send Notification</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <DialogContent>
            <TextInput
              model={model}
              modelErrors={notificationErrors}
              onChange={this.inputChanged}
              attribute="message"
              label="Email text"
              rows={5}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeForm}>Cancel</Button>
            <Button type="submit" color="primary">
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

NotificationForm.propTypes = {
  notification: PropTypes.object,
  model: PropTypes.object,
  models: PropTypes.object,
  closeForm: PropTypes.func.isRequired,
  setSendSuccessState: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  notificationErrors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,

  submitModel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  notificationErrors: makeSelectNotificationFormErrors(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setNotificationErrors(errors)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'notificationForm', reducer });

export default compose(
  withReducer,
  withConnect,
)(withCrud('notificationsPage', 'notifications', NotificationForm, false));
