import { fromJS } from 'immutable';
import {
  LOAD_CONFIG,
  LOAD_CONFIG_SUCCESS,
  SET_ERROR_OBJECT,
} from './constants';

export const initialState = fromJS({
  config: [],
  error: null,
  loading: true,
});

function authCheckerReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CONFIG_SUCCESS:
      return state.set('config', action.config).set('loading', false);
    case SET_ERROR_OBJECT:
      return state.set('error', action.error);
    case LOAD_CONFIG:
      return state.set('loading', true);
    default:
      return state;
  }
}

export default authCheckerReducer;
