import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, get, isEmpty, remove } from 'lodash';

import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import FileCopy from '@material-ui/icons/FileCopy';

import TextInput from 'components/TextInput';
import FileUpload from 'containers/FileUpload';
import ConfirmDialog from 'components/ConfirmDialog';
import withCrud from '../ModelWrapper';

const DeleteDialog = props => (
  <ConfirmDialog
    open
    onClose={props.close}
    cancelAction={props.close}
    confirmAction={() => {
      props.close();
      props.removeFromList(props.file);
    }}
    title={`Are you sure you want to delete ${props.file.attributes.name}?`}
    description="This action can not be undone."
  />
);

DeleteDialog.propTypes = {
  file: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired,
};

const FileDeleteDialog = withCrud(
  'deleteItemFile',
  'files',
  DeleteDialog,
  false,
);

class ItemFormForHunts extends Component {
  state = {
    deleteFile: null,
  };

  attachFile(file) {
    if (isEmpty(file)) {
      return;
    }
    const item = cloneDeep(this.props.model);
    if (isEmpty(item.relationships.files)) {
      item.relationships.files = { data: [] };
    }
    const attachments = item.relationships.files.data;
    attachments.push({
      id: file.id,
      type: 'files',
    });
    item.relationships = Object.assign({}, item.relationships, {
      files: { data: attachments },
    });
    const included = cloneDeep(this.props.modelIncludes);
    included.unshift(file);
    this.props.setModel(item);
    this.props.setModelIncludes(included);
    setTimeout(() => {
      this.props.showInfoToast(
        'You have to click on save button to finalize file upload!',
      );
    }, 1500);
  }

  dettachFile(file) {
    if (isEmpty(file)) {
      return;
    }
    const item = cloneDeep(this.props.model);
    remove(item.relationships.files.data, itm => itm.id === file.id);

    const included = cloneDeep(this.props.modelIncludes);
    remove(included, itm => itm.id === file.id);

    this.props.setModel(item);
    this.props.setModelIncludes(included);

    this.props.showInfoToast(
      'You have to click on save button to finialize file changes!',
    );
  }

  openDialog(file) {
    this.setState({ deleteFile: file });
  }

  render() {
    const {
      model,
      auctions,
      itemErrors,
      classes,
      onSubmit,
      onChange,
      modelIncludes,
      history,
    } = this.props;

    const files = modelIncludes.filter(item => item.type === 'files');
    return (
      <form onSubmit={onSubmit}>
        <InputLabel>Select Image/Video/Document</InputLabel>
        <div style={{ margin: '10px 0' }}>
          <div className="e">
            {this.state.deleteFile && (
              <FileDeleteDialog
                file={this.state.deleteFile}
                close={() => {
                  this.setState(() => ({ deleteFile: null }));
                }}
                removeFromList={file => {
                  this.dettachFile(file);
                }}
              />
            )}
            <FileUpload
              accept="image/*,video/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.txt"
              value={null}
              error={itemErrors.file_id}
              attachFile={file => this.attachFile(file)}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>-</TableCell>
                  <TableCell>File name</TableCell>
                  <TableCell>Size (Bytes)</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.attributes.fileType === 'image' && (
                        <img src={row.attributes.dimensions.thumbnail} alt="" />
                      )}
                      {row.attributes.fileType === 'video' && (
                        <video
                          width="300"
                          height="200"
                          src={row.attributes.dimensions.original}
                          controls
                        >
                          <track kind="captions" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      {row.attributes.fileType === 'application' && (
                        <FileCopy />
                      )}
                    </TableCell>
                    <TableCell>{row.attributes.name}</TableCell>
                    <TableCell>{row.attributes.size}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Delete"
                        onClick={() => this.openDialog(row)}
                      >
                        <Icon fontSize="small">delete</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
        <TextInput
          model={model}
          modelErrors={itemErrors}
          attribute="name"
          label="Type of hunt"
          onChange={onChange}
        />
        <TextInput
          model={model}
          modelErrors={itemErrors}
          attribute="starting_bid"
          label="Starting bid"
          onChange={onChange}
        />
        <FormControl
          aria-describedby="value-error-text"
          fullWidth
          error={Boolean(itemErrors.assigned_auctions.length)}
        >
          <InputLabel htmlFor="assigned_auctions">Assigned auctions</InputLabel>
          <Select
            id="assigned_auctions"
            name="assigned_auctions"
            value={model.attributes.assigned_auctions || ''}
            onChange={onChange}
          >
            {Object.keys(auctions.data).map(key => (
              <MenuItem value={auctions.data[key].id} key={key}>
                {auctions.data[key].attributes.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText id="value-error-text">
            {itemErrors.assigned_auctions}
          </FormHelperText>
        </FormControl>
        <TextInput
          model={model}
          modelErrors={itemErrors}
          attribute="donor"
          label="Donor"
          onChange={onChange}
        />
        <TextInput
          model={model}
          modelErrors={itemErrors}
          attribute="delegation"
          label="Delegation"
          onChange={onChange}
        />
        <TextInput
          model={model}
          modelErrors={itemErrors}
          attribute="place"
          label="Place of hunt"
          onChange={onChange}
        />
        <FormControlLabel
          label="Accomodation included"
          control={
            <Checkbox
              checked={Boolean(model.attributes.accomodation_included)}
              onChange={(event, checked) => {
                onChange({
                  target: {
                    name: 'accomodation_included',
                    value: checked ? 1 : 0,
                  },
                });
              }}
              name="accomodation_included"
              color="primary"
            />
          }
        />
        <TextInput
          model={model}
          modelErrors={itemErrors}
          attribute="website"
          label="Website"
          onChange={onChange}
        />
        <TextInput
          model={model}
          modelErrors={itemErrors}
          attribute="uniqueness"
          label="What is unique about it"
          onChange={onChange}
        />
        <TextInput
          model={model}
          modelErrors={itemErrors}
          attribute="participants"
          label="Participants"
          onChange={onChange}
        />
        <TextInput
          model={model}
          modelErrors={itemErrors}
          attribute="game_offered"
          label="Game offered"
          onChange={onChange}
        />
        <TextInput
          model={model}
          modelErrors={itemErrors}
          attribute="suggested_time_frame"
          label="Suggested time frame"
          onChange={onChange}
        />
        <FormControlLabel
          label="Enable bidding"
          control={
            <Checkbox
              checked={Boolean(model.attributes.bidding_enable)}
              onChange={(event, checked) => {
                onChange({
                  target: {
                    name: 'bidding_enable',
                    value: checked ? 1 : 0,
                  },
                });
              }}
              name="bidding_enable"
              color="primary"
            />
          }
        />
        <FormControlLabel
          label="Notify admins about every bid"
          control={
            <Checkbox
              checked={Boolean(model.attributes.notify_bid)}
              onChange={(event, checked) => {
                onChange({
                  target: {
                    name: 'notify_bid',
                    value: checked ? 1 : 0,
                  },
                });
              }}
              name="notify_bid"
              color="primary"
            />
          }
        />
        <FormControlLabel
          label="Hide pay button"
          control={
            <Checkbox
              checked={Boolean(model.attributes.hide_pay)}
              onChange={(event, checked) => {
                onChange({
                  target: {
                    name: 'hide_pay',
                    value: checked ? 1 : 0,
                  },
                });
              }}
              name="hide_pay"
              color="primary"
            />
          }
        />
        <FormGroup row>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.button}
          >
            Submit
          </Button>
          {get(model, 'id', false) && (
            <Button
              type="button"
              color="secondary"
              variant="contained"
              className={classes.button}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
          )}
        </FormGroup>
      </form>
    );
  }
}

ItemFormForHunts.defaultProps = {
  modelIncludes: [],
};

ItemFormForHunts.propTypes = {
  model: PropTypes.object.isRequired,
  modelIncludes: PropTypes.array,
  auctions: PropTypes.object.isRequired,
  itemErrors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  showInfoToast: PropTypes.func.isRequired,
  setModelIncludes: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default ItemFormForHunts;
