import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _, { cloneDeep, isEmpty, isEqual } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import MoneyInput from 'components/MoneyInput';
import injectReducer from '../../utils/injectReducer';
import withCrud from '../ModelWrapper';
import reducer from './reducer';
import { transactionErrors as resetErrors } from './constants';
import { setTransactionErrors } from './actions';
import { makeSelectTransactionFormErrors } from './selectors';

const initialState = {};

class TransactionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.closeForm = this.closeForm.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
  }

  componentWillMount() {
    this.setState(initialState);
    if (!isEmpty(this.props.transaction) && isEmpty(this.props.model)) {
      this.setDefaultModel(this.props.transaction);
    }
  }

  componentWillReceiveProps(nextProps) {
    const redirectUrl = _.get(
      nextProps,
      'submitResponse.attributes.redirect_url',
    );
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }

    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.props.refreshList();
      this.props.closeForm();
      return;
    }

    if (
      !isEqual(nextProps.model, this.props.model) &&
      !isEmpty(nextProps.model) &&
      nextProps.model.attributes.balance <= 1000
    ) {
      const selectedTransaction = cloneDeep(nextProps.model);
      this.submit(selectedTransaction);
    }

    if (!isEmpty(nextProps.transaction) && isEmpty(nextProps.model)) {
      this.setState(initialState);
      this.setDefaultModel(nextProps.transaction);
    }

    if (!isEqual(this.props.models, nextProps.models)) {
      if (nextProps.models.meta) {
        const { model } = nextProps;
        this.props.setModel(model);
      }
    }
  }

  setDefaultModel(defaultModel) {
    const model = cloneDeep(defaultModel);
    this.props.setModel(model);
  }

  inputChanged(event) {
    const selectedTransaction = cloneDeep(this.props.model);
    selectedTransaction.attributes[event.target.name] = event.target.value;
    this.props.setModel(selectedTransaction);
  }

  closeForm() {
    this.setState(initialState);
    this.props.setModel({});
    this.props.setErrors(cloneDeep(resetErrors));
    this.props.closeForm();
  }

  submit(model) {
    const { setErrors } = this.props;
    if (!model) {
      model = this.props.model; // eslint-disable-line
    }

    setErrors(cloneDeep(resetErrors));
    const transactionErrors = cloneDeep(resetErrors);

    const requiredFields = {
      amount: 'Amount',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!model.attributes[field].toString().length) {
        transactionErrors[field] = `${requiredFields[field]} can not be empty`;
      }
    });

    if (model.attributes.balance > 1000 && model.attributes.amount < 1000) {
      transactionErrors.amount = 'The amount may not be less than 1000.00 EUR';
    }

    if (model.attributes.amount > model.attributes.balance) {
      transactionErrors.amount = `The amount may not exceed ${
        model.attributes.balance
      } EUR`;
    }

    if (!isEqual(transactionErrors, resetErrors)) {
      this.props.setErrors(transactionErrors);
      return this.forceUpdate();
    }

    this.setState(initialState);
    return this.props.submitModel();
  }

  render() {
    const { model, transactionErrors } = this.props;

    if (isEmpty(model)) {
      return null;
    }

    return (
      <Dialog
        open={model.attributes.balance > 1000}
        onClose={this.closeForm}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Pay Invoices</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <DialogContent>
            <Typography component="p" gutterBottom>
              Please specify an amount to pay. You can pay the whole amount or
              just a part of it.
            </Typography>
            <p>
              <MoneyInput
                model={model}
                modelErrors={transactionErrors}
                onChange={this.inputChanged}
                attribute="amount"
                label="Amount"
              />
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeForm}>Cancel</Button>
            <Button type="submit" color="primary">
              Checkout
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

TransactionForm.propTypes = {
  transaction: PropTypes.object,
  model: PropTypes.object,
  models: PropTypes.object,
  closeForm: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  transactionErrors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  transactionErrors: makeSelectTransactionFormErrors(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setTransactionErrors(errors)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'transactionForm', reducer });

export default compose(
  withReducer,
  withConnect,
)(withCrud('transactionsPage', 'invoice-transactions', TransactionForm, false));
