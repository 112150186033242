import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const ActiveFilter = props => (
  <Select
    isClearable={props.isClearable}
    options={[{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }]}
    defaultValue={
      props.defaultActive && {
        value: 1,
        label: 'Yes',
      }
    }
    onChange={value => {
      const event = {
        target: {
          name: 'active',
          value: value ? value.value : null,
        },
      };
      props.setFilter(event);
    }}
  />
);

ActiveFilter.propTypes = {
  isClearable: PropTypes.bool,
  defaultActive: PropTypes.bool,
  setFilter: PropTypes.func.isRequired,
};

export default ActiveFilter;
