import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the events state domain
 */

const selectEventFormDomain = state => state.get('eventForm', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Events
 */

const makeSelectEvents = () =>
  createSelector(selectEventFormDomain, substate => substate.toJS());

const makeSelectEventErrors = () =>
  createSelector(
    selectEventFormDomain,
    substate => substate.toJS().eventErrors,
  );

export default makeSelectEvents;
export { selectEventFormDomain, makeSelectEventErrors };
