import React, { Fragment } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import moment from 'moment';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Money from 'components/Money';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import ConfirmDialog from 'components/ConfirmDialog';
import { makeSelectEnumValuesOf } from '../App/selectors';
import { DATE_FORMAT } from '../../utils/constants';
import Authorization from '../../utils/authorization';

const AdminAction = Authorization([
  'SYSTEM_ADMINISTRATOR',
  'MEMBERSHIP_ADMINISTRATOR',
]);

const DeleteButton = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <Icon fontSize="small">delete</Icon>
  </IconButton>
);
DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const EditButton = ({ onClick }) => (
  <IconButton aria-label="Edit" onClick={onClick}>
    <Icon fontSize="small">edit_icon</Icon>
  </IconButton>
);
EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const EditAction = AdminAction(EditButton);
const DeleteAction = AdminAction(DeleteButton);

class MemberPaymentTable extends React.Component {
  constructor() {
    super();

    this.state = {
      deleteDialogOpen: false,
      dialogTitle: '',
    };
  }

  openDialog(payment) {
    this.setState(() => ({
      deleteDialogOpen: true,
      destroyPayment: payment,
      dialogTitle: `Are you sure you want to remove payment?`,
    }));
  }

  handleConfirmDelete() {
    this.props.setModelType('payments');
    this.props.deleteModel(this.state.destroyPayment);
    this.props.setModelType('invoices');
    this.setState(() => ({ deleteDialogOpen: false }));
  }

  render() {
    const { includedPayments, invoicePayments, enumValues } = this.props;

    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>PID</TableCell>
              <TableCell>Method</TableCell>
              <TableCell>Date of Payment</TableCell>
              <TableCell>Amount Paid</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {invoicePayments.map(row =>
              includedPayments.map(
                payment =>
                  row.id === payment.id ? (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>
                        {
                          enumValues.find(
                            item =>
                              item.column_id ===
                              payment.attributes.payment_method,
                          ).value
                        }
                      </TableCell>
                      <TableCell>
                        {moment(payment.attributes.payment_date.date).format(
                          DATE_FORMAT,
                        )}
                      </TableCell>
                      <TableCell>
                        <Money amount={payment.attributes.paid_amount} />
                      </TableCell>
                      <TableCell>
                        <DeleteAction onClick={() => this.openDialog(row)} />
                        <EditAction
                          onClick={() => this.props.handleEditOnclick(payment)}
                        />
                      </TableCell>
                    </TableRow>
                  ) : null,
              ),
            )}
          </TableBody>
        </Table>
        <ConfirmDialog
          open={this.state.deleteDialogOpen}
          onClose={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          cancelAction={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          confirmAction={() => {
            this.handleConfirmDelete();
          }}
          title={this.state.dialogTitle}
          description="This action can not be undone."
        />
      </Fragment>
    );
  }
}

MemberPaymentTable.propTypes = {
  invoicePayments: PropTypes.array.isRequired,
  includedPayments: PropTypes.array.isRequired,
  enumValues: PropTypes.array.isRequired,
  setModelType: PropTypes.func.isRequired,
  deleteModel: PropTypes.func.isRequired,
  handleEditOnclick: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  enumValues: makeSelectEnumValuesOf('payment_method'),
});

export default connect(mapStateToProps)(MemberPaymentTable);
