import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';

import { SET_USER_ERROR, userErrors } from './constants';

export const initialState = fromJS({
  userErrors: cloneDeep(userErrors),
});

const userPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ERROR:
      return state.set('userErrors', action.errors);
    default:
      return state;
  }
};

export default userPageReducer;
