import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import PropTypes from 'prop-types';
import ContainerPaper from '../../components/ContainerPaper';
import download from '../../utils/download';
import withCrud from '../ModelWrapper';

class NewsLetterPage extends Component {
  handleSubmit = () => {
    axios({
      url: 'export/newsletter/issues',
      method: 'GET',
      responseType: 'blob',
    })
      .then(response => download(response, response.headers['file-name']))
      .catch(error => {
        this.props.showErrorToast(error.message);
      });
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Newsletter</title>
        </Helmet>
        <ContainerPaper>
          <Typography variant="h5" gutterBottom>
            Download the issue report generated from the Susspressions data in
            Sendgrid
          </Typography>
          <Typography variant="body2" gutterBottom>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Download Sendgrid Issue Report
            </Button>
          </Typography>
        </ContainerPaper>
      </div>
    );
  }
}

NewsLetterPage.propTypes = {
  showErrorToast: PropTypes.func.isRequired,
};

export default withCrud('newsLetter', 'newsletter', NewsLetterPage, false);
