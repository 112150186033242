import React from 'react';
// import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Filters from './Filters';

const DatabaseReportPage = () => (
  <div>
    <Helmet>
      <title>Database Report</title>
    </Helmet>
    <Filters />
  </div>
);

// DatabaseReportPage.propTypes = {
// };

export default DatabaseReportPage;
