import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the userPage state domain
 */

const selectUserPageDomain = state => state.get('userPage', initialState);

const makeSelectUserErrors = () =>
  createSelector(selectUserPageDomain, substate => substate.toJS().userErrors);

export { makeSelectUserErrors };
