import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';
import { cloneDeep, get, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import FileCopy from '@material-ui/icons/FileCopy';
import ThumbUp from '@material-ui/icons/ThumbUp';
import LeftArrow from '@material-ui/icons/KeyboardArrowLeft';
import RightArrow from '@material-ui/icons/KeyboardArrowRight';

import withCrud from '../../containers/ModelWrapper';
import GridRow from '../../components/GridRow';
import { makeSelectModelIncludes } from '../../containers/ModelWrapper/selectors';
import MediaCarousel from '../../components/MediaCarousel';

import { PageRootContainerStyles, paperWrapperStyles } from '../../mui-theme';
import ContainerPaper from '../../components/ContainerPaper';
import {
  getCurrentBid,
  getCurrentBidAmount,
  getImageUrl,
  getNextBidAmount,
  isAuctionActive,
  roundToDecimals,
} from './actions';
import { getIncludedModelById } from '../../utils/fractal';
import BidForm from './BidForm';
import { bidSkeleton } from './constants';
import { userHasRole } from '../../utils/userHasRole';
import {
  deInitWebsocketConnection,
  initWebsocketConnection,
} from '../../utils/axios';
import { makeRolesSelector } from '../AuthButton/selectors';
import BidsTable from './BidsTable';

const styles = theme => ({
  root: PageRootContainerStyles,
  paperWrapper: paperWrapperStyles,
  paper: {
    maxWidth: theme.breakpoints.values.md,
  },
  image: {
    marginBottom: theme.spacing.unit * 3,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    width: 'auto',
    maxWidth: '300px',
    height: 'auto',
    maxHeight: '200px',
  },
  placeBid: {
    position: 'fixed',
    bottom: theme.spacing.unit * 10,
    right: theme.spacing.unit * 3,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

let historyListen = null;

class ItemView extends React.Component {
  isUserAdmin = userHasRole(this.props.roles, [
    'SYSTEM_ADMINISTRATOR',
    'MEMBERSHIP_ADMINISTRATOR',
  ]);

  channelType = this.isUserAdmin ? 'auction-bids-admin' : 'auction-bids';

  state = {
    bid: null,
  };

  constructor(props) {
    super(props);
    this.setBid = this.setBid.bind(this);
    this.refreshBids = this.refreshBids.bind(this);
    this.bidUpdatedCallback = this.bidUpdatedCallback.bind(this);
  }

  componentWillMount() {
    const currentState = get(window.history, 'state.state', false);
    historyListen = this.props.history.listen((location, action) => {
      if (
        currentState &&
        action === 'POP' &&
        location.pathname.indexOf('/items') !== -1
      ) {
        this.props.history.replace(location.pathname, currentState);
      }
    });
    this.refreshBids();
    initWebsocketConnection(
      this.channelType,
      '.BidUpdated',
      this.bidUpdatedCallback,
    );
  }

  componentWillReceiveProps(nextProps) {
    this.updateBids(nextProps);

    if (this.props.match.params.itemId !== nextProps.match.params.itemId) {
      this.props.showModel(nextProps.match.params.itemId);
    }
  }

  componentWillUnmount() {
    deInitWebsocketConnection(this.channelType);
    window.setTimeout(() => {
      historyListen();
    }, 300);
  }

  bidUpdatedCallback = data => {
    const itemId = parseInt(this.props.model.id, 10);
    if (!itemId || itemId !== data.auction_item_id) {
      return;
    }
    this.setCurrentBid(data);
  };

  refreshBids() {
    const { match } = this.props;
    this.props.showModel(match.params.itemId);
    this.updateBids(this.props);
  }

  updateBids(props) {
    const { match, model, included } = props;
    if (!included || !model) {
      return;
    }
    const auction = { id: match.params.auctionId };
    const bid = getCurrentBid(auction, model, included);
    const currentBid = bid ? bid.attributes : undefined;
    this.setState({
      // bid,
      currentBid,
      currentBidAmount: getCurrentBidAmount(currentBid, model),
      nextBidAmount: getNextBidAmount(currentBid, model),
    });
  }

  setBid() {
    const { match, model } = this.props;
    const bidModel = cloneDeep(bidSkeleton);

    bidModel.attributes.auction_id = match.params.auctionId;
    bidModel.attributes.auction_item_id = model.id;
    bidModel.attributes.amount = roundToDecimals(this.state.nextBidAmount, 2);

    this.setState({ bid: bidModel });
  }

  setCurrentBid(data) {
    const {
      /* eslint-disable camelcase */
      auction_id,
      auction_item_id,
      author_user_id,
      is_current,
      amount,
      created_at,
    } = data;
    const { model } = this.props;
    const currentBidSkeleton = {
      auction_id,
      auction_item_id,
      author_user_id,
      is_current,
      amount,
      created_at,
    };
    this.setState({
      currentBidAmount: getCurrentBidAmount(currentBidSkeleton, model),
      nextBidAmount: getNextBidAmount(currentBidSkeleton, model),
      currentBid: currentBidSkeleton,
    });
  }

  render() {
    const {
      model,
      classes,
      included,
      match,
      history,
      location,
      modelIncludes,
    } = this.props;

    if (!model || isEmpty(model) || isEmpty(included)) {
      return null;
    }

    const auction = getIncludedModelById(
      match.params.auctionId,
      'auctions',
      included,
    );

    const getClosestNumber = (array, val, reverse) => {
      if (array.length === 1) return array[0];
      const idx = array.indexOf(val);
      if (!reverse) {
        return array[idx - 1] || array[array.length - 1];
      }
      return array[idx + 1] || array[0];
    };
    const siblingItems = get(location.state, 'siblingItems', []);
    const switcherDisabled = siblingItems.length < 2;
    const getPath = (next = false) => ({
      pathname: `/items/${getClosestNumber(
        siblingItems,
        match.params.itemId,
        next,
      )}/${match.params.auctionId}`,
      state: {
        siblingItems,
      },
    });
    const ItemSwitcher = () => (
      <Grid container justify="space-between">
        <Grid item>
          <Link to={getPath()}>
            <Button
              color="primary"
              className={classes.button}
              aria-label="Previous item in auction"
              title="Previous item in auction"
              disabled={switcherDisabled}
            >
              <LeftArrow />
              Previous Item
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <Link to={getPath(true)}>
            <Button
              color="primary"
              className={classes.button}
              aria-label="Next item in auction"
              title="Next item in auction"
              disabled={switcherDisabled}
            >
              Next Item
              <RightArrow />
            </Button>
          </Link>
        </Grid>
      </Grid>
    );

    const PlaceBidButton = () =>
      isAuctionActive(auction) && (
        <Button
          variant="extendedFab"
          size="small"
          color="primary"
          aria-label="Place a Bid"
          onClick={this.setBid}
          className={classes.placeBid}
        >
          <ThumbUp className={classes.extendedIcon} />
          Place a Bid
        </Button>
      );

    const bid = {
      type: this.state.currentBid ? 'Current bid' : 'Starting bid',
      value: this.state.currentBidAmount,
    };

    const huntData = [
      { label: 'Type of hunt', attribute: 'name' },
      { label: bid.type, type: 'value', value: bid.value },
      { label: 'Donor', attribute: 'donor' },
      { label: 'Delegation', attribute: 'delegation' },
      { label: 'Place of hunt', attribute: 'place' },
      {
        label: 'Accomodation included',
        attribute: 'accomodation_included',
        type: 'boolean',
      },
      { label: 'Website', attribute: 'website', type: 'url' },
      { label: 'What is unique about it', attribute: 'uniqueness' },
      { label: 'Participants', attribute: 'participants' },
      { label: 'Game offered', attribute: 'game_offered' },
      { label: 'Suggested time frame', attribute: 'suggested_time_frame' },
    ];

    const objectData = [
      { label: 'Description', attribute: 'name' },
      { label: bid.type, type: 'value', value: bid.value },
      { label: 'Donor', attribute: 'donor' },
      { label: 'Delegation', attribute: 'delegation' },
      { label: 'Website', attribute: 'website', type: 'url' },
      { label: 'What is unique about it', attribute: 'uniqueness' },
    ];

    const gridData =
      model.attributes.item_type === 'OBJECT' ? objectData : huntData;
    const files = included
      .filter(e => e.type === 'files' && !isEmpty(e.attributes))
      .map(file => ({
        name: file.attributes.name,
        src: file.attributes.dimensions.original,
        type: file.attributes.fileType,
      }));
    const images = files.filter(file => file.type !== 'application');
    const docs = files.filter(file => file.type === 'application');

    return (
      <div className={classes.paperWrapper}>
        <ContainerPaper className={classes.paper}>
          {siblingItems && <ItemSwitcher />}
          <img
            src={getImageUrl(model, included)}
            alt="Item"
            className={classes.image}
          />
          <div>
            {model.attributes.bidding_enable && <PlaceBidButton />}
            {this.state.bid && (
              <BidForm refreshList={this.refreshBids} bid={this.state.bid} />
            )}
          </div>
          {gridData.map(item => (
            <GridRow key={item.label} data={model} row={item} />
          ))}
          <MediaCarousel files={images} />
          <div style={{ margin: '10px 0' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h2>Documents</h2>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {docs.map(doc => (
                  <TableRow key={doc.name}>
                    <TableCell>
                      <a href={doc.src} target="_blank">
                        <FileCopy style={{ verticalAlign: 'bottom' }} />
                        {doc.name}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {this.isUserAdmin && (
              <BidsTable
                bidList={modelIncludes}
                refreshList={this.refreshBids}
              />
            )}
            {get(model, 'id', false) && (
              <Button
                type="button"
                color="secondary"
                variant="contained"
                className={classes.button}
                onClick={() => {
                  history.goBack();
                }}
                style={{ marginTop: '10px' }}
              >
                Back
              </Button>
            )}
          </div>
        </ContainerPaper>
      </div>
    );
  }
}

ItemView.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  included: PropTypes.array,
  classes: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  modelIncludes: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  included: makeSelectModelIncludes('itemView'),
  roles: makeRolesSelector(),
});

const withConnect = connect(
  mapStateToProps,
  withRouter,
);

export default compose(withConnect)(
  withStyles(styles)(withCrud('itemView', 'items', ItemView, false)),
);
