import axios from 'axios';
import { put, takeLatest, call } from 'redux-saga/effects';

import { loadConfigSuccess } from './actions';
import { LOAD_CONFIG } from './constants';

export function* loadConfig() {
  try {
    const response = yield call(() => axios.get(`/config`).then(res => res));
    yield put(loadConfigSuccess(response.data.data));
  } catch (err) {
    //
  }
}

// Individual exports for testing
export default function* user() {
  yield takeLatest(LOAD_CONFIG, loadConfig);
}
