import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Editor } from '@tinymce/tinymce-react';

const TINYMCE_API_KEY = 'jx31cffz6lx9fye1qzrtuhkg2616uoxnyscjpvrstufpnq0j';
const TINYMCE_TOOLBAR =
  'formatselect | bold italic underline | alignleft aligncenter alignright | outdent indent | blockquote | bullist numlist | link image table';

class TinyMCE extends Component {
  constructor(props) {
    super(props);

    this.state = { content: props.initialValue };
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  handleEditorChange(content) {
    this.setState({ content });
  }

  componentWillReceiveProps(nextProps) {
    this.handleEditorChange(nextProps.initialValue);
  }

  render() {
    const { onChange, Id, plugins, toolbar } = this.props;

    return (
      <Editor
        apiKey={TINYMCE_API_KEY}
        value={this.state.content}
        id={Id}
        init={{
          plugins: plugins || 'link lists image table',
          menubar: false,
          toolbar: toolbar || TINYMCE_TOOLBAR,
          branding: false,
          height: this.props.height,
        }}
        onEditorChange={this.handleEditorChange}
        onChange={event => onChange(event)}
      />
    );
  }
}

TinyMCE.defaultProps = {
  height: 500,
};

TinyMCE.propTypes = {
  initialValue: PropTypes.string.isRequired,
  Id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  height: PropTypes.number,
  plugins: PropTypes.string,
  toolbar: PropTypes.string,
};

export default TinyMCE;
