import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectModelWrapperSubDomain = state => reducerKey =>
  state.get(reducerKey, initialState);

const makeSelectModels = reducerKey =>
  createSelector(
    state => selectModelWrapperSubDomain(state)(reducerKey),
    substate => substate.toJS().models,
  );

const makeSelectModel = reducerKey =>
  createSelector(
    state => selectModelWrapperSubDomain(state)(reducerKey),
    substate =>
      isEmpty(substate.toJS().model)
        ? substate.toJS().model
        : substate.toJS().model.data,
  );

const makeSelectSubmitResponse = reducerKey =>
  createSelector(
    state => selectModelWrapperSubDomain(state)(reducerKey),
    substate =>
      isEmpty(substate.toJS().submitResponse)
        ? substate.toJS().submitResponse
        : substate.toJS().submitResponse.data,
  );

const makeSelectModelIncludes = reducerKey =>
  createSelector(
    state => selectModelWrapperSubDomain(state)(reducerKey),
    substate => substate.toJS().model && substate.toJS().model.included,
  );

const makeSelectModelType = reducerKey =>
  createSelector(
    state => selectModelWrapperSubDomain(state)(reducerKey),
    substate => substate.toJS().modelType,
  );

const makeSelectParams = reducerKey =>
  createSelector(
    state => selectModelWrapperSubDomain(state)(reducerKey),
    substate => substate.get('params').toJS(),
  );

const makeSelectSortBy = reducerKey =>
  createSelector(
    state => selectModelWrapperSubDomain(state)(reducerKey),
    substate =>
      Object.keys(substate.get('params').toJS().sort).length
        ? Object.keys(substate.get('params').toJS().sort)[0]
        : '',
  );

const makeSelectSortDirection = reducerKey =>
  createSelector(
    state => selectModelWrapperSubDomain(state)(reducerKey),
    substate =>
      Object.values(substate.get('params').toJS().sort).length
        ? Object.values(substate.get('params').toJS().sort)[0]
        : 'asc',
  );

const makeSelectModelId = reducerKey =>
  createSelector(
    state => selectModelWrapperSubDomain(state)(reducerKey),
    substate => substate.get('modelId'),
  );

const makeSelectLoading = reducerKey =>
  createSelector(
    state => selectModelWrapperSubDomain(state)(reducerKey),
    substate => substate.get('loading'),
  );

export {
  makeSelectLoading,
  makeSelectModels,
  makeSelectModel,
  makeSelectParams,
  makeSelectSortBy,
  makeSelectSortDirection,
  makeSelectModelId,
  makeSelectModelType,
  makeSelectModelIncludes,
  makeSelectSubmitResponse,
};
