import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

import AccessDeniedPage from 'containers/AccessDeniedPage/AccessDeniedPage';
import {
  makeRolesSelector,
  makeUserSelector,
} from 'containers/AuthButton/selectors';
import { userHasAccess } from './userHasRole';

/* eslint-disable react/prefer-stateless-function */
const Authorization = (
  allowedRoles = [],
  exact = false,
  isPageAccess = false,
) => WrappedComponent => {
  class WithAuthorization extends React.Component {
    render() {
      const accessGranted = userHasAccess(
        this.props.roles,
        allowedRoles,
        exact,
      );
      if (
        this.props.location &&
        (isEmpty(this.props.user) || !this.props.user) &&
        !localStorage.getItem('accessToken')
      ) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { path: this.props.location.pathname },
            }}
          />
        );
      }
      if (accessGranted) {
        return <WrappedComponent {...this.props} />;
      }
      if (this.props.user && this.props.roles.length !== 0 && isPageAccess) {
        return <AccessDeniedPage />;
      }
      return null;
    }
  }
  WithAuthorization.propTypes = {
    roles: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    location: PropTypes.object,
  };
  const mapStateToProps = createStructuredSelector({
    roles: makeRolesSelector(),
    user: makeUserSelector(),
  });
  return connect(mapStateToProps)(WithAuthorization);
};

export default Authorization;
