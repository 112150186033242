import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'utils/constants';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectInput from 'components/SelectInput';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateSelector from 'components/DatePicker';
import TextInput from 'components/TextInput';
import TinyMCE from 'components/TinyMCE';
import MemberAttachments from './MemberAttachments';
import MoneyInput from '../../components/MoneyInput';
import ProfileImageUpload from './ProfileImageUpload';

const styles = theme => ({
  copyName: {
    marginRight: theme.spacing.unit,
  },
});

const MemberFormForExistingMember = ({
  model,
  memberErrors,
  classes,
  countries,
  delegations,
  enumValues,
  genders,
  getEnumTypeIndex,
  onSubmit,
  onChange,
  openDialog,
  cancel,
  birthDate,
  leftCIC,
  memberSince,
  profileImage,
  setSubscription,
  subscriptions,
  isUserAdmin,
  isUserDelegationHead,
  isUserYO,
  isUserArtemis,
  copyName,
  copyNameAndAddress,
  uploadProfileImage,
  handleEditorChange,
}) => (
  <form onSubmit={onSubmit}>
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="space-between"
      style={{ marginBottom: '16px' }}
    >
      <Typography variant="h5" gutterBottom>
        Edit Member
      </Typography>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={copyName}
          className={classes.copyName}
        >
          Copy name
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={copyNameAndAddress}
        >
          Copy name and address
        </Button>
      </div>
    </Grid>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Membership data</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <TextInput
            model={model}
            modelErrors={memberErrors}
            attribute="member_number"
            label="Member number"
            disabled
          />
          <SelectInput
            attribute="member_type"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            disabled={isUserDelegationHead || isUserYO || isUserArtemis}
            label="Member type"
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('member_type') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <SelectInput
            attribute="delegation"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            disabled={!isUserAdmin && !isUserYO}
            label="Delegation"
          >
            {delegations.attributes.delegation.map(item => (
              <MenuItem value={item.column_id} key={item.column_id}>
                {item.name}
              </MenuItem>
            ))}
          </SelectInput>
          <MoneyInput
            model={model}
            modelErrors={memberErrors}
            attribute="fee"
            label="Fee"
            onChange={onChange}
            disabled={!isUserAdmin && !isUserYO}
          />
          <MoneyInput
            model={model}
            modelErrors={memberErrors}
            attribute="delegation_fee"
            label="Delegation fee"
            onChange={onChange}
            disabled={!isUserAdmin && !isUserDelegationHead && !isUserYO}
          />
          <DateSelector
            className={classes.dateSelector}
            label="Member since"
            value={memberSince}
            disabled={!isUserAdmin && !isUserDelegationHead && !isUserYO}
            onChange={date =>
              onChange({
                target: {
                  name: 'member_since',
                  value: date || '',
                  field: 'memberSince',
                },
              })
            }
            onClear={() =>
              onChange({
                target: {
                  name: 'member_since',
                  value: '',
                  field: 'memberSince',
                },
              })
            }
            error={memberErrors.member_since}
            helperText={memberErrors.member_since}
          />
          <DateSelector
            className={classes.dateSelector}
            label="Left CIC"
            value={leftCIC}
            disabled={!isUserAdmin && !isUserDelegationHead && !isUserYO}
            onChange={date =>
              onChange({
                target: {
                  name: 'left_cic',
                  value: date || '',
                  field: 'leftCic',
                },
              })
            }
            onClear={() =>
              onChange({
                target: {
                  name: 'left_cic',
                  value: '',
                  field: 'leftCic',
                },
              })
            }
            error={memberErrors.left_cic}
            helperText={memberErrors.left_cic}
          />
          <SelectInput
            attribute="reason_for_leaving"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            disabled={!isUserAdmin && !isUserDelegationHead && !isUserYO}
            label="Reason for leaving"
            nullable
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('exit_reason') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Name</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <SelectInput
            attribute="first_title"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            label="First title"
            disabled={isUserArtemis}
            nullable
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('member_title') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <SelectInput
            attribute="second_title"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            label="Second title"
            disabled={isUserArtemis}
            nullable
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('member_title') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <TextInput
            model={model}
            modelErrors={memberErrors}
            attribute="first_name"
            label="First name"
            onChange={onChange}
            disabled={!isUserAdmin && !isUserDelegationHead && !isUserYO}
          />
          <SelectInput
            attribute="particle"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            label="Particle"
            disabled={!isUserAdmin && !isUserDelegationHead && !isUserYO}
            nullable
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('member_particle') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <TextInput
            model={model}
            modelErrors={memberErrors}
            attribute="last_name"
            label="Last name"
            onChange={onChange}
            disabled={!isUserAdmin && !isUserDelegationHead && !isUserYO}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Personal details</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <InputLabel>Select profile picture</InputLabel>
          <div style={{ margin: '15px 0' }}>
            <div className="e">
              <ProfileImageUpload
                image={profileImage}
                error={memberErrors.profile_image}
                visibility="public"
                onUpload={image => uploadProfileImage(image)}
              />
            </div>
          </div>
          <FormControl
            aria-describedby="value-error-text"
            fullWidth
            error={Boolean(memberErrors.short_bio.length)}
          >
            <Typography variant="body1" style={{ color: 'rgba(0,0,0,0.54)' }}>
              Short biography
            </Typography>
            <TinyMCE
              Id="short_bio"
              name="short_bio"
              initialValue={model.attributes.short_bio}
              onChange={handleEditorChange}
              height={100}
            />
            <FormHelperText id="value-error-text">
              {memberErrors.short_bio}
            </FormHelperText>
          </FormControl>
          <SelectInput
            attribute="gender"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            label="Gender"
            disabled={isUserArtemis}
            nullable
          >
            {Object.keys(genders.attributes).map(key => (
              <MenuItem value={key} key={key}>
                {genders.attributes[key]}
              </MenuItem>
            ))}
          </SelectInput>
          <SelectInput
            attribute="preferred_language"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            disabled={isUserArtemis}
            label="Preferred language"
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('preferred_language') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <DateSelector
            label="Date of birth"
            value={birthDate}
            disabled={isUserArtemis}
            onChange={date =>
              onChange({
                target: {
                  name: 'date_of_birth',
                  value: date || '',
                  field: 'birthDate',
                },
              })
            }
            onClear={() =>
              onChange({
                target: {
                  name: 'date_of_birth',
                  value: '',
                  field: 'birthDate',
                },
              })
            }
            error={memberErrors.date_of_birth}
            helperText={memberErrors.date_of_birth}
          />
          <SelectInput
            attribute="expertise"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            label="Expertise"
            disabled={isUserArtemis}
            nullable
          >
            {Object.keys(enumValues.attributes.enumvalue).map(
              key =>
                enumValues.attributes.enumvalue[key].enum_type_id ===
                  getEnumTypeIndex('expertise') && (
                  <MenuItem
                    value={enumValues.attributes.enumvalue[key].column_id}
                    key={key}
                  >
                    {enumValues.attributes.enumvalue[key].value}
                  </MenuItem>
                ),
            )}
          </SelectInput>
          <TextInput
            rows={4}
            model={model}
            modelErrors={memberErrors}
            attribute="comments"
            label="Comments"
            onChange={onChange}
            disabled={!isUserAdmin && !isUserDelegationHead && !isUserYO}
          />
          <TextInput
            model={model}
            modelErrors={memberErrors}
            attribute="last_modified_by"
            label="Last modified by"
            disabled
          />
          <FormControl
            aria-describedby="value-error-text"
            fullWidth
            error={Boolean(memberErrors.updated_at.length)}
          >
            <TextField
              label="Last modified"
              id="updated_at"
              name="updated_at"
              value={moment(model.attributes.updated_at.date).format(
                DATE_TIME_FORMAT,
              )}
              disabled
              type="datetime"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormHelperText id="value-error-text">
              {memberErrors.updated_at}
            </FormHelperText>
          </FormControl>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Postal address</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <SelectInput
            attribute="postal_country"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            label="Postal country"
            disabled={isUserArtemis}
            nullable
          >
            {countries.attributes.country.map(item => (
              <MenuItem value={item.column_id} key={item.column_id}>
                {item.name}
              </MenuItem>
            ))}
          </SelectInput>
          <TextInput
            model={model}
            modelErrors={memberErrors}
            attribute="postal_city"
            label="Postal city"
            onChange={onChange}
            disabled={isUserArtemis}
          />
          <TextInput
            model={model}
            modelErrors={memberErrors}
            attribute="postal_zip"
            label="Postal zip"
            onChange={onChange}
            disabled={isUserArtemis}
          />
          <TextInput
            model={model}
            modelErrors={memberErrors}
            attribute="postal_address"
            label="Postal address"
            onChange={onChange}
            disabled={isUserArtemis}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Secondary address</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <SelectInput
            attribute="secondary_country"
            model={model}
            modelErrors={memberErrors}
            onChange={onChange}
            label="Secondary country"
            disabled={isUserArtemis}
            nullable
          >
            {countries.attributes.country.map(item => (
              <MenuItem value={item.column_id} key={item.column_id}>
                {item.name}
              </MenuItem>
            ))}
          </SelectInput>
          <TextInput
            model={model}
            modelErrors={memberErrors}
            attribute="secondary_city"
            label="Secondary city"
            disabled={isUserArtemis}
            onChange={onChange}
          />
          <TextInput
            model={model}
            modelErrors={memberErrors}
            attribute="secondary_zip"
            label="Secondary zip"
            disabled={isUserArtemis}
            onChange={onChange}
          />
          <TextInput
            model={model}
            modelErrors={memberErrors}
            attribute="secondary_address"
            label="Secondary address"
            disabled={isUserArtemis}
            onChange={onChange}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Contact information</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          {[
            ['contact_person', 'Contact person'],
            ['private_tel', 'Phone - Home 1'],
            ['private_tel2', 'Phone - Home 2'],
            ['private_mobile', 'Phone - Mobile'],
            ['office_tel', 'Phone - Office 1'],
            ['office_tel2', 'Phone - Office 2'],
            ['other_tel', 'Phone - Other'],
            ['private_fax', 'Fax - Home'],
            ['office_fax', 'Fax - Office'],
            ['other_fax', 'Fax - Other'],
            ['private_email', 'Email 1', true],
            ['office_email', 'Email 2'],
            ['other_email', 'Email 3'],
            ['private_skype', 'Skype'],
            ['website', 'Website'],
          ].map(item => (
            <TextInput
              key={item[0]}
              model={model}
              modelErrors={memberErrors}
              attribute={item[0]}
              label={item[1]}
              onChange={onChange}
              required={item[2] || false}
            />
          ))}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    {!isUserArtemis && (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Change Password</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            {/* eslint-disable indent */}
            {!isUserAdmin &&
              !isUserYO && (
                <TextInput
                  model={model}
                  modelErrors={memberErrors}
                  attribute="current_password"
                  label="Current Password"
                  onChange={onChange}
                  type="password"
                  toggleVisibility
                />
              )}
            {/* eslint-enable */}
            <TextInput
              model={model}
              modelErrors={memberErrors}
              attribute="password"
              label="New Password"
              onChange={onChange}
              type="password"
              toggleVisibility
              autoComplete="new-password"
            />
            <TextInput
              model={model}
              modelErrors={memberErrors}
              attribute="password_confirmation"
              label="Retype New Password"
              onChange={onChange}
              type="password"
              toggleVisibility
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )}
    {isUserAdmin && (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Payments</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(model.attributes.can_pay)}
                  onChange={(event, checked) =>
                    onChange({
                      target: { name: 'can_pay', value: checked ? 1 : 0 },
                    })
                  }
                  name="can_pay"
                  color="primary"
                />
              }
              label="Can pay invoices"
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )}
    {(isUserAdmin || isUserDelegationHead) && (
      <MemberAttachments classes={classes} />
    )}
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Notifications</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(subscriptions && subscriptions.news)}
                onChange={(event, checked) =>
                  setSubscription({
                    target: {
                      name: 'subscriptions.news',
                      value: checked ? 1 : 0,
                    },
                  })
                }
                name="news"
                color="primary"
              />
            }
            label="Notification about News"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(subscriptions && subscriptions.auctions)}
                onChange={(event, checked) =>
                  setSubscription({
                    target: {
                      name: 'subscriptions.auctions',
                      value: checked ? 1 : 0,
                    },
                  })
                }
                name="auctions"
                color="primary"
              />
            }
            label="Notification about Auctions"
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <FormGroup row>
      <Button color="secondary" className={classes.button} onClick={cancel}>
        Cancel
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        className={classes.button}
      >
        Save
      </Button>
      {isUserAdmin &&
        (model.attributes.status === 'NEW' && (
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={openDialog}
          >
            Activate
          </Button>
        ))}
    </FormGroup>
  </form>
);

MemberFormForExistingMember.propTypes = {
  model: PropTypes.object.isRequired,
  memberErrors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  countries: PropTypes.object.isRequired,
  delegations: PropTypes.object.isRequired,
  enumValues: PropTypes.object.isRequired,
  genders: PropTypes.object.isRequired,
  getEnumTypeIndex: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  setSubscription: PropTypes.func.isRequired,
  uploadProfileImage: PropTypes.func.isRequired,
  handleEditorChange: PropTypes.func.isRequired,
  subscriptions: PropTypes.object,
  birthDate: PropTypes.object,
  leftCIC: PropTypes.object,
  memberSince: PropTypes.object,
  profileImage: PropTypes.object,
  isUserAdmin: PropTypes.bool.isRequired,
  isUserYO: PropTypes.bool.isRequired,
  isUserArtemis: PropTypes.bool.isRequired,
  isUserDelegationHead: PropTypes.bool.isRequired,
  copyName: PropTypes.func.isRequired,
  copyNameAndAddress: PropTypes.func.isRequired,
};

export default withStyles(styles)(MemberFormForExistingMember);
