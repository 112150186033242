import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

const styles = theme => ({
  input: {
    [theme.breakpoints.down('sm')]: {
      marginTop: `${theme.spacing.unit * 5}px !important`,
    },
  },
});

class TextInput extends React.PureComponent {
  state = {
    visible: false,
  };

  visibilityAdornment = () => (
    <InputAdornment position="end">
      <IconButton
        onClick={this.toggleVisibility}
        aria-label="Toggle password visibility"
      >
        {this.state.visible ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  toggleVisibility = () =>
    this.setState({
      visible: !this.state.visible,
    });

  render() {
    const {
      value,
      model,
      modelErrors,
      attribute,
      label,
      onChange,
      onBlur,
      disabled,
      autoComplete,
      type,
      required,
      toggleVisibility,
      classes,
      rows,
    } = this.props;

    const inputValue = String(
      _.get(model, `attributes.${attribute}`, value) || '',
    );

    return (
      <FormControl
        aria-describedby="value-error-text"
        fullWidth
        error={!!modelErrors[attribute].length}
      >
        <InputLabel
          htmlFor={attribute}
          required={required}
          shrink={!!(inputValue || isMobile)}
        >
          {label}
        </InputLabel>
        <Input
          multiline={!!rows}
          rows={rows}
          className={classes.input}
          id={attribute}
          name={attribute}
          required={required}
          disabled={disabled}
          autoComplete={autoComplete}
          value={inputValue}
          onChange={onChange}
          onBlur={onBlur}
          type={this.state.visible ? 'text' : type}
          endAdornment={toggleVisibility ? this.visibilityAdornment() : null}
        />
        <FormHelperText id="value-error-text">
          {modelErrors[attribute]}
        </FormHelperText>
      </FormControl>
    );
  }
}

TextInput.defaultProps = {
  disabled: false,
  type: 'text',
  value: '',
};

TextInput.propTypes = {
  rows: PropTypes.number,
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  model: PropTypes.object.isRequired,
  modelErrors: PropTypes.object.isRequired,
  attribute: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  toggleVisibility: PropTypes.bool,
  onBlur: PropTypes.func,
};

export default withStyles(styles)(TextInput);
