import { isEqual } from 'lodash';

export function userHasRole(userRoles, requiredRoles) {
  return userRoles.some(userRole => requiredRoles.includes(userRole));
}

export function userHasAccess(userRoles, allowedRoles, exact = false) {
  let accessGranted = false;
  const matchedRoles = allowedRoles.filter(
    role => userRoles.indexOf(role) > -1,
  );
  if (matchedRoles.length) {
    accessGranted = true;
  }

  if (!allowedRoles.length) {
    accessGranted = true;
  }

  if (exact) {
    accessGranted = isEqual(allowedRoles, userRoles);
  }
  return accessGranted;
}
