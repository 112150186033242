import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import {
  setModel,
  actionPreProcessor,
  showModelSuccess,
} from 'containers/ModelWrapper/actions';
import { makeSelectModel } from 'containers/ModelWrapper/selectors';
import { setMessage } from 'containers/Snackbar/actions';
import { MESSAGE_TYPES } from 'containers/Snackbar/constants';
import {
  makeSelectFile,
  makeSelectImageId,
  makeSelectVisibility,
} from './selectors';
import { fileUploadSuccess, fileUploadError } from './actions';
import { UPLOAD_FILE, DELETE_FILE, LOAD_IMAGE } from './constants';

export function* uploadFile() {
  try {
    const fileToUpload = yield select(makeSelectFile());
    const visibility = yield select(makeSelectVisibility());
    if (!fileToUpload) {
      return;
    }
    const formData = new FormData();
    yield put(actionPreProcessor(setModel({}), 'fileUploader'));
    formData.append('file', fileToUpload);
    formData.append('visibility', visibility);
    const response = yield call(() =>
      axios.post('files', formData).then(res => res),
    );
    yield put(
      actionPreProcessor(showModelSuccess(response.data), 'fileUploader'),
    );
    yield put(fileUploadSuccess());
    yield put(
      setMessage({
        type: MESSAGE_TYPES.SUCCESS,
        value: 'File upload complete.',
      }),
    );
  } catch (error) {
    yield put(fileUploadError());
    yield put(
      setMessage({
        type: MESSAGE_TYPES.ERROR,
        value: error.message,
      }),
    );
  }
}

export function* deleteFile() {
  try {
    const model = yield select(makeSelectModel('fileUploader'));
    if (isEmpty(model)) {
      return;
    }
    yield call(() => axios.delete(`files/${model.data.id}`));
    yield put(actionPreProcessor(setModel({}), 'fileUploader'));
  } catch (error) {
    //
  }
}

export function* loadImage() {
  try {
    const imageId = yield select(makeSelectImageId());
    if (!imageId) {
      return;
    }
    const response = yield call(() =>
      axios.get(`files/${imageId}`).then(res => res),
    );
    yield put(
      actionPreProcessor(showModelSuccess(response.data), 'fileUploader'),
    );
  } catch (error) {
    //
  }
}

// Individual exports for testing
export default function* file() {
  yield takeLatest(UPLOAD_FILE, uploadFile);
  yield takeLatest(DELETE_FILE, deleteFile);
  yield takeLatest(LOAD_IMAGE, loadImage);
}
