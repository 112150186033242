import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { TableCell, TableRow, withStyles } from '@material-ui/core';

import TextFilter from 'components/TextFilter';
import ActiveFilter from 'components/ActiveFilter';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import {
  DATE_FORMAT,
  DATE_FORMAT_MASK,
  BACKEND_DATE,
} from '../../utils/constants';

const styles = () => ({
  datePicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  datePickerWrapper: {
    width: '145px',
  },
  tr: {
    maxWidth: '250px',
  },
});

const EventsFilter = props => {
  const { params } = props;
  return (
    <TableRow>
      <TableCell className={props.classes.tr}>
        <TextFilter
          Id="search-for-title"
          label="Search for title"
          name="title"
          setFilter={props.setFilter}
        />
      </TableCell>
      <TableCell>
        <ActiveFilter
          setFilter={props.setFilter}
          value={params.filter.active}
        />
      </TableCell>
      <TableCell className={props.classes.tr}>
        <TextFilter
          Id="search-for-location"
          label="Search for location"
          name="location"
          setFilter={props.setFilter}
        />
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={props.classes.datePicker}>
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="From"
              format={DATE_FORMAT}
              clearable
              adornmentPosition="end"
              value={
                (params.filter.start_date_from &&
                  moment(params.filter.start_date_from)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'start_date_from',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="To"
              format={DATE_FORMAT}
              adornmentPosition="end"
              clearable
              value={
                (params.filter.start_date_to &&
                  moment(params.filter.start_date_to)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'start_date_to',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={props.classes.datePicker}>
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="From"
              format={DATE_FORMAT}
              clearable
              adornmentPosition="end"
              value={
                (params.filter.end_date_from &&
                  moment(params.filter.end_date_from)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'end_date_from',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="To"
              format={DATE_FORMAT}
              adornmentPosition="end"
              clearable
              value={
                (params.filter.end_date_to &&
                  moment(params.filter.end_date_to)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'end_date_to',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </TableCell>

      <TableCell />
    </TableRow>
  );
};

EventsFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(EventsFilter));
