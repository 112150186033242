import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { Link as LinkTo } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import {
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Table,
  Icon,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Money from 'components/Money';
import SortableCells from 'components/SortableCells';
import TableToolbar from 'components/TableToolbar';
import TableFooter from 'components/TableFooter';
import ConfirmDialog from 'components/ConfirmDialog';
import {
  makeUserSelector,
  makeRolesSelector,
} from 'containers/AuthButton/selectors';
import withCrud from 'containers/ModelWrapper';
import { userHasRole } from 'utils/userHasRole';
import { setMessage } from 'containers/Snackbar/actions';
import { MESSAGE_TYPES } from 'containers/Snackbar/constants';
import {
  DATE_FORMAT,
  ROLES_ADMINS_AND_HEAD_OF_DELEGATION,
  ROWS_PER_PAGE_OPTIONS,
} from 'utils/constants';
import {
  PageRootContainerOpenedStyles,
  PageRootContainerStyles,
} from '../../mui-theme';
import { makeSelectSidebarIsOpen } from '../Layout/selectors';
import InvoicesFilter from './InvoicesFilter';
import Authorization from '../../utils/authorization';
import {
  invoiceSkeleton,
  notificationSkeleton,
  paymentSkeleton,
  transactionSkeleton,
} from './constants';
import { makeSelectModelType } from '../ModelWrapper/selectors';
import ContainerPaper from '../../components/ContainerPaper';
import MemberPaymentTable from './MemberPaymentTable';
import download from '../../utils/download';
import InvoiceForm from './InvoiceForm';
import PaymentForm from './PaymentForm';
import TransactionForm from './TransactionForm';
import NotificationForm from './NotificationForm';
import InvoiceExpanionWrapper from './InvoiceExpanionWrapper';

const styles = () => ({
  rootClosed: PageRootContainerStyles,
  rootOpened: PageRootContainerOpenedStyles,
  table: {
    width: '100%',
  },
  tableCell: {
    padding: '0px 0px !important',
  },
  expansionPanelSummary: {
    padding: '0px 0px',
    display: '-webkit-box',
  },
  total: {
    float: 'right',
  },
  download: {
    margin: '0px 24px',
  },
});

const AdminAction = Authorization([
  'SYSTEM_ADMINISTRATOR',
  'MEMBERSHIP_ADMINISTRATOR',
  'YOUNG_OPINION',
]);

const DownloadButton = ({ onClick, icon }) => (
  <IconButton onClick={onClick}>
    <Icon fontSize="small">{icon}</Icon>
  </IconButton>
);
DownloadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};

const PaymentButton = ({ onClick, icon, show }) => (
  <IconButton
    onClick={onClick}
    style={{ display: show ? 'inline-block' : 'none' }}
  >
    <Icon fontSize="small">{icon}</Icon>
  </IconButton>
);
PaymentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

const CreateButton = ({ onClick }) => (
  <Button color="primary" onClick={onClick}>
    <Icon>add</Icon>Add new
  </Button>
);
CreateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const EditButton = ({ onClick }) => (
  <IconButton aria-label="Edit" onClick={onClick}>
    <Icon fontSize="small">edit_icon</Icon>
  </IconButton>
);
EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const DeleteButton = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <Icon fontSize="small">delete</Icon>
  </IconButton>
);
DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const CreatePaymentButton = ({ onClick }) => (
  <IconButton aria-label="Add" onClick={onClick}>
    <Icon fontSize="small">add</Icon>
  </IconButton>
);
CreatePaymentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const CreateAction = AdminAction(CreateButton);
const EditAction = AdminAction(EditButton);
const DeleteAction = AdminAction(DeleteButton);

const DownloadAction = AdminAction(DownloadButton);
const DownloadPDFAction = AdminAction(DownloadButton);
const DownloadMemberPDFAction = DownloadButton;
const PayAction = PaymentButton;
const CreatePaymentAction = AdminAction(CreatePaymentButton);

const getInvoiceAllPaidAmount = (payments, paidAmounts) => {
  let paidAmount = 0;
  if (paidAmounts.length !== 0) {
    paidAmounts.forEach(amount => {
      payments.forEach(payment => {
        if (payment.id === amount.id) {
          paidAmount += payment.attributes.paid_amount || 0;
        }
      });
    });
  }
  return paidAmount || 0;
};

const getBalance = (included, row) =>
  getInvoiceAllPaidAmount(
    included.filter(p => p.type === 'payments'),
    row.relationships.payments.data,
  ) - row.attributes.amount;

class InvoicesTable extends React.Component {
  constructor() {
    super();

    this.state = {
      filterOpen: false,
      invoiceModel: null,
      paymentModel: null,
      notificationModel: null,
      deleteDialogOpen: false,
      dialogTitle: '',
      selectedInvoices: [],
      transactionModel: null,
    };
    this.setInvoiceModel = this.setInvoiceModel.bind(this);
    this.createSortHandler = this.createSortHandler.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.downloadAllInvoice = this.downloadAllInvoice.bind(this);
  }

  componentWillMount() {
    const {
      setFilter,
      resetFilter,
      setSorting,
      user,
      member,
      isNotificationPage,
      setRowsPerPage,
    } = this.props;
    setSorting({}, 'number', 'desc');
    resetFilter();

    if (isNotificationPage) {
      setFilter({
        target: {
          name: 'balance',
          value: true,
        },
      });
      setRowsPerPage({
        target: {
          name: 'size',
          value: 200,
        },
      });
    }

    setFilter({
      target: {
        name: 'delegation',
        value: user.delegation_id,
      },
    });

    if (!member) {
      setFilter({
        target: {
          name: 'status',
          value: 'ACTIVE',
        },
      });
    }

    if (member) {
      this.loadInvoices(this.props);
    } else {
      this.props.loadModels();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.params, nextProps.params) && nextProps.modelType) {
      this.props.loadModels();
    }
  }

  loadInvoices(props) {
    const { setFilter, setSorting, member } = props;
    if (member) {
      setFilter({
        target: {
          name: 'member_id',
          value: member.id,
        },
      });
    }

    setSorting({}, 'number', 'desc');
  }

  refreshList() {
    this.loadInvoices(this.props);
    this.props.loadModels();
  }

  toggleFilter() {
    const { setFilter, user, isNotificationPage } = this.props;

    if (this.state.filterOpen) {
      this.props.resetFilter();
    }
    setFilter({
      target: {
        name: 'delegation',
        value: user.delegation_id,
      },
    });
    if (isNotificationPage) {
      setFilter({
        target: {
          name: 'balance',
          value: true,
        },
      });
    }
    this.setState(state => ({ filterOpen: !state.filterOpen }));
  }

  setInvoiceModel(e, selectedModel) {
    const model = selectedModel || cloneDeep(invoiceSkeleton);
    model.attributes.member_id = this.props.member.id;

    this.setState({
      invoiceModel: model,
    });
  }

  setPaymentModel(selectedInvoice, selectedModel) {
    const model = selectedModel || cloneDeep(paymentSkeleton);
    model.attributes.invoice_id = selectedInvoice.id;
    this.setState({
      paymentModel: model,
    });
  }

  setNotificationModel() {
    const model = cloneDeep(notificationSkeleton);
    model.attributes.invoices = this.state.selectedInvoices;
    this.setState({
      notificationModel: model,
    });
  }

  setTransactionModel(invoice, total) {
    const model = cloneDeep(transactionSkeleton);
    model.attributes.invoice_id = invoice.id;
    const amount = total * -1;
    model.attributes.balance = amount;
    model.attributes.amount = amount;
    this.setState({
      transactionModel: model,
    });
  }

  createSortHandler(property) {
    return event => {
      const { sortBy, sortDirection } = this.props;
      let direction = 'asc';
      if (property === sortBy) {
        direction = sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.props.setSorting(event, property, direction);
    };
  }

  openDialog(invoice) {
    this.setState(() => ({
      deleteDialogOpen: true,
      destroyMember: invoice,
      dialogTitle: `Are you sure you want to remove "${
        invoice.attributes.number
      }" invoice?`,
    }));
  }

  closeForm(key) {
    const state = {};
    state[key] = null;
    this.setState(state);
  }

  downloadAllInvoice(format) {
    const {
      params: { filter },
    } = this.props;
    if (!filter.delegation) {
      this.props.showErrorToast(
        'Delegation filter must be set for invoice export!',
      );
      return;
    }

    Object.keys(filter).forEach(param => {
      filter[param] = filter[param].toString();
    });
    axios({
      url: `export/invoice/${format}s`,
      method: 'POST',
      data: {
        filter,
      },
      responseType: 'blob',
    })
      .then(response => download(response, `Invoices.zip`))
      .catch(error => {
        this.props.showErrorToast(error.message);
      });
  }

  downloadExport(invoice, format = 'doc') {
    const formats = { doc: 'docx', pdf: 'pdf' };
    axios({
      url: `export/invoice/${format}`,
      method: 'POST',
      data: { id: invoice.id },
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
      },
    })
      .then(response => {
        download(
          response,
          `CICInvoice_${invoice.attributes.number.replace('/', '-')}.${
            formats[format]
          }`,
        );
      })
      .catch(error => {
        this.props.showErrorToast(error.message);
      });
  }

  handlePay = (invoice, amount) => {
    this.setTransactionModel(invoice, amount);
  };

  handleChangePageNumber = (event, page) => {
    this.setState({
      selectedInvoices: [],
    });
    this.props.setPageNumber(event, page);
  };

  checkInvoice = (id, checked) => {
    const { selectedInvoices } = this.state;
    if (checked) {
      selectedInvoices.push(id);
    } else {
      const index = selectedInvoices.indexOf(id);
      if (index !== -1) {
        selectedInvoices.splice(index, 1);
      }
    }
    this.setState({
      selectedInvoices,
    });
  };

  setSendSuccessState = sentInvoices => {
    this.props.showSuccessToast(`${sentInvoices.length} email has been sent`);
    sentInvoices.forEach(id => this.checkInvoice(id, false));
  };

  render() {
    const {
      member,
      models,
      classes,
      sortBy,
      sortDirection,
      setRowsPerPage,
      setFilter,
      params,
      sidebarIsOpen,
      isNotificationPage,
    } = this.props;
    const { data, meta, included } = models;

    if (isEmpty(meta)) {
      return null;
    }
    if (member && member.attributes.status !== 'ACTIVE') {
      return null;
    }

    const cells = {
      'Invoice Number': 'number',
      'Member Number': 'member_number',
      Delegation: 'delegation',
      'Year of Fee': 'year',
      'Invoice Date': 'invoice_date',
      'Due Date': 'due_date',
      Amount: 'amount',
      Balance: null,
    };

    const isUserHoD = userHasRole(this.props.roles, ['HEAD_OF_DELEGATION']);
    const displayDownloadButton = userHasRole(this.props.roles, [
      ...ROLES_ADMINS_AND_HEAD_OF_DELEGATION,
      'YOUNG_OPINION',
    ]);

    if (member) {
      delete cells.Delegation;
      delete cells['Member Number'];
    } else if (isNotificationPage) {
      delete cells.Balance;
    } else {
      delete cells['Member Number'];
    }

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;
    return (
      <ContainerPaper
        className={rootClass}
        style={{ marginLeft: '0px', marginRight: '0px' }}
      >
        {!member && (
          <React.Fragment>
            {!isNotificationPage && (
              <React.Fragment>
                <Helmet>
                  <title>View and Download Invoices</title>
                </Helmet>
                <TableToolbar
                  title="View and Download Invoices"
                  onClick={this.toggleFilter}
                />
                {displayDownloadButton && (
                  <div className={classes.download}>
                    <Button
                      disabled={!data.length}
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.downloadAllInvoice('doc')}
                      style={{ marginBottom: '20px' }}
                    >
                      Export Invoices
                    </Button>
                    <Button
                      disabled={!data.length}
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onClick={() => this.downloadAllInvoice('pdf')}
                      style={{ marginLeft: '20px', marginBottom: '20px' }}
                    >
                      Export Invoices - pdf
                    </Button>
                  </div>
                )}
              </React.Fragment>
            )}
            {isNotificationPage && (
              <React.Fragment>
                <Helmet>
                  <title>Invoice notification</title>
                </Helmet>
                <TableToolbar
                  title="Invoice notification"
                  onClick={this.toggleFilter}
                />
                <div className={classes.download}>
                  <Button
                    disabled={this.state.selectedInvoices.length === 0}
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={() => this.setNotificationModel()}
                    style={{ marginBottom: '20px' }}
                  >
                    Send notifications
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {member && (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Invoices
            </Typography>
            <CreateAction onClick={this.setInvoiceModel} />
            <Typography variant="h6" gutterBottom className={classes.total}>
              Total Balance:
              <Money
                amount={
                  (included &&
                    (included.filter(
                      row => row.type === 'memberTotalBalance',
                    )[0].attributes.total ||
                      0)) ||
                  0
                }
              />
            </Typography>
          </React.Fragment>
        )}
        <Table className="paymentsTable">
          <TableBody>
            <TableRow>
              {isNotificationPage && <TableCell />}
              <SortableCells
                sortDirection={sortDirection}
                sortBy={sortBy}
                onClick={this.createSortHandler}
                cells={cells}
              />
              {!isNotificationPage && <TableCell />}
            </TableRow>
            {this.state.filterOpen && (
              <InvoicesFilter
                params={params}
                setFilter={setFilter}
                delegationFilterDisabled={isUserHoD}
                isNotificationPage={isNotificationPage}
              />
            )}
            <TableRow>
              <TableCell colSpan={12} className={classes.tableCell}>
                {data.map(row => (
                  <InvoiceExpanionWrapper
                    key={row.id}
                    expansionDetail={
                      row.relationships.payments.data.length !== 0 && (
                        <MemberPaymentTable
                          invoicePayments={row.relationships.payments.data}
                          includedPayments={included.filter(
                            p => p.type === 'payments',
                          )}
                          handleEditOnclick={selectedModel =>
                            this.setPaymentModel(row, selectedModel)
                          }
                          {...this.props}
                        />
                      )
                    }
                    isNotificationPage={isNotificationPage}
                    classes={classes}
                  >
                    <Table
                      className={`paymentsTable${
                        isNotificationPage ? ' bordered' : ''
                      }`}
                    >
                      <TableBody>
                        <TableRow>
                          {isNotificationPage && (
                            <TableCell>
                              <Checkbox
                                onChange={event =>
                                  this.checkInvoice(
                                    row.id,
                                    event.target.checked,
                                  )
                                }
                                checked={
                                  this.state.selectedInvoices.indexOf(
                                    row.id,
                                  ) !== -1
                                }
                              />
                            </TableCell>
                          )}
                          <TableCell>{row.attributes.number}</TableCell>
                          {isNotificationPage && (
                            <TableCell>
                              {included
                                .filter(include => include.type === 'members')
                                .map(
                                  include =>
                                    include.id ===
                                      String(
                                        row.relationships.member.data.id,
                                      ) && (
                                      <span key={include.id}>
                                        {include.attributes.member_number}
                                      </span>
                                    ),
                                )}
                            </TableCell>
                          )}
                          {!member && (
                            <TableCell>
                              {included
                                .filter(
                                  include => include.type === 'delegations',
                                )
                                .map(
                                  include =>
                                    include.id ===
                                      String(
                                        row.relationships.delegation.data.id,
                                      ) && (
                                      <span key={include.id}>
                                        {include.attributes.member_code}
                                      </span>
                                    ),
                                )}
                            </TableCell>
                          )}
                          <TableCell>{row.attributes.year}</TableCell>
                          <TableCell>
                            {moment(row.attributes.invoice_date.date).format(
                              DATE_FORMAT,
                            )}
                          </TableCell>
                          <TableCell>
                            {moment(row.attributes.due_date.date).format(
                              DATE_FORMAT,
                            )}
                          </TableCell>
                          <TableCell>
                            <Money amount={row.attributes.amount} />
                          </TableCell>
                          {!isNotificationPage && (
                            <TableCell>
                              <Money amount={getBalance(included, row)} />
                            </TableCell>
                          )}
                          {!isNotificationPage && (
                            <TableCell>
                              <React.Fragment>
                                {!member && (
                                  <LinkTo
                                    to={`/admin/members/edit/${
                                      row.relationships.member.data.id
                                    }`}
                                  >
                                    <IconButton aria-label="Edit">
                                      <Icon fontSize="small">edit_icon</Icon>
                                    </IconButton>
                                  </LinkTo>
                                )}
                                {member && (
                                  <React.Fragment>
                                    {this.props.roles[0] ===
                                      'AUTHENTICATED_USER' && (
                                      <React.Fragment>
                                        <DownloadMemberPDFAction
                                          icon="picture_as_pdf"
                                          onClick={() =>
                                            this.downloadExport(row, 'pdf')
                                          }
                                        />
                                        {getBalance(included, row) < 0 && (
                                          <PayAction
                                            icon="credit_card"
                                            show={
                                              member.attributes.can_pay === 1
                                            }
                                            onClick={() =>
                                              this.handlePay(
                                                row,
                                                getBalance(included, row),
                                              )
                                            }
                                          />
                                        )}
                                      </React.Fragment>
                                    )}
                                    <CreatePaymentAction
                                      onClick={() => this.setPaymentModel(row)}
                                    />
                                    <EditAction
                                      onClick={e =>
                                        this.setInvoiceModel(e, row)
                                      }
                                    />
                                    <DeleteAction
                                      onClick={() => this.openDialog(row)}
                                    />
                                  </React.Fragment>
                                )}
                                <DownloadAction
                                  icon="cloud_download"
                                  onClick={() =>
                                    this.downloadExport(row, 'doc')
                                  }
                                />
                                <DownloadPDFAction
                                  icon="picture_as_pdf"
                                  onClick={() =>
                                    this.downloadExport(row, 'pdf')
                                  }
                                />
                              </React.Fragment>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </InvoiceExpanionWrapper>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
          {data.length ? (
            <TableFooter
              colSpan={12}
              meta={meta}
              onChangePage={this.handleChangePageNumber}
              onChangeRowsPerPage={setRowsPerPage}
              rowsPerPageOptions={
                isNotificationPage
                  ? ROWS_PER_PAGE_OPTIONS.concat([200])
                  : ROWS_PER_PAGE_OPTIONS
              }
            />
          ) : null}
        </Table>
        {this.state.invoiceModel && (
          <InvoiceForm
            refreshList={this.refreshList}
            invoice={this.state.invoiceModel}
            member={this.props.member}
            closeForm={() => this.closeForm('invoiceModel')}
          />
        )}
        {this.state.paymentModel && (
          <PaymentForm
            refreshList={this.refreshList}
            payment={this.state.paymentModel}
            closeForm={() => this.closeForm('paymentModel')}
          />
        )}
        {this.state.transactionModel && (
          <TransactionForm
            refreshList={this.refreshList}
            transaction={this.state.transactionModel}
            closeForm={() => this.closeForm('transactionModel')}
          />
        )}

        {this.state.notificationModel && (
          <NotificationForm
            refreshList={this.refreshList}
            setSendSuccessState={this.setSendSuccessState}
            notification={this.state.notificationModel}
            closeForm={() => this.closeForm('notificationModel')}
          />
        )}

        <ConfirmDialog
          open={this.state.deleteDialogOpen}
          onClose={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          cancelAction={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          confirmAction={() => {
            this.props.deleteModel(this.state.destroyMember);
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          title={this.state.dialogTitle}
          description="This action can not be undone."
        />
      </ContainerPaper>
    );
  }
}

InvoicesTable.propTypes = {
  loadModels: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  models: PropTypes.object.isRequired,
  member: PropTypes.object,
  setPageNumber: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  resetFilter: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  deleteModel: PropTypes.func.isRequired,
  modelType: PropTypes.string.isRequired,
  showErrorToast: PropTypes.func.isRequired,
  showSuccessToast: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  isNotificationPage: PropTypes.bool,
};

const reducerKey = 'InvoicesTable';

const mapStateToProps = createStructuredSelector({
  sidebarIsOpen: makeSelectSidebarIsOpen(),
  modelType: makeSelectModelType(reducerKey),
  user: makeUserSelector(),
  roles: makeRolesSelector(),
});

const mapDispatchToProps = dispatch => ({
  showSuccessToast: message =>
    dispatch(
      setMessage({
        type: MESSAGE_TYPES.SUCCESS,
        value: message,
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withCrud(reducerKey, 'invoices', InvoicesTable, false)));
