/*
 *
 * Layout reducer
 *
 */

import { fromJS } from 'immutable';
import { isMobile } from 'react-device-detect';
import { cloneDeep } from 'lodash';
import { DEFAULT_ACTION, SET_SIDEBAR_OPEN } from './constants';

const defaultState = {
  sidebarIsOpen: !isMobile,
};

export const initialState = fromJS(cloneDeep(defaultState));

function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SIDEBAR_OPEN:
      return state.set('sidebarIsOpen', action.sidebarIsOpen);
    case DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export default layoutReducer;
