import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectLoginPageDomain = state => state.get('loginPage', initialState);

const makeSelectLoginPage = () =>
  createSelector(selectLoginPageDomain, substate => substate.toJS());

export const makeEmailSelector = () =>
  createSelector(selectLoginPageDomain, substate => substate.get('email'));

export const makePasswordSelector = () =>
  createSelector(selectLoginPageDomain, substate => substate.get('password'));

export const makeLoginErrorSelector = () =>
  createSelector(selectLoginPageDomain, substate => substate.get('loginError'));

export default makeSelectLoginPage;
