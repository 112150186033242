import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the userPage state domain
 */

const selectInvoiceFormDomain = state => state.get('invoiceForm', initialState);
const selectPaymentFormDomain = state => state.get('paymentForm', initialState);
const selectTransactionFormDomain = state =>
  state.get('transactionForm', initialState);
const selectNotificationFormDomain = state =>
  state.get('notificationForm', initialState);
const selectUpdateFeesFormDomain = state =>
  state.get('updateFeesForm', initialState);

const selectUpdateDueDatesFormDomain = state =>
  state.get('updateDueDatesForm', initialState);

const makeSelectInvoiceFormErrors = () =>
  createSelector(
    selectInvoiceFormDomain,
    substate => substate.toJS().invoiceErrors,
  );

const makeSelectPaymentFormErrors = () =>
  createSelector(
    selectPaymentFormDomain,
    substate => substate.toJS().paymentErrors,
  );

const makeSelectTransactionFormErrors = () =>
  createSelector(
    selectTransactionFormDomain,
    substate => substate.toJS().transactionErrors,
  );

const makeSelectNotificationFormErrors = () =>
  createSelector(
    selectNotificationFormDomain,
    substate => substate.toJS().notificationErrors,
  );

const makeSelectUpdateFeesFormErrors = () =>
  createSelector(
    selectUpdateFeesFormDomain,
    substate => substate.toJS().updateFeeErrors,
  );
const makeSelectUpdateDueDatesFormErrors = () =>
  createSelector(
    selectUpdateDueDatesFormDomain,
    substate => substate.toJS().updateDueDatesErrors,
  );

export {
  makeSelectInvoiceFormErrors,
  makeSelectPaymentFormErrors,
  makeSelectTransactionFormErrors,
  makeSelectUpdateFeesFormErrors,
  makeSelectUpdateDueDatesFormErrors,
  makeSelectNotificationFormErrors,
};
