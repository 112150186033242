import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty, throttle } from 'lodash';

import { makeSelectAreaType } from 'containers/App/selectors';
import {
  makeSelectFunctionalArea,
  makeSelectFunctionalAreaErrors,
} from './selectors';
import {
  setFunctionalArea,
  setFunctionalAreaErrors,
  submitFunctionalArea,
} from './actions';

const styles = theme => ({
  root: {
    width: '210px',
    backgroundColor: theme.palette.background.paper,
    height: '320px',
  },
  linkto: {
    color: '#000',
    width: '100%',
  },
  li: {
    '&:hover': {
      backgroundColor: '#ccc',
    },
  },
});

class FunctionalAreaForm extends React.Component {
  inputChanged(event) {
    const selectedFunctionalArea = Object.assign({}, this.props.functionalArea);
    selectedFunctionalArea.attributes[event.target.name] = event.target.value;
    if (event.target.name === 'area_type') {
      selectedFunctionalArea.attributes.phone_prefix = '';
      selectedFunctionalArea.attributes.member_code = '';
    }
    this.props.changeFunctionalArea(selectedFunctionalArea);
  }
  checkboxChanged(event, checked) {
    const selectedFunctionalArea = Object.assign({}, this.props.functionalArea);
    selectedFunctionalArea.attributes.active = checked ? 1 : 0;
    this.props.changeFunctionalArea(selectedFunctionalArea);
  }
  submit() {
    const { functionalArea, functionalAreaErrors } = this.props;
    if (!functionalArea.attributes.name.length) {
      functionalAreaErrors.name = 'Name can not be empty';
      this.props.setErrors(functionalAreaErrors);
      this.forceUpdate();
      return;
    }
    if (!functionalArea.attributes.area_type.length) {
      functionalAreaErrors.area_type = 'Area type can not be empty';
      this.props.setErrors(functionalAreaErrors);
      this.forceUpdate();
      return;
    }
    this.props.submitFunctionalArea();
  }
  render() {
    if (isEmpty(this.props.functionalArea)) {
      return null;
    }
    const { areaTypes, functionalArea, functionalAreaErrors } = this.props;
    const areaType = functionalArea.attributes.area_type;
    const showMemberCode =
      ['COUNTRY_WITH_DELEGATION', 'DELEGATION'].indexOf(areaType) > -1;
    const showPhonePrefix =
      ['COUNTRY', 'COUNTRY_WITH_DELEGATION'].indexOf(areaType) > -1;
    return (
      <Dialog
        open={!isEmpty(functionalArea)}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {functionalArea.id
            ? `Edit "${functionalArea.attributes.name}" Functional Area`
            : 'Add New Functional Area'}
        </DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <DialogContent>
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(functionalAreaErrors.name.length)}
            >
              <InputLabel htmlFor="name">Name</InputLabel>
              <Input
                id="name"
                name="name"
                autoFocus
                value={functionalArea.attributes.name}
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <FormHelperText id="value-error-text">
                {functionalAreaErrors.name}
              </FormHelperText>
            </FormControl>
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(functionalAreaErrors.area_type.length)}
            >
              <InputLabel htmlFor="area_type">Area type</InputLabel>
              <Select
                id="area_type"
                name="area_type"
                value={functionalArea.attributes.area_type}
                onChange={throttle(event => this.inputChanged(event), 200)}
              >
                {Object.keys(areaTypes.attributes).map(key => (
                  <MenuItem value={key} key={key}>
                    {areaTypes.attributes[key]}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="value-error-text">
                {functionalAreaErrors.area_type}
              </FormHelperText>
            </FormControl>
            {showMemberCode && (
              <FormControl
                aria-describedby="value-error-text"
                fullWidth
                error={Boolean(functionalAreaErrors.member_code.length)}
              >
                <InputLabel htmlFor="member_code">Member code</InputLabel>
                <Input
                  id="member_code"
                  name="member_code"
                  value={functionalArea.attributes.member_code || ''}
                  onChange={throttle(event => this.inputChanged(event), 200)}
                />
                <FormHelperText id="value-error-text">
                  {functionalAreaErrors.member_code}
                </FormHelperText>
              </FormControl>
            )}
            {showPhonePrefix && (
              <FormControl
                aria-describedby="value-error-text"
                fullWidth
                error={Boolean(functionalAreaErrors.phone_prefix.length)}
              >
                <InputLabel htmlFor="phone_prefix">Phone prefix</InputLabel>
                <Input
                  id="phone_prefix"
                  name="phone_prefix"
                  value={functionalArea.attributes.phone_prefix || ''}
                  onChange={throttle(event => this.inputChanged(event), 200)}
                />
                <FormHelperText id="value-error-text">
                  {functionalAreaErrors.phone_prefix}
                </FormHelperText>
              </FormControl>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(functionalArea.attributes.active)}
                  onChange={(event, checked) =>
                    this.checkboxChanged(event, checked)
                  }
                  name="active"
                  color="primary"
                />
              }
              label="Active"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose}>Cancel</Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

FunctionalAreaForm.propTypes = {
  functionalArea: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  changeFunctionalArea: PropTypes.func.isRequired,
  functionalAreaErrors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  submitFunctionalArea: PropTypes.func.isRequired,
  areaTypes: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  functionalArea: makeSelectFunctionalArea(),
  functionalAreaErrors: makeSelectFunctionalAreaErrors(),
  areaTypes: makeSelectAreaType(),
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(setFunctionalArea({})),
  changeFunctionalArea: selectedFunctionalArea =>
    dispatch(setFunctionalArea(selectedFunctionalArea)),
  setErrors: errors => dispatch(setFunctionalAreaErrors(errors)),
  submitFunctionalArea: () => dispatch(submitFunctionalArea()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(FunctionalAreaForm));
