import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty, cloneDeep } from 'lodash';
import { Link as LinkTo } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';

import SortableCells from 'components/SortableCells';
import ConfirmDialog from 'components/ConfirmDialog';
import TableToolbar from 'components/TableToolbar';
import TableFooter from 'components/TableFooter';
import { makeUserSelector } from 'containers/AuthButton/selectors';
import UserFilter from './UserFilter';
import { userSkeleton } from './constants';
import {
  TableContainerOpenedStyles,
  TableContainerStyles,
} from '../../mui-theme';
import { makeSelectSidebarIsOpen } from '../Layout/selectors';

const styles = () => ({
  rootClosed: TableContainerStyles,
  rootOpened: TableContainerOpenedStyles,
  table: {
    width: '100%',
  },
  cell: {
    fontSize: '16px',
  },
  flex: {
    display: 'flex',
  },
});

class UserTable extends React.Component {
  constructor(props) {
    super(props);
    this.createSortHandler = this.createSortHandler.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.state = {
      filterOpen: false,
      dialogOpen: false,
      dialogTitle: '',
      dialogDescription: '',
      selectedRow: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.url !== this.props.match.url &&
      nextProps.match.url === '/admin/users'
    ) {
      this.props.loadModels();
    }
  }

  toggleFilter() {
    if (this.state.filterOpen) {
      this.props.resetFilter();
    }
    this.setState(state => ({ filterOpen: !state.filterOpen }));
  }

  createSortHandler(property) {
    return event => {
      const { sortBy, sortDirection } = this.props;
      let direction = 'asc';
      if (property === sortBy) {
        direction = sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.props.setSorting(event, property, direction);
    };
  }

  openDialog(row) {
    const activate = row.attributes.active ? 'deactivate' : 'activate';
    const selectedRow = cloneDeep(row);
    selectedRow.attributes.active = row.attributes.active === 1 ? 0 : 1;
    this.setState(() => ({
      selectedRow,
      dialogOpen: true,
      dialogTitle: `Are you sure you want to ${activate} "${
        row.attributes.name
      }" user?`,
      dialogDescription: row.attributes.active
        ? 'Deactivated users can not access the website'
        : 'Active users can access the website',
    }));
  }

  render() {
    const {
      classes,
      setPageNumber,
      sortBy,
      sortDirection,
      setRowsPerPage,
      params,
      setFilter,
      models,
      makeValidUser,
      sidebarIsOpen,
    } = this.props;

    const { data, meta, included } = models;

    if (isEmpty(meta)) {
      return null;
    }

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;
    return (
      <Paper className={rootClass}>
        <ConfirmDialog
          open={this.state.dialogOpen}
          onClose={() => {
            this.setState(() => ({ dialogOpen: false }));
          }}
          cancelAction={() => {
            this.setState(() => ({ dialogOpen: false }));
          }}
          confirmAction={() => {
            this.props.setModel(makeValidUser(this.state.selectedRow));
            this.props.submitModel();
            this.props.setModel(null);
            this.setState(() => ({ dialogOpen: false }));
            this.props.loadModels();
          }}
          title={this.state.dialogTitle}
          description={this.state.dialogDescription}
        />
        <TableToolbar title="Users" onClick={this.toggleFilter} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <SortableCells
                sortDirection={sortDirection}
                sortBy={sortBy}
                onClick={this.createSortHandler}
                cells={{
                  'First Name': 'first_name',
                  'Last Name': 'last_name',
                  Username: 'name',
                  Role: null,
                  Delegation: 'delegation_iso_code',
                  Active: 'active',
                }}
              />
              <TableCell>
                <LinkTo to="/admin/users/edit/new">
                  <Button
                    color="primary"
                    onClick={() => this.props.setModel(cloneDeep(userSkeleton))}
                  >
                    <Icon>add</Icon>Add new
                  </Button>
                </LinkTo>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.filterOpen && (
              <UserFilter params={params} setFilter={setFilter} />
            )}
            {data.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.attributes.first_name}</TableCell>
                <TableCell>{row.attributes.last_name}</TableCell>
                <TableCell>{row.attributes.name}</TableCell>
                <TableCell>
                  {row.relationships.roles.data.map(userRole =>
                    included.map(
                      include =>
                        include.type === 'roles' &&
                        userRole.id === include.id &&
                        include.attributes.name !== 'AUTHENTICATED_USER' && (
                          <span key={include.id}>
                            {include.attributes.display_name}
                            <br />
                          </span>
                        ),
                    ),
                  )}
                </TableCell>
                <TableCell>
                  {included.map(
                    include =>
                      include.type === 'delegations' &&
                      include.id === String(row.attributes.delegation_id) && (
                        <span key={include.id}>
                          {include.attributes.iso_code}
                        </span>
                      ),
                  )}
                </TableCell>
                <TableCell>
                  <Checkbox checked={!!row.attributes.active} disableRipple />
                </TableCell>
                <TableCell>
                  {this.props.user.id !== row.id && (
                    <Tooltip
                      title={
                        row.attributes.active
                          ? 'Deactivate user'
                          : 'Activate user'
                      }
                    >
                      <IconButton
                        aria-label={
                          row.attributes.active
                            ? 'Deactivate user'
                            : 'Activate user'
                        }
                        onClick={() => {
                          this.openDialog(row);
                        }}
                      >
                        <Icon fontSize="small">
                          {row.attributes.active ? 'lock' : 'lock_open'}
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                  <LinkTo to={`/admin/users/edit/${row.id}`}>
                    <IconButton aria-label="Edit">
                      <Icon fontSize="small">edit_icon</Icon>
                    </IconButton>
                  </LinkTo>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {data.length ? (
            <TableFooter
              colSpan={12}
              meta={meta}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setRowsPerPage}
            />
          ) : null}
        </Table>
      </Paper>
    );
  }
}

UserTable.propTypes = {
  classes: PropTypes.object.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  resetFilter: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  submitModel: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  makeValidUser: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  loadModels: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeUserSelector(),
  sidebarIsOpen: makeSelectSidebarIsOpen(),
});

export default connect(mapStateToProps)(withStyles(styles)(UserTable));
