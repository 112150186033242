import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import _, { isEqual, cloneDeep } from 'lodash';
import moment from 'moment';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import Money from 'components/Money';
import TextInput from 'components/TextInput';
import SelectInput from 'components/SelectInput';
import ContainerPaper from 'components/ContainerPaper';
import { getEnumTypeArray } from 'utils/fractal';

class ParticipantItem extends React.Component {
  state = {
    open: true,
    total: 0,
    accessPrice: 0,
  };

  constructor(props) {
    super(props);
    this.setAccessPrice = this.setAccessPrice.bind(this);
  }

  handleOpenToogle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  setAccessPrice = accessPrice => {
    this.setState({ accessPrice });
    this.setTotal(accessPrice);
  };

  setTotal = accessPrice => {
    let total = 0;
    total += accessPrice || this.state.accessPrice;
    const participantsKey = this.props.value;
    const programs = cloneDeep(
      this.props.eventRegistration.attributes.participants[participantsKey]
        .attributes.program_ids,
    );
    programs.forEach(id => {
      const program = this.props.modelIncludes.filter(
        obj =>
          obj.type === 'programs' && parseInt(obj.id, 10) === parseInt(id, 10),
      );
      if (program[0]) {
        total += program[0].attributes.fee;
      }
    });

    this.setState({
      total,
    });
    this.props.addParticipanTotal(total, participantsKey);
  };

  findLabelByColumnId = value => {
    const { allEnumValues } = this.props;
    const enumvalue = allEnumValues.attributes.allenumvalues.find(
      item => item.column_id === value,
    );
    return enumvalue ? enumvalue.value : '';
  };

  componentDidUpdate(prevProps) {
    const participantsKey = this.props.value;
    if (
      !isEqual(
        this.props.eventRegistration.attributes.participants[participantsKey]
          .attributes.program_ids,
        prevProps.eventRegistration.attributes.participants[participantsKey]
          .attributes.program_ids,
      )
    ) {
      this.setTotal();
    }
  }

  getPreviewName = () => {
    const participantsKey = this.props.value;
    const {
      eventRegistration: {
        attributes: { participants },
      },
      enumValues,
    } = this.props;
    if (
      participants[participantsKey].attributes.name !== '' ||
      participants[participantsKey].attributes.member_id !== ''
    ) {
      let title = '';
      if (participants[participantsKey].attributes.member_id) {
        title = enumValues.attributes.enumvalue.filter(
          obj =>
            obj.column_id ===
            participants[participantsKey].attributes.member_id,
        );
      }
      return `${(title && title[0] && title[0].value) || ''} ${
        participants[participantsKey].attributes.name
      } - `;
    }
    return null;
  };

  getPreviewAccess = () => {
    const participantsKey = this.props.value;
    const {
      eventRegistration: {
        attributes: { participants },
      },
      eventAccesses,
    } = this.props;

    const eventAccessId =
      participants[participantsKey].attributes.event_access_id;
    const eventAccess = eventAccesses.find(
      access => access.id === eventAccessId,
    );
    let label = '';
    if (eventAccess) {
      label = this.findLabelByColumnId(eventAccess.attributes.name);
    }

    return label ? `${label} - ` : '';
  };

  render() {
    const {
      classes,
      enumValues,
      enumTypes,
      countries: countryAttributes,
      eventRegistration,
      eventRegistrationErrors,
      inputParticipantChanged,
      inputParticipantProgramChanged,
      programs,
      programCount,
      freeProgramCount,
      paidProgramCount,
      isReviewScreen,
      deleteParticipant,
      eventAccesses,
    } = this.props;

    const participantsKey = this.props.value;

    const titles = getEnumTypeArray(enumTypes, enumValues, 'member_title');

    const countries = countryAttributes.attributes.country.map(item => ({
      label: item.name,
      value: item.column_id,
    }));

    const checkedProgramCount =
      eventRegistration.attributes.participants[participantsKey].attributes
        .program_ids.length;

    const titleError = _.get(
      eventRegistrationErrors.participants[participantsKey],
      'title',
      false,
    );

    const countryError = _.get(
      eventRegistrationErrors.participants[participantsKey],
      'country',
      false,
    );

    return (
      <React.Fragment>
        <ContainerPaper className={classes.participant}>
          <Grid
            onClick={this.handleOpenToogle}
            container
            className={classes.GridMargin}
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Typography component="div">
                {this.getPreviewName()} {this.getPreviewAccess()}{' '}
                {checkedProgramCount} out of {programCount} programs
                {!isReviewScreen && (
                  <IconButton
                    aria-label="Delete"
                    onClick={event => {
                      deleteParticipant(event, participantsKey);
                    }}
                    disabled={
                      eventRegistration.attributes.participants.length < 2
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Typography>
            </Grid>
            <Grid item xs="auto" sm={6}>
              <Typography component="div" className={classes.price}>
                <Money amount={this.state.total} />
              </Typography>
            </Grid>
          </Grid>
        </ContainerPaper>
        {this.state.open && (
          <React.Fragment>
            <ContainerPaper className={classes.paper}>
              <Grid container className={classes.GridMargin} spacing={8}>
                <Grid item xs={12} sm={4}>
                  <div className={classes.formControl}>
                    <InputLabel error={titleError}>Title</InputLabel>
                    <Select
                      className={classes.robotoFont}
                      isClearable
                      options={titles}
                      onChange={e => {
                        const event = {
                          target: {
                            name: 'title',
                            value: e ? e.value : '',
                          },
                        };
                        inputParticipantChanged(event, participantsKey);
                      }}
                      isDisabled={isReviewScreen}
                    />
                    {titleError && (
                      <FormHelperText required error>
                        {titleError}
                      </FormHelperText>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    model={
                      eventRegistration.attributes.participants[participantsKey]
                    }
                    modelErrors={
                      eventRegistrationErrors.participants[participantsKey]
                    }
                    attribute="name"
                    label="Name"
                    fullWidth
                    onChange={event => {
                      inputParticipantChanged(event, participantsKey);
                    }}
                    disabled={isReviewScreen}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    attribute="event_access_id"
                    model={
                      eventRegistration.attributes.participants[participantsKey]
                    }
                    modelErrors={
                      eventRegistrationErrors.participants[participantsKey]
                    }
                    onChange={event => {
                      const eventAccess = eventAccesses.find(
                        access => access.id === event.target.value,
                      );
                      if (eventAccess) {
                        this.setAccessPrice(eventAccess.attributes.fee);
                        inputParticipantChanged(event, participantsKey);
                      }
                    }}
                    label="Access"
                    disabled={isReviewScreen}
                  >
                    {Object.keys(eventAccesses).map(key => (
                      <MenuItem value={eventAccesses[key].id} key={key}>
                        {this.findLabelByColumnId(
                          eventAccesses[key].attributes.name,
                        )}&nbsp;-&nbsp;
                        <Money
                          amount={eventAccesses[key].attributes.fee}
                          style={{ display: 'contents' }}
                        />
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    model={
                      eventRegistration.attributes.participants[participantsKey]
                    }
                    modelErrors={
                      eventRegistrationErrors.participants[participantsKey]
                    }
                    attribute="email"
                    label="Email"
                    fullWidth
                    onChange={event => {
                      inputParticipantChanged(event, participantsKey);
                    }}
                    disabled={isReviewScreen}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    model={
                      eventRegistration.attributes.participants[participantsKey]
                    }
                    modelErrors={
                      eventRegistrationErrors.participants[participantsKey]
                    }
                    attribute="phone"
                    label="Phone"
                    fullWidth
                    onChange={event => {
                      inputParticipantChanged(event, participantsKey);
                    }}
                    disabled={isReviewScreen}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className={classes.formControl}>
                    <InputLabel error={countryError}>Delegation</InputLabel>
                    <Select
                      className={classes.robotoFont}
                      isClearable
                      options={countries}
                      onChange={e => {
                        const event = {
                          target: {
                            name: 'country',
                            value: e ? e.value : '',
                          },
                        };
                        inputParticipantChanged(event, participantsKey);
                      }}
                      isDisabled={isReviewScreen}
                    />
                    {countryError && (
                      <FormHelperText required error>
                        {countryError}
                      </FormHelperText>
                    )}
                  </div>
                </Grid>
                {programCount !== 0 && (
                  <Grid item xs={12}>
                    {freeProgramCount !== 0 && (
                      <React.Fragment>
                        <Typography component="div" gutterBottom>
                          Select optional programs to attend - included in the
                          registration fee
                        </Typography>
                        {Object.keys(programs.free).map(
                          (date, index) =>
                            programs.free[date].length === 0 ? null : (
                              <div
                                className={classes.additional}
                                key={`pf${date}`}
                              >
                                <Typography component="div">
                                  {index + 1}. day -{' '}
                                  {moment(date).format('MMM D. dddd')}
                                  <br />
                                  {programs.free[date].map(p => (
                                    <FormControlLabel
                                      key={p.id}
                                      control={
                                        <Checkbox
                                          disabled={isReviewScreen}
                                          checked={Boolean(
                                            _.indexOf(
                                              eventRegistration.attributes
                                                .participants[participantsKey]
                                                .attributes.program_ids,
                                              p.id,
                                            ) !== -1,
                                          )}
                                          onChange={(event, checked) =>
                                            inputParticipantProgramChanged(
                                              {
                                                target: {
                                                  name: p.id,
                                                  value: checked,
                                                },
                                              },
                                              participantsKey,
                                            )
                                          }
                                          name={p.id}
                                          color="primary"
                                        />
                                      }
                                      label={p.title}
                                    />
                                  ))}
                                </Typography>
                              </div>
                            ),
                        )}
                      </React.Fragment>
                    )}
                    {paidProgramCount !== 0 && (
                      <React.Fragment>
                        <Typography component="div" gutterBottom>
                          Select additional programs to attend - not included in
                          the registration fee
                        </Typography>
                        {Object.keys(programs.paid).map(
                          (date, index) =>
                            programs.paid[date].length === 0 ? null : (
                              <div
                                className={classes.additional}
                                key={`pf${date}`}
                              >
                                <Typography component="div">
                                  {index + 1}. day -{' '}
                                  {moment(date).format('MMM D. dddd')}
                                  <br />
                                  {programs.paid[date].map(p => (
                                    <FormControlLabel
                                      key={p.id}
                                      control={
                                        <Checkbox
                                          disabled={isReviewScreen}
                                          checked={Boolean(
                                            _.indexOf(
                                              eventRegistration.attributes
                                                .participants[participantsKey]
                                                .attributes.program_ids,
                                              p.id,
                                            ) !== -1,
                                          )}
                                          onChange={(event, checked) =>
                                            inputParticipantProgramChanged(
                                              {
                                                target: {
                                                  name: p.id,
                                                  value: checked,
                                                },
                                              },
                                              participantsKey,
                                            )
                                          }
                                          name={p.id}
                                          color="primary"
                                        />
                                      }
                                      label={`${p.title} - €${p.fee}`}
                                    />
                                  ))}
                                </Typography>
                              </div>
                            ),
                        )}
                      </React.Fragment>
                    )}
                  </Grid>
                )}
              </Grid>
            </ContainerPaper>
            <ContainerPaper className={classes.paper}>
              <Grid container className={classes.GridMargin}>
                <Grid item xs={12}>
                  <Typography component="div" align="right">
                    Participant fee
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="div" className={classes.price}>
                    <Money amount={this.state.total} />
                  </Typography>
                </Grid>
              </Grid>
            </ContainerPaper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

ParticipantItem.defaultProps = {};

ParticipantItem.propTypes = {
  classes: PropTypes.object.isRequired,
  countries: PropTypes.object,
  enumValues: PropTypes.object,
  enumTypes: PropTypes.object,
  allEnumValues: PropTypes.object,
  eventRegistration: PropTypes.object,
  eventRegistrationErrors: PropTypes.object,
  programs: PropTypes.object,
  eventAccesses: PropTypes.array,
  modelIncludes: PropTypes.array,
  programCount: PropTypes.number,
  freeProgramCount: PropTypes.number,
  paidProgramCount: PropTypes.number,
  inputParticipantChanged: PropTypes.func,
  inputParticipantProgramChanged: PropTypes.func,
  addParticipanTotal: PropTypes.func,
  isReviewScreen: PropTypes.bool,
  value: PropTypes.number,
  deleteParticipant: PropTypes.func,
};

export default ParticipantItem;
