import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { throttle } from 'lodash';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import { makeUserSelector } from 'containers/AuthButton/selectors';
import TextInput from 'components/TextInput/Alt';
import {
  makeEmailSelector,
  makePasswordSelector,
  makeLoginErrorSelector,
} from './selectors';
import { login, inputChange } from './actions';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const LoginForm = ({
  classes,
  onClick,
  onChange,
  email,
  password,
  loginError,
}) => (
  <form onSubmit={onClick} className={classes.form}>
    <TextInput
      name="email"
      value={email}
      autoFocus
      onChange={throttle(event => {
        event.persist();
        onChange(event);
      }, 200)}
      label="Member Number or Email"
      error={loginError}
    />
    <TextInput
      name="password"
      type="password"
      value={password}
      autoFocus
      onChange={throttle(event => {
        event.persist();
        onChange(event);
      }, 200)}
      label="Password"
      error={loginError}
      toggleVisibility
    />

    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
      onClick={onClick}
    >
      Sign in
    </Button>
  </form>
);

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  loginError: PropTypes.string,
};

LoginForm.defaultProps = {
  email: '',
  password: '',
  loginError: '',
};

const mapStateToProps = createStructuredSelector({
  user: makeUserSelector(),
  email: makeEmailSelector(),
  password: makePasswordSelector(),
  loginError: makeLoginErrorSelector(),
});

export const mapDispatchToProps = dispatch => ({
  onClick: event => {
    event.preventDefault();
    dispatch(login());
  },
  onChange: input => dispatch(inputChange(input)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(LoginForm));
