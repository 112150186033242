import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { isEmpty, cloneDeep, isEqual, range, padStart } from 'lodash';
import moment from 'moment';

import MoneyInput from 'components/MoneyInput';
import { makeSelectInvoiceFormErrors } from './selectors';
import { setInvoiceErrors } from './actions';
import { invoiceErrors as resetErrors } from './constants';
import withCrud from '../ModelWrapper';
import reducer from './reducer';
import injectReducer from '../../utils/injectReducer';
import DateSelector from '../../components/DatePicker';
import { BACKEND_DATE } from '../../utils/constants';
import TextInput from '../../components/TextInput';

const initialState = {
  invoiceDate: null,
  dueDate: null,
};

const years = range(1999, moment().year() + 6).map(i => i.toString());

const generateInvoiceNumber = (memberNumber, year, count) =>
  `${memberNumber}/${year}/${padStart(count + 1, 2, '0')}`;

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.setDate = this.setDate.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
  }

  componentWillMount() {
    this.setState(initialState);
    if (!isEmpty(this.props.invoice) && isEmpty(this.props.model)) {
      this.setDefaultModel(this.props.invoice);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.props.refreshList();
      this.props.closeForm();
      return;
    }

    if (!isEmpty(nextProps.invoice) && isEmpty(nextProps.model)) {
      this.setState(initialState);
      this.setDefaultModel(nextProps.invoice);
    }

    if (!isEqual(nextProps.model, this.props.model)) {
      if (
        this.state.invoiceDate === null &&
        nextProps.model.attributes.invoice_date
      ) {
        this.setState({
          invoiceDate: moment(nextProps.model.attributes.invoice_date.date),
        });
      }

      if (this.state.dueDate === null && nextProps.model.attributes.due_date) {
        this.setState({
          dueDate: moment(nextProps.model.attributes.due_date.date),
        });
      }
    }

    if (
      !isEmpty(nextProps.invoice) &&
      !nextProps.invoice.attributes.number &&
      !isEqual(this.props.model, nextProps.model) &&
      !isEmpty(nextProps.model)
    ) {
      this.setFilters(
        nextProps.model.attributes.member_id,
        nextProps.model.attributes.year,
      );
    }

    if (!isEqual(this.props.params, nextProps.params)) {
      this.props.loadModels();
    }

    if (!isEqual(this.props.models, nextProps.models)) {
      if (nextProps.models.meta) {
        const { model } = nextProps;

        model.attributes.number = generateInvoiceNumber(
          this.props.member.attributes.member_number,
          model.attributes.year,
          nextProps.models.meta.pagination.total,
        );

        this.props.setModel(model);
      }
    }
  }

  setDefaultModel(defaultModel) {
    const model = cloneDeep(defaultModel);

    model.attributes.year =
      defaultModel.attributes.year ||
      moment()
        .year()
        .toString();

    this.props.setModel(model);
  }

  setFilters(memberId, year) {
    if (memberId) {
      this.props.setFilter({
        target: {
          name: 'member_id',
          value: memberId,
        },
      });
    }
    if (year) {
      this.props.setFilter({
        target: {
          name: 'year',
          value: year,
        },
      });
    }
  }

  inputChanged(event) {
    const selectedInvoice = cloneDeep(this.props.model);
    selectedInvoice.attributes[event.target.name] = event.target.value;

    this.props.setModel(selectedInvoice);
  }

  setDate(event) {
    const { state } = this;

    if (!state[event.target.name]) {
      state[event.target.name] = moment();
    }
    state[event.target.name] = event.target.value;
    this.setState(state);
  }

  closeForm() {
    this.setState(initialState);
    this.props.setModel({});
    this.props.setErrors(cloneDeep(resetErrors));
    this.props.closeForm();
  }

  submit() {
    const { setErrors, model } = this.props;

    model.attributes.invoice_date = this.state.invoiceDate
      ? this.state.invoiceDate.format(BACKEND_DATE)
      : '';
    model.attributes.due_date = this.state.dueDate
      ? this.state.dueDate.format(BACKEND_DATE)
      : '';

    setErrors(cloneDeep(resetErrors));
    const invoiceErrors = cloneDeep(resetErrors);

    const requiredFields = {
      year: 'Year',
      invoice_date: 'Invoice Date',
      due_date: 'Due Date',
      amount: 'Amount',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!model.attributes[field].toString().length) {
        invoiceErrors[field] = `${requiredFields[field]} can not be empty`;
      }
    });

    if (!isEqual(invoiceErrors, resetErrors)) {
      this.props.setErrors(invoiceErrors);
      return this.forceUpdate();
    }

    this.setState(initialState);
    return this.props.submitModel();
  }

  render() {
    const { model, invoiceErrors } = this.props;
    const { invoiceDate, dueDate } = this.state;

    if (isEmpty(model)) {
      return null;
    }

    return (
      <Dialog open onClose={this.closeForm} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {model.id
            ? `Edit "${model.attributes.number}" Invoice`
            : 'Add New Invoice'}
        </DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <DialogContent>
            <TextInput
              model={model}
              modelErrors={invoiceErrors}
              attribute="number"
              label="Number"
              disabled
            />

            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(invoiceErrors.year.length)}
            >
              <InputLabel htmlFor="year">Year</InputLabel>
              <Select
                id="year"
                name="year"
                value={model.attributes.year}
                onChange={this.inputChanged}
              >
                {years.map(key => (
                  <MenuItem value={key} key={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="value-error-text">
                {invoiceErrors.year}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              error={Boolean(invoiceErrors.invoice_date.length)}
            >
              <DateSelector
                label="Invoice Date"
                value={invoiceDate}
                error={Boolean(invoiceErrors.invoice_date.length)}
                helperText={invoiceErrors.invoice_date}
                onChange={date => {
                  const event = {
                    target: {
                      name: 'invoiceDate',
                      value: date,
                    },
                  };
                  this.setDate(event);
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              error={Boolean(invoiceErrors.due_date.length)}
            >
              <DateSelector
                label="Due Date"
                value={dueDate}
                error={Boolean(invoiceErrors.due_date.length)}
                helperText={invoiceErrors.due_date}
                onChange={date => {
                  const event = {
                    target: {
                      name: 'dueDate',
                      value: date,
                    },
                  };
                  this.setDate(event);
                }}
              />
            </FormControl>
            <MoneyInput
              model={model}
              modelErrors={invoiceErrors}
              onChange={this.inputChanged}
              attribute="amount"
              label="Amount"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeForm}>Cancel</Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

InvoiceForm.propTypes = {
  invoice: PropTypes.object,
  member: PropTypes.object,
  model: PropTypes.object,
  models: PropTypes.object,
  params: PropTypes.object,
  invoiceErrors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  loadModels: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  invoiceErrors: makeSelectInvoiceFormErrors(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setInvoiceErrors(errors)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'invoiceForm', reducer });

export default compose(
  withReducer,
  withConnect,
)(withCrud('invoicePage', 'invoices', InvoiceForm, false));
