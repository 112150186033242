export const UPLOAD_FILE = 'app/FileUpload/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'app/FileUpload/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'app/FileUpload/UPLOAD_FILE_ERROR';

export const DELETE_FILE = 'app/FileUpload/DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'app/FileUpload/UPLOAD_FILE_SUCCESS';

export const RESET_STORE = 'app/FileUpload/RESET_STORE';
export const LOAD_IMAGE = 'app/FileUpload/LOAD_IMAGE';

export const SET_VISIBILITY = 'app/FileUpload/SET_VISIBILITY';
