import React from 'react';
import PropTypes from 'prop-types';
import {
  PageRootContainerStyles,
  PageRootContainerOpenedStyles,
} from 'mui-theme';

import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  FormGroup,
  Button,
  Icon,
  withStyles,
  Paper,
} from '@material-ui/core';

import TinyMCE from 'components/TinyMCE';
import TextInput from 'components/TextInput';
import { slugify } from 'utils/constants';
import RegistrantTable from '../Registrant/RegistrantsTable';
import DateSelector from '../../../components/DatePicker';
import ContainerPaper from '../../../components/ContainerPaper';
import EventAccessRow from './EventAccessRow';
import EventProgramRow from './EventProgramRow';

const styles = theme => ({
  rootClosed: {
    ...PageRootContainerStyles,
  },
  rootOpened: {
    ...PageRootContainerOpenedStyles,
  },
  button: {
    ...theme.button,
  },
  dateSelector: {
    ...theme.dateSelector,
  },
  datePicker: {
    root: { marginBottom: '20px' },
  },
});

const EventEditorForm = ({
  model,
  eventErrors,
  onSubmit,
  onChange,
  classes,
  handleEditorChange,
  setDate,
  eventAccesses,
  onAddEventAccess,
  onDeleteEventAccess,
  eventAccessFieldChange,
  programs,
  onAddProgram,
  onDeleteProgram,
  programFieldChange,
  rootClass,
  enumValues,
  enumTypes,
  allEnumValues,
}) => (
  <React.Fragment>
    <form onSubmit={onSubmit}>
      <ContainerPaper className={classes.paper}>
        <Grid container style={{ marginBottom: '16px' }}>
          <Typography variant="h5" gutterBottom>
            {model.id ? 'Edit event' : 'Create Event'}
          </Typography>
        </Grid>
        <Grid container spacing={8}>
          <Grid item md style={{ flexGrow: 1 }}>
            <TextInput
              required
              model={model}
              modelErrors={eventErrors}
              attribute="title"
              label="Title"
              onChange={onChange}
              onBlur={event => {
                if (!model.attributes.title_slug.length) {
                  onChange({
                    target: {
                      name: 'title_slug',
                      value: slugify(event.target.value.trim()),
                    },
                  });
                }
              }}
            />
          </Grid>
          <Grid item md style={{ flexGrow: 1 }}>
            <TextInput
              required
              model={model}
              modelErrors={eventErrors}
              attribute="title_slug"
              label="Slug"
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item md={6} style={{ flexGrow: 1 }}>
            <TextInput
              required
              model={model}
              modelErrors={eventErrors}
              attribute="location"
              label="Location"
              onChange={onChange}
            />
          </Grid>
          <Grid item md style={{ flexGrow: 1, alignSelf: 'baseline' }}>
            <DateSelector
              className={classes.datePicker}
              required
              label="Start date"
              value={model.attributes.start_date}
              onChange={date => {
                const event = {
                  target: {
                    name: 'start_date',
                    value: date || '',
                  },
                };
                setDate(event);
              }}
              error={Boolean(eventErrors.start_date)}
              helperText={eventErrors.start_date}
            />
          </Grid>
          <Grid item md style={{ flexGrow: 1, alignSelf: 'baseline' }}>
            <DateSelector
              className={classes.datePicker}
              required
              label="End Date"
              value={model.attributes.end_date}
              onChange={date => {
                const event = {
                  target: {
                    name: 'end_date',
                    value: date || '',
                  },
                };
                setDate(event);
              }}
              error={Boolean(eventErrors.end_date)}
              helperText={eventErrors.end_date}
            />
          </Grid>
        </Grid>
        <FormControl
          aria-describedby="value-error-text"
          fullWidth
          error={Boolean(eventErrors.useful_information.length)}
        >
          <Typography variant="body1" style={{ color: 'rgba(0,0,0,0.54)' }}>
            Useful information
          </Typography>
          <TinyMCE
            Id="useful_information"
            name="useful_information"
            initialValue={model.attributes.useful_information}
            onChange={handleEditorChange}
            height={100}
          />
          <FormHelperText id="value-error-text">
            {eventErrors.useful_information}
          </FormHelperText>
        </FormControl>
        <FormControl
          aria-describedby="value-error-text"
          fullWidth
          error={Boolean(eventErrors.description.length)}
        >
          <Typography variant="body1" style={{ color: 'rgba(0,0,0,0.54)' }}>
            Description*
          </Typography>
          <TinyMCE
            Id="description"
            name="description"
            initialValue={model.attributes.description}
            onChange={handleEditorChange}
            height={100}
          />
          <FormHelperText id="value-error-text">
            {eventErrors.description}
          </FormHelperText>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(model.attributes.active)}
              onChange={(event, checked) =>
                onChange({
                  target: { name: 'active', value: checked },
                })
              }
              name="active"
              color="primary"
            />
          }
          label="Active"
        />
        <FormGroup>
          <Typography variant="subtitle2" gutterBottom>
            Access prices*
          </Typography>
          {eventAccesses.map((data, index) => {
            const eventAccessErrors = eventErrors.eventAccesses[index];
            const excludedValues = eventAccesses
              .map(eventAccess => eventAccess.attributes.name)
              .filter(name => name !== data.attributes.name);
            return (
              <EventAccessRow
                key={index} // eslint-disable-line react/no-array-index-key
                index={index}
                enumValues={enumValues}
                enumTypes={enumTypes}
                allEnumValues={allEnumValues}
                data={data}
                errors={eventAccessErrors}
                excludedValues={excludedValues}
                onChange={eventAccessFieldChange}
                onDelete={event => onDeleteEventAccess(event, index)}
              />
            );
          })}
        </FormGroup>
        <Grid container direction="column" alignContent="flex-end">
          <Button type="button" color="primary" onClick={onAddEventAccess}>
            <Icon>add</Icon>Add
          </Button>
        </Grid>
        <FormGroup>
          <Typography variant="subtitle2" gutterBottom>
            Programs
          </Typography>
          {programs.map((data, index) => {
            const programErrors = eventErrors.programs[index];
            return (
              <EventProgramRow
                key={index} // eslint-disable-line react/no-array-index-key
                index={index}
                data={data}
                errors={programErrors}
                onChange={programFieldChange}
                onDelete={event => onDeleteProgram(event, index)}
              />
            );
          })}
        </FormGroup>
        <Grid container direction="column" alignContent="flex-end">
          <Button type="button" color="primary" onClick={onAddProgram}>
            <Icon>add</Icon>Add
          </Button>
          <br />
          <Button type="submit" color="primary" variant="contained">
            Save
          </Button>
        </Grid>
      </ContainerPaper>
    </form>
    {model.id && (
      <Paper className={rootClass}>
        <RegistrantTable eventId={parseInt(model.id, 10)} programs={programs} />
      </Paper>
    )}
  </React.Fragment>
);

EventEditorForm.propTypes = {
  model: PropTypes.object.isRequired,
  eventErrors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleEditorChange: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  eventAccesses: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddEventAccess: PropTypes.func.isRequired,
  onDeleteEventAccess: PropTypes.func.isRequired,
  eventAccessFieldChange: PropTypes.func.isRequired,
  programs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddProgram: PropTypes.func.isRequired,
  onDeleteProgram: PropTypes.func.isRequired,
  programFieldChange: PropTypes.func.isRequired,
  rootClass: PropTypes.string.isRequired,
  enumValues: PropTypes.object,
  enumTypes: PropTypes.object,
  allEnumValues: PropTypes.object,
};

export default withStyles(styles)(EventEditorForm);
