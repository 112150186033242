import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: `${theme.spacing.unit * 2}px auto 0 auto`,
  },
});

const Loader = ({ classes }) => (
  <CircularProgress color="primary" className={classes.loader} />
);

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loader);
