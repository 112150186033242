/*
 *
 * Members constants
 *
 */

export const DEFAULT_ACTION = 'app/Members/DEFAULT_ACTION';
export const SET_MEMBER_ERROR = 'app/Members/SET_MEMBER_ERROR';

export const memberSkeleton = Object.freeze({
  id: null,
  type: 'members',
  attributes: {
    status: '',
    member_number: '',
    fee: '',
    delegation_fee: '',
    preferred_language: '',
    first_title: '',
    second_title: '',
    first_name: '',
    last_name: '',
    particle: '',
    gender: '',
    delegation: '',
    date_of_birth: '',
    member_type: '',
    expertise: '',
    postal_country: '',
    postal_city: '',
    postal_zip: '',
    postal_address: '',
    secondary_country: '',
    secondary_city: '',
    secondary_zip: '',
    secondary_address: '',
    contact_person: '',
    private_tel: '',
    private_tel2: '',
    private_fax: '',
    private_email: '',
    private_mobile: '',
    private_skype: '',
    office_tel: '',
    office_tel2: '',
    office_fax: '',
    office_email: '',
    other_tel: '',
    other_fax: '',
    other_email: '',
    website: '',
    comments: '',
    member_since: '',
    left_cic: '',
    reason_for_leaving: '',
    last_modified_by: '',
    password: '',
    password_confirmation: '',
    current_password: '',
    updated_at: '',
    can_pay: 0,
    short_bio: '',
    profile_image: '',
    subscriptions: {
      news: 0,
      auctions: 0,
    },
  },
});

export const memberErrors = {
  status: '',
  member_number: '',
  fee: '',
  delegation_fee: '',
  preferred_language: '',
  first_title: '',
  second_title: '',
  first_name: '',
  last_name: '',
  particle: '',
  gender: '',
  delegation: '',
  date_of_birth: '',
  member_type: '',
  expertise: '',
  postal_country: '',
  postal_city: '',
  postal_zip: '',
  postal_address: '',
  secondary_country: '',
  secondary_city: '',
  secondary_zip: '',
  secondary_address: '',
  contact_person: '',
  private_tel: '',
  private_tel2: '',
  private_fax: '',
  private_email: '',
  private_mobile: '',
  private_skype: '',
  office_tel: '',
  office_tel2: '',
  office_fax: '',
  office_email: '',
  other_tel: '',
  other_fax: '',
  other_email: '',
  website: '',
  comments: '',
  member_since: '',
  left_cic: '',
  reason_for_leaving: '',
  last_modified_by: '',
  password: '',
  password_confirmation: '',
  current_password: '',
  updated_at: '',
  short_bio: '',
  profile_image: '',
};

export const memberFunctionSkeleton = {
  id: null,
  type: 'member-functions',
  attributes: {
    member_id: null,
    team: '',
    function_enum_id: '',
    start_date: null,
    end_date: null,
  },
};

export const memberFunctionErrors = {
  team_type: '',
  team: '',
  function_enum_id: '',
  start_date: '',
  end_date: '',
};
