import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { isEmpty, throttle, cloneDeep, isEqual } from 'lodash';

import { makeSelectModelIncludes } from 'containers/ModelWrapper/selectors';
import {
  makeSelectUserRoles,
  makeSelectDelegations,
} from 'containers/App/selectors';
import TextInput from 'components/TextInput/Alt';
import { makeSelectUserErrors } from './selectors';
import { setUserErrors } from './actions';
import { userErrors as resetErrors, userSkeleton } from './constants';

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.closeForm = this.closeForm.bind(this);
    this.state = {
      included: [],
    };
  }

  componentWillMount() {
    this.preLoadModel(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.included &&
      !isEqual(nextProps.included, this.state.included)
    ) {
      this.setState({ included: nextProps.included });
    }
    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.preLoadModel(nextProps);
      return;
    }
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.props.history.push('/admin/users');
    }
  }

  preLoadModel(props) {
    const { match } = props;
    if (isEmpty(match.params)) {
      return;
    }
    this.props.setModel(cloneDeep(userSkeleton));
    this.props.setErrors(cloneDeep(resetErrors));
    if (parseInt(match.params.id, 10)) {
      this.props.showModel(match.params.id);
    }
  }

  inputChanged(event) {
    const selectedUser = cloneDeep(this.props.model);
    selectedUser.attributes[event.target.name] = event.target.value;
    if (event.target.name === 'role') {
      selectedUser.attributes.delegation_id = '';
    }
    this.props.setModel(selectedUser);
  }

  closeForm() {
    this.props.setModel(null);
    this.props.setErrors(cloneDeep(resetErrors));
  }

  submit() {
    const { makeValidUser } = this.props;
    const { included } = this.state;
    const model = makeValidUser(this.props.model, included);
    const isNewUser = model.id === null;
    this.props.setErrors(cloneDeep(resetErrors));
    const userErrors = cloneDeep(resetErrors);
    const requiredFields = {
      first_name: 'First name',
      last_name: 'Last name',
      name: 'Username',
      role: 'Role',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!model.attributes[field].length) {
        userErrors[field] = `${requiredFields[field]} can not be empty`;
      }
    });

    const isPasswordFormatValid = /^.*(?=.{3,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/.test(
      model.attributes.password,
    );
    const isPasswordLengthValid =
      model.attributes.password && model.attributes.password.length >= 6;
    const isPasswordEmpty =
      !model.attributes.password ||
      (model.attributes.password && model.attributes.password.length === 0);
    if (!isPasswordEmpty && !isPasswordFormatValid) {
      userErrors.password =
        'Password should contain contain upper and lowercase letters and numbers';
    }
    if (
      (isNewUser && !isPasswordLengthValid) ||
      (!isNewUser && !isPasswordEmpty && !isPasswordLengthValid)
    ) {
      userErrors.password = 'Password should be at least 6 characters long.';
    }
    if (isNewUser && isPasswordEmpty) {
      userErrors.password = 'Password can not be empty';
    }
    if (
      !isPasswordEmpty &&
      model.attributes.password !== model.attributes.password_confirmation
    ) {
      userErrors.password_confirmation =
        'Retype password and password does not match.';
    }

    if (model.attributes.name.indexOf('@') > -1) {
      userErrors.name = 'Username cannot contain @ character';
    }

    if (
      model.attributes.role === 'HEAD_OF_DELEGATION' &&
      !model.attributes.delegation_id.toString().length
    ) {
      userErrors.delegation_id = 'Delegation can not be empty';
    }
    if (!isEqual(userErrors, resetErrors)) {
      this.props.setErrors(userErrors);
      return this.forceUpdate();
    }
    return this.props.submitModel();
  }

  render() {
    if (isEmpty(this.props.model)) {
      return null;
    }
    const { makeValidUser, userErrors, roles, delegations } = this.props;
    const { included } = this.state;

    const model = makeValidUser(this.props.model, included);
    const isNewUser = model.id === null;
    const showDelegation = model.attributes.role === 'HEAD_OF_DELEGATION';
    return (
      <Dialog open onClose={this.closeForm} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {model.id ? `Edit "${model.attributes.name}" User` : 'Add New User'}
        </DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <DialogContent>
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(userErrors.first_name.length)}
            >
              <InputLabel htmlFor="first_name">First name</InputLabel>
              <Input
                id="first_name"
                name="first_name"
                autoFocus
                value={model.attributes.first_name}
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <FormHelperText id="value-error-text">
                {userErrors.first_name}
              </FormHelperText>
            </FormControl>
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(userErrors.last_name.length)}
            >
              <InputLabel htmlFor="last_name">Last name</InputLabel>
              <Input
                id="last_name"
                name="last_name"
                value={model.attributes.last_name}
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <FormHelperText id="value-error-text">
                {userErrors.last_name}
              </FormHelperText>
            </FormControl>
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(userErrors.name.length)}
            >
              <InputLabel htmlFor="name">Username</InputLabel>
              <Input
                id="name"
                name="name"
                value={model.attributes.name}
                disabled={!isNewUser}
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <FormHelperText id="value-error-text">
                {userErrors.name}
              </FormHelperText>
            </FormControl>
            <TextInput
              name="password"
              type="password"
              label="Password"
              error={userErrors.password}
              value={model.attributes.password}
              onChange={throttle(event => this.inputChanged(event), 200)}
              toggleVisibility
            />
            <TextInput
              name="password_confirmation"
              type="password"
              label="Retype password"
              error={userErrors.password_confirmation}
              value={model.attributes.password_confirmation}
              onChange={throttle(event => this.inputChanged(event), 200)}
              toggleVisibility
            />

            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(userErrors.role.length)}
            >
              <InputLabel htmlFor="role">Role</InputLabel>
              <Select
                id="role"
                name="role"
                value={model.attributes.role}
                onChange={event => {
                  this.inputChanged(event);
                }}
              >
                {Object.keys(roles.attributes).map(
                  key =>
                    key !== 'AUTHENTICATED_USER' && (
                      <MenuItem value={key} key={key}>
                        {roles.attributes[key]}
                      </MenuItem>
                    ),
                )}
              </Select>
              <FormHelperText id="value-error-text">
                {userErrors.role}
              </FormHelperText>
            </FormControl>
            {showDelegation && (
              <FormControl
                aria-describedby="value-error-text"
                fullWidth
                error={Boolean(userErrors.delegation_id.length)}
              >
                <InputLabel htmlFor="delegation_id">Delegation</InputLabel>
                <Select
                  id="delegation_id"
                  name="delegation_id"
                  value={model.attributes.delegation_id}
                  onChange={event => {
                    this.inputChanged(event);
                  }}
                >
                  {delegations.attributes.delegation.map(item => (
                    <MenuItem value={item.column_id} key={item.column_id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="value-error-text">
                  {userErrors.delegation_id}
                </FormHelperText>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeForm}>Cancel</Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

UserForm.defaultProps = {
  roles: {
    attributes: [],
  },
  delegations: {
    attributes: {
      delegation: [],
    },
  },
};

UserForm.propTypes = {
  model: PropTypes.object,
  userErrors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  roles: PropTypes.object,
  delegations: PropTypes.object,
  submitModel: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  makeValidUser: PropTypes.func.isRequired,
  included: PropTypes.array,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  userErrors: makeSelectUserErrors(),
  roles: makeSelectUserRoles(),
  delegations: makeSelectDelegations(),
  included: makeSelectModelIncludes('userForm'),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setUserErrors(errors)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(UserForm);
