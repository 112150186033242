import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import withCrud from 'containers/ModelWrapper';

class MemberFunctionTeamSelector extends React.Component {
  componentWillMount() {
    if (this.props.areaType) {
      this.props.setFilter({
        target: {
          name: 'area_type',
          value: this.props.areaType,
        },
      });
      this.props.setRowsPerPage({
        target: {
          name: 'size',
          value: 9999,
        },
      });
      this.props.loadModels();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.areaType !== this.props.areaType && nextProps.areaType) {
      this.props.setFilter({
        target: {
          name: 'area_type',
          value: nextProps.areaType,
        },
      });
      this.props.setRowsPerPage({
        target: {
          name: 'size',
          value: 9999,
        },
      });
      this.props.loadModels();
    }
  }
  render() {
    const {
      errors,
      memberFunction,
      models: { data },
    } = this.props;
    return (
      <FormControl
        aria-describedby="value-error-text"
        fullWidth
        error={Boolean(errors.team.length)}
      >
        <InputLabel htmlFor="team">Team</InputLabel>
        <Select
          id="team"
          name="team"
          value={memberFunction.attributes.team}
          onChange={this.props.inputChanged}
        >
          {data.map(item => (
            <MenuItem value={item.id} key={item.id}>
              {item.attributes.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText id="value-error-text">{errors.team}</FormHelperText>
      </FormControl>
    );
  }
}

MemberFunctionTeamSelector.propTypes = {
  errors: PropTypes.object.isRequired,
  memberFunction: PropTypes.object.isRequired,
  models: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  areaType: PropTypes.string.isRequired,
  inputChanged: PropTypes.func.isRequired,
  loadModels: PropTypes.func.isRequired,
};

export default withCrud(
  'memberFunctionTeamSelector',
  'functional-areas',
  MemberFunctionTeamSelector,
  false,
);
