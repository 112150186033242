import axios from 'axios';

import { deleteUser } from 'containers/AuthButton/actions';
import { BACKEND_URL_LOCAL, ECHO_URL_LOCAL } from 'utils/constants';
import Echo from 'laravel-echo';
window.io = require('socket.io-client');

const BACKEND_URL = process.env.BACKEND_URL || BACKEND_URL_LOCAL;
const ECHO_URL = process.env.ECHO_URL || ECHO_URL_LOCAL;

const getEchoClient = () => {
  if (!window.EchoClient) {
    window.EchoClient = new Echo({
      broadcaster: 'socket.io',
      host: ECHO_URL,
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      },
    });
    window.EchoClient.connector.socket.on('connect', () => {
      const socketId = window.EchoClient.socketId();
      axios.defaults.headers.common['X-Socket-ID'] = socketId;
    });
  }
  return window.EchoClient;
};

const initWebsocketConnection = (channelType, eventName, eventCallback) => {
  getEchoClient()
    .private(channelType)
    .listen(eventName, eventCallback);
};

const deInitWebsocketConnection = channelType => {
  getEchoClient().leave(channelType);
};

export { getEchoClient, initWebsocketConnection, deInitWebsocketConnection };

const handleBlobError = error =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      error.response.data = JSON.parse(reader.result); // eslint-disable-line no-param-reassign
      resolve(Promise.reject(error));
    };

    reader.onerror = () => {
      reject(error);
    };

    reader.readAsText(error.response.data);
  });

export default {
  setupAxios: store => {
    axios.defaults.baseURL = BACKEND_URL;
    if (localStorage.getItem('accessToken')) {
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'accessToken',
      )}`;
    }

    // Add a response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        if (!error.response) {
          throw new Error(error.message);
        }
        if (error.response && error.response.status === 401) {
          getEchoClient().connector.options.auth.headers.Authorization = null;
          store.dispatch(deleteUser());
        }
        if (
          error.request.responseType === 'blob' &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
          handleBlobError(error).then(err => {
            throw err;
          });
        }

        switch (error.response.status) {
          case 400:
            throw error;
          case 404:
            throw new Error('Not found');
          case 401:
            throw new Error('Unauthorized');
          default:
            throw new Error('An error occured please try again later.');
        }
      },
    );
  },
};

const getBackendURL = process.env.BACKEND_URL || BACKEND_URL_LOCAL;

export { getBackendURL };
