import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import Button from '@material-ui/core/Button';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { makeUserSelector } from './selectors';
import saga from './saga';
import reducer from './reducer';
import { resetUser, refreshUser } from './actions';

class AuthButton extends React.Component {
  componentWillMount() {
    if (localStorage.getItem('accessToken', '')) {
      this.props.refreshUser();
    }
  }
  render() {
    const label = this.props.user && this.props.user.name ? 'Logout' : 'Login';
    return (
      <Button
        variant="contained"
        color="default"
        onClick={this.props.resetUser}
      >
        {label}
      </Button>
    );
  }
}

AuthButton.propTypes = {
  user: PropTypes.object.isRequired,
  refreshUser: PropTypes.func.isRequired,
  resetUser: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeUserSelector(),
});

const mapDispatchToProps = dispatch => ({
  resetUser: () => dispatch(resetUser()),
  refreshUser: () => dispatch(refreshUser()),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'authButton', reducer });
const withSaga = injectSaga({ key: 'authButton', saga });

export default compose(
  withSaga,
  withReducer,
  withConnect,
)(AuthButton);
