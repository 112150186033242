export const SET_USER_ERROR = 'app/UserPage/SET_USER_ERROR';

export const userSkeleton = Object.freeze({
  id: null,
  type: 'users',
  attributes: {
    first_name: '',
    last_name: '',
    name: '',
    password: '',
    password_confirmation: '',
    role: '',
    delegation_id: '',
  },
});

export const userErrors = {
  first_name: '',
  last_name: '',
  name: '',
  password: '',
  password_confirmation: '',
  role: '',
  delegation_id: '',
};
