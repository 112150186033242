/**
 *
 * InvoiceGeneratePage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import ContainerPaper from 'components/ContainerPaper';
import Typography from '@material-ui/core/Typography';

import makeSelectGenerateInvoicePage from './selectors';
import reducer from './reducer';
import saga from './saga';
import { submitForm, getInvoicedCount } from './actions';
import GenerateInvoiceForm from './GenerateInvoiceForm';

/* eslint-disable react/prefer-stateless-function */
export class GenerateInvoicePage extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Generate Invoices</title>
        </Helmet>
        <ContainerPaper>
          <Typography variant="h5" gutterBottom>
            Generate Invoices
          </Typography>
          <GenerateInvoiceForm
            memberCounts={this.props.model.count}
            getInvoicedCount={this.props.getInvoicedCount}
            errors={this.props.model.errors}
            onSubmit={this.props.submitForm}
          />
        </ContainerPaper>
      </div>
    );
  }
}

GenerateInvoicePage.propTypes = {
  submitForm: PropTypes.func.isRequired,
  getInvoicedCount: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  model: makeSelectGenerateInvoicePage(),
});

function mapDispatchToProps(dispatch) {
  return {
    submitForm: data => dispatch(submitForm(data)),
    getInvoicedCount: year => dispatch(getInvoicedCount(year)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'generateInvoicePage', reducer });
const withSaga = injectSaga({ key: 'generateInvoicePage', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(GenerateInvoicePage);
