import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';

import {
  DatePicker as DateSelector,
  MuiPickersUtilsProvider,
} from 'material-ui-pickers';
import { DATE_FORMAT, DATE_FORMAT_MASK } from 'utils/constants';
import { isMobile } from 'react-device-detect';

MomentUtils.prototype.startOfMonth = MomentUtils.prototype.getStartOfMonth;

const DatePicker = props => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <DateSelector
      InputLabelProps={{
        shrink: Boolean(props.value || isMobile),
        required: props.required,
      }}
      label={props.label}
      classes={props.classes}
      className={props.className}
      required={props.required}
      keyboard
      fullWidth
      format={DATE_FORMAT}
      clearable
      adornmentPosition="end"
      value={props.value}
      mask={date => (date ? DATE_FORMAT_MASK : [])}
      disableOpenOnEnter
      onChange={props.onChange}
      onClear={props.onClear}
      error={props.error}
      disabled={props.disabled}
      helperText={props.helperText}
    />
  </MuiPickersUtilsProvider>
);

DatePicker.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helperText: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DatePicker;
