import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty, find, isEqual } from 'lodash';
import { Link as LinkTo } from 'react-router-dom';
import withCrud from 'containers/ModelWrapper';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

import SortableCells from 'components/SortableCells';
import TableToolbar from 'components/TableToolbar';
import TableFooter from 'components/TableFooter';
import ConfirmDialog from 'components/ConfirmDialog';
import { makeSelectDelegations } from 'containers/App/selectors';
import {
  makeUserSelector,
  makeRolesSelector,
} from 'containers/AuthButton/selectors';
import Authorization from 'utils/authorization';
import download from 'utils/download';
import { userHasRole } from 'utils/userHasRole';

import MembersFilter from './MembersFilter';
import {
  TableContainerStyles,
  TableContainerOpenedStyles,
} from '../../mui-theme';
import {
  DATE_TIME_FORMAT,
  BACKEND_DATE,
  ROLES_MAINTAINERS,
} from '../../utils/constants';
import { makeSelectSidebarIsOpen } from '../Layout/selectors';

const styles = () => ({
  rootClosed: TableContainerStyles,
  rootOpened: TableContainerOpenedStyles,
});

const memberActive = row => {
  if (!row.attributes.left_cic) {
    return true;
  }
  const now = moment().format('X');
  const leftCic = moment(row.attributes.left_cic, BACKEND_DATE).format('X');
  return leftCic > now;
};

class MembersTable extends React.Component {
  constructor(props) {
    super(props);
    this.createSortHandler = this.createSortHandler.bind(this);
    this.state = {
      filterOpen: false,
      deleteDialogOpen: false,
      dialogTitle: '',
    };
  }

  componentWillMount() {
    this.loadMembers(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.loadMembers(nextProps);
      return;
    }
    if (!isEqual(this.props.params, nextProps.params)) {
      this.props.loadModels();
    }
  }

  getListType = type =>
    Object.entries({
      NEW: 'waiting-for-approval',
      ACTIVE: 'approved',
    }).filter(item => item[1] === type);

  loadMembers(props) {
    const { setFilter, match, roles, user } = props;
    if (match.params.type) {
      const type = this.getListType(match.params.type);
      setFilter({
        target: {
          name: 'status',
          value: type.length ? String(type[0][0]) : 'NEW',
        },
      });

      if (match.params.type === 'approved') {
        setFilter({
          target: {
            name: 'active',
            value: 1,
          },
        });
      }
      if (
        !roles.includes(['SYSTEM_ADMINISTRATOR', 'MEMBERSHIP_ADMINISTRATOR'])
      ) {
        setFilter({
          target: {
            name: 'limit_delegation_scope',
            value: user.delegation_id,
          },
        });
      }
    }
  }

  toggleFilter = () => {
    const { match, user, roles } = this.props;
    const type = this.getListType(match.params.type);

    if (this.state.filterOpen) {
      this.props.resetFilter({
        status: type.length ? String(type[0][0]) : 'NEW',
        limit_delegation_scope: ![
          'SYSTEM_ADMINISTRATOR',
          'MEMBERSHIP_ADMINISTRATOR',
        ].some(allowedRole => roles.includes(allowedRole))
          ? user.delegation_id
          : null,
      });
    }
    this.setState(state => ({ filterOpen: !state.filterOpen }));
  };

  createSortHandler(property) {
    return event => {
      const { sortBy, sortDirection } = this.props;
      let direction = 'asc';
      if (property === sortBy) {
        direction = sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.props.setSorting(event, property, direction);
    };
  }

  openDialog(member) {
    this.setState(() => ({
      deleteDialogOpen: true,
      destroyMember: member,
      dialogTitle: `Are you sure you want to remove "${
        member.attributes.first_name
      } ${member.attributes.last_name}" member?`,
    }));
  }

  downloadPDF(member) {
    axios({
      url: 'export/member/pdf',
      method: 'POST',
      responseType: 'blob',
      data: { data: member },
    }).then(response => {
      download(response, response.headers['x-file-name'] || 'member.pdf');
    });
  }
  render() {
    const {
      models,
      classes,
      setPageNumber,
      sortBy,
      sortDirection,
      setRowsPerPage,
      setFilter,
      params,
      sidebarIsOpen,
    } = this.props;

    const { data, meta } = models;

    if (isEmpty(meta) || isEmpty(this.props.delegations)) {
      return null;
    }

    const { delegation } = this.props.delegations.attributes;
    const titles = {
      NEW: 'Members waiting for approval',
      ACTIVE: 'Approved members',
    };

    const approvedList =
      params.filter.status && params.filter.status === 'ACTIVE';

    let cells = {
      Name: 'name',
      Delegation: 'delegation',
      'Created date': 'created_at',
      'Modified date': 'updated_at',
    };

    if (approvedList) {
      cells = {
        Number: 'member_number',
        Name: 'name',
        Delegation: 'delegation',
        Active: 'status',
        'Modified date': 'updated_at',
      };
    }

    const tableTitle = isEmpty(params.filter.status)
      ? 'Members'
      : titles[params.filter.status];

    const MaintainerAuthorization = Authorization(ROLES_MAINTAINERS);

    const isAdmin = userHasRole(this.props.roles, [
      'SYSTEM_ADMINISTRATOR',
      'MEMBERSHIP_ADMINISTRATOR',
    ]);
    const isYO = userHasRole(this.props.roles, ['YOUNG_OPINION']);
    const showAttachmentButton = (isYO || isAdmin) && !approvedList;

    const ActionsTableCell = MaintainerAuthorization(({ row }) => (
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <LinkTo to={`/admin/members/edit/${row.id}`}>
          <IconButton aria-label="Edit">
            <Icon fontSize="small">edit_icon</Icon>
          </IconButton>
        </LinkTo>
        {(isYO || isAdmin) && (
          <IconButton aria-label="Edit" onClick={() => this.openDialog(row)}>
            <Icon fontSize="small">delete</Icon>
          </IconButton>
        )}
        {showAttachmentButton && (
          <IconButton aria-label="PDF" onClick={() => this.downloadPDF(row)}>
            <Icon fontSize="small">picture_as_pdf</Icon>
          </IconButton>
        )}
      </TableCell>
    ));

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;

    return (
      <Paper className={rootClass}>
        <ConfirmDialog
          open={this.state.deleteDialogOpen}
          onClose={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          cancelAction={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          confirmAction={() => {
            this.props.deleteModel(this.state.destroyMember);
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          title={this.state.dialogTitle}
          description="This action can not be undone."
        />
        <TableToolbar title={tableTitle} onClick={this.toggleFilter} />
        <Table className={classes.table} padding="checkbox">
          <TableHead>
            <TableRow>
              <SortableCells
                sortDirection={sortDirection}
                sortBy={sortBy}
                onClick={this.createSortHandler}
                cells={cells}
              />
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.filterOpen && (
              <MembersFilter
                params={params}
                setFilter={setFilter}
                approvedList={approvedList}
              />
            )}
            {data.map(row => (
              <TableRow key={row.id}>
                {approvedList && (
                  <TableCell>{row.attributes.member_number}</TableCell>
                )}
                <TableCell>
                  {`${row.attributes.first_name} ${row.attributes.particle} ${
                    row.attributes.last_name
                  }`}
                </TableCell>
                <TableCell>
                  {row.attributes.delegation && (
                    <span>
                      {
                        find(delegation, {
                          column_id: row.attributes.delegation,
                        }).name
                      }
                    </span>
                  )}
                </TableCell>
                {!approvedList && (
                  <TableCell>
                    {moment(row.attributes.created_at.date).format(
                      DATE_TIME_FORMAT,
                    )}
                  </TableCell>
                )}
                {approvedList && (
                  <TableCell>
                    <Checkbox checked={memberActive(row)} disableRipple />
                  </TableCell>
                )}
                <TableCell>
                  {moment(row.attributes.updated_at.date).format(
                    DATE_TIME_FORMAT,
                  )}
                </TableCell>
                <ActionsTableCell row={row} />
              </TableRow>
            ))}
          </TableBody>
          {data.length ? (
            <TableFooter
              colSpan={Object.keys(cells).length + 1}
              meta={meta}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setRowsPerPage}
            />
          ) : null}
        </Table>
      </Paper>
    );
  }
}

MembersTable.defaultProps = {
  delegations: {
    attributes: {
      delegation: [],
    },
  },
};

MembersTable.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  resetFilter: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  delegations: PropTypes.object,
  deleteModel: PropTypes.func.isRequired,
  loadModels: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  delegations: makeSelectDelegations(),
  roles: makeRolesSelector(),
  user: makeUserSelector(),
  sidebarIsOpen: makeSelectSidebarIsOpen(),
});

export default connect(mapStateToProps)(
  withStyles(styles)(withCrud('membersTable', 'members', MembersTable, false)),
);
