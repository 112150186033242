import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty, isEqual } from 'lodash';
import withCrud from 'containers/ModelWrapper';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import FileCopy from '@material-ui/icons/FileCopy';

import SortableCells from 'components/SortableCells';
import TableToolbar from 'components/TableToolbar';
import TableFooter from 'components/TableFooter';
import ConfirmDialog from 'components/ConfirmDialog';
import Authorization from 'utils/authorization';
import FileUpload from 'containers/FileUpload';

import MediaFilter from './MediaFilter';
import {
  TableContainerOpenedStyles,
  TableContainerStyles,
} from '../../mui-theme';
import { DATE_TIME_FORMAT, ROLES_ADMINS } from '../../utils/constants';
import { makeSelectSidebarIsOpen } from '../Layout/selectors';
import { MESSAGE_TYPES } from '../Snackbar/constants';
import { setMediaErrors } from './actions';
import { setMessage } from '../Snackbar/actions';

const styles = () => ({
  rootClosed: TableContainerStyles,
  rootOpened: TableContainerOpenedStyles,
});

class MediaTable extends React.Component {
  constructor(props) {
    super(props);
    this.createSortHandler = this.createSortHandler.bind(this);
    this.state = {
      filterOpen: false,
      deleteDialogOpen: false,
      dialogTitle: '',
    };
  }

  componentWillMount() {
    this.props.setSorting({}, 'created_at', 'desc');
    this.loadMedia(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.loadMedia(nextProps);
      return;
    }
    if (!isEqual(this.props.params, nextProps.params)) {
      this.props.loadModels();
    }
  }

  loadMedia(props) {
    props.loadModels();
  }

  toggleFilter = () => {
    this.setState(state => ({ filterOpen: !state.filterOpen }));
  };

  createSortHandler(property) {
    return event => {
      const { sortBy, sortDirection } = this.props;
      let direction = 'asc';
      if (property === sortBy) {
        direction = sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.props.setSorting(event, property, direction);
    };
  }

  openDialog(file) {
    this.setState(() => ({
      deleteDialogOpen: true,
      destroyMedia: file,
      dialogTitle: `Are you sure you want to remove "${file.attributes.name} ${
        file.attributes.name
      }" file?`,
    }));
  }

  copyToClipboard = link => {
    navigator.clipboard.writeText(link);
    this.props.setMessage({
      type: MESSAGE_TYPES.SUCCESS,
      value: "'Copy to clipboard' action was successful!",
    });
  };

  render() {
    const {
      models,
      classes,
      setPageNumber,
      sortBy,
      sortDirection,
      setRowsPerPage,
      setFilter,
      params,
      sidebarIsOpen,
    } = this.props;

    const { data, meta } = models;

    if (isEmpty(meta)) {
      return null;
    }

    const cells = {
      Thumbnail: null,
      Name: 'name',
      'Created date': 'created_at',
    };

    const tableTitle = 'Media list';

    const AdminAuthorization = Authorization(ROLES_ADMINS);

    const ActionsTableCell = AdminAuthorization(({ row }) => (
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <IconButton
          aria-label="Link"
          onClick={() => this.copyToClipboard(row.attributes.link)}
        >
          <Icon fontSize="small">link</Icon>
        </IconButton>
        <IconButton aria-label="Delete" onClick={() => this.openDialog(row)}>
          <Icon fontSize="small">delete</Icon>
        </IconButton>
      </TableCell>
    ));

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;

    return (
      <Paper className={rootClass}>
        <ConfirmDialog
          open={this.state.deleteDialogOpen}
          onClose={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          cancelAction={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          confirmAction={() => {
            this.props.deleteModel(this.state.destroyMedia);
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          title={this.state.dialogTitle}
          description="This action can not be undone."
        />
        <TableToolbar title={tableTitle} onClick={this.toggleFilter} />
        <Table className={classes.table} padding="checkbox">
          <TableHead>
            <TableRow>
              <SortableCells
                sortDirection={sortDirection}
                sortBy={sortBy}
                onClick={this.createSortHandler}
                cells={cells}
              />
              <TableCell style={{ maxWidth: 100 }}>
                <FileUpload
                  accept="image/*,video/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.txt"
                  value={null}
                  visibility="media"
                  attachFile={this.props.loadModels}
                  standalone
                />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.filterOpen && (
              <MediaFilter params={params} setFilter={setFilter} />
            )}
            {data.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  {row.attributes.fileType === 'image' && (
                    <img src={row.attributes.dimensions.thumbnail} alt="" />
                  )}
                  {row.attributes.fileType === 'video' && (
                    <video
                      width="300"
                      height="200"
                      src={row.attributes.link}
                      controls
                    >
                      <track kind="captions" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  {row.attributes.fileType === 'application' && <FileCopy />}
                </TableCell>
                <TableCell>{row.attributes.name}</TableCell>
                <TableCell>
                  {moment(row.attributes.created_at.date).format(
                    DATE_TIME_FORMAT,
                  )}
                </TableCell>
                <ActionsTableCell row={row} />
              </TableRow>
            ))}
          </TableBody>
          {data.length ? (
            <TableFooter
              colSpan={Object.keys(cells).length + 1}
              meta={meta}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setRowsPerPage}
            />
          ) : null}
        </Table>
      </Paper>
    );
  }
}

MediaTable.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  models: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  deleteModel: PropTypes.func.isRequired,
  loadModels: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  setMessage: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  sidebarIsOpen: makeSelectSidebarIsOpen(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setMediaErrors(errors)),
  setMessage: message => dispatch(setMessage(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withCrud('mediaTable', 'files', MediaTable, false)));
