import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link as LinkTo } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { isEmpty, cloneDeep, isEqual } from 'lodash';
import { Helmet } from 'react-helmet';

import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { BACKEND_DATE } from 'utils/constants';
import withCrud from 'containers/ModelWrapper';
import DateSelector from 'components/DatePicker';
import { getIncludedModelAttributesOfType } from '../../utils/fractal';
import {
  makeSelectAreaType,
  makeSelectEnumValuesOf,
} from '../../containers/App/selectors';
import MemberFunctionTeamSelector from './MemberFunctionTeamSelector';
import DisplayName from './DisplayMemberName';
import { memberFunctionSkeleton, memberFunctionErrors } from './constants';
import ContainerPaper from '../../components/ContainerPaper';
import { PageRootContainerStyles } from '../../mui-theme';

const styles = theme => ({
  root: PageRootContainerStyles,
  button: {
    margin: `${theme.spacing.unit}px`,
  },
  datePicker: {
    marginBottom: '25px',
  },
});

const teamTypeOptions = teamTypes =>
  Object.entries(teamTypes.attributes).filter(item =>
    ['COUNCIL', 'EXECUTIVE_COMMITTEE', 'WORKING_GROUP'].includes(item[0]),
  );

class MemberFunctionForm extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    errors: cloneDeep(memberFunctionErrors),
    teamType: '',
  };

  constructor(props) {
    super(props);
    this.setDate = this.setDate.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.teamTypeChanged = this.teamTypeChanged.bind(this);
  }

  componentWillMount() {
    this.preLoadModel(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.model, this.props.model)) {
      if (isEmpty(nextProps.model)) {
        this.props.history.push(
          `/admin/members/edit/${this.props.match.params.memberId}`,
        );
        return;
      }
      if (
        this.state.startDate === null &&
        nextProps.model.attributes.start_date
      ) {
        this.setState({
          startDate: moment(nextProps.model.attributes.start_date),
        });
      }
      if (this.state.endDate === null && nextProps.model.attributes.end_date) {
        this.setState({
          endDate: moment(nextProps.model.attributes.end_date),
        });
      }
      const includes = getIncludedModelAttributesOfType(
        'functional-areas',
        nextProps.modelIncludes,
      );
      if (isEmpty(this.state.teamType) && includes.length) {
        this.setState({ teamType: includes[0].area_type });
      }
    }

    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.preLoadModel(nextProps);
    }
  }

  preLoadModel(props) {
    const { match } = props;
    this.props.setModel(cloneDeep(memberFunctionSkeleton));
    if (parseInt(match.params.functionId, 10)) {
      this.props.showModel(match.params.functionId);
    }
  }

  inputChanged(event) {
    const selectedItem = cloneDeep(this.props.model);
    selectedItem.attributes[event.target.name] = event.target.value;
    this.props.setModel(selectedItem);
  }

  teamTypeChanged(event) {
    const selectedItem = cloneDeep(this.props.model);
    selectedItem.attributes.team = '';
    this.setState({ teamType: event.target.value });
    this.props.setModel(selectedItem);
  }

  submit() {
    const { model, match } = this.props;
    const { startDate, endDate, teamType } = this.state;
    const functionErrors = cloneDeep(memberFunctionErrors);

    const requiredFields = {
      team: 'Team',
      function_enum_id: 'Member function',
    };
    Object.entries(requiredFields).forEach(item => {
      if (!model.attributes[item[0]]) {
        functionErrors[item[0]] = `${item[1]} field is required`;
      }
    });

    if (!teamType) {
      functionErrors.team_type = 'Team type field is required';
    }

    if (!isEqual(functionErrors, memberFunctionErrors)) {
      this.setState({ errors: functionErrors });
      return;
    }
    const selectedItem = cloneDeep(this.props.model);
    selectedItem.attributes.start_date = null;
    selectedItem.attributes.end_date = null;
    selectedItem.attributes.member_id = match.params.memberId;
    if (startDate) {
      selectedItem.attributes.start_date = startDate.format(BACKEND_DATE);
    }
    if (endDate) {
      selectedItem.attributes.end_date = endDate.format(BACKEND_DATE);
    }
    this.props.setModel(selectedItem);
    this.props.submitModel();
  }

  setDate(event) {
    const { state } = this;
    if (!event.target.value) {
      state[event.target.name] = null;
      this.setState(state);
      return;
    }
    if (!state[event.target.name]) {
      state[event.target.name] = moment();
    }
    state[event.target.name].year(event.target.value.year());
    state[event.target.name].month(event.target.value.month());
    state[event.target.name].dayOfYear(event.target.value.dayOfYear());
    this.setState(state);
  }

  render() {
    const { model, classes, teamTypes, enumValues, match } = this.props;
    const { startDate, endDate, errors, teamType } = this.state;

    if (!model || isEmpty(model)) {
      return null;
    }

    const title = model.id ? 'Edit Function' : 'Add Function';

    const teamTypeSelectorDisabled =
      model.id && teamType === 'COUNTRY_WITH_DELEGATION';

    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <ContainerPaper>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.submit();
            }}
          >
            <DisplayName memberId={match.params.memberId} />
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(errors.team_type.length)}
            >
              <InputLabel htmlFor="team_type">Team type</InputLabel>
              <Select
                id="team_type"
                name="team_type"
                value={teamType}
                onChange={this.teamTypeChanged}
                disabled={teamTypeSelectorDisabled}
              >
                {teamTypeOptions(teamTypes).map(item => (
                  <MenuItem value={item[0]} key={item[0]}>
                    {item[1]}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="value-error-text">
                {errors.team_type}
              </FormHelperText>
            </FormControl>
            <MemberFunctionTeamSelector
              errors={errors}
              inputChanged={this.inputChanged}
              memberFunction={model}
              areaType={teamType}
            />
            <FormControl fullWidth error={Boolean(errors.function_enum_id)}>
              <InputLabel htmlFor="function_enum_id">
                Member function
              </InputLabel>
              <Select
                id="function_enum_id"
                name="function_enum_id"
                value={model.attributes.function_enum_id}
                onChange={this.inputChanged}
              >
                {enumValues.map(item => (
                  <MenuItem value={item.column_id} key={item.column_id}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="value-error-text">
                {errors.function_enum_id}
              </FormHelperText>
            </FormControl>
            <DateSelector
              className={classes.datePicker}
              label="Start Date"
              value={startDate}
              onChange={date => {
                const event = {
                  target: {
                    name: 'startDate',
                    value: date || '',
                  },
                };
                this.setDate(event);
              }}
            />
            <DateSelector
              className={classes.datePicker}
              label="End Date"
              value={endDate}
              onChange={date => {
                const event = {
                  target: {
                    name: 'endDate',
                    value: date || '',
                  },
                };
                this.setDate(event);
              }}
            />
            <FormGroup row>
              <LinkTo to={`/admin/members/edit/${match.params.memberId}`}>
                <Button color="secondary" className={classes.button}>
                  Cancel
                </Button>
              </LinkTo>
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </FormGroup>
          </form>
        </ContainerPaper>
      </React.Fragment>
    );
  }
}

MemberFunctionForm.defaultProps = {
  modelIncludes: [],
};

MemberFunctionForm.propTypes = {
  match: PropTypes.object,
  model: PropTypes.object,
  classes: PropTypes.object.isRequired,
  submitModel: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
  teamTypes: PropTypes.object.isRequired,
  enumValues: PropTypes.array.isRequired,
  modelIncludes: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  teamTypes: makeSelectAreaType(),
  enumValues: makeSelectEnumValuesOf('member_functions'),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withRouter,
  withConnect,
)(
  withStyles(styles)(
    withCrud(
      'memberFunctionForm',
      'member-functions',
      MemberFunctionForm,
      false,
    ),
  ),
);
