import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { isEmpty, cloneDeep, isEqual } from 'lodash';

import TextInput from 'components/TextInput';
import { BACKEND_DATE_TIME_FORMAT } from 'utils/constants';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withCrud from 'containers/ModelWrapper';
import TimePicker from 'containers/TimePicker';
import DateSelector from 'components/DatePicker';
import { auctionSkeleton, auctionErrors as errors } from './constants';
import ItemGridList from './ItemGridList';
import { PageRootContainerStyles } from '../../mui-theme';
import ContainerPaper from '../../components/ContainerPaper';

const styles = theme => ({
  root: PageRootContainerStyles,
  button: {
    margin: theme.spacing.unit,
  },
  datePicker: {
    marginBottom: '25px',
  },
});

class AuctionForm extends React.Component {
  state = {
    startTime: null,
    endTime: null,
    auctionErrors: cloneDeep(errors),
  };
  constructor(props) {
    super(props);
    this.setDate = this.setDate.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.setRelationShips = this.setRelationShips.bind(this);
    this.nameChanged = this.nameChanged.bind(this);
  }
  componentWillMount() {
    this.props.setIncludes('items');
    this.preLoadModel(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.props.history.push('/admin/auctions');
      return;
    }

    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.setState({
        startTime: null,
        endTime: null,
      });
      this.preLoadModel(nextProps);
      return;
    }
    if (!isEqual(nextProps.model, this.props.model)) {
      if (
        this.state.startTime === null &&
        nextProps.model.attributes.start_time
      ) {
        this.setState({
          startTime: moment(nextProps.model.attributes.start_time),
          endTime: moment(nextProps.model.attributes.end_time),
        });
      }
    }
  }

  preLoadModel(props) {
    const { match } = props;
    this.props.setModel(cloneDeep(auctionSkeleton));
    if (parseInt(match.params.itemId, 10)) {
      this.props.showModel(match.params.itemId);
    }
  }

  inputChanged(event) {
    const selectedItem = cloneDeep(this.props.model);
    selectedItem.attributes[event.target.name] = event.target.checked;
    this.props.setModel(selectedItem);
  }

  nameChanged(event) {
    const selectedItem = cloneDeep(this.props.model);
    selectedItem.attributes[event.target.name] = event.target.value;
    this.props.setModel(selectedItem);
  }

  submit() {
    const { model } = this.props;
    const { startTime, endTime } = this.state;
    const itemErrors = cloneDeep(errors);

    if (!startTime) {
      this.props.showErrorToast('You must select start date and time!');
      return;
    }

    if (!endTime) {
      this.props.showErrorToast('You must select end date and time!');
      return;
    }

    if (startTime.format('X') > endTime.format('X')) {
      this.props.showErrorToast('End date must be after start date!');
      return;
    }

    if (!model.attributes.name.length) {
      itemErrors.name = 'Name field is required';
    }
    if (!isEqual(itemErrors, errors)) {
      this.setState({ auctionErrors: itemErrors });
      return;
    }
    const selectedItem = cloneDeep(this.props.model);
    selectedItem.attributes.start_time = this.state.startTime.format(
      BACKEND_DATE_TIME_FORMAT,
    );
    selectedItem.attributes.end_time = this.state.endTime.format(
      BACKEND_DATE_TIME_FORMAT,
    );
    this.props.setModel(selectedItem);
    this.props.submitModel();
  }

  setDate(event) {
    const { state } = this;
    if (!state[event.target.name]) {
      state[event.target.name] = moment();
    }
    state[event.target.name] = moment(event.target.value);

    this.setState(state);
  }

  setTime(event) {
    const { state } = this;
    if (!state[event.target.name]) {
      state[event.target.name] = moment();
    }
    if (event.target.value) {
      state[event.target.name].hour(event.target.value.hour());
      state[event.target.name].minute(event.target.value.minute());
    }

    this.setState(state);
  }

  setRelationShips(selectedItems) {
    const assignedItems = selectedItems.map(item => ({
      id: item,
      type: 'items',
    }));
    const selectedItem = cloneDeep(this.props.model);
    selectedItem.relationships = { items: { data: assignedItems } };
    this.props.setModel(selectedItem);
  }

  render() {
    const { model, classes } = this.props;
    const { startTime, endTime, auctionErrors } = this.state;

    if (!model || isEmpty(model)) {
      return null;
    }

    let value = [];
    if (model.relationships && model.relationships.items) {
      value = model.relationships.items.data.map(item => item.id);
    }

    return (
      <ContainerPaper className={classes.paper}>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <TextInput
            model={model}
            modelErrors={auctionErrors}
            attribute="name"
            label="Name"
            onChange={this.nameChanged}
          />
          <DateSelector
            className={classes.datePicker}
            label="Start Date"
            value={startTime}
            onChange={date => {
              const event = {
                target: {
                  name: 'startTime',
                  value: date || '',
                },
              };
              this.setDate(event);
            }}
          />
          <TimePicker
            name="startTime"
            label="Start Time"
            value={startTime}
            onChange={date => {
              const event = {
                target: {
                  name: 'startTime',
                  value: date || '',
                },
              };
              this.setTime(event);
            }}
          />
          <DateSelector
            className={classes.datePicker}
            label="End Date"
            value={endTime}
            onChange={date => {
              const event = {
                target: {
                  name: 'endTime',
                  value: date || '',
                },
              };
              this.setDate(event);
            }}
          />
          <TimePicker
            name="endTime"
            label="End Time"
            value={endTime}
            onChange={date => {
              const event = {
                target: {
                  name: 'endTime',
                  value: date || '',
                },
              };
              this.setTime(event);
            }}
          />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(model.attributes.live)}
                  onChange={this.inputChanged}
                  value="live"
                  name="live"
                />
              }
              label="Live"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(model.attributes.enable_notification)}
                  onChange={this.inputChanged}
                  value="enable_notification"
                  name="enable_notification"
                />
              }
              label="Enable notification"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!model.attributes.notify_winner}
                  onChange={() => {
                    this.inputChanged({
                      target: {
                        name: 'notify_winner',
                        checked: !model.attributes.notify_winner,
                      },
                    });
                  }}
                  value="notify_winner"
                  name="notify_winner"
                />
              }
              label="Do not notify winners"
            />
          </div>
          <ItemGridList onChange={this.setRelationShips} value={value} />
          <FormGroup row>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </FormGroup>
        </form>
      </ContainerPaper>
    );
  }
}

AuctionForm.propTypes = {
  match: PropTypes.object,
  model: PropTypes.object,
  classes: PropTypes.object.isRequired,
  submitModel: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
  setIncludes: PropTypes.func.isRequired,
  showErrorToast: PropTypes.func.isRequired,
};

export default compose(withRouter)(
  withStyles(styles)(
    withCrud('auctionFormPage', 'auctions', AuctionForm, false),
  ),
);
