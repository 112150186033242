import { cloneDeep } from 'lodash';
import { fromJS } from 'immutable';
import { DEFAULT_ACTION, SET_MEMBER_ERROR, memberErrors } from './constants';

export const initialState = fromJS({
  memberErrors: cloneDeep(memberErrors),
});

function membersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MEMBER_ERROR:
      return state.set('memberErrors', action.errors);
    case DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export default membersReducer;
