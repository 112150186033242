export const DEFAULT_ACTION = 'app/PasswordResetPage/DEFAULT_ACTION';
export const SET_PASSWORD_RESET_ERROR =
  'app/PasswordResetPage/SET_PASSWORD_RESET_ERROR';

export const passwordResetSkeleton = Object.freeze({
  id: null,
  type: 'passwordReset',
  attributes: {
    email: '',
    password: '',
    password_confirmation: '',
    token: '',
  },
});

export const passwordResetErrors = {
  email: '',
  password: '',
  password_confirmation: '',
};
