import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { isEqual } from 'lodash';

import { setMessage } from 'containers/Snackbar/actions';
import { MESSAGE_TYPES } from 'containers/Snackbar/constants';
import Loader from 'components/Loader';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import {
  makeSelectParams,
  makeSelectModels,
  makeSelectSortBy,
  makeSelectSortDirection,
  makeSelectModel,
  makeSelectModelIncludes,
  makeSelectLoading,
  makeSelectSubmitResponse,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import {
  setModelType,
  resetReducer,
  loadModels,
  setPageNumber,
  setRowsPerPage,
  setSorting,
  resetFilter,
  setFilter,
  setModel,
  submitModel,
  deleteModel,
  showModel,
  setIncludes,
  actionPreProcessor,
  setModelIncludes,
} from './actions';

const withCrud = (
  reducerKey,
  modelType,
  Component = false,
  load = true,
  LoaderComponent = Loader,
) => {
  class ModelWrapper extends React.Component {
    componentWillMount() {
      this.props.setModelType(modelType);
      if (load) {
        this.props.loadModels();
      }
    }

    componentWillReceiveProps(nextProps) {
      if (!isEqual(this.props.params, nextProps.params) && load) {
        this.props.loadModels();
      }
    }

    componentWillUnmount() {
      this.props.resetReducer();
    }

    render() {
      return (
        <React.Fragment>
          {LoaderComponent && this.props.loading && <LoaderComponent />}
          {Component && <Component {...this.props} />}
        </React.Fragment>
      );
    }
  }

  ModelWrapper.propTypes = {
    setModelType: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    resetReducer: PropTypes.func.isRequired,
    loadModels: PropTypes.func.isRequired,
    setPageNumber: PropTypes.func.isRequired,
    setRowsPerPage: PropTypes.func.isRequired,
    models: PropTypes.object.isRequired,
    submitResponse: PropTypes.object.isRequired,
    setSorting: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    setModel: PropTypes.func.isRequired,
    submitModel: PropTypes.func.isRequired,
    modelIncludes: PropTypes.array,
  };

  const mapStateToProps = createStructuredSelector({
    params: makeSelectParams(reducerKey),
    models: makeSelectModels(reducerKey),
    loading: makeSelectLoading(reducerKey),
    model: makeSelectModel(reducerKey),
    modelIncludes: makeSelectModelIncludes(reducerKey),
    sortDirection: makeSelectSortDirection(reducerKey),
    sortBy: makeSelectSortBy(reducerKey),
    submitResponse: makeSelectSubmitResponse(reducerKey),
  });

  const mapDispatchToProps = dispatch => ({
    setModelType: model =>
      dispatch(actionPreProcessor(setModelType(model), reducerKey)),
    resetReducer: () =>
      dispatch(actionPreProcessor(resetReducer(), reducerKey)),
    loadModels: () => dispatch(actionPreProcessor(loadModels(), reducerKey)),
    setPageNumber: (event, page) =>
      dispatch(actionPreProcessor(setPageNumber(event, page), reducerKey)),
    setRowsPerPage: page =>
      dispatch(actionPreProcessor(setRowsPerPage(page), reducerKey)),
    setSorting: (event, property, direction) =>
      dispatch(
        actionPreProcessor(setSorting(event, property, direction), reducerKey),
      ),
    resetFilter: filters =>
      dispatch(actionPreProcessor(resetFilter(filters), reducerKey)),
    setFilter: event =>
      dispatch(actionPreProcessor(setFilter(event), reducerKey)),
    setModel: model =>
      dispatch(actionPreProcessor(setModel(model), reducerKey)),
    setModelIncludes: included =>
      dispatch(actionPreProcessor(setModelIncludes(included), reducerKey)),
    submitModel: () => dispatch(actionPreProcessor(submitModel(), reducerKey)),
    deleteModel: model =>
      dispatch(actionPreProcessor(deleteModel(model), reducerKey)),
    showModel: modelId =>
      dispatch(actionPreProcessor(showModel(modelId), reducerKey)),
    setIncludes: includes =>
      dispatch(actionPreProcessor(setIncludes(includes), reducerKey)),
    showErrorToast: error =>
      dispatch(
        setMessage({
          type: MESSAGE_TYPES.ERROR,
          value: error,
        }),
      ),
    showInfoToast: info =>
      dispatch(
        setMessage({
          type: MESSAGE_TYPES.INFO,
          value: info,
        }),
      ),
  });

  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );

  const withReducer = injectReducer({
    key: reducerKey,
    reducer: reducer(reducerKey),
  });
  const withSaga = injectSaga({
    key: reducerKey,
    saga: saga(reducerKey),
  });

  return compose(
    withReducer,
    withSaga,
    withConnect,
  )(ModelWrapper);
};

export default withCrud;
