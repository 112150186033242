import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';

import {
  SET_ITEM_ERROR,
  itemErrors,
  LOAD_AUCTIONS,
  LOAD_AUCTIONS_SUCCESS,
  LOAD_AUCTIONS_ERROR,
} from './constants';

export const initialState = fromJS({
  isLoading: true,
  itemErrors: cloneDeep(itemErrors),
  auctions: { data: [] },
});

const itemFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AUCTIONS:
      return state.set('isLoading', true);
    case LOAD_AUCTIONS_SUCCESS:
      return state.set('isLoading', false).set('auctions', action.response);
    case LOAD_AUCTIONS_ERROR:
      return state
        .set('isLoading', false)
        .set('errorMessage', action.error)
        .set('auctions', []);
    case SET_ITEM_ERROR:
      return state.set('itemErrors', action.errors);
    default:
      return state;
  }
};

export default itemFormReducer;
