import {
  LOAD_CONFIG,
  LOAD_CONFIG_SUCCESS,
  SET_ERROR_OBJECT,
} from './constants';

export const loadConfig = () => ({
  type: LOAD_CONFIG,
});

export const loadConfigSuccess = config => ({
  type: LOAD_CONFIG_SUCCESS,
  config,
});

export const setErrorObject = error => ({
  type: SET_ERROR_OBJECT,
  error,
});
