import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';

import { SET_MESSAGE, messageSkeleton } from './constants';

export const initialState = fromJS({
  message: cloneDeep(messageSkeleton),
});

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return state.set('message', action.message);
    default:
      return state;
  }
};

export default snackbarReducer;
