import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the itemPage state domain
 */

const selectItemFormDomain = state => state.get('itemForm', initialState);

const makeSelectIsLoading = () =>
  createSelector(selectItemFormDomain, substate => substate.toJS().isLoading);

const makeSelectAuctions = () =>
  createSelector(selectItemFormDomain, substate => substate.toJS().auctions);

const makeSelectItemErrors = () =>
  createSelector(selectItemFormDomain, substate => substate.toJS().itemErrors);

export { makeSelectIsLoading, makeSelectAuctions, makeSelectItemErrors };
