import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { Link as LinkTo } from 'react-router-dom';
import classNames from 'classnames';
import AnchorComponent from './Anchor';

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
    color: '#000',
  },
  a: {
    color: '#000',
  },
  whiteSpace: {
    whiteSpace: 'normal',
  },
});

/* eslint-disable jsx-a11y/anchor-is-valid */
const MenuItem = ({ classes, url, title, icon, external, selected }) => {
  const Link = external ? AnchorComponent : LinkTo;
  return (
    <Link to={url} className={classes.a}>
      <ListItem
        button
        selected={selected}
        className={classNames({ [classes.nested]: !icon }, classes.whiteSpace)}
      >
        <ListItemIcon>
          <Icon>{icon || 'keyboard_arrow_right'}</Icon>
        </ListItemIcon>
        <ListItemText inset primary={title} />
      </ListItem>
    </Link>
  );
};

MenuItem.defaultProps = {
  icon: null,
};

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  external: PropTypes.bool,
  selected: PropTypes.bool,
};

export default withStyles(styles)(MenuItem);
