export const LOAD_ENUM_TYPES = 'app/EnumPage/LOAD_ENUM_TYPES';
export const LOAD_ENUM_TYPES_SUCCESS = 'app/EnumPage/LOAD_ENUM_TYPES_SUCCESS';
export const LOAD_ENUM_TYPES_ERROR = 'app/EnumPage/LOAD_ENUM_TYPES_ERROR';

export const SET_ENUM_TYPE = 'app/EnumPage/SET_ENUM_VALUES';

export const LOAD_ENUM_VALUES = 'app/EnumPage/LOAD_ENUM_VALUES';
export const LOAD_ENUM_VALUES_SUCCESS = 'app/EnumPage/LOAD_ENUM_VALUES_SUCCESS';
export const LOAD_ENUM_VALUES_ERROR = 'app/EnumPage/LOAD_ENUM_VALUES_ERROR';

export const SET_PAGE_NUMBER = 'app/EnumPage/SET_PAGE_NUMBER';
export const SET_ROWS_PER_PAGE = 'app/EnumPage/SET_ROWS_PER_PAGE';

export const SET_ENUM_VALUE = 'app/EnumPage/SET_ENUM_VALUE';
export const SET_ENUM_ERROR = 'app/EnumPage/SET_ENUM_ERROR';
export const SUBMIT_ENUM = 'app/EnumPage/SUBMIT_ENUM';
export const SUBMIT_ENUM_SUCCESS = 'app/EnumPage/SUBMIT_ENUM_SUCCESS';
export const SUBMIT_ENUM_ERROR = 'app/EnumPage/SUBMIT_ENUM_ERROR';

export const enumSkeleton = Object.freeze({
  id: null,
  type: 'enum_values',
  attributes: {
    enum_type_id: 0,
    value: '',
    active: 1,
  },
});

export const enumErrors = {
  value: '',
};
