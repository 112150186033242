import { fromJS } from 'immutable';

import { getIncludedModelAttributesOfType } from 'utils/fractal';
import {
  USER_LOGIN,
  LOAD_USER,
  INPUT_CHANGE,
  USER_LOGOUT,
  USER_LOGIN_ERROR,
  DELETE_USER,
} from './constants';

export const initialState = fromJS({
  user: {},
  email: '',
  password: '',
  loginError: '',
  config: [],
  roles: [],
});

const authButtonReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return state;
    case LOAD_USER:
      if (!action.user) {
        return state;
      }
      return state
        .set(
          'user',
          Object.assign(
            { id: action.user.data.data.id },
            action.user.data.data.attributes,
          ),
        )
        .set(
          'roles',
          getIncludedModelAttributesOfType(
            'roles',
            action.user.data.included,
          ).map(item => item.name),
        )
        .set('loginError', '');
    case INPUT_CHANGE:
      return state
        .set(action.input.target.name, action.input.target.value)
        .set('loginError', '');
    case USER_LOGIN_ERROR:
      return state.set('loginError', action.error.message);
    case USER_LOGOUT:
    case DELETE_USER:
      localStorage.clear();
      return initialState;
    default:
      return state;
  }
};

export default authButtonReducer;
