import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the functionalAreaPage state domain
 */

const selectFunctionalAreaPageDomain = state =>
  state.get('functionalAreaPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by FunctionalAreaPage
 */

const makeSelectFunctionalAreaPage = () =>
  createSelector(selectFunctionalAreaPageDomain, substate => substate.toJS());

const makeSelectFunctionalAreas = () =>
  createSelector(
    selectFunctionalAreaPageDomain,
    substate => substate.toJS().functionalAreas.data,
  );

const makeSelectMetaData = () =>
  createSelector(
    selectFunctionalAreaPageDomain,
    substate => substate.toJS().functionalAreas.meta,
  );

const makeSelectParams = () =>
  createSelector(
    selectFunctionalAreaPageDomain,
    substate => substate.toJS().params,
  );

const makeSelectFunctionalArea = () =>
  createSelector(
    selectFunctionalAreaPageDomain,
    substate => substate.toJS().selectedFunctionalArea,
  );

const makeSelectFunctionalAreaErrors = () =>
  createSelector(
    selectFunctionalAreaPageDomain,
    substate => substate.toJS().functionalAreaErrors,
  );

export default makeSelectFunctionalAreaPage;
export {
  selectFunctionalAreaPageDomain,
  makeSelectFunctionalAreas,
  makeSelectParams,
  makeSelectMetaData,
  makeSelectFunctionalArea,
  makeSelectFunctionalAreaErrors,
};
