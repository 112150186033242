import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { cloneDeep, isEmpty, isEqual, get } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Step, StepButton, Stepper } from '@material-ui/core';

import StepContent from 'components/StepContent';
import ItemTypeSelector from 'components/ItemTypeSelector';
import withCrud from 'containers/ModelWrapper';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { itemTypes, storableItemTypes } from 'utils/constants';
import reducer from './reducer';
import saga from './saga';
import { makeSelectAuctions, makeSelectItemErrors } from './selectors';
import { loadAuctions, setItemErrors } from './actions';
import { itemErrors as resetErrors, itemSkeleton } from './constants';
import ItemFormForHunts from './ItemFormForHunts';
import ItemFormForObjects from './ItemFormForObjects';
import { TableContainerStyles } from '../../mui-theme';
import BidsTable from './BidsTable';

const styles = theme => ({
  root: TableContainerStyles,
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

let historyListen = null;

class ItemForm extends React.Component {
  state = {
    activeStep: 0,
  };

  constructor(props) {
    super(props);
    this.refreshBids = this.refreshBids.bind(this);
  }

  componentWillMount() {
    this.props.setIncludes('auctions');
    this.props.loadAuctions();
    this.preLoadModel(this.props);
    const currentState = get(window.history, 'state.state', false);
    historyListen = this.props.history.listen((location, action) => {
      if (
        currentState &&
        action === 'POP' &&
        location.pathname.indexOf('/item') !== -1
      ) {
        this.props.history.replace(location.pathname, currentState);
      }
    });
  }

  refreshBids() {
    this.preLoadModel(this.props);
  }

  componentWillUnmount() {
    window.setTimeout(() => {
      historyListen();
    }, 300);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.props.history.push(
        `/admin/items/${itemTypes[this.props.model.attributes.item_type].slug}`,
        get(window.history, 'state.state', null),
      );
      return;
    }

    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.preLoadModel(nextProps);
    }
  }
  preLoadModel(props) {
    const { match } = props;
    this.props.setModel(cloneDeep(itemSkeleton));
    this.props.setModelIncludes(cloneDeep([]));
    this.props.setErrors(cloneDeep(resetErrors));
    this.props.setIncludes(cloneDeep([]));
    this.setState({ activeStep: 0 });
    if (parseInt(match.params.itemId, 10)) {
      this.props.showModel(match.params.itemId);
      this.setState({ activeStep: 1 });
    }
  }
  inputChanged(event) {
    const selectedItem = cloneDeep(this.props.model);
    selectedItem.attributes[event.target.name] = event.target.value;
    selectedItem.included = this.props.modelIncludes;
    this.props.setModel(selectedItem);
  }

  handleNext = itemType => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
    const selectedItem = cloneDeep(itemSkeleton);
    selectedItem.attributes.item_type = itemType;
    this.props.setModel(selectedItem);
    this.props.setErrors(cloneDeep(resetErrors));
  };

  handleStepClick = step => {
    this.setState(state => ({
      activeStep: state.activeStep < step ? state.activeStep : step,
    }));
  };

  imageFilter = e => e.type === 'files' && e.attributes.fileType === 'image';

  submit() {
    const { model, modelIncludes } = this.props;
    const itemErrors = cloneDeep(resetErrors);

    Object.entries({
      name: 'Description',
      starting_bid: 'Starting bid',
    }).forEach(field => {
      if (!String(model.attributes[field[0]]).length) {
        itemErrors[field[0]] = `${field[1]} can not be empty`;
      }
    });

    const validFloatFormat = /^\d*(\.\d{2})?$/;
    if (!validFloatFormat.test(model.attributes.starting_bid)) {
      itemErrors.starting_bid =
        'Fee should contain numbers only and maximum 2 digits in the fractional part';
    }
    if (
      model.attributes.starting_bid < 0 ||
      model.attributes.starting_bid > 99999999.99
    ) {
      itemErrors.fee = 'Fee should have valid value';
    }
    const images = !isEmpty(modelIncludes)
      ? modelIncludes.filter(this.imageFilter)
      : [];

    if (!images.length) {
      itemErrors.file_id = 'You have to upload an image';
    }

    if (!parseInt(model.attributes.assigned_auctions, 10)) {
      itemErrors.assigned_auctions = 'You have to assign an auction';
    }

    if (!isEqual(itemErrors, resetErrors)) {
      this.props.setErrors(itemErrors);
      this.props.showErrorToast('Form data invalid');
      this.forceUpdate();
      return;
    }

    this.props.submitModel();
  }

  render() {
    const {
      model,
      auctions,
      itemErrors,
      classes,
      modelIncludes,
      history,
    } = this.props;
    const { activeStep } = this.state;
    const steps = ['Select Item Type', 'Fill Item Form', 'Create Item'];
    const itemTypeTitles = Object.assign(
      ...Object.entries(storableItemTypes).map(([k, v]) => ({ [k]: v.title })),
    );

    if (!model || isEmpty(model)) {
      return null;
    }

    if (isEmpty(model.attributes.assigned_auctions)) {
      model.attributes.assigned_auctions = isEmpty(modelIncludes)
        ? ''
        : modelIncludes.filter(e => e.type === 'auctions').map(e => e.id)[0];
    }

    const Form =
      model.attributes.item_type === 'OBJECT'
        ? ItemFormForObjects
        : ItemFormForHunts;
    return (
      <React.Fragment>
        <div className={classes.root}>
          {!model.id && (
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton
                    onClick={() => this.handleStepClick(index)}
                    completed={index < activeStep}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          )}
          <div>
            <div>
              {activeStep === 0 && (
                <StepContent
                  classes={classes}
                  title="Set Auction Item Type"
                  render={
                    <ItemTypeSelector
                      itemTypes={itemTypeTitles}
                      handleNext={this.handleNext}
                    />
                  }
                />
              )}
              {activeStep === 1 && (
                <StepContent
                  classes={classes}
                  title={
                    model.id
                      ? `Edit item`
                      : `Add new ${itemTypeTitles[model.attributes.item_type]}`
                  }
                  render={
                    <Form
                      model={model}
                      auctions={auctions}
                      itemErrors={itemErrors}
                      modelIncludes={modelIncludes}
                      setModel={this.props.setModel}
                      setModelIncludes={this.props.setModelIncludes}
                      showInfoToast={this.props.showInfoToast}
                      classes={classes}
                      history={history}
                      onSubmit={e => {
                        e.preventDefault();
                        this.submit();
                      }}
                      onChange={event => {
                        this.inputChanged(event);
                      }}
                    />
                  }
                />
              )}
              <BidsTable
                bidList={modelIncludes}
                refreshList={this.refreshBids}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ItemForm.propTypes = {
  match: PropTypes.object,
  model: PropTypes.object,
  loadAuctions: PropTypes.func.isRequired,
  auctions: PropTypes.object,
  itemErrors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  setModelIncludes: PropTypes.func.isRequired,
  showErrorToast: PropTypes.func.isRequired,
  showInfoToast: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
  setIncludes: PropTypes.func.isRequired,
  modelIncludes: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  auctions: makeSelectAuctions(),
  itemErrors: makeSelectItemErrors(),
});

const mapDispatchToProps = dispatch => ({
  loadAuctions: () => dispatch(loadAuctions()),
  setErrors: errors => dispatch(setItemErrors(errors)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'itemForm', reducer });
const withSaga = injectSaga({ key: 'itemForm', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect,
)(withStyles(styles)(withCrud('itemFormPage', 'items', ItemForm, false)));
