export function getIncludedModel(attributes, key, type, included) {
  return attributes[key]
    ? getIncludedModelById(attributes[key], type, included)
    : null;
}

export function getIncludedModelById(id, type, included) {
  if (!id) {
    return null;
  }
  const models = included.filter(
    item => item.type === type && parseInt(item.id, 10) === parseInt(id, 10),
  );

  return models[0] ? models[0].attributes : null;
}

export function getIncludedModelAttributesOfType(type, included) {
  return included
    .filter(item => item.type === type)
    .map(item => item.attributes);
}

export function getIncludedModelsOfType(type, included) {
  return included.filter(item => item.type === type);
}

export function getEnumTypeIndex(enumTypes, machineName) {
  return enumTypes.attributes.enumtype[
    enumTypes.attributes.enumtype.map(e => e.machine_name).indexOf(machineName)
  ].column_id;
}

export function getEnumValues(enumTypes, enumValues, machineName) {
  return Object.keys(enumValues.attributes.enumvalue).filter(
    key =>
      enumValues.attributes.enumvalue[key].enum_type_id ===
      getEnumTypeIndex(enumTypes, machineName),
  );
}

export function getEnumTypeArray(enumTypes, enumValues, machineName) {
  return getEnumValues(enumTypes, enumValues, machineName).map(key => ({
    label: enumValues.attributes.enumvalue[key].value,
    value: enumValues.attributes.enumvalue[key].column_id,
  }));
}
