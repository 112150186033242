import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEqual, isEmpty, cloneDeep } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import { ROWS_PER_PAGE_OPTIONS } from 'utils/constants';
import {
  loadFunctionalAreas,
  setPageNumber,
  setRowsPerPage,
  setFunctionalArea,
} from './actions';
import {
  makeSelectParams,
  makeSelectFunctionalAreas,
  makeSelectMetaData,
} from './selectors';
import { functionalAreaSkeleton } from './constants';
import { PageRootContainerStyles } from '../../mui-theme';

const styles = () => ({
  root: PageRootContainerStyles,
  table: {
    width: '100%',
  },
  cell: {
    fontSize: '16px',
  },
});

class FunctionalAreaTable extends React.Component {
  componentWillMount() {
    const { functionalAreas } = this.props;
    if (!functionalAreas.length) {
      this.props.loadFunctionalAreas();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.params, nextProps.params)) {
      this.props.loadFunctionalAreas();
    }
  }

  render() {
    const {
      rows,
      meta,
      classes,
      onPageChanged,
      onAddNew,
      onEditFunctionalArea,
    } = this.props;
    if (isEmpty(meta)) {
      return null;
    }
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.cell}>Name</TableCell>
              <TableCell className={classes.cell}>Country</TableCell>
              <TableCell className={classes.cell}>Delegation</TableCell>
              <TableCell className={classes.cell}>Group</TableCell>
              <TableCell className={classes.cell}>Member code</TableCell>
              <TableCell className={classes.cell}>Phone prefix</TableCell>
              <TableCell numeric className={classes.cell}>
                Active
              </TableCell>
              <TableCell>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={onAddNew}
                >
                  <Icon>add</Icon>
                  Add new
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.attributes.name}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={
                      row.attributes.area_type === 'COUNTRY' ||
                      row.attributes.area_type === 'COUNTRY_WITH_DELEGATION'
                    }
                    disableRipple
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={
                      row.attributes.area_type === 'COUNTRY_WITH_DELEGATION' ||
                      row.attributes.area_type === 'DELEGATION'
                    }
                    disableRipple
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={row.attributes.area_type === 'WORKING_GROUP'}
                    disableRipple
                  />
                </TableCell>
                <TableCell>{row.attributes.member_code}</TableCell>
                <TableCell>{row.attributes.phone_prefix}</TableCell>
                <TableCell>
                  <Checkbox checked={!!row.attributes.active} disableRipple />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="Edit"
                    onClick={() => onEditFunctionalArea(row)}
                  >
                    <Icon fontSize="small">edit_icon</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {!rows.length ? null : (
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={12}
                  count={meta.pagination.total}
                  rowsPerPage={meta.pagination.per_page}
                  page={meta.pagination.current_page - 1}
                  onChangePage={onPageChanged}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Paper>
    );
  }
}

FunctionalAreaTable.defaultProps = {
  functionalAreas: [],
  rows: [],
  meta: null,
};

FunctionalAreaTable.propTypes = {
  functionalAreas: PropTypes.array,
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array,
  meta: PropTypes.object,
  params: PropTypes.object.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  loadFunctionalAreas: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onAddNew: PropTypes.func.isRequired,
  onEditFunctionalArea: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  params: makeSelectParams(),
  rows: makeSelectFunctionalAreas(),
  meta: makeSelectMetaData(),
});

const mapDispatchToProps = dispatch => ({
  loadFunctionalAreas: () => dispatch(loadFunctionalAreas()),
  onPageChanged: (event, page) => dispatch(setPageNumber(page)),
  onChangeRowsPerPage: event => dispatch(setRowsPerPage(event)),
  onAddNew: () =>
    dispatch(setFunctionalArea(cloneDeep(functionalAreaSkeleton))),
  onEditFunctionalArea: selectedFunctionalArea =>
    dispatch(setFunctionalArea(cloneDeep(selectedFunctionalArea))),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(FunctionalAreaTable));
