import React from 'react';
import PropTypes from 'prop-types';

const Anchor = ({ to, className, children }) => (
  <a href={to} className={className} target="_blank">
    {children}
  </a>
);

Anchor.propTypes = {
  className: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Anchor;
