/*
 *
 * InvoiceGeneratePage constants
 *
 */

export const ADD_SELECTION = 'app/InvoiceSummaryPage/ADD_SELECTION';
export const REMOVE_SELECTION = 'app/InvoiceSummaryPage/REMOVE_SELECTION';
export const SUBMIT_FORM = 'app/InvoiceSummaryPage/SUBMIT_FORM';

export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 200];
