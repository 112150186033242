import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the members state domain
 */

const selectMemberFormDomain = state => state.get('memberForm', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Members
 */

const makeSelectMembers = () =>
  createSelector(selectMemberFormDomain, substate => substate.toJS());

const makeSelectMemberErrors = () =>
  createSelector(
    selectMemberFormDomain,
    substate => substate.toJS().memberErrors,
  );

export default makeSelectMembers;
export { selectMemberFormDomain, makeSelectMemberErrors };
