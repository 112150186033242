import {
  LOAD_ENUM_TYPES,
  LOAD_ENUM_TYPES_ERROR,
  LOAD_ENUM_TYPES_SUCCESS,
  SET_ENUM_TYPE,
  LOAD_ENUM_VALUES,
  LOAD_ENUM_VALUES_SUCCESS,
  LOAD_ENUM_VALUES_ERROR,
  SET_PAGE_NUMBER,
  SET_ROWS_PER_PAGE,
  SET_ENUM_VALUE,
  SET_ENUM_ERROR,
  SUBMIT_ENUM,
  SUBMIT_ENUM_SUCCESS,
  SUBMIT_ENUM_ERROR,
} from './constants';

export const loadEnumTypes = () => ({
  type: LOAD_ENUM_TYPES,
});

export const loadEnumTypesSuccessed = response => ({
  type: LOAD_ENUM_TYPES_SUCCESS,
  response,
});

export const loadEnumTypesError = error => ({
  type: LOAD_ENUM_TYPES_ERROR,
  error,
});

export const selectEnumType = id => ({
  type: SET_ENUM_TYPE,
  id,
});

export const loadEnumValues = () => ({
  type: LOAD_ENUM_VALUES,
});

export const setEnumType = machineName => ({
  type: SET_ENUM_TYPE,
  machineName,
});

export const loadEnumValuesSuccessed = response => ({
  type: LOAD_ENUM_VALUES_SUCCESS,
  response,
});

export const loadEnumValuesError = error => ({
  type: LOAD_ENUM_VALUES_ERROR,
  error,
});

export const setPageNumber = page => ({
  type: SET_PAGE_NUMBER,
  page,
});

export const setRowsPerPage = event => ({
  type: SET_ROWS_PER_PAGE,
  event,
});

export const setEnumValue = selectedEnum => ({
  type: SET_ENUM_VALUE,
  selectedEnum,
});

export const setEnumErrors = errors => ({
  type: SET_ENUM_ERROR,
  errors,
});

export const submitEnum = () => ({
  type: SUBMIT_ENUM,
});

export const submitEnumSuccess = enumValue => ({
  type: SUBMIT_ENUM_SUCCESS,
  enumValue,
});

export const submitEnumError = error => ({
  type: SUBMIT_ENUM_ERROR,
  error,
});
