import { call, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'react-router-redux';
import { get } from 'lodash';

import { makeRolesSelector } from 'containers/AuthButton/selectors';
import { makeSelectLocation } from 'containers/App/selectors';
import { userHasRole } from 'utils/userHasRole';
import { loadUser } from 'containers/AuthButton/actions';
import { BACKEND_URL_LOCAL } from 'utils/constants';
import { getEchoClient } from 'utils/axios';
import { USER_LOGIN } from './constants';
import { loginError } from './actions';
import { makeEmailSelector, makePasswordSelector } from './selectors';

export function* loginUser() {
  const email = yield select(makeEmailSelector());
  const password = yield select(makePasswordSelector());
  const attributes =
    email.indexOf('@') > -1 ? { email, password } : { name: email, password };
  try {
    const axiosInstance = axios.create();
    axiosInstance.defaults.baseURL =
      process.env.BACKEND_URL || BACKEND_URL_LOCAL;
    const response = yield call(() =>
      axiosInstance
        .post(`/auth/login`, {
          data: {
            type: 'login',
            attributes,
          },
        })
        .then(res => res)
        .catch(error => {
          switch (error.response.status) {
            case 404:
            case 401:
              throw new Error('Wrong login credentials');
            default:
              throw new Error('En error occured please try again later.');
          }
        }),
    );

    const token = response.data.data.attributes.access_token;
    localStorage.setItem('accessToken', token);
    getEchoClient().connector.options.auth.headers.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    yield put(loadUser(response));
    const roles = yield select(makeRolesSelector());
    const isAdminAuthenticated = userHasRole(roles, [
      'SYSTEM_ADMINISTRATOR',
      'MEMBERSHIP_ADMINISTRATOR',
    ]);
    const { search } = yield select(makeSelectLocation());
    const refRouteMap = {
      goToAuction: '/auction-home',
    };

    if (get(window.history, 'state.state.path', false)) {
      yield put(push(window.history.state.state.path));
    } else if (/ref=\w*/i.test(search)) {
      const refKey = search.match(/ref=(\w+)/)[1];
      const nextUrl = refRouteMap[refKey];
      yield put(push(nextUrl));
    } else if (isAdminAuthenticated) {
      yield put(push('/admin/members/approved'));
    } else {
      yield put(push('/'));
    }
  } catch (err) {
    yield put(loginError(err));
  }
}

// Individual exports for testing
export default function* user() {
  yield takeLatest(USER_LOGIN, loginUser);
}
