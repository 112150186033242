import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import { Link as LinkTo } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import withCrud from 'containers/ModelWrapper';
import Authorization from 'utils/authorization';
import { PageRootContainerStyles, paperWrapperStyles } from '../../mui-theme';
import ContainerPaper from '../../components/ContainerPaper';

const styles = () => ({
  root: PageRootContainerStyles,
  paperWrapper: paperWrapperStyles,
});

const canEdit = Authorization([
  'SYSTEM_ADMINISTRATOR',
  'MEMBERSHIP_ADMINISTRATOR',
]);

const EditButton = canEdit(() => (
  <LinkTo to="admin/auction-rules/edit">
    <Button color="primary">Edit</Button>
  </LinkTo>
));

class AuctionRules extends React.Component {
  componentWillMount() {
    this.props.showModel('auction-rules');
  }

  render() {
    const { model, classes } = this.props;

    if (!model || isEmpty(model)) {
      return null;
    }

    return (
      <div className={[classes.root, classes.paperWrapper]}>
        <Helmet>
          <title>Auction rules</title>
          <meta name="description" content="Description of AuctionRulesPage" />
        </Helmet>
        <ContainerPaper className={classes.paper}>
          <Typography variant="h5">Auction rules</Typography>
          <EditButton />

          <Typography
            component="div"
            dangerouslySetInnerHTML={{
              __html: model.attributes.content,
            }}
          />
        </ContainerPaper>
      </div>
    );
  }
}

AuctionRules.propTypes = {
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withCrud('auctionRules', 'pages', AuctionRules, false),
);
