/*
 *
 * Members actions
 *
 */

import { DEFAULT_ACTION, SET_EVENT_ERROR } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export const setEventErrors = errors => ({
  type: SET_EVENT_ERROR,
  errors,
});
