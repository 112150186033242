import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 4,
    maxWidth: '100%',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    minHeight: 300,
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
});

class MediaCarousel extends React.Component {
  state = {
    activeStep: 0,
  };

  setActiveStep = step =>
    this.setState({
      activeStep: step,
    });

  handleBack = () => this.setActiveStep(this.state.activeStep - 1);
  handleNext = () => this.setActiveStep(this.state.activeStep + 1);

  render() {
    const { classes, files } = this.props;
    const { activeStep } = this.state;
    const maxSteps = files.length;

    if (!files.length) {
      return false;
    }

    return (
      <div className={classes.root}>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button
              size="small"
              onClick={this.handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={this.handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
        {files[activeStep].type === 'video' ? (
          <video width="500" height="300" src={files[activeStep].src} controls>
            <track kind="captions" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            className={classes.img}
            src={files[activeStep].src}
            alt={files[activeStep].name}
          />
        )}
      </div>
    );
  }
}

MediaCarousel.propTypes = {
  files: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MediaCarousel);
