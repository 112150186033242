import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';

import TextInput from 'components/TextInput';
import SelectInput from 'components/SelectInput';
import { getEnumTypeIndex } from 'utils/fractal';

const styles = theme => ({
  papper: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: '100%',
  },
  grid: {
    flexGrow: 1,
  },
  datePickerGrid: {
    flexGrow: 1,
    alignSelf: 'baseline',
  },
});

const EventAccessRow = ({
  data,
  classes,
  onChange,
  onDelete,
  errors,
  index,
  enumValues,
  enumTypes,
  excludedValues,
  allEnumValues,
}) => {
  const access = allEnumValues.attributes.allenumvalues.find(
    item => item.column_id === data.attributes.name,
  );

  return (
    <Paper key={index} className={classes.papper}>
      <Grid container alignItems="flex-end" spacing={8}>
        <Grid item md={6} className={classes.grid}>
          <SelectInput
            disabled={data.id}
            required
            model={data}
            attribute="name"
            label="Name"
            modelErrors={errors || { name: '' }}
            onChange={event => onChange(event, index, 'input')}
          >
            {data.id ? (
              <MenuItem value={data.attributes.name} key={data.attributes.name}>
                {access ? access.value : ''}
              </MenuItem>
            ) : (
              Object.keys(enumValues.attributes.enumvalue).map(
                key =>
                  enumValues.attributes.enumvalue[key].enum_type_id ===
                    getEnumTypeIndex(enumTypes, 'event_access') &&
                  !excludedValues.includes(
                    enumValues.attributes.enumvalue[key].column_id,
                  ) && (
                    <MenuItem
                      value={enumValues.attributes.enumvalue[key].column_id}
                      key={key}
                    >
                      {enumValues.attributes.enumvalue[key].value}
                    </MenuItem>
                  ),
              )
            )}
          </SelectInput>
        </Grid>
        <Grid item md className={classes.grid}>
          <TextInput
            required
            type="number"
            model={data}
            attribute="fee"
            label="Fee"
            modelErrors={errors || { fee: '' }}
            onChange={event => onChange(event, index, 'input')}
          />
        </Grid>
        <Grid>
          <IconButton
            aria-label="Delete"
            onClick={event => onDelete(event, index)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

EventAccessRow.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.object,
  index: PropTypes.number.isRequired,
  enumValues: PropTypes.object,
  enumTypes: PropTypes.object,
  excludedValues: PropTypes.array,
  allEnumValues: PropTypes.object,
};

export default withStyles(styles)(EventAccessRow);
