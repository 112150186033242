import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the generateInvoicePage state domain
 */

const selectGenerateInvoicePageDomain = state =>
  state.get('generateInvoicePage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by InvoiceGeneratePage
 */

const makeSelectGenerateInvoicePage = () =>
  createSelector(selectGenerateInvoicePageDomain, substate => substate.toJS());

export default makeSelectGenerateInvoicePage;
export { selectGenerateInvoicePageDomain };
