import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';

const ItemTypeSelector = ({ itemTypes, handleNext }) => (
  <List component="nav">
    {Object.keys(itemTypes).map(item => (
      <ListItem
        button
        key={item}
        onClick={() => {
          handleNext(item);
        }}
      >
        <ListItemIcon>
          <Icon>radio_button_unchecked</Icon>
        </ListItemIcon>
        <ListItemText primary={itemTypes[item]} />
      </ListItem>
    ))}
  </List>
);

ItemTypeSelector.propTypes = {
  itemTypes: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default ItemTypeSelector;
