import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import withCrud from 'containers/ModelWrapper';
import injectReducer from 'utils/injectReducer';
import reducer from './reducer';
import UserTable from './UserTable';
import UserForm from './UserForm';

const UserPageContent = withCrud('userTable', 'users', UserTable);
const UserFormContent = withCrud('userForm', 'users', UserForm, false);

const makeValidUser = (user, included = []) => {
  if (!user.id) {
    return user;
  }
  const validUser = user;
  const role = () => {
    let roleString = '';
    user.relationships.roles.data.forEach(roleData => {
      const includeValue = included.find(
        include =>
          include.type === 'roles' &&
          include.id === roleData.id &&
          include.attributes.name !== 'AUTHENTICATED_USER',
      );
      roleString = includeValue ? includeValue.attributes.name : roleString;
    });
    return roleString;
  };
  validUser.attributes = Object.assign({}, user.attributes, {
    role: isEmpty(user.attributes.role) ? role() : user.attributes.role,
    delegation_id: user.attributes.delegation_id
      ? String(user.attributes.delegation_id)
      : '',
  });
  return validUser;
};

const UserPage = ({ match, history }) => (
  <div>
    <Helmet>
      <title>UserPage</title>
      <meta name="description" content="Description of UserPage" />
    </Helmet>
    <UserPageContent
      makeValidUser={makeValidUser}
      history={history}
      match={match}
    />
    <UserFormContent
      match={match}
      makeValidUser={makeValidUser}
      history={history}
    />
  </div>
);

UserPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const withReducer = injectReducer({ key: 'userPage', reducer });

export default compose(
  withReducer,
  withRouter,
)(UserPage);
