import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { setMessage } from 'containers/Snackbar/actions';
import { SUBMIT_FORM } from './constants';
import { actionPreProcessor, loadModels } from '../ModelWrapper/actions';

export function* submitFormSaga(action) {
  try {
    const response = yield call(() =>
      axios.post(`/payments/generate`, action.payload),
    );
    if (response) {
      const amount = parseFloat(response.data);
      yield put(
        setMessage({
          type: 'success',
          value: `EUR ${amount} was successfully added to the database`,
        }),
      );
    }
    yield put(actionPreProcessor(loadModels(), 'invoiceSummaryPageCrud'));
  } catch (error) {
    yield put(
      setMessage({
        type: 'error',
        value: error.toString(),
      }),
    );
  }
}

export default function* items() {
  yield takeLatest(SUBMIT_FORM, submitFormSaga);
}
