import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import qs from 'qs';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import ContainerPaper from '../../components/ContainerPaper';
import withCrud from '../../containers/ModelWrapper';
import download from '../../utils/download';
import { userHasRole } from '../../utils/userHasRole';
import { makeSelectDelegations } from '../../containers/App/selectors';
import { ROLES_ADMINS } from '../../utils/constants';
import {
  makeRolesSelector,
  makeUserSelector,
} from '../../containers/AuthButton/selectors';
import { setMessage } from '../../containers/Snackbar/actions';
import { MESSAGE_TYPES } from '../../containers/Snackbar/constants';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 150,
  },
});

class DelegationReportPage extends Component {
  handleSubmit = () => {
    axios({
      url: 'export/delegation-report',
      method: 'GET',
      responseType: 'blob',
      params: { filter: this.props.params.filter },
      paramsSerializer: param => qs.stringify(param, { encode: false }),
    })
      .then(response => download(response, 'delegation-report.pdf'))
      .catch(error => {
        this.props.showErrorToast(error.response.data.errors[0].detail);
      });
  };

  render() {
    const delegations = this.props.delegations.attributes.delegation.map(
      item => ({
        value: item.column_id,
        label: item.name,
      }),
    );

    const { setFilter, user, roles, classes, params } = this.props;
    const isAdmin = userHasRole(roles, ROLES_ADMINS);

    const userDelegation = delegations.find(
      delegation => delegation.value === user.delegation_id,
    );

    const getDefaultValue = () => {
      if (isAdmin) return false;

      setFilter({
        target: {
          name: 'delegation',
          value: userDelegation.value,
        },
      });

      return {
        label: userDelegation.label,
        value: userDelegation.value,
      };
    };

    return (
      <div>
        <Helmet>
          <title>Delegation Report</title>
        </Helmet>
        <ContainerPaper className={classes.root}>
          <Typography variant="h5" gutterBottom className={classes.formControl}>
            Delegation Report
          </Typography>
          <FormGroup row>
            <div className={classes.formControl}>
              <InputLabel>Filter delegation</InputLabel>
              <Select
                isClearable
                defaultValue={getDefaultValue()}
                placeholder={isAdmin ? 'All' : userDelegation.label}
                options={delegations}
                isDisabled={!isAdmin}
                required
                onChange={value => {
                  const event = {
                    target: {
                      name: 'delegation',
                      value: value ? value.value : null,
                    },
                  };
                  setFilter(event);
                }}
              />
            </div>
          </FormGroup>
          <Button
            className={classes.formControl}
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
            disabled={isEmpty(params.filter)}
          >
            Download
          </Button>
        </ContainerPaper>
      </div>
    );
  }
}

DelegationReportPage.defaultProps = {
  delegations: {
    attributes: {
      delegation: [],
    },
  },
};

DelegationReportPage.propTypes = {
  delegations: PropTypes.object,
  roles: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  showErrorToast: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  delegations: makeSelectDelegations(),
  roles: makeRolesSelector(),
  user: makeUserSelector(),
});

const mapDispatchToProps = dispatch => ({
  showErrorToast: () =>
    dispatch(
      setMessage({
        type: MESSAGE_TYPES.ERROR,
        value: 'Error',
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles, { withTheme: true })(
    withCrud('DelegationReportPage', 'invoices', DelegationReportPage, false),
  ),
);
