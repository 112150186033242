import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import qs from 'qs';
import { getEnumTypeArray } from 'utils/fractal';
import download from 'utils/download';
import axios from 'axios';
import {
  withStyles,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Button,
} from '@material-ui/core';

import SortableCells from 'components/SortableCells';
import TableToolbar from 'components/TableToolbar';
import TableFooter from 'components/TableFooter';
import withCrud from 'containers/ModelWrapper';
import {
  makeSelectDelegations,
  makeSelectEnumValues,
  makeSelectEnumTypes,
} from 'containers/App/selectors';
import {
  TableContainerOpenedStyles,
  TableContainerStyles,
} from '../../../mui-theme';
import RegistrantRow from './RegistrantsTableRow';
import RegistrantsFilter from './RegistrantsFilter';

const styles = () => ({
  rootClosed: TableContainerStyles,
  rootOpened: TableContainerOpenedStyles,
  exportButton: {
    margin: '0px 0px 15px 20px',
  },
});

class RegistrantsTable extends React.Component {
  constructor(props) {
    super(props);
    this.createSortHandler = this.createSortHandler.bind(this);
    this.state = {
      filterOpen: false,
    };

    this.props.setFilter({
      target: {
        name: 'event_id',
        value: this.props.eventId,
      },
    });
  }

  toggleFilter = () => {
    if (this.state.filterOpen) {
      this.props.resetFilter({
        event_id: this.props.eventId,
      });
    }
    this.setState(state => ({ filterOpen: !state.filterOpen }));
  };

  createSortHandler(property) {
    return event => {
      const { sortBy, sortDirection } = this.props;
      let direction = 'asc';
      if (property === sortBy) {
        direction = sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.props.setSorting(event, property, direction);
    };
  }

  handleExport = () => {
    const filter = {
      event_id: this.props.eventId,
    };
    axios({
      url: 'export/registrants/xls',
      method: 'GET',
      responseType: 'blob',
      params: { filter },
      paramsSerializer: param => qs.stringify(param, { encode: false }),
    })
      .then(response => download(response, response.headers['file-name']))
      .catch(error => {
        this.props.showErrorToast(error.message);
      });
  };

  handleReportExport = () => {
    const filter = {
      event_id: this.props.eventId,
    };
    axios({
      url: 'export/registrants/report',
      method: 'GET',
      responseType: 'blob',
      params: { filter },
      paramsSerializer: param => qs.stringify(param, { encode: false }),
    })
      .then(response => download(response, response.headers['file-name']))
      .catch(error => {
        this.props.showErrorToast(error.message);
      });
  };

  render() {
    const {
      models: { data, meta, included },
      classes,
      setPageNumber,
      sortBy,
      sortDirection,
      setRowsPerPage,
      setFilter,
      params,
      delegations,
      enumTypes,
      enumValues,
      programs,
    } = this.props;

    const sortableCells = {
      Name: 'name',
      Email: 'email',
      Phone: 'phone',
      Delegation: 'country',
      'Registration date': 'registration_date',
      Access: 'access',
      Price: '',
      Programs: '',
      'Is member': '',
      Status: '',
    };

    const accessValues = getEnumTypeArray(
      enumTypes,
      enumValues,
      'event_access',
    );

    return (
      <React.Fragment>
        <TableToolbar title="Registrants list" onClick={this.toggleFilter} />
        <Button
          className={classes.exportButton}
          variant="contained"
          color="primary"
          onClick={this.handleExport}
        >
          Export Registrants
        </Button>
        <Button
          className={classes.exportButton}
          variant="contained"
          color="primary"
          onClick={this.handleReportExport}
        >
          Export Voting Calculation
        </Button>
        <Table className={classes.table} padding="checkbox">
          <TableHead>
            <TableRow>
              <SortableCells
                sortDirection={sortDirection}
                sortBy={sortBy}
                onClick={this.createSortHandler}
                cells={sortableCells}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.filterOpen && (
              <RegistrantsFilter
                params={params}
                setFilter={setFilter}
                delegations={delegations}
                programs={programs}
              />
            )}
            {data.map(row => {
              const eventRegistration =
                included &&
                included.find(
                  model =>
                    model.type === 'event-registration' &&
                    model.id === row.relationships.event_registration.data.id,
                );
              return (
                <RegistrantRow
                  row={row}
                  key={row.id}
                  accessValues={accessValues}
                  countries={delegations.attributes.delegation}
                  programs={programs}
                  eventRegistration={eventRegistration}
                />
              );
            })}
          </TableBody>
          {data.length ? (
            <TableFooter
              meta={meta}
              colSpan={Object.keys(sortableCells).length + 1}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setRowsPerPage}
            />
          ) : null}
        </Table>
      </React.Fragment>
    );
  }
}

RegistrantsTable.defaultProps = {
  delegations: {
    attributes: {
      delegation: [],
    },
  },
};

RegistrantsTable.propTypes = {
  delegations: PropTypes.object,
  enumValues: PropTypes.object,
  enumTypes: PropTypes.object,
  params: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setPageNumber: PropTypes.func,
  setSorting: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  resetFilter: PropTypes.func,
  models: PropTypes.object,
  setFilter: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  eventId: PropTypes.number.isRequired,
  programs: PropTypes.array,
  showErrorToast: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  delegations: makeSelectDelegations(),
  enumValues: makeSelectEnumValues(),
  enumTypes: makeSelectEnumTypes(),
});

export default connect(mapStateToProps)(
  withStyles(styles)(
    withCrud('registrantTable', 'registrants', RegistrantsTable, true),
  ),
);
