import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import PhoneBookTable from './PhoneBookTable';
import PhoneBookDisclaimer from './PhoneBookDisclaimer';

const PhoneBookPage = ({ match, history }) => (
  <div>
    <Helmet>
      <title>Phonebook</title>
    </Helmet>
    <PhoneBookTable history={history} match={match} />
    <PhoneBookDisclaimer />
  </div>
);

PhoneBookPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(withRouter)(PhoneBookPage);
