import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';

import { TABLE_DEFAULT_PAGE_SIZE } from 'utils/constants';
import {
  LOAD_FUNCTIONAL_AREAS,
  LOAD_FUNCTIONAL_AREAS_SUCCESS,
  LOAD_FUNCTIONAL_AREAS_ERROR,
  SET_PAGE_NUMBER,
  SET_ROWS_PER_PAGE,
  functionalAreaErrors,
  SET_FUNCTIONAL_AREA,
  SET_FUNCTIONAL_AREA_ERROR,
  SUBMIT_FUNCTIONAL_AREA,
  SUBMIT_FUNCTIONAL_AREA_SUCCESS,
  SUBMIT_FUNCTIONAL_AREA_ERROR,
} from './constants';

const defaultParams = fromJS({
  filter: {},
  page: {
    size: TABLE_DEFAULT_PAGE_SIZE,
    number: 1,
  },
});

export const initialState = fromJS({
  functionalAreas: {
    data: [],
    meta: {},
  },
  loading: false,
  errorMessage: '',
  successMessage: '',
  params: defaultParams,
  selectedFunctionalArea: {},
  functionalAreaErrors: cloneDeep(functionalAreaErrors),
});

const functionalAreaPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FUNCTIONAL_AREAS:
      return state.set('loading', true);
    case LOAD_FUNCTIONAL_AREAS_SUCCESS:
      return state
        .set('loading', false)
        .set('functionalAreas', action.response);
    case LOAD_FUNCTIONAL_AREAS_ERROR:
      return state
        .set('loading', false)
        .set('errorMessage', action.error)
        .set('functionalAreas', []);
    case SET_PAGE_NUMBER:
      return state.setIn(['params', 'page', 'number'], action.page + 1);
    case SET_ROWS_PER_PAGE:
      return state
        .setIn(['params', 'page', 'size'], action.event.target.value)
        .setIn(['params', 'page', 'number'], 1);
    case SET_FUNCTIONAL_AREA:
      return state
        .set('selectedFunctionalArea', action.selectedFunctionalArea)
        .set('functionalAreaErrors', cloneDeep(functionalAreaErrors));
    case SET_FUNCTIONAL_AREA_ERROR:
      return state.set('functionalAreaErrors', action.errors);
    case SUBMIT_FUNCTIONAL_AREA:
      return state.set('functionalAreaErrors', cloneDeep(functionalAreaErrors));
    case SUBMIT_FUNCTIONAL_AREA_SUCCESS:
      return state
        .set('selectedFunctionalArea', {})
        .set('successMessage', 'Functional area has been saved.');
    case SUBMIT_FUNCTIONAL_AREA_ERROR:
      return state.set('errorMessage', action.error);
    default:
      return state;
  }
};

export default functionalAreaPageReducer;
