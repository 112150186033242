/*
 *
 * InvoiceGeneratePage actions
 *
 */

import { SUBMIT_FORM, ADD_SELECTION, REMOVE_SELECTION } from './constants';

export function submitForm(payload) {
  return {
    type: SUBMIT_FORM,
    payload: {
      data: {
        attributes: payload,
      },
    },
  };
}

export function addSelection(payload) {
  return {
    type: ADD_SELECTION,
    payload,
  };
}
export function removeSelection(payload) {
  return {
    type: REMOVE_SELECTION,
    payload,
  };
}
