import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { createStructuredSelector } from 'reselect';

import Dialog from '@material-ui/core/Dialog/Dialog';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Button from '@material-ui/core/Button/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';

import MoneyInput from 'components/MoneyInput';
import TextInput from 'components/TextInput';
import Authorization from 'utils/authorization';
import { userHasRole } from 'utils/userHasRole';
import withCrud from 'containers/ModelWrapper';
import {
  makeRolesSelector,
  makeUserSelector,
} from 'containers/AuthButton/selectors';
import UserFilter from './UserFilter';
import { bidErrors } from './constants';

const AdminRole = Authorization([
  'SYSTEM_ADMINISTRATOR',
  'MEMBERSHIP_ADMINISTRATOR',
]);

const UserSelect = AdminRole(UserFilter);
const CustomUserInput = AdminRole(TextInput);
const CommentInput = AdminRole(TextInput);

class BidForm extends React.Component {
  constructor(props) {
    super(props);
    this.closeForm = this.closeForm.bind(this);
    this.state = {
      errors: cloneDeep(bidErrors),
      isUserAdmin: userHasRole(props.roles, [
        'SYSTEM_ADMINISTRATOR',
        'MEMBERSHIP_ADMINISTRATOR',
      ]),
    };
  }

  componentWillMount() {
    if (!isEmpty(this.props.bid) && isEmpty(this.props.model)) {
      this.props.setModel(this.props.bid);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.props.refreshList();
      return;
    }
    if (!isEmpty(nextProps.bid) && isEmpty(nextProps.model)) {
      this.props.setModel(nextProps.bid);
    }
  }

  inputChanged(event) {
    const model = cloneDeep(this.props.model);
    model.attributes[event.target.name] = event.target.value;

    this.setState({ errors: cloneDeep(bidErrors) });

    this.props.setModel(model);
  }

  closeForm() {
    this.setState({ errors: cloneDeep(bidErrors) });
    this.props.setModel(null);
  }

  submit() {
    const { model, bid } = this.props;
    const { errors } = this.state;

    this.setState({ errors: cloneDeep(bidErrors) });

    let requiredFields = {
      amount: 'Amount',
    };

    if (!this.state.isUserAdmin) {
      requiredFields = {
        amount: 'Amount',
      };
      model.attributes.user_id = this.props.user.id;
      this.props.setModel(model);
    }

    Object.keys(requiredFields).forEach(field => {
      if (!String(model.attributes[field]).length) {
        errors[field] = `${requiredFields[field]} can not be empty`;
      }
    });

    if (
      !String(model.attributes.user_id).length &&
      !String(model.attributes.custom_name).length
    ) {
      errors.user_id = 'User or custom name cannot be empty';
      errors.custom_name = 'User or custom name cannot be empty';
    }

    if (
      parseFloat(model.attributes.amount) < parseFloat(bid.attributes.amount)
    ) {
      errors.amount = `Amount can not be smaller then ${bid.attributes.amount}`;
    }

    if (!isEqual(bidErrors, errors)) {
      this.props.showErrorToast('Form data invalid');
      this.setState({ errors });
      return;
    }
    this.props.submitModel();
  }

  render() {
    const { model } = this.props;
    const { errors } = this.state;

    if (isEmpty(model)) {
      return null;
    }

    return (
      <Dialog
        open={!isEmpty(model)}
        onClose={this.closeForm}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Place a Bid</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <DialogContent>
            <UserSelect
              bidErrors={errors}
              value={model.attributes.user_id}
              onChange={user => {
                const event = {
                  target: {
                    name: 'user_id',
                    value: user.id,
                  },
                };
                this.inputChanged(event);
              }}
            />
            <CustomUserInput
              model={model}
              modelErrors={errors}
              attribute="custom_name"
              label="Custom name"
              onChange={event => {
                this.inputChanged(event);
              }}
            />
            <MoneyInput
              model={model}
              modelErrors={errors}
              attribute="amount"
              label="Amount"
              onChange={event => {
                this.inputChanged(event);
              }}
            />
            <CommentInput
              model={model}
              modelErrors={errors}
              attribute="comment"
              label="Comment"
              onChange={event => {
                this.inputChanged(event);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeForm}>Cancel</Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

BidForm.propTypes = {
  model: PropTypes.object,
  setModel: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  showErrorToast: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  bid: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  roles: makeRolesSelector(),
  user: makeUserSelector(),
});

export default connect(mapStateToProps)(
  withCrud('bids', 'bids', BidForm, false),
);
