import { call, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';

import { setMessage } from 'containers/Snackbar/actions';
import { MESSAGE_TYPES } from 'containers/Snackbar/constants';
import { setErrorObject } from 'containers/App/actions';
import {
  LOAD_MODELS,
  SUBMIT_MODEL,
  DELETE_MODEL,
  SHOW_MODEL,
} from './constants';
import {
  loadModelsSuccess,
  submitModelSuccess,
  submitModelError,
  loadModels,
  deleteModelSuccess,
  showModelSuccess,
  actionPreProcessor,
} from './actions';
import {
  makeSelectParams,
  makeSelectModel,
  makeSelectModelId,
  makeSelectModelType,
} from './selectors';

export function* getModels(reducerKey) {
  const modelType = yield select(makeSelectModelType(reducerKey));
  const params = yield select(makeSelectParams(reducerKey));
  try {
    const response = yield call(() =>
      axios
        .get(`/${modelType}`, {
          params,
          paramsSerializer: param => qs.stringify(param, { encode: false }),
        })
        .then(res => res),
    );
    yield put(actionPreProcessor(loadModelsSuccess(response.data), reducerKey));
  } catch (error) {
    yield put(
      setMessage({
        type: MESSAGE_TYPES.ERROR,
        value: error.message,
      }),
    );
    yield put(setErrorObject(error));
  }
}

export function* submitModel(reducerKey) {
  const modelType = yield select(makeSelectModelType(reducerKey));
  const data = yield select(makeSelectModel(reducerKey));
  try {
    let url = `/${modelType}`;
    let method = 'post';
    if (data.id) {
      method = 'put';
      url += `/${data.id}`;
    }
    const response = yield call(() =>
      axios({
        method,
        url,
        data: { data },
      }).then(res => res),
    );
    yield put(
      setMessage({
        type: MESSAGE_TYPES.SUCCESS,
        value: 'Changes has been saved',
      }),
    );
    yield put(
      actionPreProcessor(submitModelSuccess(response.data), reducerKey),
    );
  } catch (error) {
    yield put(
      setMessage({
        type: MESSAGE_TYPES.ERROR,
        value: error.message,
      }),
    );
    yield put(setErrorObject(error));
    yield put(actionPreProcessor(submitModelError(), reducerKey));
  }
}

export function* deleteModel(reducerKey) {
  const modelType = yield select(makeSelectModelType(reducerKey));
  const id = yield select(makeSelectModelId(reducerKey));
  try {
    const url = `/${modelType}/${id}`;
    const method = 'delete';
    yield call(() =>
      axios({
        method,
        url,
      }).then(res => res),
    );
    yield put(actionPreProcessor(deleteModelSuccess(), reducerKey));
    yield put(
      setMessage({
        type: MESSAGE_TYPES.SUCCESS,
        value: 'Successfully removed.',
      }),
    );
    yield put(actionPreProcessor(loadModels(), reducerKey));
  } catch (error) {
    yield put(
      setMessage({
        type: MESSAGE_TYPES.ERROR,
        value: error.message,
      }),
    );
    yield put(setErrorObject(error));
  }
}

export function* showModel(reducerKey) {
  const modelType = yield select(makeSelectModelType(reducerKey));
  const id = yield select(makeSelectModelId(reducerKey));
  const params = yield select(makeSelectParams(reducerKey));
  try {
    const url = `/${modelType}/${id}`;
    const response = yield call(() =>
      axios
        .get(url, {
          params: {
            include: params.include || null,
          },
        })
        .then(res => res),
    );
    yield put(actionPreProcessor(showModelSuccess(response.data), reducerKey));
  } catch (error) {
    yield put(
      setMessage({
        type: MESSAGE_TYPES.ERROR,
        value: error.message,
      }),
    );
    yield put(setErrorObject(error));
  }
}

// Individual exports for testing
const saga = reducerKey =>
  function* enums() {
    yield takeLatest(`${LOAD_MODELS}/${reducerKey}`, () =>
      getModels(reducerKey),
    );
    yield takeLatest(`${SUBMIT_MODEL}/${reducerKey}`, () =>
      submitModel(reducerKey),
    );
    yield takeLatest(`${DELETE_MODEL}/${reducerKey}`, () =>
      deleteModel(reducerKey),
    );
    yield takeLatest(`${SHOW_MODEL}/${reducerKey}`, () =>
      showModel(reducerKey),
    );
  };

export default saga;
