import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

const styles = theme => ({
  input: {
    [theme.breakpoints.down('sm')]: {
      marginTop: `${theme.spacing.unit * 5}px !important`,
    },
  },
});

class SelectInput extends React.PureComponent {
  state = {
    visible: false,
  };

  visibilityAdornment = () => (
    <InputAdornment position="end">
      <IconButton
        onClick={this.toggleVisibility}
        aria-label="Toggle password visibility"
      >
        {this.state.visible ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  toggleVisibility = () =>
    this.setState({
      visible: !this.state.visible,
    });

  render() {
    const {
      value,
      model,
      modelErrors,
      attribute,
      label,
      onChange,
      disabled,
      required,
      classes,
      nullable,
      children,
    } = this.props;
    const inputValue = model.attributes[attribute] || value || '';

    return (
      <FormControl
        aria-describedby="value-error-text"
        fullWidth
        error={Boolean(modelErrors[attribute].length)}
      >
        <InputLabel
          htmlFor={attribute}
          required={required}
          shrink={!!(inputValue || isMobile)}
        >
          {label}
        </InputLabel>
        <Select
          className={classes.input}
          id={attribute}
          name={attribute}
          value={inputValue}
          onChange={onChange}
          disabled={disabled}
          required={required}
        >
          {nullable && (
            <MenuItem value="" key={null}>
              -
            </MenuItem>
          )}
          {children}
        </Select>
        <FormHelperText id="value-error-text">
          {modelErrors[attribute]}
        </FormHelperText>
      </FormControl>
    );
  }
}

SelectInput.defaultProps = {
  disabled: false,
  value: '',
};

SelectInput.propTypes = {
  classes: PropTypes.object.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  model: PropTypes.object.isRequired,
  modelErrors: PropTypes.object.isRequired,
  attribute: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  nullable: PropTypes.bool,
};

export default withStyles(styles)(SelectInput);
