import { fromJS } from 'immutable';

import {
  USER_LOGIN,
  LOAD_USER,
  INPUT_CHANGE,
  USER_LOGOUT,
  USER_LOGIN_ERROR,
} from './constants';

export const initialState = fromJS({
  user: {},
  email: '',
  password: '',
  loginError: '',
  roles: [],
});

const loginPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return state;
    case LOAD_USER:
      return state
        .set('user', action.user.data.data.attributes)
        .set('roles', action.user.data.included)
        .set('loginError', '');
    case INPUT_CHANGE:
      return state
        .set(action.input.target.name, action.input.target.value)
        .set('loginError', '');
    case USER_LOGIN_ERROR:
      return state.set('loginError', action.error.message);
    case USER_LOGOUT:
      localStorage.clear();
      return initialState;
    default:
      return state;
  }
};

export default loginPageReducer;
