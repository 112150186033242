export const eventRegistrationParticipant = {
  title: '',
  name: '',
  event_access_id: '',
  phone: '',
  email: '',
  country: '',
  program_ids: [],
};

export const eventRegistrationParticipantSkeleton = {
  attributes: eventRegistrationParticipant,
};

export const eventRegistrationSkeleton = Object.freeze({
  attributes: {
    event_id: null,
    dietary: '',
    comment: '',
    participants: [eventRegistrationParticipantSkeleton],
  },
  type: 'event-registration',
});

export const eventRegistrationErrors = {
  participants: [eventRegistrationParticipant],
  dietary: '',
  comment: '',
};

export const validEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
