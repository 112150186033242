import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty, throttle } from 'lodash';

import { makeSelectEnumValue, makeSelectEnumErrors } from './selectors';
import { setEnumValue, setEnumErrors, submitEnum } from './actions';

const styles = theme => ({
  root: {
    width: '210px',
    backgroundColor: theme.palette.background.paper,
    height: '320px',
  },
  linkto: {
    color: '#000',
    width: '100%',
  },
  li: {
    '&:hover': {
      backgroundColor: '#ccc',
    },
  },
});

class EnumForm extends React.Component {
  inputChanged(event) {
    const selectedEnum = Object.assign({}, this.props.enumValue);
    selectedEnum.attributes.value = event.target.value;
    this.props.changeEnum(selectedEnum);
  }
  checkboxChanged(event, checked) {
    const selectedEnum = Object.assign({}, this.props.enumValue);
    selectedEnum.attributes.active = checked ? 1 : 0;
    this.props.changeEnum(selectedEnum);
  }
  submit() {
    const { enumValue, enumErrors } = this.props;
    if (!enumValue.attributes.value.length) {
      enumErrors.value = 'Value can not be empty';
      this.props.setErrors(enumErrors);
      this.forceUpdate();
      return;
    }
    this.props.submitEnum();
  }
  render() {
    if (isEmpty(this.props.enumValue)) {
      return null;
    }
    return (
      <Dialog
        open={!isEmpty(this.props.enumValue)}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.props.enumValue.id
            ? `Edit "${this.props.enumValue.attributes.value}" Enum`
            : 'Add New Enum'}
        </DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <DialogContent>
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(this.props.enumErrors.value.length)}
            >
              <InputLabel htmlFor="value">Value</InputLabel>
              <Input
                id="value"
                name="value"
                autoFocus
                value={this.props.enumValue.attributes.value}
                onChange={throttle(event => this.inputChanged(event), 200)}
              />
              <FormHelperText id="value-error-text">
                {this.props.enumErrors.value}
              </FormHelperText>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(this.props.enumValue.attributes.active)}
                  onChange={(event, checked) =>
                    this.checkboxChanged(event, checked)
                  }
                  name="active"
                  color="primary"
                />
              }
              label="Active"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose}>Cancel</Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

EnumForm.propTypes = {
  enumValue: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  changeEnum: PropTypes.func.isRequired,
  enumErrors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  submitEnum: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  enumValue: makeSelectEnumValue(),
  enumErrors: makeSelectEnumErrors(),
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(setEnumValue({})),
  changeEnum: selectedEnum => dispatch(setEnumValue(selectedEnum)),
  setErrors: errors => dispatch(setEnumErrors(errors)),
  submitEnum: () => dispatch(submitEnum()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(EnumForm));
