/*
 *
 * InvoiceGeneratePage reducer
 *
 */

import { fromJS } from 'immutable';
import { ADD_SELECTION, REMOVE_SELECTION } from './constants';

export const initialState = fromJS({
  selectedInvoices: [],
});

function generateInvoicePageReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SELECTION:
      return state.update('selectedInvoices', array =>
        array.concat(action.payload),
      );
    case REMOVE_SELECTION:
      return state.update('selectedInvoices', array =>
        array.filter(item => item !== action.payload),
      );
    default:
      return state;
  }
}

export default generateInvoicePageReducer;
