import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import withCrud from 'containers/ModelWrapper';
import ContainerPaper from 'components/ContainerPaper';
import ConfirmDialog from 'components/ConfirmDialog';
import { DATE_TIME_FORMAT, formatAmount } from 'utils/constants';
import { paperWrapperStyles, TableContainerStyles } from '../../mui-theme';

const BidTableRow = props => {
  const { bid, users, openDialog } = props;
  return (
    <TableRow key={bid.id}>
      <TableCell>
        {/* eslint-disable prettier/prettier */}
        {bid.attributes.custom_name
          ? bid.attributes.custom_name
          : users
            .filter(u => u.id === bid.attributes.user_id)
            .map(u => u.name)[0]}
        {/* eslint-enable */}
      </TableCell>
      <TableCell>{formatAmount(bid.attributes.amount)}</TableCell>
      <TableCell>{bid.attributes.comment}</TableCell>
      <TableCell>
        {moment(bid.attributes.created_at.date).format(DATE_TIME_FORMAT)}
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        {!bid.attributes.is_paid && (
          <IconButton aria-label="Delete" onClick={() => openDialog(bid)}>
            <Icon fontSize="small">delete</Icon>
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

BidTableRow.propTypes = {
  bid: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  openDialog: PropTypes.func.isRequired,
};

const styles = () => ({
  paperWrapper: paperWrapperStyles,
  tableWrapper: TableContainerStyles,
});

class BidsTable extends React.Component {
  constructor(props) {
    super(props);
    this.openDialog = this.openDialog.bind(this);
    this.state = {
      deleteDialogOpen: false,
      dialogTitle: '',
    };
  }

  openDialog(bid) {
    this.setState(() => ({
      deleteDialogOpen: true,
      destroyBid: bid,
      dialogTitle: `Are you sure you want to remove?`,
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loading && !nextProps.loading) {
      this.props.refreshList();
    }
  }

  render() {
    const { bidList, classes } = this.props;

    let bids = [];
    let users = [];
    if (!isEmpty(bidList)) {
      bids = bidList.filter(e => e.type === 'bids').sort((a, b) => b.id - a.id);

      users = bidList.filter(e => e.type === 'users').map(e => ({
        id: parseInt(e.id, 10),
        name: [
          e.attributes.first_name,
          e.attributes.particle,
          e.attributes.last_name,
        ].join(' '),
      }));
    }

    const displayBidsTable = bids.length > 0;

    return (
      <React.Fragment>
        <ConfirmDialog
          open={this.state.deleteDialogOpen}
          onClose={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          cancelAction={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          confirmAction={() => {
            this.props.deleteModel(this.state.destroyBid);
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          title={this.state.dialogTitle}
          description="This action can not be undone."
        />
        {displayBidsTable && (
          <ContainerPaper className={[classes.paper, classes.tableWrapper]}>
            <Typography variant="h5" gutterBottom>
              Bids
            </Typography>
            <div>
              <Table className={classes.table}>
                <TableHead className={classes.head}>
                  <TableRow>
                    <TableCell className={classes.cell}>Bidder</TableCell>
                    <TableCell className={classes.cell}>Amount</TableCell>
                    <TableCell className={classes.cell}>Comment</TableCell>
                    <TableCell className={classes.cell}>Created Date</TableCell>
                    <TableCell className={classes.cell} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bids.map(bid => (
                    <BidTableRow
                      key={bid.id}
                      bid={bid}
                      users={users}
                      openDialog={this.openDialog}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>
          </ContainerPaper>
        )}
      </React.Fragment>
    );
  }
}

BidsTable.propTypes = {
  bidList: PropTypes.array,
  classes: PropTypes.object.isRequired,
  deleteModel: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(withCrud('bids', 'bids', BidsTable, false));
