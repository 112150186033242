import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import withCrud from 'containers/ModelWrapper';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { PageRootContainerStyles, paperWrapperStyles } from '../../mui-theme';
import ContainerPaper from '../../components/ContainerPaper';

const styles = theme => ({
  root: PageRootContainerStyles,
  paperWrapper: paperWrapperStyles,
  paper: {
    minWidth: 275,
    maxWidth: theme.breakpoints.values.lg,
  },
});

class NewsView extends React.Component {
  componentWillMount() {
    const { match } = this.props;
    this.props.showModel(match.params.newsId);
  }

  render() {
    const { model, classes } = this.props;

    if (!model || isEmpty(model)) {
      return null;
    }

    return (
      <div className={[classes.root, classes.paperWrapper]}>
        <ContainerPaper className={classes.paper}>
          <Typography variant="h5" component="h2">
            {model.attributes.title}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            {moment(model.attributes.publish_time).format(DATE_TIME_FORMAT)}
          </Typography>
          <Typography
            component="div"
            className="newsView"
            dangerouslySetInnerHTML={{
              __html: model.attributes.content,
            }}
          />
        </ContainerPaper>
      </div>
    );
  }
}

NewsView.propTypes = {
  match: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
};

export default compose(withRouter)(
  withStyles(styles)(withCrud('newsView', 'news', NewsView, false)),
);
