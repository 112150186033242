import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { DRAWER_WIDTH } from 'utils/constants';
import messages from './messages';

const styles = () => ({
  footer: {
    padding: '10px 24px 0',
    position: 'absolute',
    bottom: 10,
    marginLeft: 72,
  },
  footerShift: {
    marginLeft: DRAWER_WIDTH,
  },
});

const Footer = ({ classes, sidebarIsOpen }) => (
  <Typography
    component="footer"
    className={classNames(classes.footer, sidebarIsOpen && classes.footerShift)}
    variant="caption"
  >
    <FormattedMessage {...messages.footer} />
  </Typography>
);

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Footer);
