import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

const EuroFormat = props => {
  const { inputRef, name, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      prefix="€"
    />
  );
};

EuroFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

const MoneyInput = ({
  model,
  modelErrors,
  attribute,
  label,
  onChange,
  disabled,
  required,
}) => (
  <FormControl
    aria-describedby="value-error-text"
    fullWidth
    error={Boolean(modelErrors[attribute].length)}
  >
    <TextField
      required={required}
      id={attribute}
      name={attribute}
      label={label}
      value={_.get(model.attributes, attribute, '')}
      onChange={onChange}
      disabled={disabled}
      error={Boolean(modelErrors[attribute].length)}
      InputProps={{
        inputComponent: EuroFormat,
      }}
    />
    <FormHelperText id="value-error-text">
      {modelErrors[attribute]}
    </FormHelperText>
  </FormControl>
);

MoneyInput.defaultProps = {
  disabled: false,
  type: 'text',
};

MoneyInput.propTypes = {
  model: PropTypes.object.isRequired,
  modelErrors: PropTypes.object.isRequired,
  attribute: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default MoneyInput;
