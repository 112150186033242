import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withCrud from 'containers/ModelWrapper';
import ContainerPaper from '../../components/ContainerPaper';

const styles = () => ({});

class Page extends React.Component {
  componentWillMount() {
    this.loadPage(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.slug, this.props.slug)) {
      this.loadPage(nextProps);
    }
  }

  loadPage(props) {
    const { showModel, slug } = props;
    if (slug) {
      showModel(slug);
    }
  }

  render() {
    const { model } = this.props;

    if (!model || isEmpty(model)) {
      return null;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{model.attributes.title}</title>
        </Helmet>
        <ContainerPaper>
          <Typography variant="h5" gutterBottom>
            {model.attributes.title}
          </Typography>

          <Typography
            component="div"
            dangerouslySetInnerHTML={{
              __html: model.attributes.content,
            }}
          />
        </ContainerPaper>
      </React.Fragment>
    );
  }
}

Page.propTypes = {
  model: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
};

export default withStyles(styles)(withCrud('staticPage', 'pages', Page, false));
