import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as LinkTo } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { isEmpty, isEqual, cloneDeep } from 'lodash';

import {
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Icon,
  Button,
} from '@material-ui/core';

import withCrud from 'containers/ModelWrapper';
import SortableCells from 'components/SortableCells';
import TableToolbar from 'components/TableToolbar';
import TableFooter from 'components/TableFooter';

import EventsFilter from './EventsFilter';
import {
  TableContainerOpenedStyles,
  TableContainerStyles,
} from '../../mui-theme';
import { makeSelectSidebarIsOpen } from '../Layout/selectors';
import { setEventErrors } from './actions';
import { eventSkeleton } from './constants';
import EventsTableRow from './EventTableRow';

const styles = () => ({
  rootClosed: TableContainerStyles,
  rootOpened: TableContainerOpenedStyles,
});

class EventsTable extends React.Component {
  constructor(props) {
    super(props);
    this.createSortHandler = this.createSortHandler.bind(this);
    this.state = {
      filterOpen: false,
    };
  }

  componentWillMount() {
    const { setSorting, loadModels } = this.props;

    loadModels(this.props);
    setSorting({}, 'start_date', 'desc');
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.props.loadModels(nextProps);
      return;
    }
    if (!isEqual(this.props.params, nextProps.params)) {
      this.props.loadModels();
    }
  }

  toggleFilter = () => {
    if (this.state.filterOpen) {
      this.props.resetFilter();
    }
    this.setState(state => ({ filterOpen: !state.filterOpen }));
  };

  createSortHandler(property) {
    return event => {
      const { sortBy, sortDirection } = this.props;
      let direction = 'asc';
      if (property === sortBy) {
        direction = sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.props.setSorting(event, property, direction);
    };
  }

  render() {
    const {
      models,
      classes,
      setPageNumber,
      sortBy,
      sortDirection,
      setRowsPerPage,
      setFilter,
      params,
      sidebarIsOpen,
    } = this.props;

    const { data, meta } = models;

    if (isEmpty(meta)) {
      return null;
    }

    const sortableCells = {
      Title: 'title',
      Active: 'active',
      Location: 'location',
      'Start date': 'start_date',
      'End date': 'end_date',
      Link: '',
    };

    const rootClass = sidebarIsOpen ? classes.rootOpened : classes.rootClosed;

    return (
      <Paper className={rootClass}>
        <TableToolbar title="Event list" onClick={this.toggleFilter} />
        <Table className={classes.table} padding="checkbox">
          <TableHead>
            <TableRow>
              <SortableCells
                sortDirection={sortDirection}
                sortBy={sortBy}
                onClick={this.createSortHandler}
                cells={sortableCells}
              />
              <TableCell>
                <LinkTo to="/admin/events/edit/new">
                  <Button
                    color="primary"
                    onClick={() =>
                      this.props.setModel(cloneDeep(eventSkeleton))
                    }
                  >
                    <Icon>add</Icon>Add new
                  </Button>
                </LinkTo>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.state.filterOpen && (
              <EventsFilter params={params} setFilter={setFilter} />
            )}
            {data.map(row => <EventsTableRow key={row.id} row={row} />)}
          </TableBody>
          {data.length ? (
            <TableFooter
              colSpan={Object.keys(sortableCells).length + 1}
              meta={meta}
              onChangePage={setPageNumber}
              onChangeRowsPerPage={setRowsPerPage}
            />
          ) : null}
        </Table>
      </Paper>
    );
  }
}

EventsTable.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  resetFilter: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  loadModels: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  sidebarIsOpen: makeSelectSidebarIsOpen(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setEventErrors(errors)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withCrud('eventsTable', 'events', EventsTable, false)));
