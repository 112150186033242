import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import withCrud from 'containers/ModelWrapper';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import download from 'utils/download';
import { userHasRole } from 'utils/userHasRole';
import qs from 'qs';
import { makeSelectDelegations } from 'containers/App/selectors';
import {
  makeRolesSelector,
  makeUserSelector,
} from 'containers/AuthButton/selectors';
import { setMessage } from 'containers/Snackbar/actions';
import { MESSAGE_TYPES } from 'containers/Snackbar/constants';
import ContainerPaper from '../../components/ContainerPaper';
import { ROLES_ADMINS } from '../../utils/constants';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 150,
  },
});

function DownloadButton(props) {
  return (
    <Button
      className={props.classes.formControl}
      variant="contained"
      color="primary"
      onClick={() => props.onClick(props.sheet)}
    >
      Download {props.sheet}
    </Button>
  );
}

DownloadButton.propTypes = {
  classes: PropTypes.any,
  sheet: PropTypes.string,
  onClick: PropTypes.func,
};

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      mFunction: true,
      status: true,
    };
    this.props.setFilter({
      target: {
        name: 'active',
        value: true,
      },
    });
    this.props.setFilter({
      target: {
        name: 'function',
        value: 'ACTIVE',
      },
    });
    this.props.setFilter({
      target: {
        name: 'status',
        value: 'ACTIVE',
      },
    });
  }

  handleSubmit = sheet => {
    const filter = { ...this.props.params.filter, sheet };

    axios({
      url: 'export/member/xls',
      method: 'GET',
      responseType: 'blob',
      params: { filter },
      paramsSerializer: param => qs.stringify(param, { encode: false }),
    })
      .then(response => download(response, response.headers['file-name']))
      .catch(error => {
        this.props.showErrorToast(error.message);
      });
  };

  render() {
    const delegations = this.props.delegations.attributes.delegation.map(
      item => ({
        value: item.column_id,
        label: item.name,
      }),
    );

    const { setFilter, user, roles, classes } = this.props;
    const noDelegationFilter = userHasRole(roles, [
      ...ROLES_ADMINS,
      'ARTEMIS',
      'YOUNG_OPINION',
    ]);
    const isUserArtemis = userHasRole(roles, ['ARTEMIS']);
    const userDelegation = delegations.find(
      delegation => delegation.value === user.delegation_id,
    );

    return (
      <ContainerPaper className={classes.root}>
        <Typography variant="h5" gutterBottom className={classes.formControl}>
          Database Report
        </Typography>
        <FormGroup row>
          <div className={classes.formControl}>
            <InputLabel>Filter delegation</InputLabel>
            <Select
              isClearable
              placeholder={noDelegationFilter ? 'All' : userDelegation.label}
              options={delegations}
              isDisabled={!noDelegationFilter}
              onChange={value => {
                const event = {
                  target: {
                    name: 'delegation',
                    value: value ? value.value : null,
                  },
                };
                setFilter(event);
              }}
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                onChange={value => {
                  const event = {
                    target: {
                      name: 'active',
                      value: value.target.checked ? true : null,
                    },
                  };
                  this.setState({ active: value.target.checked });
                  setFilter(event);
                }}
                color="primary"
                checked={this.state.active}
              />
            }
            label="Active members only"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={value => {
                  const event = {
                    target: {
                      name: 'function',
                      value: value.target.checked ? 'ACTIVE' : null,
                    },
                  };
                  this.setState({ mFunction: value.target.checked });
                  setFilter(event);
                }}
                color="primary"
                checked={this.state.mFunction}
              />
            }
            label="Active functions only"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={value => {
                  const event = {
                    target: {
                      name: 'status',
                      value: value.target.checked ? 'ACTIVE' : null,
                    },
                  };
                  this.setState({ status: value.target.checked });
                  setFilter(event);
                }}
                color="primary"
                checked={this.state.status}
              />
            }
            label="Approved members only"
          />
        </FormGroup>
        {!isUserArtemis && (
          <DownloadButton
            classes={classes}
            sheet="members"
            onClick={this.handleSubmit}
          />
        )}
        <DownloadButton
          classes={classes}
          sheet="memberships"
          onClick={this.handleSubmit}
        />
        {!isUserArtemis && (
          <DownloadButton
            classes={classes}
            sheet="invoices"
            onClick={this.handleSubmit}
          />
        )}
        {!isUserArtemis && (
          <DownloadButton
            classes={classes}
            sheet="payments"
            onClick={this.handleSubmit}
          />
        )}
      </ContainerPaper>
    );
  }
}
Filter.defaultProps = {
  delegations: {
    attributes: {
      delegation: [],
    },
  },
};

Filter.propTypes = {
  delegations: PropTypes.object,
  roles: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  showErrorToast: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  delegations: makeSelectDelegations(),
  roles: makeRolesSelector(),
  user: makeUserSelector(),
});

const mapDispatchToProps = dispatch => ({
  showErrorToast: () =>
    dispatch(
      setMessage({
        type: MESSAGE_TYPES.ERROR,
        value: 'Error',
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles, { withTheme: true })(
    withCrud('DatabaseReportPage', 'members', Filter, false),
  ),
);
