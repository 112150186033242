import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectPasswordResetDomain = state =>
  state.get('passwordReset', initialState);

const makeSelectPasswordResetErrors = () =>
  createSelector(
    selectPasswordResetDomain,
    substate => substate.toJS().passwordResetErrors,
  );

export { selectPasswordResetDomain, makeSelectPasswordResetErrors };
