import React from 'react';
import PropTypes from 'prop-types';
import { Link as LinkTo } from 'react-router-dom';
import { isEqual, isEmpty, get } from 'lodash';
import { Helmet } from 'react-helmet';
import Select from 'react-select';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableFooter from 'components/TableFooter';

import withCrud from 'containers/ModelWrapper';
import Authorization from 'utils/authorization';
import { itemTypes } from 'utils/constants';
import Item from './Item';
import BidForm from './BidForm';
import AuctionFilter from './AuctionFilter';
import { sortByOptions } from './constants';
import ContainerPaper from '../../components/ContainerPaper';

const styles = () => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 200,
  },
  filters: {
    display: 'flex',
    flexDirection: 'columns',
    flexWrap: 'wrap',
  },
  select: {
    margin: '15px 30px 15px 0px',
    minWidth: 150,
  },
});

class ItemList extends React.Component {
  state = {
    selectedAuction: {},
    bid: {},
    pageType: '',
    pageTitle: '',
  };
  constructor(props) {
    super(props);
    this.auctionFilterChange = this.auctionFilterChange.bind(this);
    this.setBidModel = this.setBidModel.bind(this);
  }

  componentWillMount() {
    this.props.resetFilter();
    this.props.setRowsPerPage({
      target: { value: 50 },
    });
    if (get(this.props.history.location, 'state.auction', false)) {
      this.auctionFilterChange(this.props.history.location.state.auction);
    } else {
      this.loadItems(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.props.resetFilter();
      this.setState({ selectedAuction: {}, bid: {} });
      this.loadItems(nextProps);
      return;
    }
    if (!isEqual(this.props.params, nextProps.params)) {
      this.setState({ bid: {} });
      this.props.loadModels();
    }
    if (
      get(nextProps.history.location, 'state.auction', false) &&
      !isEqual(
        this.state.selectedAuction,
        nextProps.history.location.state.auction,
      )
    ) {
      this.auctionFilterChange(nextProps.history.location.state.auction);
    }
  }

  setBidModel(bid) {
    this.setState({ bid });
  }

  loadItems(props) {
    const { setFilter, match } = props;
    if (match.params.type) {
      const typeFromSlug = Object.entries(itemTypes).filter(
        item => item[1].slug === match.params.type,
      );

      const type = typeFromSlug.length ? String(typeFromSlug[0][0]) : null;
      this.setState({ pageType: type });

      switch (type) {
        case 'EUROPEAN_HUNT':
        case 'NON_EUROPEAN_HUNT_OR_HOLIDAY':
        case 'OBJECT':
          setFilter({
            target: {
              name: 'item_type',
              value: type,
            },
          });
          break;
        case 'LIVE':
          setFilter({
            target: {
              name: 'live',
              value: 1,
            },
          });
          break;
        case 'MY_BIDS':
          setFilter({
            target: {
              name: 'my_bids',
              value: 1,
            },
          });
          break;
        default:
          return;
      }

      switch (type) {
        case 'MY_BIDS':
        case 'EUROPEAN_HUNT':
        case 'NON_EUROPEAN_HUNT_OR_HOLIDAY':
        case 'OBJECT':
        case 'LIVE':
          this.setState({ pageTitle: itemTypes[type].title });
          break;
        default:
      }

      props.loadModels();
    }
  }

  auctionFilterChange(auction) {
    if (!auction) {
      return null;
    }
    const filter = {
      target: {
        name: 'assigned_auctions',
        value: auction.id,
      },
    };
    this.props.setFilter(filter);
    this.props.history.replace(this.props.history.location.pathname, null);
    return this.setState({ selectedAuction: auction });
  }

  handleSort() {
    return event => {
      this.props.setSorting(event, event.key, event.dir);
    };
  }

  render() {
    const {
      classes,
      models,
      setPageNumber,
      setRowsPerPage,
      history,
      match,
    } = this.props;
    const { data, included, meta } = models;
    const { selectedAuction } = this.state;
    const displayItems = !isEmpty(selectedAuction) && data && meta && included;

    const live = this.state.pageType === 'LIVE';

    const isAdmin = Authorization([
      'SYSTEM_ADMINISTRATOR',
      'MEMBERSHIP_ADMINISTRATOR',
    ]);

    const AddButton = isAdmin(() => (
      <LinkTo to="/admin/items/edit/new">
        <Button color="primary">
          <Icon>add</Icon>Add new
        </Button>
      </LinkTo>
    ));

    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.pageTitle}</title>
        </Helmet>
        <ContainerPaper className={classes.paper}>
          <Typography gutterBottom variant="h5" component="h2">
            {this.state.pageTitle}
          </Typography>
          <AddButton />
          <div className={classes.filters}>
            <div className={classes.select}>
              <InputLabel>Sort By</InputLabel>
              <Select onChange={this.handleSort()} options={sortByOptions} />
            </div>
            <AuctionFilter
              listLiveAuctions={live}
              onChange={this.auctionFilterChange}
              selectedAuction={this.state.selectedAuction}
              requestedAuctionId={match && match.params.auctionId}
            />
          </div>

          {displayItems && (
            <React.Fragment>
              <Grid container spacing={24}>
                {data.map(row => (
                  <Grid key={row.id} item xs={12} sm={6} md={4} lg={3}>
                    <Item
                      model={row}
                      included={included}
                      auction={selectedAuction}
                      setBidModel={this.setBidModel}
                      deleteModel={this.props.deleteModel}
                      history={history}
                      data={data}
                    />
                  </Grid>
                ))}
              </Grid>
              <BidForm
                refreshList={this.props.loadModels}
                bid={this.state.bid}
              />
              <Table className={classes.table}>
                {data.length ? (
                  <TableFooter
                    colSpan={1000}
                    meta={meta}
                    onChangePage={setPageNumber}
                    onChangeRowsPerPage={setRowsPerPage}
                  />
                ) : null}
              </Table>
            </React.Fragment>
          )}
        </ContainerPaper>
      </React.Fragment>
    );
  }
}

ItemList.propTypes = {
  classes: PropTypes.object.isRequired,
  setSorting: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  models: PropTypes.object.isRequired,
  loadModels: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  deleteModel: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withCrud('itemList', 'items', ItemList, false),
);
