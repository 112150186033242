import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import withCrud from 'containers/ModelWrapper';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography/Typography';
import TablePagination from '@material-ui/core/TablePagination/TablePagination';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import ConfirmDialog from 'components/ConfirmDialog';
import { makeSelectNewsCategories } from 'containers/App/selectors';
import { ROWS_PER_PAGE_OPTIONS, slugify } from 'utils/constants';
import NewsItem from './NewsItem';
import { PageRootContainerStyles } from '../../mui-theme';

const newsPadding = theme => ({
  paddingLeft: `${theme.spacing.unit * 3}px`,
  paddingRight: `${theme.spacing.unit * 3}px`,
  [theme.breakpoints.only('xs')]: {
    paddingLeft: `${theme.spacing.unit}px`,
    paddingRight: `${theme.spacing.unit}px`,
  },
});

const styles = theme => ({
  root: PageRootContainerStyles,
  headerTitle: {
    ...newsPadding(theme),
    minHeight: '64px',
    display: 'flex',
    alignItems: 'center',
  },
  p24: newsPadding(theme),
});

class NewsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteDialogOpen: false,
      dialogTitle: '',
    };
  }

  componentWillMount() {
    this.loadNews(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.loadNews(nextProps);
      return;
    }
    if (!isEqual(this.props.params, nextProps.params)) {
      this.props.loadModels();
    }
  }

  loadNews(props) {
    const { setFilter, match, newsCategories } = props;
    if (match.params.category) {
      const type = Object.entries(newsCategories.attributes).filter(
        item => slugify(item[0]) === match.params.category,
      );

      setFilter({
        target: {
          name: 'news_category',
          value: type.length ? String(type[0][0]) : 'AUCTION',
        },
      });
    } else {
      setFilter({
        target: {
          name: 'news_category',
          value: 'AUCTION',
        },
      });
    }
  }

  openDialog(news) {
    this.setState(() => ({
      deleteDialogOpen: true,
      destroyNews: news,
      dialogTitle: `Are you sure you want to remove "${
        news.attributes.title
      }" article?`,
    }));
  }

  render() {
    const {
      models,
      classes,
      setPageNumber,
      setRowsPerPage,
      params,
      newsCategories,
    } = this.props;

    const { data, meta } = models;

    if (isEmpty(meta) || isEmpty(newsCategories)) {
      return null;
    }

    const headerText = isEmpty(params.filter.news_category)
      ? 'News'
      : newsCategories.attributes[params.filter.news_category];

    return (
      <div>
        <Helmet>
          <title>{headerText}</title>
        </Helmet>

        <ConfirmDialog
          open={this.state.deleteDialogOpen}
          onClose={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          cancelAction={() => {
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          confirmAction={() => {
            this.props.deleteModel(this.state.destroyNews);
            this.setState(() => ({ deleteDialogOpen: false }));
          }}
          title={this.state.dialogTitle}
          description="This action can not be undone."
        />

        <Typography
          className={`${classes.headerTitle} ${classes.p24}`}
          variant="h5"
          gutterBottom
          component="h2"
        >
          {headerText}
        </Typography>
        <div className={classes.p24}>
          {data.map(row => (
            <NewsItem
              model={row}
              key={row.id}
              openDialog={() => this.openDialog(row)}
            />
          ))}
        </div>

        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TablePagination
                colSpan={1000}
                count={meta.pagination.total}
                rowsPerPage={meta.pagination.per_page}
                page={meta.pagination.current_page - 1}
                onChangePage={setPageNumber}
                onChangeRowsPerPage={setRowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              />
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

NewsList.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  deleteModel: PropTypes.func.isRequired,
  loadModels: PropTypes.func.isRequired,
  newsCategories: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  newsCategories: makeSelectNewsCategories(),
});

export default connect(mapStateToProps)(
  withStyles(styles)(withCrud('newsList', 'news', NewsList, false)),
);
