import axios from 'axios';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import withCrud from 'containers/ModelWrapper';

const styles = {
  card: {
    width: 300,
  },
  media: {
    height: 140,
  },
  loader: {
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  button: {
    display: 'flex',
    flex: 1,
  },
  error: {
    color: '#f44336',
  },
};

class ProfileImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
    };
    this.deleteFile = this.deleteFile.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async uploadFile(file) {
    const { visibility, onUpload, showErrorToast } = this.props;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('visibility', visibility || 'public');
    axios
      .post('member-files', formData)
      .then(response => response.data.data)
      .then(image => onUpload(image))
      .catch(error => showErrorToast(error.message));
  }

  async deleteFile() {
    const { image } = this.props;

    if (image) {
      axios.delete(`member-files/${image.id}`);
    }
  }

  async handleChange(event) {
    event.preventDefault();

    const file = event.target.files[0];
    this.setState({ showLoader: true });
    await this.deleteFile();
    await this.uploadFile(file);
    this.setState({ showLoader: false });
  }

  render() {
    const { image, error, classes } = this.props;
    const { showLoader } = this.state;

    const Loader = (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );

    const Inputs = (
      <Fragment>
        <input
          accept="image/*"
          className={classes.input}
          id="profile-image-input"
          type="file"
          style={{ opacity: 0, width: 0 }}
          onChange={this.handleChange}
        />
        <label htmlFor="profile-image-input">
          <Button component="span" className={classes.button}>
            {isEmpty(image) ? 'Add image' : 'Change image'}
          </Button>
        </label>
      </Fragment>
    );

    const Error = (
      <div className={classes.loader}>
        <span className={classes.error}>{error}</span>
      </div>
    );

    let dimensions = null;
    if (image && image.dimensions) {
      dimensions = image.dimensions; // eslint-disable-line prefer-destructuring
    } else if (image && image.attributes && image.attributes.dimensions) {
      dimensions = image.attributes.dimensions; // eslint-disable-line prefer-destructuring
    }

    return (
      <Card className={classes.card}>
        {dimensions && (
          <CardActionArea>
            <CardMedia className={classes.media} image={dimensions.thumbnail} />
          </CardActionArea>
        )}
        {showLoader && Loader}
        {!image && !showLoader && Boolean(error.length) && Error}
        <CardActions>{Inputs}</CardActions>
      </Card>
    );
  }
}

ProfileImageUpload.propTypes = {
  image: PropTypes.object,
  error: PropTypes.string,
  visibility: PropTypes.string,
  classes: PropTypes.object.isRequired,
  showErrorToast: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withCrud('memberFormPage', 'members', ProfileImageUpload, false),
);
