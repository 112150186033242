import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

const calculateStyle = level => {
  if (!level) return {};

  return {
    paddingLeft: 16 * level,
  };
};
const SubmenuCollapse = props => (
  <React.Fragment>
    <ListItem button onClick={props.toggle} style={calculateStyle(props.level)}>
      <ListItemIcon>
        <Icon>{props.icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={props.label} />
      {props.open ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    {props.open && <Divider />}
    <Collapse
      in={props.open}
      timeout="auto"
      unmountOnExit
      style={calculateStyle(props.level)}
    >
      <List component="div" disablePadding>
        {props.children}
      </List>
    </Collapse>
  </React.Fragment>
);

SubmenuCollapse.propTypes = {
  toggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};

export default SubmenuCollapse;
