import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';

import injectReducer from 'utils/injectReducer';
import withCrud from 'containers/ModelWrapper';

import { BACKEND_DATE_TIME_FORMAT, slugify } from 'utils/constants';
import TextInput from 'components/TextInput';
import TinyMCE from 'components/TinyMCE';
import TimePicker from '../TimePicker';

import reducer from './reducer';
import { newsErrors as resetErrors, newsSkeleton } from './constants';
import { makeSelectNewsErrors } from './selectors';
import { setNewsErrors } from './actions';
import { makeSelectNewsCategories } from '../App/selectors';
import DateSelector from '../../components/DatePicker';
import ContainerPaper from '../../components/ContainerPaper';
import { PageRootContainerStyles } from '../../mui-theme';

const styles = theme => ({
  root: {
    ...PageRootContainerStyles,
    minHeight: '500px',
  },
  button: {
    margin: theme.spacing.unit,
  },
  optionItem: {
    paddingLeft: 4 * theme.spacing.unit,
  },
  optionGroup: {
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1,
  },
});

class NewsForm extends React.Component {
  constructor(props) {
    super(props);
    this.setDate = this.setDate.bind(this);
    this.setTime = this.setTime.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.state = {
      publishTime: null,
    };
  }

  componentWillMount() {
    this.preLoadModel(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      const slug = slugify(this.props.model.attributes.news_category);
      this.props.history.push(`/admin/news/${slug}`);
      return;
    }

    if (!isEqual(nextProps.match.params, this.props.match.params)) {
      this.preLoadModel(nextProps);
      return;
    }
    if (!isEqual(nextProps.model, this.props.model)) {
      if (
        this.state.publishTime === null &&
        nextProps.model.attributes.publish_time
      ) {
        this.setState({
          publishTime: moment(nextProps.model.attributes.publish_time),
        });
      }
    }
  }

  preLoadModel(props) {
    const { match } = props;
    this.props.setModel(cloneDeep(newsSkeleton));
    this.props.setErrors(cloneDeep(resetErrors));
    if (parseInt(match.params.newsId, 10)) {
      this.props.showModel(match.params.newsId);
    }
  }

  setDate(event) {
    const { state } = this;
    if (!state[event.target.name]) {
      state[event.target.name] = moment();
    }
    state[event.target.name].year(event.target.value.year());
    state[event.target.name].month(event.target.value.month());
    state[event.target.name].dayOfYear(event.target.value.dayOfYear());
    this.setState(state);
  }

  setTime(event) {
    const { state } = this;
    if (!state[event.target.name]) {
      state[event.target.name] = moment();
    }
    if (event.target.value) {
      state[event.target.name].hour(event.target.value.hour());
      state[event.target.name].minute(event.target.value.minute());
    }

    this.setState(state);
  }

  inputChanged(event) {
    const selectedNews = cloneDeep(this.props.model);
    selectedNews.attributes[event.target.name] = event.target.value;
    this.props.setModel(selectedNews);
  }

  submit() {
    const { model } = this.props;
    const { publishTime } = this.state;
    const newsErrors = cloneDeep(resetErrors);

    /* publish_time is required, but handled differently */
    const requiredFields = {
      title: 'Title',
      news_category: 'News category',
      content: 'Content',
      send_notification: 'Send notification',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!String(model.attributes[field]).length) {
        newsErrors[field] = `${requiredFields[field]} can not be empty`;
      }
    });

    if (!publishTime) {
      this.props.showErrorToast('You must select publish date and time!');
      return;
    }

    if (!isEqual(newsErrors, resetErrors)) {
      this.props.setErrors(newsErrors);
      this.props.showErrorToast('Form data invalid');
      return;
    }

    model.attributes.publish_time = this.state.publishTime.format(
      BACKEND_DATE_TIME_FORMAT,
    );
    this.props.setModel(model);
    this.props.submitModel();
  }

  handleEditorChange(event) {
    this.inputChanged({
      target: {
        name: event.target.id,
        value: event.target.getContent(),
      },
    });
  }

  render() {
    const { model, classes, newsErrors, newsCategories } = this.props;
    const { publishTime } = this.state;
    if (isEmpty(model) || isEmpty(newsCategories)) {
      return null;
    }

    function transformToMenuItem() {
      return key => (
        <MenuItem
          key={key}
          value={key}
          className={
            key.match(/_SUB_/) ? classes.optionItem : classes.optionGroup
          }
        >
          {newsCategories.attributes[key]}
        </MenuItem>
      );
    }

    const displayPublicControl = model.attributes.news_category.match('_SUB_');

    return (
      <ContainerPaper className={classes.paper}>
        <Typography gutterBottom variant="h5" component="h2">
          News Post
        </Typography>
        <form
          onSubmit={event => {
            event.preventDefault();
            this.submit();
          }}
        >
          <div>
            <TextInput
              model={model}
              modelErrors={newsErrors}
              attribute="title"
              label="Title"
              onChange={this.inputChanged}
              required
            />
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(newsErrors.news_category.length)}
            >
              <InputLabel htmlFor="news_category" required>
                News Categories
              </InputLabel>
              <Select
                id="news_category"
                name="news_category"
                value={model.attributes.news_category}
                onChange={this.inputChanged}
              >
                {Object.keys(newsCategories.attributes).map(
                  transformToMenuItem(),
                )}
              </Select>
              <FormHelperText id="value-error-text">
                {newsErrors.news_category}
              </FormHelperText>
            </FormControl>
            <DateSelector
              required
              className={classes.datePicker}
              label="Publish Date"
              value={publishTime}
              helperText="Please note that the publish time is in GMT+0, not local time."
              onChange={date => {
                const event = {
                  target: {
                    name: 'publishTime',
                    value: date || '',
                  },
                };
                this.setDate(event);
              }}
            />
            <TimePicker
              required
              name="publishTime"
              label="Publish Time"
              value={publishTime}
              onChange={date => {
                const event = {
                  target: {
                    name: 'publishTime',
                    value: date || '',
                  },
                };
                this.setTime(event);
              }}
            />
            {displayPublicControl && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(model.attributes.is_public)}
                    onChange={(event, checked) =>
                      this.inputChanged({
                        target: { name: 'is_public', value: checked },
                      })
                    }
                    name="is_public"
                    color="primary"
                  />
                }
                label="Public"
              />
            )}
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(newsErrors.preview_content.length)}
            >
              <Typography variant="caption">Preview content</Typography>
              <TinyMCE
                Id="preview_content"
                name="preview_content"
                initialValue={model.attributes.preview_content}
                onChange={event => this.handleEditorChange(event)}
              />
              <FormHelperText id="value-error-text">
                {newsErrors.preview_content}
              </FormHelperText>
            </FormControl>
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(newsErrors.content.length)}
            >
              <Typography variant="caption">Content *</Typography>
              <TinyMCE
                Id="content"
                name="content"
                attributeName="content"
                initialValue={model.attributes.content.replace(
                  'style="height:0;width:100%;"',
                  'style="position:relative;padding-top:max(60%,326px);height:0;width:100%"',
                )}
                onChange={event => this.handleEditorChange(event)}
                plugins="link lists image table code"
                toolbar="formatselect | bold italic underline | alignleft aligncenter alignright | outdent indent | blockquote | bullist numlist | link image table | code"
              />
              <FormHelperText id="value-error-text">
                {newsErrors.content}
              </FormHelperText>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={model.id}
                  checked={Boolean(model.attributes.send_notification)}
                  onChange={(event, checked) =>
                    this.inputChanged({
                      target: { name: 'send_notification', value: checked },
                    })
                  }
                  name="send_notification"
                />
              }
              label="Send notification"
            />
          </div>
          <FormGroup row>
            <Button
              color="secondary"
              className={classes.button}
              onClick={() => this.props.setModel({})}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
            >
              Save
            </Button>
          </FormGroup>
        </form>
      </ContainerPaper>
    );
  }
}

NewsForm.defaultProps = {};

NewsForm.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  newsErrors: PropTypes.object,
  setModel: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  showErrorToast: PropTypes.func.isRequired,
  newsCategories: PropTypes.object,
  history: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  newsErrors: makeSelectNewsErrors(),
  newsCategories: makeSelectNewsCategories(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errs => dispatch(setNewsErrors(errs)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'newsForm', reducer });

export default compose(
  withReducer,
  withConnect,
)(withStyles(styles)(withCrud('newsFormPage', 'news', NewsForm, false)));
