import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    display: 'flex',
  },
  spacer: {
    flex: 1,
  },
});

const TableToolbar = ({ classes, title, onClick, disableGutters }) => (
  <Toolbar className={classes.root} disableGutters={disableGutters}>
    <div>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
    </div>
    <div className={classes.spacer} />
    <div className={classes.actions}>
      <Tooltip title="Filter list">
        <IconButton aria-label="Filter list" onClick={onClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </div>
  </Toolbar>
);

TableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disableGutters: PropTypes.bool,
};

export default withStyles(styles)(TableToolbar);
