import { call, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';

import { setMessage } from 'containers/Snackbar/actions';
import { MESSAGE_TYPES } from 'containers/Snackbar/constants';
import { LOAD_FUNCTIONAL_AREAS, SUBMIT_FUNCTIONAL_AREA } from './constants';
import {
  loadFunctionalAreas,
  loadFunctionalAreasSuccessed,
  loadFunctionalAreasError,
  submitFunctionalAreaSuccess,
} from './actions';
import { makeSelectParams, makeSelectFunctionalArea } from './selectors';

export function* getFunctionalAreas() {
  const params = yield select(makeSelectParams());
  try {
    const response = yield call(() =>
      axios
        .get(`/functional-areas`, {
          params,
          paramsSerializer: param => qs.stringify(param, { encode: false }),
        })
        .then(res => res),
    );
    yield put(loadFunctionalAreasSuccessed(response.data));
  } catch (err) {
    yield put(loadFunctionalAreasError(err));
  }
}

export function* submitFunctionalArea() {
  const data = yield select(makeSelectFunctionalArea());
  try {
    let url = '/functional-areas';
    let method = 'post';
    if (data.id) {
      method = 'put';
      url += `/${data.id}`;
    }
    yield call(() =>
      axios({
        method,
        url,
        data: { data },
      }).then(res => res),
    );
    yield put(submitFunctionalAreaSuccess());
    yield put(
      setMessage({
        type: MESSAGE_TYPES.SUCCESS,
        value: 'Functional area has been saved',
      }),
    );
    yield put(loadFunctionalAreas());
  } catch (error) {
    yield put(
      setMessage({
        type: MESSAGE_TYPES.ERROR,
        value: error.message,
      }),
    );
  }
}

// Individual exports for testing
export default function* functionalAreas() {
  yield takeLatest(LOAD_FUNCTIONAL_AREAS, getFunctionalAreas);
  yield takeLatest(SUBMIT_FUNCTIONAL_AREA, submitFunctionalArea);
}
