import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { setMessage } from 'containers/Snackbar/actions';
import { GET_INVOICED_COUNT, SUBMIT_FORM } from './constants';
import { getInvoicedCountSuccess } from './actions';

export function* submitFormSaga(action) {
  try {
    yield call(() =>
      axios.post(`/bulk-invoices`, action.payload).then(res => res),
    );
    yield put(
      setMessage({
        type: 'success',
        value: 'Invoices have been successfully generated!',
      }),
    );
  } catch (error) {
    yield put(
      setMessage({
        type: 'error',
        value: error.toString(),
      }),
    );
  }
}

export function* getInvoicedCountSaga(action) {
  try {
    const response = yield call(() =>
      axios
        .get(`/bulk-invoices`, {
          params: {
            year: action.year,
          },
        })
        .then(res => res),
    );
    yield put(getInvoicedCountSuccess(response.data));
  } catch (error) {
    yield put(
      setMessage({
        type: 'error',
        value: error.toString(),
      }),
    );
  }
}

// Individual exports for testing
export default function* items() {
  yield takeLatest(SUBMIT_FORM, submitFormSaga);
  yield takeLatest(GET_INVOICED_COUNT, getInvoicedCountSaga);
}
