import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

class InvoiceExpanionWrapper extends React.PureComponent {
  render() {
    const {
      classes,
      children,
      expansionDetail,
      isNotificationPage,
    } = this.props;

    if (isNotificationPage) {
      return <React.Fragment>{children}</React.Fragment>;
    }

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          className={`${classes.expansionPanelSummary} invoiceExpansionPanel`}
        >
          {children}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{expansionDetail}</ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

InvoiceExpanionWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  expansionDetail: PropTypes.any,
  isNotificationPage: PropTypes.bool,
};

export default InvoiceExpanionWrapper;
