/*
 *
 * Menu constants
 *
 */

import { userHasAccess } from '../../utils/userHasRole';
import {
  itemTypes,
  ROLES_ADMINS,
  ROLES_ADMINS_AND_HEAD_OF_DELEGATION,
  ROLES_ALL_USER,
  ROLES_MAINTAINERS,
  ROLES_MEMBER_ONLY,
  slugify,
} from '../../utils/constants';
import { getBackendURL } from '../../utils/axios';

export const ROUTE_MAP = {
  '/admin/auction-rules/edit': '/auction-rules',
  '/admin/member-home/edit': '/member-home',
  '/admin/auction-home/edit': '/auction-home',
  '/admin/auctions/edit/[0-9]+': '/admin/auctions',
  '/admin/items/edit/[0-9]+/': '::history::',
  '/admin/members/waiting-for-approval': '/',
  '/admin/members/edit/[0-9]+/member-function/[0-9]+':
    '/admin/members/approved',
  '/admin/members/edit/[0-9]+': '/admin/members/approved',
  '/admin/news/edit/[0-9]+': '::history::',
  '/admin/users/edit/[0-9]+': '/admin/users',
  '/auction-news/view/[0-9]+': '::history::',
  '/auction-rules/edit': '/auction-rules',
  '/member-home/edit': '/member-home',
  '/auction-home/edit': '/auction-home',
  '/delegation': '/',
  '/items/[0-9]+/': '::history::',
  '/member-home': '/',
  '/news/view/[0-9]+': '::history::',
};

export const createMenuStructure = (roles, newsCategories) => {
  const adminLead =
    !userHasAccess(roles, [
      'TROPHY_ADMINISTRATOR',
      'ARTEMIS',
      'YOUNG_OPINION',
    ]) && !userHasAccess(roles, ROLES_MEMBER_ONLY, true);
  const urlLead = adminLead ? '/admin/news/' : '/news/';
  return [
    {
      type: 'item',
      url: '/',
      title: 'Home',
      icon: 'home',
      roles: ROLES_ALL_USER,
    },
    {
      type: 'item',
      url: '/my-profile',
      title: 'My Profile',
      icon: 'account_circle',
      roles: ROLES_MEMBER_ONLY,
    },
    {
      type: 'submenu',
      stateKey: 'members',
      title: 'Members',
      icon: 'account_circle',
      roles: ROLES_MAINTAINERS,
      items: [
        {
          type: 'item',
          url: '/admin/members/approved',
          title: 'List of members',
          roles: ROLES_MAINTAINERS,
        },
        {
          type: 'item',
          url: '/admin/members/edit/new',
          title: 'Create Member',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/newsletter',
          title: 'Newsletter',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/members/database-report',
          title: 'Database Report',
          roles: ROLES_MAINTAINERS,
        },
        {
          type: 'item',
          url: '/admin/download-documents',
          title: 'Download Documents',
          roles: ROLES_ADMINS,
        },
      ],
    },
    {
      type: 'submenu',
      stateKey: 'invoice',
      title: 'Invoice operations',
      icon: 'account_balance:wallet',
      roles: [...ROLES_ADMINS_AND_HEAD_OF_DELEGATION, 'YOUNG_OPINION'],
      items: [
        {
          type: 'item',
          url: '/admin/invoices/view_and_download_invoices',
          title: 'View and Download Invoices',
          roles: [...ROLES_ADMINS_AND_HEAD_OF_DELEGATION, 'YOUNG_OPINION'],
        },
        {
          type: 'item',
          url: '/admin/invoices/notifications',
          title: 'Notification',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/invoices/generate-invoices',
          title: 'Generate Invoices',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/invoices/update-fees',
          title: 'Update Fees',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/invoices/update-due-dates',
          title: 'Update Due Dates',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/invoices/summary-of-invoices',
          title: 'Summary of Invoices',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/invoices/delegation-report',
          title: 'Delegation Report',
          roles: ROLES_ADMINS_AND_HEAD_OF_DELEGATION,
        },
      ],
    },
    {
      type: 'item',
      url: '/phonebook',
      title: 'Phonebook',
      icon: 'contacts',
      roles: ROLES_ALL_USER,
    },
    {
      type: 'item',
      url: '/admin/users',
      title: 'Users',
      icon: 'account_circle',
      roles: ROLES_ADMINS,
    },
    {
      type: 'item',
      url: '/admin/media',
      title: 'Media',
      icon: 'photo_library',
      roles: ROLES_ADMINS,
    },
    {
      type: 'item',
      url: `/admin/events`,
      stateKey: 'events',
      title: 'Events',
      icon: 'calendar_today',
      roles: ROLES_ADMINS,
    },
    {
      type: 'submenu',
      stateKey: 'administration',
      title: 'Administration',
      icon: 'list',
      roles: ROLES_ADMINS,
      items: [
        {
          type: 'item',
          url: '/admin/enum/functional-areas',
          title: 'Functional areas',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/enum/exit_reason',
          title: 'Exit reason',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/enum/expertise',
          title: 'Expertise',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/enum/member_functions',
          title: 'Member Functions',
          roles: ROLES_ADMINS,
        },

        {
          type: 'item',
          url: '/admin/enum/member_title',
          title: 'Member title',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/enum/member_type',
          title: 'Member type',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/enum/payment_method',
          title: 'Payment method',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/enum/preferred_language',
          title: 'Preferred language',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/enum/event_access',
          title: 'Event access',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/enum/member_particle',
          title: 'Member particle',
          roles: ROLES_ADMINS,
        },
      ],
    },
    {
      type: 'submenu',
      stateKey: 'auction',
      title: 'Hunting Auction',
      icon: 'gavel',
      roles: ROLES_ALL_USER,
      items: [
        {
          type: 'item',
          url: '/auction-home',
          title: 'Auction Home',
          roles: ROLES_ALL_USER,
        },
        {
          type: 'item',
          url: '/admin/auctions',
          title: 'List of Auctions',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/auctions/edit/new',
          title: 'Create Auction',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/admin/items/edit/new',
          title: 'Create Item',
          roles: ROLES_ADMINS,
        },
        {
          type: 'item',
          url: '/auction-rules',
          title: 'Auction Rules',
          roles: ROLES_ALL_USER,
        },
        {
          type: 'submenu',
          stateKey: 'auctionNewsSub',
          title: 'Auction News',
          icon: 'keyboard_arrow_right',
          roles: ROLES_ALL_USER,

          items: Object.entries(newsCategories.attributes)
            .filter(item => item[0].match(/^AUCTION_SUB_/))
            .map(item => {
              const slug = slugify(item[0]);
              return {
                type: 'item',
                url: `${urlLead}${slug}`,
                title: item[1],
                roles: ROLES_ALL_USER,
              };
            }),
        },
        {
          type: 'item',
          url: `/items/${itemTypes.LIVE.slug}`,
          title: itemTypes.LIVE.title,
          roles: ROLES_ALL_USER,
        },
        {
          type: 'submenu',
          stateKey: 'auctionSub',
          title: 'Silent Auction Items',
          icon: 'keyboard_arrow_right',
          roles: ROLES_ALL_USER,
          items: [
            {
              type: 'item',
              url: `/admin/items/${itemTypes.EUROPEAN_HUNT.slug}`,
              title: itemTypes.EUROPEAN_HUNT.title,
              roles: ROLES_ALL_USER,
            },
            {
              type: 'item',
              url: `/admin/items/${
                itemTypes.NON_EUROPEAN_HUNT_OR_HOLIDAY.slug
              }`,
              title: itemTypes.NON_EUROPEAN_HUNT_OR_HOLIDAY.title,
              roles: ROLES_ALL_USER,
            },
            {
              type: 'item',
              url: `/admin/items/${itemTypes.OBJECT.slug}`,
              title: itemTypes.OBJECT.title,
              roles: ROLES_ALL_USER,
            },
          ],
        },
        {
          type: 'item',
          url: `/admin/items/my-bids`,
          title: 'My bids',
          roles: ROLES_MEMBER_ONLY,
        },
      ],
    },
    {
      type: 'submenu',
      stateKey: 'news',
      title: 'News',
      icon: 'rss_feed',
      roles: ROLES_ALL_USER,
      items: [
        {
          type: 'item',
          url: `${urlLead}edit/new`,
          title: 'Create New Post',
          roles: ROLES_ADMINS,
        },
        ...Object.entries(newsCategories.attributes)
          .filter(item => item[0].match(/^(?!AUCTION.*$).*/))
          .map(item => {
            const slug = slugify(item[0]);
            return {
              type: 'item',
              url: `${urlLead}${slug}`,
              title: item[1],
              roles: ROLES_ALL_USER,
            };
          }),
      ],
    },
    {
      type: 'submenu',
      stateKey: 'content',
      title: 'Content',
      icon: 'file_copy',
      roles: ROLES_ADMINS,
      items: [
        {
          type: 'item',
          url: '/admin/member-home/edit',
          title: 'Member Home Page',
          roles: ROLES_MAINTAINERS,
        },
        {
          type: 'item',
          url: '/admin/auction-home/edit',
          title: 'Auction Home Page',
          roles: ROLES_MAINTAINERS,
        },
        {
          type: 'item',
          url: '/admin/terms-and-conditions/edit',
          title: 'Events Terms and Conditions',
          roles: ROLES_MAINTAINERS,
        },
      ],
    },
    {
      type: 'item',
      url: `${getBackendURL}/storage/user_manual.pdf`,
      external: true,
      title: 'User Manual',
      icon: 'book',
      roles: ROLES_MEMBER_ONLY,
    },
  ];
};
