import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles as muiWithStyles } from '@material-ui/core/styles';

import NotFound from 'containers/NotFoundPage';
import { setErrorObject } from 'containers/App/actions';
import { makeSelectErrorObject } from '../App/selectors';
import EventRegistrationForm from './EventRegistrationForm';

const styles = () => ({
  flex: {
    display: 'flex',
  },
});

class EventRegistration extends React.Component {
  componentWillUnmount() {
    this.props.setErrorObject(null);
  }

  render() {
    const { classes, error, match } = this.props;
    if (error && error.message === 'Not found') {
      return <NotFound />;
    }

    return (
      <div>
        <Helmet>
          <title>Event Registration</title>
        </Helmet>
        <div className={classes.flex}>
          <EventRegistrationForm key={match.params.slug} />
        </div>
      </div>
    );
  }
}

EventRegistration.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  error: PropTypes.object,
  setErrorObject: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  error: makeSelectErrorObject(),
});

const mapDispatchToProps = dispatch => ({
  setErrorObject: error => dispatch(setErrorObject(error)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withStyles = muiWithStyles(styles);

export default compose(
  withConnect,
  withStyles,
)(EventRegistration);
