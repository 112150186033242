import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { throttle } from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DATE_FORMAT, DATE_FORMAT_MASK, BACKEND_DATE } from 'utils/constants';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import Select from 'react-select';
import {
  makeSelectDelegations,
  makeSelectInvoiceYears,
} from '../App/selectors';

const styles = () => ({
  datePicker: {
    display: 'block',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  datePickerWrapper: {
    width: '100%',
    display: 'block',
  },
  input: {
    fontsize: '12px',
  },
});

const InvoicesFilter = props => {
  const { params, delegationFilterDisabled, isNotificationPage } = props;

  const delegations = props.delegations.attributes.delegation
    .filter(
      delegation =>
        !params.filter.delegation ||
        delegation.column_id === params.filter.delegation,
    )
    .map(item => ({
      value: item.column_id,
      label: item.name,
    }));

  const years = props.years.attributes.invoice
    .sort((a, b) => b.year - a.year)
    .map(item => ({
      value: item.year,
      label: item.year,
    }));

  return (
    <TableRow>
      <TableCell>
        <FormControl>
          <InputLabel htmlFor="search-for-invoice-number">
            Search for invoice number
          </InputLabel>
          <Input
            id="search-for-invoice-number"
            name="number"
            onChange={throttle(event => {
              event.persist();
              props.setFilter(event);
            }, 200)}
            startAdornment={
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            }
          />
        </FormControl>
      </TableCell>
      {isNotificationPage && (
        <TableCell>
          <FormControl>
            <InputLabel htmlFor="search-for-member-number">
              Search for member number
            </InputLabel>
            <Input
              id="search-for-member-number"
              name="member_number"
              onChange={throttle(event => {
                event.persist();
                props.setFilter(event);
              }, 200)}
              startAdornment={
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              }
            />
          </FormControl>
        </TableCell>
      )}
      <TableCell>
        <Select
          isClearable
          isSearchable
          isDisabled={delegationFilterDisabled}
          options={delegations}
          onChange={value => {
            const event = {
              target: {
                name: 'delegation',
                value: value ? value.value : null,
              },
            };
            props.setFilter(event);
          }}
        />
      </TableCell>
      <TableCell>
        <FormControl>
          <Select
            isClearable
            isSearchable
            options={years}
            onChange={value => {
              const event = {
                target: {
                  name: 'year',
                  value: value ? value.value : null,
                },
              };
              props.setFilter(event);
            }}
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={props.classes.datePicker}>
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="From"
              format={DATE_FORMAT}
              clearable
              adornmentPosition="end"
              value={
                (params.filter.invoice_date_from &&
                  moment(params.filter.invoice_date_from)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'invoice_date_from',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="To"
              format={DATE_FORMAT}
              adornmentPosition="end"
              clearable
              value={
                (params.filter.invoice_date_to &&
                  moment(params.filter.invoice_date_to)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'invoice_date_to',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={props.classes.datePicker}>
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="From"
              format={DATE_FORMAT}
              clearable
              adornmentPosition="end"
              value={
                (params.filter.due_date_from &&
                  moment(params.filter.due_date_from)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'due_date_from',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="To"
              format={DATE_FORMAT}
              adornmentPosition="end"
              clearable
              value={
                (params.filter.due_date_to &&
                  moment(params.filter.due_date_to)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'due_date_to',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </TableCell>
      <TableCell>
        <FormControl>
          <InputLabel htmlFor="search-for-amount">Search for amount</InputLabel>
          <Input
            id="search-for-amount"
            name="amount"
            onChange={throttle(event => {
              event.persist();
              props.setFilter(event);
            }, 200)}
            startAdornment={
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            }
          />
        </FormControl>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

InvoicesFilter.defaultProps = {};

InvoicesFilter.propTypes = {
  params: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  years: PropTypes.object.isRequired,
  delegations: PropTypes.object.isRequired,
  delegationFilterDisabled: PropTypes.bool.isRequired,
  isNotificationPage: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  delegations: makeSelectDelegations(),
  years: makeSelectInvoiceYears(),
});

export default connect(mapStateToProps)(withStyles(styles)(InvoicesFilter));
