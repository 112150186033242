import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import FunctionalAreaTable from './FunctionalAreaTable';
import FunctionalAreaForm from './FunctionalAreaForm';

const styles = () => ({
  flex: {
    display: 'flex',
  },
});

const FunctionalAreaPage = ({ classes }) => (
  <div>
    <Helmet>
      <title>FunctionalAreaPage</title>
      <meta name="description" content="Description of FunctionalAreaPage" />
    </Helmet>
    <div className={classes.flex}>
      <FunctionalAreaTable />
      <FunctionalAreaForm />
    </div>
  </div>
);
FunctionalAreaPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const withReducer = injectReducer({ key: 'functionalAreaPage', reducer });
const withSaga = injectSaga({ key: 'functionalAreaPage', saga });

export default compose(
  withReducer,
  withSaga,
)(withStyles(styles)(FunctionalAreaPage));
