import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';

class TextInput extends React.PureComponent {
  state = {
    visible: false,
  };

  visibilityAdornment = () => (
    <InputAdornment position="end">
      <IconButton
        onClick={this.toggleVisibility}
        aria-label="Toggle password visibility"
      >
        {this.state.visible ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  toggleVisibility = () =>
    this.setState({
      visible: !this.state.visible,
    });

  render() {
    const {
      toggleVisibility,
      error,
      name,
      label,
      autoFocus,
      required,
      value,
      onChange,
      type,
    } = this.props;
    return (
      <FormControl
        margin="normal"
        required={required}
        fullWidth
        error={Boolean(error.length)}
      >
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Input
          id={name}
          name={name}
          type={this.state.visible ? 'text' : type}
          autoComplete={name}
          autoFocus={autoFocus}
          value={value}
          onChange={onChange}
          endAdornment={toggleVisibility ? this.visibilityAdornment() : null}
        />
        <FormHelperText id="component-error-text">{error}</FormHelperText>
      </FormControl>
    );
  }
}

TextInput.defaultProps = {
  type: 'text',
};

TextInput.propTypes = {
  error: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.bool,
  toggleVisibility: PropTypes.bool,
};

export default TextInput;
