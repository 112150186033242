import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/LockOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link as LinkTo } from 'react-router-dom';
import classNames from 'classnames';

import ContainerPaper from 'components/ContainerPaper';
import { getBackendURL } from 'utils/axios';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import LoginForm from './LoginForm';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: theme.breakpoints.values.sm,
  },
  helpPaper: {
    marginTop: '64px !important',
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  link: {
    marginTop: theme.spacing.unit * 3,
  },
  downloadButton: {
    marginTop: theme.spacing.unit * 3,
  },
  helpEmail: {
    marginTop: theme.spacing.unit * 2,
  },
  helpSubtitle: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing.unit,
  },
  contactInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  phoneIcon: {
    marginRight: theme.spacing.unit,
  },
  phoneInfo: {
    marginBottom: theme.spacing.unit * 2,
  },
  emailInfo: {
    marginBottom: theme.spacing.unit * 2,
  },
  emailIcon: {
    marginRight: theme.spacing.unit,
  },
});

class LoginPage extends React.Component {
  state = {
    dialogOpen: false,
  };

  handleDialogOpen = () => this.setState({ dialogOpen: true });
  handleDialogClose = () => this.setState({ dialogOpen: false });

  downloadPDF = language => {
    const link = document.createElement('a');
    link.href = `${getBackendURL}/storage/handbook_${language}.pdf`;
    link.target = '_blank';
    link.click();
  };

  scrollToHelp = () => {
    const help = document.getElementById('help');
    if (help) {
      help.scrollIntoView({ behavior: 'smooth' });
    }
  };

  render() {
    const { classes, history } = this.props;
    const { dialogOpen } = this.state;

    return (
      <div>
        <Helmet>
          <title>Login Page | CIC</title>
        </Helmet>
        <Grid container direction="column" alignItems="center">
          <ContainerPaper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography variant="h5">Sign in</Typography>
            <LoginForm history={history} />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <LinkTo
                className={classes.link}
                to="#help"
                onClick={this.scrollToHelp}
              >
                Cannot login?
              </LinkTo>
              <LinkTo className={classes.link} to="/password-reset">
                Reset password
              </LinkTo>
            </Grid>
          </ContainerPaper>
          <ContainerPaper
            id="help"
            className={classNames(classes.paper, classes.helpPaper)}
          >
            <Avatar className={classes.avatar}>
              <HelpIcon />
            </Avatar>
            <Typography variant="h5">
              Still having problems logging in?
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.helpSubtitle}
            >
              Should you require additional support, our contact details are as
              follows:
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.contactInfo}
            >
              <PhoneIcon color="primary" className={classes.phoneIcon} />
              <span>
                {' '}
                Telephone: <strong>+36 23 453 830</strong>
              </span>
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              className={classNames(classes.contactInfo, classes.emailInfo)}
            >
              <EmailIcon color="primary" className={classes.emailIcon} />
              <span>
                {' '}
                Electronic Mail: <strong>membership@cic-wildlife.org</strong>
              </span>
            </Typography>
            <Typography variant="caption" gutterBottom>
              Simply send a message with the subject &quot;Login Details&quot;
              to <u>membership@cic-wildlife.org</u> and we will promptly forward
              the necessary credentials to you.
            </Typography>
            <Typography variant="caption" gutterBottom>
              We are committed to providing a seamless and user-friendly
              experience on our membership platform and are at your full
              disposal for any assistance you may need.
            </Typography>
            {/* <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.downloadButton}
              onClick={() => this.downloadPDF('english')}
            >
              Download english handbook
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.downloadButton}
              onClick={() => this.downloadPDF('german')}
            >
              Download german handbook
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.downloadButton}
              onClick={() => this.downloadPDF('french')}
            >
              Download french handbook
            </Button>
            <Grid container direction="row-reverse">
              <LinkTo
                className={classes.link}
                to="#"
                onClick={this.handleDialogOpen}
              >
                If you need help, call our number or write an email
              </LinkTo>
            </Grid> */}
          </ContainerPaper>
        </Grid>
        <Dialog
          open={dialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Contact information</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container direction="column">
                <strong>Telephone:</strong>
                +36 23 453 830
                <strong className={classes.helpEmail}>Email:</strong>
                membership@cic-wildlife.org
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.handleDialogClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const withReducer = injectReducer({ key: 'loginPage', reducer });
const withSaga = injectSaga({ key: 'loginPage', saga });

export default compose(
  withSaga,
  withReducer,
)(withStyles(styles)(LoginPage));
