import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { throttle } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import MomentUtils from '@date-io/moment';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';

import { DATE_FORMAT, DATE_FORMAT_MASK, BACKEND_DATE } from 'utils/constants';

const styles = () => ({
  datePicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  datePickerWrapper: {
    width: '145px',
  },
  input: {
    fontsize: '12px',
  },
});

const AuctionsFilter = props => {
  const { params } = props;
  return (
    <TableRow>
      <TableCell>
        <FormControl>
          <InputLabel htmlFor="search-for-name">Search for name</InputLabel>
          <Input
            id="search-for-name"
            name="name"
            onChange={throttle(event => {
              event.persist();
              props.setFilter(event);
            }, 200)}
            startAdornment={
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            }
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={props.classes.datePicker}>
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="From"
              format={DATE_FORMAT}
              clearable
              adornmentPosition="end"
              value={
                (params.filter.start_time_from &&
                  moment(params.filter.start_time_from)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'start_time_from',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="To"
              format={DATE_FORMAT}
              adornmentPosition="end"
              clearable
              value={
                (params.filter.start_time_to &&
                  moment(params.filter.start_time_to)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'start_time_to',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </TableCell>
      <TableCell>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={props.classes.datePicker}>
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="From"
              format={DATE_FORMAT}
              clearable
              adornmentPosition="end"
              value={
                (params.filter.end_time_from &&
                  moment(params.filter.end_time_from)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'end_time_from',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
            <DatePicker
              className={props.classes.datePickerWrapper}
              keyboard
              label="To"
              format={DATE_FORMAT}
              disableFuture
              adornmentPosition="end"
              clearable
              value={
                (params.filter.end_time_to &&
                  moment(params.filter.end_time_to)) ||
                ''
              }
              mask={value => (value ? DATE_FORMAT_MASK : [])}
              disableOpenOnEnter
              onChange={date => {
                const event = {
                  target: {
                    name: 'end_time_to',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </TableCell>
      <TableCell>
        <Select
          isClearable
          isSearchable
          options={[{ label: 'yes', value: 1 }, { label: 'no', value: 0 }]}
          onChange={value => {
            const event = {
              target: {
                name: 'live',
                value: value ? value.value : null,
              },
            };
            props.setFilter(event);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

AuctionsFilter.defaultProps = {};

AuctionsFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps)(withStyles(styles)(AuctionsFilter));
