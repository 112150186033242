import axios from 'axios';
import { put, takeLatest, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { deleteUser, loadUser } from './actions';
import { USER_LOGOUT, REFRESH_USER } from './constants';

export function* logoutUser() {
  yield put(push('/login'));
}

export function* refreshUser() {
  try {
    const response = yield call(() => axios.get(`/auth/me`).then(res => res));
    yield put(loadUser(response));
  } catch (err) {
    yield put(deleteUser());
  }
}

// Individual exports for testing
export default function* user() {
  yield takeLatest(USER_LOGOUT, logoutUser);
  yield takeLatest(REFRESH_USER, refreshUser);
}
