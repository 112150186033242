import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withStyles as muiWithStyles } from '@material-ui/core/styles';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import Authorization from 'utils/authorization';
import WelcomePage from 'containers/WelcomePage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import LoginPage from 'containers/LoginPage';
import Layout from 'containers/Layout';
import EnumPage from 'containers/EnumPage';
import DelegationScreen from 'containers/DelegationPage';
import { makeUserSelector } from 'containers/AuthButton/selectors';
import FunctionalAreaPage from 'containers/FunctionalAreaPage';
import UserPage from 'containers/UserPage';
import Media from 'containers/Media/MediaTable';
import RegistrationRequestPage from 'containers/RegistrationRequestPage';
import Members from 'containers/Members/MembersTable';
import MemberForm from 'containers/Members/MemberForm';
import ItemListPage from 'containers/ItemPage/ItemList';
import DatabaseReport from 'containers/DatabaseReportPage';
import ItemFormPage from 'containers/ItemPage/ItemForm';
import AuctionListPage from 'containers/AuctionPage/AuctionsTable';
import ItemViewPage from 'containers/ItemPage/ItemView';
import AuctionForm from 'containers/ItemPage/AuctionForm';
import PhoneBookPage from 'containers/PhoneBookPage';
import MemberFunctionFrom from 'containers/Members/MemberFunctionForm';
import UpdateFeesForm from 'containers/Invoice/UpdateFeesForm';
import UpdateDueDatesForm from 'containers/Invoice/UpdateDueDatesForm';
import InvoiceListPage from 'containers/Invoice/InvoicesTable';
import InvoiceSummaryPage from 'containers/InvoiceSummaryPage';
import DelegationReportPage from 'containers/DelegationReportPage';
import Event from 'containers/Event';
import EventForm from 'containers/Event/EventForm/index';

import AuctionRulesPage from 'containers/AuctionPage/AuctionRules';
import AuctionRulesEditPage from 'containers/AuctionPage/AuctionRulesEditPage';
import AuctionHomePage from 'containers/AuctionHome/AuctionHome';
import AuctionHomeEditPage from 'containers/AuctionHome/AuctionHomeEdit';
import MemberHomePage from 'containers/MemberHome/MemberHome';
import MemberHomeEditPage from 'containers/MemberHome/MemberHomeEdit';
import EventTermsAndConditionEditPage from 'containers/EventTermsAndCondition/EventTermsAndConditionEdit';
import Page from 'containers/Page/Page';
import PasswordResetPage from 'containers/PasswordResetPage/PasswordResetRequest';
import PasswordResetForm from 'containers/PasswordResetPage/PasswordResetForm';
import GenerateInvoicePage from 'containers/InvoiceGeneratePage';
import { loadConfig } from './actions';
import { makeSelectLoading } from './selectors';
import reducer from './reducer';
import saga from './saga';
import NewsList from '../News/NewsList';
import NewsForm from '../News/NewsForm';
import NewsView from '../News/NewsView';
import EventRegistration from '../EventRegistration';
import EventPaymentResponse from '../EventRegistration/PaymentResponse';
import InvoicesPaymentResponse from '../Invoice/InvoicesPaymentResponse';
import ItemPaymentResponse from '../ItemPage/PaymentResponse';
import {
  FOOTER_VSPACE,
  HEADER_HEIGHT,
  ROLES_ADMINS,
  ROLES_ADMINS_AND_HEAD_OF_DELEGATION,
  ROLES_MAINTAINERS,
} from '../../utils/constants';
import NewsLetterPage from '../Newsletter';

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    minHeight: '100vh',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: `${HEADER_HEIGHT}px`,
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    marginBottom: `${FOOTER_VSPACE}px`,
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing.unit}px`,
      marginBottom: `${FOOTER_VSPACE + 70}px`,
    },
  },
});

const AdminPage = Authorization(ROLES_ADMINS, false, true);
const AdminHODPage = Authorization(
  ROLES_ADMINS_AND_HEAD_OF_DELEGATION,
  false,
  true,
);
const MaintainerPage = Authorization(ROLES_MAINTAINERS, false, true);
const DelegationPage = Authorization(['HEAD_OF_DELEGATION'], false, true);
const AuthenticatedPage = Authorization(['AUTHENTICATED_USER'], false, true);
const DelegationAndMemberPage = Authorization(
  ['HEAD_OF_DELEGATION', 'AUTHENTICATED_USER'],
  false,
  true,
);
const AdminYOPage = Authorization(
  [...ROLES_ADMINS, 'YOUNG_OPINION'],
  false,
  true,
);

const AdminEnumPage = AdminPage(EnumPage);
const AdminFunctionalAreaPage = AdminPage(FunctionalAreaPage);
const AdminUsersPage = AdminPage(UserPage);
const AdminMediaListPage = AdminPage(Media);
const AdminEventsPage = AdminPage(Event);
const AdminEventFormPage = AdminPage(EventForm);
const AdminMemberListPage = AdminHODPage(Members);
const MaintainerMemberListPage = MaintainerPage(Members);
const MaintainerMemberFormPage = MaintainerPage(MemberForm);
const AuthenticatedItemListPage = AuthenticatedPage(ItemListPage);
const AdminItemFormPage = AdminPage(ItemFormPage);
const AdminAuctionListPage = AdminPage(AuctionListPage);
const AuctionFormAdminPage = AdminPage(AuctionForm);
const AdminAuctionRulesEditPage = AdminPage(AuctionRulesEditPage);
const AdminMemberHomeEditPage = AdminPage(MemberHomeEditPage);
const AdminEventTermsAndConditionEditPage = AdminPage(
  EventTermsAndConditionEditPage,
);
const AdminAuctionHomeEditPage = AdminPage(AuctionHomeEditPage);
const DatabaseReportPage = MaintainerPage(DatabaseReport);
const DelegationWelcomePage = DelegationPage(DelegationScreen);
const MemberItemViewPage = AuthenticatedPage(ItemViewPage);
const MemberPhoneBookPage = AuthenticatedPage(PhoneBookPage);
const MemberAuctionRulesPage = AuthenticatedPage(AuctionRulesPage);
const MemberMemberHomePage = AuthenticatedPage(MemberHomePage);
const MemberAuctionHomePage = AuthenticatedPage(AuctionHomePage);
const MemberMyProfile = AuthenticatedPage(MemberForm);
const AdminNewsListPage = AdminHODPage(NewsList);
const AdminNewsEditPage = AdminHODPage(NewsForm);
const UserNewsListPage = AuthenticatedPage(NewsList);
const DelegationAndMemberNewsViewPage = DelegationAndMemberPage(NewsView);
const AdminDocumentsPage = AdminPage(Page);
const MemberFunction = AdminYOPage(MemberFunctionFrom);
const DelegationYOInvoiceListPage = Authorization(
  [...ROLES_ADMINS_AND_HEAD_OF_DELEGATION, 'YOUNG_OPINION'],
  false,
  true,
)(InvoiceListPage);
const InvoiceNotificationPage = AdminPage(InvoiceListPage);
const AdminInvoiceSummaryPage = AdminPage(InvoiceSummaryPage);
const AdminHODDelegationReportPage = AdminHODPage(DelegationReportPage);
const AdminGenerateInvoicePage = AdminPage(GenerateInvoicePage);
const UpdateFeesPage = AdminPage(UpdateFeesForm);
const UpdateDueDatesPage = AdminPage(UpdateDueDatesForm);
const AdminNewsletterPage = AdminPage(NewsLetterPage);

class App extends React.Component {
  componentWillMount() {
    this.props.loadConfig();
  }

  render() {
    const { classes, loading } = this.props;
    if (loading) {
      return null;
    }
    return (
      <div className={classes.root}>
        <Layout />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/" component={WelcomePage} />
            <Route
              exact
              path="/admin/enum/functional-areas"
              component={AdminFunctionalAreaPage}
            />
            <Route exact path="/admin/enums" component={AdminEnumPage} />
            <Route
              exact
              path="/admin/enum/:machineName"
              component={AdminEnumPage}
            />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/admin/users" component={AdminUsersPage} />
            <Route exact path="/admin/media" component={AdminMediaListPage} />
            <Route exact path="/admin/events" component={AdminEventsPage} />
            <Route
              exact
              path="/admin/events/edit/:eventId"
              component={AdminEventFormPage}
            />
            <Route
              exact
              path="/admin/users/edit/:id"
              component={AdminUsersPage}
            />
            <Route
              exact
              path="/admin/members/"
              component={AdminMemberListPage}
            />
            <Route
              exact
              path="/admin/members/:type"
              component={MaintainerMemberListPage}
            />
            <Route
              exact
              path="/admin/members/edit/:memberId"
              component={MaintainerMemberFormPage}
            />
            <Route
              exact
              path="/admin/members/edit/:memberId/member-function/:functionId?"
              component={MemberFunction}
            />
            <Route
              exact
              path="/admin/items"
              component={AuthenticatedItemListPage}
            />
            <Route
              exact
              path="/admin/auctions/edit/:itemId"
              component={AuctionFormAdminPage}
            />
            <Route
              exact
              path="/admin/items/edit/:itemId/:auctionId?"
              component={AdminItemFormPage}
            />
            <Route
              exact
              path="/admin/items/:type"
              component={AuthenticatedItemListPage}
            />
            <Route
              exact
              path="/admin/items/:type/:auctionId"
              component={AuthenticatedItemListPage}
            />
            <Route
              exact
              path="/items/payment-response/:id"
              component={ItemPaymentResponse}
            />
            <Route
              exact
              path="/items/:type"
              component={AuthenticatedItemListPage}
            />
            <Route
              exact
              path="/items/:itemId(\d+)/:auctionId?"
              component={MemberItemViewPage}
            />
            <Route
              exact
              path="/items/:type/:auctionId"
              component={AuthenticatedItemListPage}
            />
            <Route
              exact
              path="/admin/auctions"
              component={AdminAuctionListPage}
            />
            <Route
              exact
              path="/auction-rules"
              component={MemberAuctionRulesPage}
            />
            <Route
              exact
              path="/admin/auction-rules/edit/"
              component={AdminAuctionRulesEditPage}
            />
            <Route exact path="/member-home" component={MemberMemberHomePage} />
            <Route
              exact
              path="/admin/member-home/edit/"
              component={AdminMemberHomeEditPage}
            />
            <Route exact path="/delegation" component={DelegationWelcomePage} />
            <Route exact path="/phonebook" component={MemberPhoneBookPage} />
            <Route
              exact
              path="/registration"
              component={RegistrationRequestPage}
            />
            <Route
              exact
              path="/terms-and-conditions"
              render={() => <Page slug="terms-and-conditions" />}
            />
            <Route
              exact
              path="/admin/terms-and-conditions/edit/"
              component={AdminEventTermsAndConditionEditPage}
            />
            <Route exact path="/my-profile" component={MemberMyProfile} />
            <Route
              exact
              path="/admin/news/:category"
              component={AdminNewsListPage}
            />
            <Route
              exact
              path="/news/view/:newsId"
              component={DelegationAndMemberNewsViewPage}
            />
            <Route
              exact
              path="/admin/news/edit/:newsId"
              component={AdminNewsEditPage}
            />
            <Route exact path="/news/:category" component={UserNewsListPage} />
            <Route
              exact
              path="/auction-home"
              component={MemberAuctionHomePage}
            />
            <Route
              exact
              path="/admin/auction-home/edit/"
              component={AdminAuctionHomeEditPage}
            />
            <Route exact path="/auction-news/:category" component={NewsList} />
            <Route
              exact
              path="/auction-news/view/:newsId"
              component={NewsView}
            />
            <Route
              exact
              path="/events/payment-response/:id"
              component={EventPaymentResponse}
            />
            <Route exact path="/events/:slug" component={EventRegistration} />
            <Route
              exact
              path="/members/database-report"
              component={DatabaseReportPage}
            />
            <Route
              exact
              path="/invoices/payment-response/:id"
              component={InvoicesPaymentResponse}
            />
            <Route
              exact
              path="/admin/invoices/update-fees"
              component={UpdateFeesPage}
            />
            <Route
              exact
              path="/admin/invoices/update-due-dates"
              component={UpdateDueDatesPage}
            />
            <Route
              exact
              path="/admin/invoices/view_and_download_invoices"
              component={DelegationYOInvoiceListPage}
            />
            <Route
              exact
              path="/admin/invoices/notifications"
              component={() => <InvoiceNotificationPage isNotificationPage />}
            />
            <Route
              exact
              path="/admin/invoices/summary-of-invoices"
              component={AdminInvoiceSummaryPage}
            />
            <Route
              exact
              path="/admin/invoices/delegation-report"
              component={AdminHODDelegationReportPage}
            />
            <Route
              exact
              path="/admin/download-documents"
              component={() => <AdminDocumentsPage slug="download-documents" />}
            />
            <Route exact path="/password-reset" component={PasswordResetPage} />
            <Route
              exact
              path="/password-reset/:token"
              component={PasswordResetForm}
            />
            <Route exact path="/newsletter" component={AdminNewsletterPage} />
            <Route
              exact
              path="/admin/invoices/generate-invoices"
              component={AdminGenerateInvoicePage}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </main>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  loadConfig: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeUserSelector(),
  loading: makeSelectLoading(),
});

const mapDispatchToProps = dispatch => ({
  loadConfig: () => dispatch(loadConfig()),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'App', reducer });
const withSaga = injectSaga({ key: 'App', saga });

const withStyles = muiWithStyles(styles);

export default compose(
  withRouter,
  withConnect,
  withReducer,
  withSaga,
  withStyles,
)(App);
