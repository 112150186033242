import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DateSelector from 'components/DatePicker';
import TextFilter from 'components/TextFilter';
import ActiveFilter from 'components/ActiveFilter';
import { makeSelectDelegations } from 'containers/App/selectors';
import { BACKEND_DATE } from '../../utils/constants';

const styles = () => ({
  datePicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  datePickerWrapper: {
    width: '145px',
  },
  input: {
    fontSize: '12px',
  },
  tr: {
    maxWidth: '250px',
  },
});

const MembersFilter = props => {
  const delegations = props.delegations.attributes.delegation.map(item => ({
    value: item.column_id,
    label: item.name,
  }));
  const { params, approvedList } = props;
  return (
    <TableRow>
      {approvedList && (
        <TableCell className={props.classes.tr}>
          <TextFilter
            Id="searc-for-member-number"
            label="Search for number"
            name="member_number"
            setFilter={props.setFilter}
          />
        </TableCell>
      )}
      <TableCell className={props.classes.tr}>
        <TextFilter
          Id="searc-for-name"
          label="Search for name"
          name="name"
          setFilter={props.setFilter}
        />
      </TableCell>
      <TableCell>
        <Select
          isClearable
          options={delegations}
          onChange={value => {
            const event = {
              target: {
                name: 'delegation',
                value: value ? value.value : null,
              },
            };
            props.setFilter(event);
          }}
        />
      </TableCell>
      {!approvedList && (
        <TableCell>
          <div className={props.classes.datePicker}>
            <DateSelector
              className={props.classes.datePickerWrapper}
              label="From"
              value={
                (params.filter.created_at_from &&
                  moment(params.filter.created_at_from)) ||
                ''
              }
              onChange={date => {
                const event = {
                  target: {
                    name: 'created_at_from',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
            <DateSelector
              className={props.classes.datePickerWrapper}
              label="To"
              value={
                (params.filter.created_at_to &&
                  moment(params.filter.created_at_to)) ||
                ''
              }
              onChange={date => {
                const event = {
                  target: {
                    name: 'created_at_to',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
          </div>
        </TableCell>
      )}
      {approvedList && (
        <TableCell>
          <ActiveFilter
            defaultActive
            setFilter={props.setFilter}
            value={params.filter.active}
          />
        </TableCell>
      )}
      <TableCell>
        {!approvedList && (
          <div className={props.classes.datePicker}>
            <DateSelector
              className={props.classes.datePickerWrapper}
              label="From"
              value={
                (params.filter.updated_at_from &&
                  moment(params.filter.updated_at_from)) ||
                ''
              }
              onChange={date => {
                const event = {
                  target: {
                    name: 'updated_at_from',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
            <DateSelector
              className={props.classes.datePickerWrapper}
              label="To"
              value={
                (params.filter.updated_at_to &&
                  moment(params.filter.updated_at_to)) ||
                ''
              }
              onChange={date => {
                const event = {
                  target: {
                    name: 'updated_at_to',
                    value: date ? moment(date).format(BACKEND_DATE) : '',
                  },
                };
                props.setFilter(event);
              }}
            />
          </div>
        )}
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

MembersFilter.defaultProps = {
  delegations: {
    attributes: {
      delegation: [],
    },
  },
};

MembersFilter.propTypes = {
  delegations: PropTypes.object,
  setFilter: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  approvedList: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  delegations: makeSelectDelegations(),
});

export default connect(mapStateToProps)(withStyles(styles)(MembersFilter));
