import { LOAD_USER, USER_LOGOUT, REFRESH_USER, DELETE_USER } from './constants';

export const loadUser = user => ({
  type: LOAD_USER,
  user,
});

export const resetUser = () => ({
  type: USER_LOGOUT,
});

export const refreshUser = () => ({
  type: REFRESH_USER,
});

export const deleteUser = () => ({
  type: DELETE_USER,
  user: null,
});
