import React from 'react';
import PropTypes from 'prop-types';
import { Link as LinkTo } from 'react-router-dom';
import moment from 'moment';

import {
  Checkbox,
  TableCell,
  TableRow,
  Icon,
  IconButton,
} from '@material-ui/core';

import { DATE_FORMAT } from 'utils/constants';

const EventsTableRow = ({ row }) => (
  <TableRow key={row.id}>
    <TableCell>{row.attributes.title}</TableCell>
    <TableCell>
      <Checkbox checked={Boolean(row.attributes.active)} />
    </TableCell>
    <TableCell>{row.attributes.location}</TableCell>
    <TableCell>
      {moment(row.attributes.start_date).format(DATE_FORMAT)}
    </TableCell>
    <TableCell>{moment(row.attributes.end_date).format(DATE_FORMAT)}</TableCell>
    <TableCell>
      <LinkTo
        to={`/events/${row.attributes.title_slug}`}
        title={!row.attributes.active && 'Inactive'}
      >
        <IconButton>
          <Icon>link</Icon>
        </IconButton>
      </LinkTo>
    </TableCell>
    <TableCell>
      <LinkTo to={`/admin/events/edit/${row.id}`}>
        <IconButton>
          <Icon>edit</Icon>
        </IconButton>
      </LinkTo>
    </TableCell>
  </TableRow>
);

EventsTableRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default EventsTableRow;
