import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import ContainerPaper from '../ContainerPaper';

const StepContent = ({ classes, title, render }) => (
  <div className={classes.paperWrapper}>
    <ContainerPaper className={classes.paper}>
      <Typography gutterBottom variant="h5" component="h2">
        {title}
      </Typography>
      {render}
    </ContainerPaper>
  </div>
);

StepContent.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  render: PropTypes.node.isRequired,
};

export default StepContent;
