import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the fileUpload state domain
 */

const selectFileUploadDomain = state => state.get('fileUpload', initialState);

/**
 * Other specific selectors
 */

const makeSelectFile = () =>
  createSelector(selectFileUploadDomain, substate => substate.get('file'));

const makeSelectImageId = () =>
  createSelector(selectFileUploadDomain, substate => substate.toJS().imageId);

const makeSelectVisibility = () =>
  createSelector(
    selectFileUploadDomain,
    substate => substate.toJS().visibility,
  );

const makeSelectLoading = () =>
  createSelector(
    selectFileUploadDomain,
    substate => substate.toJS().showLoader,
  );

/**
 * Default selector used by FileUpload
 */

const makeSelectFileUpload = () =>
  createSelector(selectFileUploadDomain, substate => substate.toJS());

export default makeSelectFileUpload;
export {
  selectFileUploadDomain,
  makeSelectFile,
  makeSelectLoading,
  makeSelectImageId,
  makeSelectVisibility,
};
