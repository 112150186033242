export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 20, 25, 50];
export const TABLE_DEFAULT_PAGE_SIZE = 25;

export const BACKEND_URL_LOCAL = 'http://localhost:8040/api';
export const ECHO_URL_LOCAL = 'http://localhost:6001';
export const RECAPTCHA_SITEKEY =
  process.env.NODE_ENV === 'development'
    ? '6Lf1HTYpAAAAABmaYgnUHH3dXMF2xHbFpCnb3dr4'
    : '6LcZSMEUAAAAAA07rpW62TwUvw0FVq80yZDM3fIH';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'H:mm';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY H:mm';
export const BACKEND_DATE = 'YYYY-MM-DD';
export const BACKEND_DATE_TIME_FORMAT = 'YYYY-MM-DD H:mm:ss';
export const DATE_FORMAT_MASK = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const DRAWER_WIDTH = 310;
export const FOOTER_VSPACE = 100;
export const HEADER_HEIGHT = 80; // should be 64, but logo img is taller

export const slugify = text =>
  text
    .toLowerCase()
    .replace(/[\s_]+/g, '-')
    .replace(/[^a-z-]/g, '');

export const formatAmount = amount =>
  new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseFloat(amount));

export const storableItemTypes = {
  EUROPEAN_HUNT: { slug: 'european-hunts', title: 'European hunts' },
  NON_EUROPEAN_HUNT_OR_HOLIDAY: {
    slug: 'non-european-hunts',
    title: 'African, American, Asian Hunts, Holidays',
  },
  OBJECT: { slug: 'objects', title: 'Guns, Accessories, Art Pieces' },
};

export const itemTypes = {
  ...storableItemTypes,
  MY_BIDS: { slug: 'my-bids', title: 'My bids' },
  LIVE: { slug: 'live', title: 'Live Auction Items' },
};

export const ROLES_ALL_USER = [
  'SYSTEM_ADMINISTRATOR',
  'MEMBERSHIP_ADMINISTRATOR',
  'HEAD_OF_DELEGATION',
  'TROPHY_ADMINISTRATOR',
  'ARTEMIS',
  'YOUNG_OPINION',
  'AUTHENTICATED_USER',
];
export const ROLES_MEMBER_ONLY = ['AUTHENTICATED_USER'];
export const ROLES_ADMINS = [
  'SYSTEM_ADMINISTRATOR',
  'MEMBERSHIP_ADMINISTRATOR',
];
export const ROLES_ADMINS_AND_HEAD_OF_DELEGATION = [
  ...ROLES_ADMINS,
  'HEAD_OF_DELEGATION',
];
export const ROLES_MAINTAINERS = [
  ...ROLES_ADMINS_AND_HEAD_OF_DELEGATION,
  'ARTEMIS',
  'YOUNG_OPINION',
];
