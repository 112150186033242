import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the enumPage state domain
 */

const selectEnumPageDomain = state => state.get('enumPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by EnumPage
 */

const makeSelectEnumPage = () =>
  createSelector(selectEnumPageDomain, substate => substate.toJS());

const makeSelectEnumTypes = () =>
  createSelector(selectEnumPageDomain, substate => substate.toJS().enumTypes);

const makeSelectEnumValues = () =>
  createSelector(
    selectEnumPageDomain,
    substate => substate.toJS().enumValues.data,
  );

const makeSelectMetaData = () =>
  createSelector(
    selectEnumPageDomain,
    substate => substate.toJS().enumValues.meta,
  );

const makeSelectParams = () =>
  createSelector(selectEnumPageDomain, substate => substate.toJS().params);

const makeSelectEnumValue = () =>
  createSelector(
    selectEnumPageDomain,
    substate => substate.toJS().selectedEnum,
  );

const makeSelectEnumErrors = () =>
  createSelector(selectEnumPageDomain, substate => substate.toJS().enumErrors);

export default makeSelectEnumPage;
export {
  selectEnumPageDomain,
  makeSelectEnumTypes,
  makeSelectEnumValues,
  makeSelectParams,
  makeSelectMetaData,
  makeSelectEnumValue,
  makeSelectEnumErrors,
};
