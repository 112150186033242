import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSnackbarDomain = state => state.get('snackbar', initialState);

const makeSelectSnackbar = () =>
  createSelector(selectSnackbarDomain, substate => substate.toJS());

const makeSelectMessage = () =>
  createSelector(selectSnackbarDomain, substate => substate.toJS().message);

export default makeSelectSnackbar;
export { selectSnackbarDomain, makeSelectMessage };
