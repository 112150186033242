import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';

const TextFilter = props => (
  <FormControl>
    <InputLabel htmlFor={props.Id}>{props.label}</InputLabel>
    <Input
      id={props.Id}
      name={props.name}
      onChange={throttle(event => {
        event.persist();
        props.setFilter(event);
      }, 200)}
      startAdornment={
        <InputAdornment position="start">
          <Icon>search</Icon>
        </InputAdornment>
      }
    />
  </FormControl>
);

TextFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  Id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default TextFilter;
