import React from 'react';
import PropTypes from 'prop-types';

import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import { ROWS_PER_PAGE_OPTIONS } from 'utils/constants';

const PaginatedTableFooter = ({
  colSpan,
  meta,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageOptions,
}) => (
  <TableFooter>
    <TableRow>
      <TablePagination
        colSpan={colSpan}
        count={meta.pagination.total}
        rowsPerPage={meta.pagination.per_page}
        page={meta.pagination.current_page - 1}
        onChangePage={onChangePage}
        rowsPerPageOptions={rowsPerPageOptions || ROWS_PER_PAGE_OPTIONS}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </TableRow>
  </TableFooter>
);

PaginatedTableFooter.propTypes = {
  colSpan: PropTypes.number.isRequired,
  meta: PropTypes.object.isRequired,
  rowsPerPageOptions: PropTypes.array,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
};

export default PaginatedTableFooter;
