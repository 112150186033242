export const SET_INVOICE_ERROR = 'app/InvoiceForm/SET_INVOICE_ERROR';
export const SET_PAYMENT_ERROR = 'app/PaymentForm/SET_PAYMENT_ERROR';
export const SET_TRANSACTION_ERROR = 'app/PaymentForm/SET_TRANSACTION_ERROR';
export const SET_NOTIFICATION_ERROR = 'app/PaymentForm/SET_NOTIFICATION_ERROR';
export const SET_UPDATEFEE_ERROR = 'app/PaymentForm/SET_UPDATEFEE_ERROR';
export const SET_UPDATEDUEDATES_ERROR =
  'app/PaymentForm/SET_UPDATEDUEDATES_ERROR';

export const invoiceSkeleton = Object.freeze({
  id: null,
  type: 'invoices',
  attributes: {
    member_id: '',
    number: '',
    year: '',
    invoice_date: '',
    due_date: '',
    amount: '',
  },
});

export const invoiceErrors = {
  member_id: '',
  number: '',
  year: '',
  invoice_date: '',
  due_date: '',
  amount: '',
};

export const paymentSkeleton = Object.freeze({
  id: null,
  type: 'payments',
  attributes: {
    invoice_id: '',
    paid_amount: '',
    payment_date: '',
    payment_method: '',
  },
});

export const paymentErrors = {
  invoice_id: '',
  paid_amount: '',
  payment_date: '',
  payment_method: '',
};

export const transactionSkeleton = Object.freeze({
  id: null,
  type: 'transaction',
  attributes: {
    amount: '',
    invoice_id: '',
    balance: '',
  },
});

export const transactionErrors = {
  amount: '',
};

export const updateFeeSkeleton = Object.freeze({
  id: null,
  type: 'updatefees',
  attributes: {
    number: '',
    amount: '',
  },
});

export const updateFeeErrors = {
  number: '',
  amount: '',
};

export const updateDueDatesErrors = {
  number: '',
  due_date: '',
};

export const notificationSkeleton = Object.freeze({
  id: null,
  type: 'notification',
  attributes: {
    invoices: [],
    message: '',
  },
});

export const notificationErrors = {
  invoices: '',
  message: '',
};
