import React from 'react';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import MailIcon from '@material-ui/icons/Mail';
import DraftsIcon from '@material-ui/icons/Drafts';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import { ListItemSecondaryAction } from '@material-ui/core';

const styles = () => ({
  unread: {
    fontWeight: 'bold',
  },
  read: {
    fontWeight: 'normal',
  },
  button: {},
});

const NotificationListItem = props => {
  const {
    classes,
    notification,
    handleMenuClose,
    notificationRead,
    deleteNotification,
    history,
  } = props;
  const {
    attributes: { read_at: readAt, data },
  } = notification;
  const isRead = Boolean(readAt);
  return (
    <MenuItem
      onClick={() => {
        notificationRead(notification).then(res => {
          handleMenuClose();
          history.push(`/items/${data.item_id}/${data.auction_id}`);
          return res;
        });
      }}
      className={isRead ? classes.read : classes.unread}
    >
      <ListItemIcon>{isRead ? <DraftsIcon /> : <MailIcon />}</ListItemIcon>
      <ListItemText primary={data.title} secondary={data.item_name} />
      <ListItemSecondaryAction>
        <IconButton
          aria-label="Delete"
          onClick={() => {
            deleteNotification(notification);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </MenuItem>
  );
};

NotificationListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  notificationRead: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotificationListItem);
