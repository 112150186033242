import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withCrud from 'containers/ModelWrapper';
import ContainerPaper from 'components/ContainerPaper';
import { DATE_FORMAT } from '../../utils/constants';
import { PageRootContainerStyles } from '../../mui-theme';

const styles = () => ({
  root: PageRootContainerStyles,
});

class PaymentResponse extends React.Component {
  state = {};

  preLoadModel(props) {
    const { match } = props;
    this.props.showModel(match.params.id);
  }

  componentWillMount() {
    this.preLoadModel(this.props);
  }

  render() {
    const { classes } = this.props;

    const paymentResponse = this.props.model;

    if (isEmpty(paymentResponse)) {
      return null;
    }

    const success = paymentResponse.attributes.status === 'completed';

    return (
      <ContainerPaper className={classes.paper}>
        <Typography variant="h5" component="h4">
          {paymentResponse.attributes.title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary" gutterBottom>
          {moment(paymentResponse.attributes.start_date).format(DATE_FORMAT)}-{moment(
            paymentResponse.attributes.end_date,
          ).format(DATE_FORMAT)}
          <br />
          {paymentResponse.attributes.location}
        </Typography>
        {success && (
          <React.Fragment>
            <Typography variant="h4" component="h3" gutterBottom>
              Successful registration!
            </Typography>
            <Typography component="div" gutterBottom>
              Thank you for your purchase. Your transaction has been
              successfully and securely processed by CIC Payment System.
            </Typography>
          </React.Fragment>
        )}
        {!success && (
          <React.Fragment>
            <Typography variant="h4" component="h3" gutterBottom>
              Error occured!
            </Typography>
            <Typography component="div" gutterBottom>
              There was an error processing your transaction. Your registration
              has not been registered! Please try again or contact us.
            </Typography>
          </React.Fragment>
        )}
      </ContainerPaper>
    );
  }
}

PaymentResponse.defaultProps = {};

PaymentResponse.propTypes = {
  classes: PropTypes.object.isRequired,
  model: PropTypes.object,
  showModel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = () => ({});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(
  withStyles(styles)(
    withCrud('paymentResponse', 'payment-response', PaymentResponse, false),
  ),
);
