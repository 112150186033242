import {
  USER_LOGIN,
  LOAD_USER,
  INPUT_CHANGE,
  USER_LOGIN_ERROR,
  USER_LOGOUT,
} from './constants';

export const login = (email, password) => ({
  type: USER_LOGIN,
  email,
  password,
});

export const loadUser = user => ({
  type: LOAD_USER,
  user,
});

export const loginError = error => ({
  type: USER_LOGIN_ERROR,
  error,
});

export const inputChange = input => ({
  type: INPUT_CHANGE,
  input,
});

export const resetUser = () => ({
  type: USER_LOGOUT,
});
