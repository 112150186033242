import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import MoneyInput from 'components/MoneyInput';
import moment from 'moment';
import injectReducer from '../../utils/injectReducer';
import withCrud from '../ModelWrapper';
import reducer from './reducer';
import DateSelector from '../../components/DatePicker';
import { paymentErrors as resetErrors } from './constants';
import { setPaymentErrors } from './actions';
import { makeSelectPaymentFormErrors } from './selectors';
import { makeSelectEnumTypes, makeSelectEnumValues } from '../App/selectors';
import { BACKEND_DATE } from '../../utils/constants';

const initialState = {
  paymentDate: null,
};

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.setDate = this.setDate.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
  }

  componentWillMount() {
    this.setState(initialState);
    if (!isEmpty(this.props.payment) && isEmpty(this.props.model)) {
      this.setDefaultModel(this.props.payment);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.props.refreshList();
      this.props.closeForm();
      return;
    }

    if (!isEmpty(nextProps.payment) && isEmpty(nextProps.model)) {
      this.setState(initialState);
      this.setDefaultModel(nextProps.payment);
    }

    if (!isEqual(nextProps.model, this.props.model)) {
      if (nextProps.model.attributes.payment_date) {
        this.setState({
          paymentDate: moment(nextProps.model.attributes.payment_date.date),
        });
      }
    }

    if (!isEqual(this.props.models, nextProps.models)) {
      if (nextProps.models.meta) {
        const { model } = nextProps;
        this.props.setModel(model);
      }
    }
  }

  setDefaultModel(defaultModel) {
    const model = cloneDeep(defaultModel);
    this.props.setModel(model);
  }

  inputChanged(event) {
    const selectedPayment = cloneDeep(this.props.model);
    selectedPayment.attributes[event.target.name] = event.target.value;

    this.props.setModel(selectedPayment);
  }

  setDate(event) {
    const { state } = this;
    state[event.target.name] = event.target.value;
    this.setState(state);
  }

  closeForm() {
    this.setState(initialState);
    this.props.setModel({});
    this.props.setErrors(cloneDeep(resetErrors));
    this.props.closeForm();
  }

  submit() {
    const { setErrors, model } = this.props;

    model.attributes.payment_date = this.state.paymentDate
      ? this.state.paymentDate.format(BACKEND_DATE)
      : '';

    setErrors(cloneDeep(resetErrors));
    const paymentErrors = cloneDeep(resetErrors);

    const requiredFields = {
      paid_amount: 'Amount Payed',
      payment_date: 'Date of Payment',
      payment_method: 'Method',
    };

    Object.keys(requiredFields).forEach(field => {
      if (!model.attributes[field].toString().length) {
        paymentErrors[field] = `${requiredFields[field]} can not be empty`;
      }
    });

    if (!isEqual(paymentErrors, resetErrors)) {
      this.props.setErrors(paymentErrors);
      return this.forceUpdate();
    }

    this.setState(initialState);
    return this.props.submitModel();
  }

  render() {
    const { model, paymentErrors, enumTypes, enumValues } = this.props;
    const { paymentDate } = this.state;

    if (isEmpty(model)) {
      return null;
    }

    const getEnumTypeIndex = machineName =>
      enumTypes.attributes.enumtype[
        enumTypes.attributes.enumtype
          .map(e => e.machine_name)
          .indexOf(machineName)
      ].column_id;

    return (
      <Dialog open onClose={this.closeForm} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {model.id ? 'Edit Payment' : 'Add New Payment'}
        </DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submit();
          }}
        >
          <DialogContent>
            <MoneyInput
              model={model}
              modelErrors={paymentErrors}
              onChange={this.inputChanged}
              attribute="paid_amount"
              label="Amount Payed"
            />
            <FormControl
              fullWidth
              error={Boolean(paymentErrors.payment_date.length)}
            >
              <DateSelector
                label="Date of Payment"
                value={paymentDate}
                error={Boolean(paymentErrors.payment_date.length)}
                helperText={paymentErrors.payment_date}
                onChange={date => {
                  const event = {
                    target: {
                      name: 'paymentDate',
                      value: date,
                    },
                  };
                  this.setDate(event);
                }}
              />
            </FormControl>
            <FormControl
              aria-describedby="value-error-text"
              fullWidth
              error={Boolean(paymentErrors.payment_method.length)}
            >
              <InputLabel htmlFor="payment_method">Method</InputLabel>
              <Select
                id="payment_method"
                name="payment_method"
                value={model.attributes.payment_method}
                onChange={this.inputChanged}
              >
                {Object.keys(enumValues.attributes.enumvalue).map(
                  key =>
                    enumValues.attributes.enumvalue[key].enum_type_id ===
                      getEnumTypeIndex('payment_method') && (
                      <MenuItem
                        value={enumValues.attributes.enumvalue[key].column_id}
                        key={key}
                      >
                        {enumValues.attributes.enumvalue[key].value}
                      </MenuItem>
                    ),
                )}
              </Select>
              <FormHelperText id="value-error-text">
                {paymentErrors.payment_method}
              </FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeForm}>Cancel</Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

PaymentForm.propTypes = {
  payment: PropTypes.object,
  model: PropTypes.object,
  models: PropTypes.object,
  closeForm: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  paymentErrors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  enumTypes: PropTypes.object,
  enumValues: PropTypes.object,
  submitModel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  paymentErrors: makeSelectPaymentFormErrors(),
  enumTypes: makeSelectEnumTypes(),
  enumValues: makeSelectEnumValues(),
});

const mapDispatchToProps = dispatch => ({
  setErrors: errors => dispatch(setPaymentErrors(errors)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'paymentForm', reducer });

export default compose(
  withReducer,
  withConnect,
)(withCrud('paymentsPage', 'payments', PaymentForm, false));
