/*
 *
 * Snackbar actions
 *
 */

import { SET_MESSAGE } from './constants';

export const setMessage = message => ({
  type: SET_MESSAGE,
  message,
});
