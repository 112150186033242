import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the generateInvoicePage state domain
 */

const InvoiceSummaryPageDomain = state =>
  state.get('invoiceSummaryPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by InvoiceGeneratePage
 */

const makeSelectInvoiceSummaryPage = () =>
  createSelector(InvoiceSummaryPageDomain, substate => substate.toJS());
const makeSelectSelectedInvoices = () =>
  createSelector(InvoiceSummaryPageDomain, substate =>
    substate.get('selectedInvoices').toJS(),
  );
export default makeSelectInvoiceSummaryPage;
export { InvoiceSummaryPageDomain, makeSelectSelectedInvoices };
