/*
 *
 * Layout actions
 *
 */

import { DEFAULT_ACTION, SET_SIDEBAR_OPEN } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export const setSidebarOpen = sidebarIsOpen => ({
  type: SET_SIDEBAR_OPEN,
  sidebarIsOpen,
});
