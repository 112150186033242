export const LOAD_FUNCTIONAL_AREAS =
  'app/FunctionalAreaPage/LOAD_FUNCTIONAL_AREAS';
export const LOAD_FUNCTIONAL_AREAS_SUCCESS =
  'app/FunctionalAreaPage/LOAD_FUNCTIONAL_AREAS_SUCCESS';
export const LOAD_FUNCTIONAL_AREAS_ERROR =
  'app/FunctionalAreaPage/LOAD_FUNCTIONAL_AREAS_ERROR';

export const SET_PAGE_NUMBER = 'app/FunctionalAreaPage/SET_PAGE_NUMBER';
export const SET_ROWS_PER_PAGE = 'app/FunctionalAreaPage/SET_ROWS_PER_PAGE';

export const SET_FUNCTIONAL_AREA = 'app/FunctionalAreaPage/SET_FUNCTIONAL_AREA';
export const SET_FUNCTIONAL_AREA_ERROR =
  'app/FunctionalAreaPage/SET_FUNCTIONAL_AREA_ERROR';
export const SUBMIT_FUNCTIONAL_AREA =
  'app/FunctionalAreaPage/SUBMIT_FUNCTIONAL_AREA';
export const SUBMIT_FUNCTIONAL_AREA_SUCCESS =
  'app/FunctionalAreaPage/SUBMIT_FUNCTIONAL_AREA_SUCCESS';
export const SUBMIT_FUNCTIONAL_AREA_ERROR =
  'app/FunctionalAreaPage/SUBMIT_FUNCTIONAL_AREA_ERROR';

export const functionalAreaSkeleton = Object.freeze({
  id: null,
  type: 'functional_areas',
  attributes: {
    area_type: '',
    active: 1,
    name: '',
    member_code: '',
    phone_prefix: '',
  },
});

export const functionalAreaErrors = {
  area_type: '',
  name: '',
  member_code: '',
  phone_prefix: '',
};
