import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const ConfirmDialog = props => (
  <Dialog
    open={props.open}
    onClose={props.onClose}
    onBackdropClick={props.onClose}
  >
    <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {props.description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.cancelAction} color="primary">
        {props.cancelText}
      </Button>
      <Button onClick={props.confirmAction} color="primary" autoFocus>
        {props.confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.defaultProps = {
  open: false,
  confirmText: 'Ok',
  cancelText: 'Cancel',
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  cancelAction: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};
export default withStyles(null, { withTheme: true })(ConfirmDialog);
