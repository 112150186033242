import { fromJS } from 'immutable';
import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  DELETE_FILE,
  LOAD_IMAGE,
  RESET_STORE,
  SET_VISIBILITY,
} from './constants';

export const initialState = fromJS({
  file: null,
  model: {},
  showLoader: false,
  imageId: 0,
});

function fileUploadReducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE:
      return state.set('file', action.file).set('showLoader', true);
    case UPLOAD_FILE_SUCCESS:
      return state.set('file', null).set('showLoader', false);
    case UPLOAD_FILE_ERROR:
      return state.set('file', null).set('showLoader', false);
    case DELETE_FILE:
      return state.set('file', null).set('showLoader', false);
    case LOAD_IMAGE:
      return state.set('imageId', parseInt(action.id, 10)).set('loading', true);
    case RESET_STORE:
      return state
        .set('file', null)
        .set('model', {})
        .set('showLoader', false)
        .set('imageId', 0);
    case SET_VISIBILITY:
      return state.set('visibility', action.visibility);
    default:
      return state;
  }
}

export default fileUploadReducer;
