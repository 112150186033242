import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Link as LinkTo } from 'react-router-dom';

import Typography from '@material-ui/core/Typography/Typography';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { DATE_TIME_FORMAT } from 'utils/constants';
import Authorization from 'utils/authorization';
import { makeRolesSelector } from '../AuthButton/selectors';
import { userHasRole } from '../../utils/userHasRole';

const styles = theme => ({
  card: {
    minWidth: 275,
    maxWidth: theme.breakpoints.values.md,
    justifySelf: 'center',
    margin: '24px auto',
  },
  actions: {
    display: 'flex',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  contentContainer: { maxWidth: '100%' },
});

const CanDelete = Authorization([
  'SYSTEM_ADMINISTRATOR',
  'MEMBERSHIP_ADMINISTRATOR',
]);

const Buttons = ({ model, openDialog }) => (
  <span>
    <LinkTo to={`/admin/news/edit/${model.id}`}>
      <IconButton size="small">
        <EditIcon />
      </IconButton>
    </LinkTo>
    <IconButton size="small" onClick={openDialog}>
      <DeleteIcon />
    </IconButton>
  </span>
);
Buttons.propTypes = {
  model: PropTypes.object.isRequired,
  openDialog: PropTypes.func.isRequired,
};

const ActionButtons = CanDelete(Buttons);

const NewsItem = ({ roles, classes, model, openDialog }) => {
  if (isEmpty(model)) {
    return null;
  }

  const isUserAuthenticated = userHasRole(roles, ['AUTHENTICATED_USER']);
  const newsViewLink = isUserAuthenticated
    ? `/news/view/${model.id}`
    : `/auction-news/view/${model.id}`;

  return (
    <Card className={classes.card}>
      <CardHeader
        action={<ActionButtons model={model} openDialog={openDialog} />}
        title={<LinkTo to={newsViewLink}>{model.attributes.title}</LinkTo>}
        subheader={moment(model.attributes.publish_time).format(
          DATE_TIME_FORMAT,
        )}
      />
      <CardContent>
        <Typography
          component="div"
          className={classes.contentContainer}
          dangerouslySetInnerHTML={{
            __html: model.attributes.preview_content
              ? model.attributes.preview_content
              : model.attributes.content,
          }}
        />
      </CardContent>
    </Card>
  );
};

NewsItem.propTypes = {
  classes: PropTypes.object,
  model: PropTypes.object.isRequired,
  openDialog: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  roles: makeRolesSelector(),
});

export default connect(mapStateToProps)(withStyles(styles)(NewsItem));
