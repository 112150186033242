/*
 *
 * News constants
 *
 */

export const DEFAULT_ACTION = 'app/News/DEFAULT_ACTION';
export const SET_NEWS_ERROR = 'app/News/SET_NEWS_ERROR';

export const newsSkeleton = Object.freeze({
  id: null,
  type: 'News',
  attributes: {
    title: '',
    news_category: '',
    publish_time: '',
    preview_content: '',
    content: '',
    is_public: true,
    send_notification: true,
  },
});

export const newsErrors = {
  title: '',
  news_category: '',
  publish_time: '',
  preview_content: '',
  content: '',
  send_notification: true,
};
