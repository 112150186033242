import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the layout state domain
 */

const selectLayoutDomain = state => state.get('layout', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Layout
 */

const makeSelectLayout = () =>
  createSelector(selectLayoutDomain, substate => substate.toJS());

const makeSelectSidebarIsOpen = () =>
  createSelector(selectLayoutDomain, substate => substate.toJS().sidebarIsOpen);

export default makeSelectLayout;
export { selectLayoutDomain, makeSelectSidebarIsOpen };
