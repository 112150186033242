import { DEFAULT_ACTION, SET_PASSWORD_RESET_ERROR } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export const setPasswordResetErrors = errors => ({
  type: SET_PASSWORD_RESET_ERROR,
  errors,
});
