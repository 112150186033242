export const SET_MESSAGE = 'app/Snackbar/SET_MESSAGE';
export const MESSAGE_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

export const messageSkeleton = {
  type: MESSAGE_TYPES.SUCCESS,
  value: '',
};
