import { cloneDeep } from 'lodash';
import { fromJS } from 'immutable';
import {
  DEFAULT_ACTION,
  SET_PASSWORD_RESET_ERROR,
  passwordResetErrors,
} from './constants';

export const initialState = fromJS({
  passwordResetErrors: cloneDeep(passwordResetErrors),
  isRequestSent: false,
});

function passwordResetReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PASSWORD_RESET_ERROR:
      return state.set('passwordResetErrors', action.errors);
    case DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export default passwordResetReducer;
