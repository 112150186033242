import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, find } from 'lodash';
import Select from 'react-select';

import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import withCrud from 'containers/ModelWrapper';
import { DATE_TIME_FORMAT } from 'utils/constants';

const styles = theme => ({
  select: {
    margin: '15px 30px 15px 0px',
    minWidth: 350,
    [theme.breakpoints.only('xs')]: {
      minWidth: 240,
    },
  },
});

const liveFilter = listLiveAuctions => item =>
  listLiveAuctions === !!item.attributes.live;

class AuctionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.selectAuction = this.selectAuction.bind(this);
  }

  selectAuction(event) {
    this.props.onChange(find(this.props.models.data, ['id', event.value]));
  }

  componentWillMount() {
    this.props.setRowsPerPage({
      target: { value: 10000 },
    });
    this.props.setSorting({}, 'start_time', 'desc');

    this.props.loadModels();
  }

  componentWillReceiveProps(nextProps) {
    if (
      isEmpty(this.props.selectedAuction) &&
      !isEmpty(nextProps.selectedAuction) &&
      !isEmpty(this.props.requestedAuctionId)
    ) {
      this.selectAuction({ value: this.props.requestedAuctionId });
    }

    if (isEmpty(nextProps.selectedAuction) && !isEmpty(nextProps.models.data)) {
      this.props.onChange(
        nextProps.models.data.filter(liveFilter(nextProps.listLiveAuctions))[0],
      );
    }
  }

  formatDate = date => moment(date).format(DATE_TIME_FORMAT);

  render() {
    const { classes, models, selectedAuction, listLiveAuctions } = this.props;
    const { data, meta } = models;
    if (isEmpty(meta)) {
      return null;
    }
    const options = data.filter(liveFilter(listLiveAuctions)).map(item => ({
      label: `${item.attributes.name} ${this.formatDate(
        item.attributes.start_time,
      )} - ${this.formatDate(item.attributes.end_time)}`,
      value: item.id,
    }));

    return (
      <div className={classes.select}>
        <InputLabel>Auction</InputLabel>
        <Select
          onChange={this.selectAuction}
          options={options}
          value={find(options, ['value', selectedAuction.id])}
        />
      </div>
    );
  }
}

AuctionFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  requestedAuctionId: PropTypes.string,
  models: PropTypes.object.isRequired,
  loadModels: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  selectedAuction: PropTypes.object.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  listLiveAuctions: PropTypes.bool,
};

export default withStyles(styles)(
  withCrud('auctionFilter', 'auctions', AuctionFilter, false, false),
);
