/*
 *
 * InvoiceGeneratePage constants
 *
 */

export const GET_INVOICED_COUNT = 'app/InvoiceGeneratePage/GET_INVOICED_COUNT';
export const GET_INVOICED_COUNT_SUCCESS =
  'app/InvoiceGeneratePage/GET_INVOICED_COUNT_SUCCESS';
export const SUBMIT_FORM = 'app/InvoiceGeneratePage/SUBMIT_FORM';
