import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { isEmpty } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

import { actionPreProcessor, setModel } from 'containers/ModelWrapper/actions';
import withCrud from 'containers/ModelWrapper';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { DAEMON } from 'utils/constants';
import { makeSelectLoading } from './selectors';
import reducer from './reducer';
import saga from './saga';
import {
  deleteFile,
  fileUpload,
  loadImage,
  resetStore,
  setVisibility,
} from './actions';

const styles = {
  card: {
    width: 300,
  },
  media: {
    height: 140,
  },
  loader: {
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  button: {
    display: 'flex',
    flex: 1,
  },
  error: {
    color: '#f44336',
  },
};

export class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.fileUpload = this.fileUpload.bind(this);
  }

  submitFile = () => {
    this.props.attachFile(this.props.model);
    this.props.setModel({});
  };

  componentWillMount() {
    this.props.setVisibility(this.props.visibility);
    if (parseInt(this.props.value, 10)) {
      this.props.loadImage(parseInt(this.props.value, 10));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoSubmit) {
      if (!isEmpty(this.props.model) && isEmpty(prevProps.model)) {
        this.submitFile();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (parseInt(nextProps.value, 10) && isEmpty(this.props.model)) {
      this.props.loadImage(parseInt(nextProps.value, 10));
      return;
    }
    if (nextProps.value === null && !isEmpty(nextProps.model)) {
      // this.props.setModel({});
    }
  }

  componentWillUnmount() {
    this.props.resetFile();
  }

  fileUpload(event) {
    const { files } = event.target;
    if (files && files.length) {
      this.props.deleteFile();
      Array.from(files).map(file => this.props.fileUpload(file));
    }
  }

  render() {
    const {
      classes,
      model,
      showLoader,
      error,
      autoSubmit,
      standalone,
    } = this.props;

    const Error = (
      <div className={classes.loader}>
        <span className={classes.error}>{error}</span>
      </div>
    );
    const Loader = (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
    const Inputs = (
      <React.Fragment>
        <input
          accept={this.props.accept}
          className={classes.input}
          id="raised-button-file"
          multiple
          type="file"
          style={{ opacity: 0, width: 0 }}
          onChange={this.fileUpload}
        />
        <label htmlFor="raised-button-file">
          <Button component="span" className={classes.button}>
            {isEmpty(this.props.model) ? 'Add file' : 'Update file'}
          </Button>
        </label>
        {!autoSubmit && (
          <Button
            className={classes.button}
            onClick={this.submitFile}
            disabled={isEmpty(this.props.model)}
          >
            Submit <CloudUploadIcon />
          </Button>
        )}
      </React.Fragment>
    );
    if (standalone) {
      return (
        <React.Fragment>
          {showLoader && Loader}
          {Inputs}
        </React.Fragment>
      );
    }
    return (
      <Card className={classes.card}>
        {!isEmpty(model) && (
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={model.attributes.dimensions.thumbnail}
            />
          </CardActionArea>
        )}
        {showLoader && Loader}
        {isEmpty(model) && !showLoader && Boolean(error.length) && Error}
        <CardActions>{Inputs}</CardActions>
      </Card>
    );
  }
}

FileUpload.defaultProps = {
  attachFile: () => {},
  value: 0,
  error: '',
  accept: 'image/*',
  visibility: 'public',
  autoSubmit: true,
  standalone: false,
};

FileUpload.propTypes = {
  attachFile: PropTypes.func,
  fileUpload: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  showLoader: PropTypes.bool.isRequired,
  deleteFile: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loadImage: PropTypes.func.isRequired,
  resetFile: PropTypes.func.isRequired,
  error: PropTypes.string,
  accept: PropTypes.string,
  setModel: PropTypes.func.isRequired,
  visibility: PropTypes.string,
  setVisibility: PropTypes.func.isRequired,
  autoSubmit: PropTypes.bool,
  standalone: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  showLoader: makeSelectLoading(),
});

const mapDispatchToProps = dispatch => ({
  fileUpload: file => dispatch(fileUpload(file)),
  deleteFile: () => dispatch(deleteFile()),
  loadImage: id => dispatch(loadImage(id)),
  resetFile: () => {
    dispatch(actionPreProcessor(setModel({}), 'fileUploader'));
    dispatch(resetStore());
  },
  setVisibility: visibility => dispatch(setVisibility(visibility)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'fileUpload', reducer });
const withSaga = injectSaga({ key: 'fileUpload', saga, mode: DAEMON });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(withStyles(styles)(withCrud('fileUploader', 'files', FileUpload, false)));
