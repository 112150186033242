export const registrationRequestSkeleton = Object.freeze({
  id: null,
  type: 'registration-requests',
  attributes: {
    status: 'NEW',
    preferred_language: '',
    first_title: '',
    second_title: '',
    first_name: '',
    last_name: '',
    gender: '',
    delegation: '',
    date_of_birth: '',
    member_type: '',
    expertise: '',
    postal_country: '',
    postal_city: '',
    postal_zip: '',
    postal_address: '',
    secondary_country: '',
    secondary_city: '',
    secondary_zip: '',
    secondary_address: '',
    private_tel: '',
    private_fax: '',
    private_email: '',
    private_mobile: '',
    private_skype: '',
    office_tel: '',
    office_fax: '',
    office_email: '',
    comments: '',
  },
});

export const registrationRequestErrors = {
  preferred_language: '',
  first_title: '',
  second_title: '',
  first_name: '',
  last_name: '',
  gender: '',
  delegation: '',
  date_of_birth: '',
  member_type: '',
  expertise: '',
  postal_country: '',
  postal_city: '',
  postal_zip: '',
  postal_address: '',
  secondary_country: '',
  secondary_city: '',
  secondary_zip: '',
  secondary_address: '',
  private_tel: '',
  private_fax: '',
  private_email: '',
  private_mobile: '',
  private_skype: '',
  office_tel: '',
  office_fax: '',
  office_email: '',
  comments: '',
};
