import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import withCrud from 'containers/ModelWrapper';
import { PageRootContainerStyles, paperWrapperStyles } from '../../mui-theme';
import ContainerPaper from '../../components/ContainerPaper';

const styles = () => ({
  root: PageRootContainerStyles,
  paperWrapper: paperWrapperStyles,
});

class AuctionHome extends React.Component {
  componentWillMount() {
    this.props.showModel('auction-home');
  }

  render() {
    const { model, classes } = this.props;

    if (isEmpty(model)) {
      return null;
    }

    return (
      <div className={[classes.root, classes.paperWrapper]}>
        <Helmet>
          <title>{model.attributes.title}</title>
        </Helmet>
        <ContainerPaper className={classes.paper}>
          <Typography
            className="content-page"
            component="div"
            dangerouslySetInnerHTML={{
              __html: model.attributes.content,
            }}
          />
        </ContainerPaper>
      </div>
    );
  }
}

AuctionHome.propTypes = {
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withCrud('AuctionHome', 'pages', AuctionHome, false),
);
