import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isEmpty, cloneDeep, isEqual } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';

import withCrud from 'containers/ModelWrapper';

import TinyMCE from 'components/TinyMCE';
import { PageRootContainerStyles, paperWrapperStyles } from '../../mui-theme';
import ContainerPaper from '../../components/ContainerPaper';

const styles = () => ({
  root: PageRootContainerStyles,
  paperWrapper: paperWrapperStyles,
});

class MemberHomeEditPage extends React.Component {
  componentWillMount() {
    this.props.showModel('member-home');
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.model, this.props.model) &&
      isEmpty(nextProps.model)
    ) {
      this.props.history.push(`/member-home`);
    }
  }

  handleEditorChange(event) {
    const selectedContent = cloneDeep(this.props.model);
    selectedContent.attributes.content = event.target.getContent();
    this.props.setModel(selectedContent);
  }

  onCancel() {
    this.props.setModel({});
  }

  onSubmit() {
    const selectedContent = cloneDeep(this.props.model);
    selectedContent.id = isEmpty(selectedContent.id) ? null : 'member-home';
    this.props.setModel(selectedContent);
    this.props.submitModel();
  }

  render() {
    const { model, classes } = this.props;

    if (!model || isEmpty(model)) {
      return null;
    }

    return (
      <div className={[classes.root, classes.paperWrapper]}>
        <Helmet>
          <title>Edit members home page</title>
        </Helmet>
        <ContainerPaper className={classes.paper}>
          <Typography variant="h5">Edit members home page</Typography>
          <TinyMCE
            Id="id"
            initialValue={model.attributes.content}
            onChange={event => this.handleEditorChange(event)}
          />
          <FormGroup row>
            <Button
              color="secondary"
              className={classes.button}
              onClick={() => this.onCancel()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => this.onSubmit()}
            >
              Save
            </Button>
          </FormGroup>
        </ContainerPaper>
      </div>
    );
  }
}

MemberHomeEditPage.propTypes = {
  model: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  showModel: PropTypes.func.isRequired,
  setModel: PropTypes.func.isRequired,
  submitModel: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withCrud('memberHomeEditPage', 'pages', MemberHomeEditPage, false),
);
