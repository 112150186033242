import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import FormControl from '@material-ui/core/FormControl/FormControl';

import withCrud from 'containers/ModelWrapper';

class UserFilter extends React.Component {
  constructor(props) {
    super(props);
    this.selectUser = this.selectUser.bind(this);
  }

  selectUser(event) {
    this.props.onChange(
      find(this.props.models.data, ['id', event.target.value]),
    );
  }

  componentWillMount() {
    this.props.setFilter({
      target: {
        name: 'role',
        value: 'AUTHENTICATED_USER',
      },
    });
    this.props.setFilter({
      target: {
        name: 'status',
        value: 'ACTIVE',
      },
    });
    this.props.setSorting({}, 'name', 'asc');
  }

  render() {
    const { models, value, bidErrors } = this.props;
    const { data } = models;

    const options = data.map(item => ({
      label: `${[
        item.attributes.first_name,
        item.attributes.particle,
        item.attributes.last_name,
      ].join(' ')} (${item.attributes.name})`,
      value: item.id,
    }));
    const val = find(options, ['value', value]);
    return (
      <FormControl fullWidth error={Boolean(bidErrors.user_id.length)}>
        <InputLabel htmlFor="user_id">On behalf of</InputLabel>
        <Select
          id="user_id"
          name="user_id"
          value={val}
          menuPosition="fixed"
          placeholder="On behalf of (type for users)"
          onInputChange={inputValue => {
            if (!inputValue) {
              return;
            }
            const event = {
              target: {
                name: 'name',
                value: inputValue,
              },
            };
            this.props.setFilter(event);
            this.props.loadModels();
          }}
          onChange={inputValue => {
            const event = {
              target: {
                name: 'name',
                value: inputValue ? inputValue.value : null,
              },
            };
            this.selectUser(event);
          }}
          options={options}
        />
        <FormHelperText>{bidErrors.user_id}</FormHelperText>
      </FormControl>
    );
  }
}

UserFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
  loadModels: PropTypes.func.isRequired,
  value: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  bidErrors: PropTypes.object,
};

export default withCrud('userBidFilter', 'users', UserFilter, false);
